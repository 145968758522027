const REQUESTED_TRAVELLER_CHANGES_STORAGE_KEY = 'requestedTravellerChanges';
const REQUESTED_PARTNER_CHANGES_STORAGE_KEY =
    'requestedContractualPartnerChanges';

const saveNewEntityToLocalStorage = ({
    entity,
    dateTime,
    bookingId,
    storageKey,
    localStorage,
}) => {
    const data = {
        timestamp: dateTime,
        data: entity,
    };
    localStorage.setItem(`${storageKey}-${bookingId}`, JSON.stringify(data));
};

const retrieveEntityRequestFromLocalStorage = ({
    bookingId,
    storageKey,
    localStorage,
}) => {
    const locStorageData = localStorage.getItem(`${storageKey}-${bookingId}`);
    if (!locStorageData) {
        return null;
    }
    try {
        const parsedDataChangeRequested = JSON.parse(locStorageData);
        return parsedDataChangeRequested.timestamp &&
            parsedDataChangeRequested.data
            ? parsedDataChangeRequested
            : null;
    } catch (e) {
        return null;
    }
};

const removeRequestFromLocalStorage = ({
    bookingId,
    storageKey,
    localStorage,
}) => {
    localStorage.removeItem(`${storageKey}-${bookingId}`);
};

const isEntityDataSame = (originalEntity, newEntity) => {
    const same = JSON.stringify(originalEntity) === JSON.stringify(newEntity);
    return same;
};

const hasItBeen48HoursSinceChange = (dateTime, timestamp) => {
    const timestampDate = new Date(timestamp);
    // changing timestampDate to message expiry time
    timestampDate.setHours(timestampDate.getHours() + 48);
    return timestampDate <= dateTime;
};

const shouldDataChangeRequestMessageBeShown = ({
    dateTime,
    bookingId,
    currentEntity,
    storageKey,
    localStorage,
}) => {
    const locStorageData = retrieveEntityRequestFromLocalStorage({
        bookingId,
        localStorage,
        storageKey,
    });
    if (!locStorageData) {
        return false;
    }

    const isMessageNeeded =
        !isEntityDataSame(currentEntity, locStorageData.data) &&
        !hasItBeen48HoursSinceChange(dateTime, locStorageData.timestamp);

    if (!isMessageNeeded) {
        removeRequestFromLocalStorage({ bookingId, localStorage, storageKey });
    }
    return isMessageNeeded;
};

export {
    saveNewEntityToLocalStorage,
    retrieveEntityRequestFromLocalStorage,
    removeRequestFromLocalStorage,
    shouldDataChangeRequestMessageBeShown,
    isEntityDataSame,
    hasItBeen48HoursSinceChange,
    REQUESTED_TRAVELLER_CHANGES_STORAGE_KEY,
    REQUESTED_PARTNER_CHANGES_STORAGE_KEY,
};
