import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { RentalcarBookingCard } from './RentalcarBookingCard';
import { RentalcarLinkCard } from './RentalcarLinkCard';
import { useFetchRentalCarBooking } from './useFetchRentalCarBooking';
import fetchRentalcarOfferCardState from '../../fetchers/fetchRentalcarOffercardState';
import { RentalcarOfferCard } from './RentalcarOfferCard';

export const getUtmParameters = (hasHcTravel, hasMietwagen) => {
    const campaign = `Travel${hasHcTravel ? 'Yes' : 'No'}Car${hasMietwagen ? 'Yes' : 'No'}`;
    return `?utm_source=mHC&utm_medium=Hub&utm_campaign=${campaign}`;
};

/*
 * The card may display an active or upcoming rental car booking, prioritizing bookings
 * that are the same time as a recent HC Booking. If no related booking exists,
 * the next upcoming booking will be shown.
 * If there is a Hc Package Booking but no Rentalcar booking we will display an offerCard,
 * creating an actual search and offers with the data from the hcTravel
 * If no booking exists or all bookings are in the past, we will show a card that
 * links to the index page of HolidayCheck - Mietwagen.
 *
 * */
const RentalcarCard = (props, context) => {
    const { trackEvent } = context;
    const { bookings, hcTravel } = useFetchRentalCarBooking(context);

    const currentBooking = bookings[0] || null;
    const hasHcTravel = Boolean(hcTravel);
    const parameters = getUtmParameters(hasHcTravel, currentBooking !== null);

    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        const fetchStatus = async () => {
            const status = await fetchRentalcarOfferCardState(context);

            setIsActive(status);
        };

        fetchStatus();
    }, []);

    const eventCategory = 'mHCHub - Rentalcar';

    if (
        isActive &&
        currentBooking === null &&
        hcTravel &&
        hcTravel.booking.travelkind === 'PACKAGE'
    ) {
        return h(RentalcarOfferCard, {
            hcTravel,
            context,
            utmParameter: {
                // prettier-ignore
                'utm_source': 'mHC', // utm parameters must be snake_case, prettier does not allow quoted keys, eslint needs camelCase keys ...
                // prettier-ignore
                'utm_medium': 'Hub', // utm parameters must be snake_case, prettier does not allow quoted keys, eslint needs camelCase keys ...
                // prettier-ignore
                'utm_campaign': 'PackageYesCarNo', // utm parameters must be snake_case, prettier does not allow quoted keys, eslint needs camelCase keys ...
            },
            eventCategory,
        });
    }

    return currentBooking !== null
        ? h(RentalcarBookingCard, {
              id: currentBooking.id,
              status: currentBooking.status,
              destinationName: currentBooking.carOfferDestination,
              pickupDatetime: currentBooking.pickupDatetime,
              dropoffDatetime: currentBooking.dropoffDatetime,
              carOfferDestinationInfo: currentBooking.carOfferDestinationRegion,
              carOfferPictureUrl: currentBooking.carOfferPictureUrl,
              transferType: currentBooking.transferType,
              trackEvent,
              eventCategory,
          })
        : h(RentalcarLinkCard, {
              bubbleImageLink: `${context.config.assetsPath}/hcMietwagen/CarBubble.png`,
              carImageLink:
                  '/mietwagen/assets-image/180x115/f080a9f0-f3c9-433a-bc27-8180dcd6e561.png',
              trackEvent,
              parameters,
          });
};

export default RentalcarCard;

RentalcarCard.contextTypes = {
    config: PropTypes.object,
    trackEvent: PropTypes.func,
    fetch: PropTypes.func,
    getCurrentDate: PropTypes.func,
    window: PropTypes.object.isRequired,
};
