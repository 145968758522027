import h from 'react-hyperscript';
import glamorous from 'glamorous';

import { pxToRem } from '../../styles/unitConverter';
import { extraTinyFont, tinyFont } from '../../styles/fonts';
import {
    smallDistance,
    mediumDistance,
    largeDistance,
    xsmallDistance,
} from '../../styles/distances';
import { applyOnMobile } from '../../styles/mediaQueries';
import colors from '../../styles/colors';
import Comment from '../icons/Comment';
import Eye from '../icons/Eye';
import ThumbsUp from '../icons/ThumbsUp';
import ReviewSelfService from './ReviewSelfService';

const {
    reviewApprovedColor,
    reviewApprovedBackgroundColor,
    reviewPendingColor,
    reviewPendingBackgroundColor,
    reviewRejectedColor,
    reviewRejectedBackgroundColor,
    reviewFooterBorderColor,
    linkColor,
    additionalInfoIconColor,
} = colors;

const labelOfStatus = {
    CHECK: 'In Prüfung',
    CONSULTATION: 'Bitte E-Mail beachten',
    VERIFICATION: 'Bitte E-Mail beachten',
    WAITING: 'Bitte E-Mail bestätigen',
    REJECTED: 'Nicht veröffentlicht',
};

const getStatusProps = (status) => {
    const statusLabel = labelOfStatus[status];
    if (statusLabel) {
        const { statusHelpText, statusStyle } =
            status === 'REJECTED'
                ? {
                      statusHelpText:
                          'Warum werden Bewertungen nicht veröffentlicht?',
                      statusStyle: {
                          backgroundColor: reviewRejectedBackgroundColor,
                          color: reviewRejectedColor,
                      },
                  }
                : {
                      statusHelpText: 'Wann werden Bewertungen veröffentlicht?',
                      statusStyle: {
                          backgroundColor: reviewPendingBackgroundColor,
                          color: reviewPendingColor,
                      },
                  };

        return {
            statusLabel,
            statusStyle,
            statusHelpText,
        };
    }

    return {
        statusLabel: 'Veröffentlicht',
    };
};

const ReviewFooterContainer = glamorous.div({
    display: 'flex',
    clear: 'both',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: `solid 1px ${reviewFooterBorderColor}`,
    padding: `${smallDistance} 0`,
    margin: `0 ${mediumDistance}`,

    [applyOnMobile]: {
        textAlign: 'center',
        flexDirection: 'column',
    },
});
ReviewFooterContainer.displayName = 'ReviewFooterContainer';

const Status = glamorous.div(tinyFont, {
    display: 'inline-block',
    borderRadius: pxToRem(9),
    padding: `0 ${pxToRem(9)}`,
    fontWeight: 700,
    lineHeight: pxToRem(18),
    backgroundColor: reviewApprovedBackgroundColor,
    color: reviewApprovedColor,
    [applyOnMobile]: {
        marginTop: smallDistance,
        marginBottom: smallDistance,
        order: 2,
    },
});
Status.displayName = 'Status';

const customIconStyle = {
    width: pxToRem(18),
    color: additionalInfoIconColor,
};

const AdditionalInfo = glamorous.div(extraTinyFont, {
    display: 'flex',

    [applyOnMobile]: {
        order: 1,
    },
});
AdditionalInfo.displayName = 'AdditionalInfo';

const SelfService = glamorous.div({
    [applyOnMobile]: {
        order: 3,
    },
});
SelfService.displayName = 'SelfService';

const AdditionalItemLabel = glamorous.span({
    marginLeft: xsmallDistance,
    verticalAlign: 'super',
});
AdditionalItemLabel.displayName = 'AdditionalItemLabel';

const AdditionalItem = glamorous.div({
    marginRight: largeDistance,
});
AdditionalItem.displayName = 'AdditionalItem';

const StatusHelp = glamorous.a(tinyFont, {
    color: linkColor,
    textDecoration: 'none',
});
StatusHelp.displayName = 'StatusHelp';

const renderSelfService = (review, status) => {
    if (status !== 'REJECTED' && status !== 'VERIFICATION') {
        return h(SelfService, {}, h(ReviewSelfService, review));
    }
    return null;
};

const renderStatusHelp = (statusHelpText) =>
    statusHelpText &&
    h(
        StatusHelp,
        {
            href:
                'https://hilfe.holidaycheck.com/de/category/bewertungen-bilder/' +
                'ich-habe-bereits-eine-bewertung-geschrieben-bzw-fotos-hochgeladen-und-habe-nun-eine-frage-dazu',
            target: '_blank',
        },
        statusHelpText,
    );

const renderAdditionalInfo = (
    status,
    ownerComment,
    helpfulCount,
    viewCount,
) => {
    return (
        status === 'PUBLISHED' &&
        h(AdditionalInfo, [
            ownerComment &&
                h(AdditionalItem, [
                    h(Comment, { css: customIconStyle }),
                    h(AdditionalItemLabel, ['Hotelierkommentar']),
                ]),
            h(AdditionalItem, [
                h(ThumbsUp, { css: customIconStyle }),
                h(AdditionalItemLabel, [`${helpfulCount} Hilfreich`]),
            ]),
            h('div', [
                h(Eye, { css: customIconStyle }),
                h(AdditionalItemLabel, [`${viewCount} Ansichten`]),
            ]),
        ])
    );
};

const ReviewFooter = (review) => {
    const { status, viewCount, helpfulCount, ownerComment } = review;
    const { statusLabel, statusHelpText, statusStyle } = getStatusProps(status);

    return h(ReviewFooterContainer, [
        h(Status, { css: statusStyle }, statusLabel),
        renderSelfService(review, status),
        renderStatusHelp(statusHelpText),
        renderAdditionalInfo(status, ownerComment, helpfulCount, viewCount),
    ]);
};

export default ReviewFooter;
