import h from 'react-hyperscript';

import Page from './Page';
import MietwagenBookingList from './myMietwagen/bookings/MietwagenBookingList';

function MyMietwagenBookings() {
    return h(
        Page,
        {
            pagename: '/mhc/mietwagen',
            title: 'meinHolidayCheck - die ReiseCommunity',
        },
        [h(MietwagenBookingList)],
    );
}

export default MyMietwagenBookings;
