import h from 'react-hyperscript';
import { Query } from '@apollo/client/react/components';

import ProfilePictureTrigger from './ProfilePictureTrigger';
import { profilePictureIsFallback as profilePictureIsFallbackQuery } from '../../queries/profile';

export default () =>
    h(
        Query,
        {
            fetchPolicy: 'cache-and-network',
            query: profilePictureIsFallbackQuery,
        },
        ({ loading, data }) => {
            if (
                !loading &&
                data.profile &&
                data.profile.profilePicture.isFallback
            ) {
                return h(ProfilePictureTrigger);
            }
            return null;
        },
    );
