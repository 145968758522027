import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { oneLine } from 'common-tags';

import BookingSectionHeadline from './BookingSectionHeadline';
import useBookingContext from './lib/useBookingContext';
import ChangeParticipantDataDialog from './rebookingDialogs/ChangeParticipantDataDialog';
import TravellerContainer from './TravellerContainer';
import { SALUTATION_VALUE_OPTIONS } from '../../lib/salutations';
import { isActiveBooking, hasStarted } from '../../../common/lib/bookingHelper';
import Hint from './Hint';
import {
    shouldDataChangeRequestMessageBeShown,
    REQUESTED_TRAVELLER_CHANGES_STORAGE_KEY,
} from './lib/dataRequestLocalStorageHelpers';
import ModalButton from '../navigation/ModalButton';
import { pxToRem } from '../../styles/unitConverter';

const TravellerList = glamorous.div({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'stretch',
});
TravellerList.displayName = 'TravellerList';

const dataChangeRequestedMessage = oneLine`Wir haben Deine Anfrage erhalten und werden sie nun bearbeiten.
Die Bearbeitung der Reiseteilnehmer ist unverbindlich und kann je nach Reiseveranstalter bis zu 48 Stunden dauern.
Sollte die Änderung kostenlos möglich sein, werden wir sie direkt veranlassen.`;

const Travellers = (
    { booking, myRef, setWillRender },
    { getCurrentDate, localStorage },
) => {
    const { travellers, tourOperator, id, bookingType } = booking;

    if (travellers.length < 1) {
        return null;
    }

    const { trackEventWithBooking } = useBookingContext();
    const currentDate = getCurrentDate();

    const [isParticipantDataDialogOpen, setParticipantDataDialogOpen] =
        useState(false);

    useEffect(() => setWillRender(true), []);

    const shouldDataChangeRequestBeShown =
        shouldDataChangeRequestMessageBeShown({
            dateTime: currentDate,
            bookingId: booking.id,
            currentEntity: travellers,
            storageKey: REQUESTED_TRAVELLER_CHANGES_STORAGE_KEY,
            localStorage,
        });

    return h(Fragment, [
        h(BookingSectionHeadline, { innerRef: myRef }, 'Reiseteilnehmer'),
        h(
            TravellerList,
            { 'data-hj-suppress': '' },
            travellers.map((traveller, index) =>
                h(TravellerContainer, { traveller, index }),
            ),
        ),
        isActiveBooking(bookingType) &&
            !hasStarted(booking, currentDate) &&
            h(ModalButton, {
                onClick: () => {
                    setParticipantDataDialogOpen(true);
                    trackEventWithBooking({
                        event: 'event',
                        eventCategory: 'mybooking',
                        eventAction: 'open',
                        eventLabel: 'participantDataChange',
                        ofOrganizerSelected:
                            tourOperator.name || tourOperator.id,
                    });
                },
                text: 'Änderungsanfrage Reiseteilnehmer',
                disabled: shouldDataChangeRequestBeShown,
            }),
        shouldDataChangeRequestBeShown &&
            h(
                Hint,
                {
                    prefix: 'Bitte beachte',
                    containerStyle: { marginTop: pxToRem(12) },
                },
                dataChangeRequestedMessage,
            ),
        isParticipantDataDialogOpen &&
            h(ChangeParticipantDataDialog, {
                travellers,
                tourOperator,
                bookingId: id,
                closeDialog: () => setParticipantDataDialogOpen(false),
            }),
    ]);
};

Travellers.propTypes = {
    booking: PropTypes.shape({
        travellers: PropTypes.arrayOf(
            PropTypes.shape({
                firstName: PropTypes.string.isRequired,
                lastName: PropTypes.string.isRequired,
                salutation: PropTypes.oneOf(SALUTATION_VALUE_OPTIONS),
                dateOfBirth: PropTypes.string,
                nationality: PropTypes.string,
            }),
        ).isRequired,
        tourOperator: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string,
        }),
        id: PropTypes.string,
        bookingType: PropTypes.string,
    }).isRequired,
    // from using `useRef` hook, `ref` prop is treated differently and would need the `forwardRef` crap
    myRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
    setWillRender: PropTypes.func.isRequired,
};

Travellers.contextTypes = {
    getCurrentDate: PropTypes.func.isRequired,
    localStorage: PropTypes.object,
};

Travellers.displayName = 'Travellers';

export default Travellers;
