import * as R from 'ramda';

const isDate = R.is(Date);
const areBothOfTypeDate = R.binary(R.unapply(R.all(isDate)));
const isIdenticalDate = R.both(areBothOfTypeDate, R.equals);
const isSameValue = R.either(R.identical, isIdenticalDate);

export default function shallowObjectDiff(objectA, objectB) {
    const keysOfObjectA = R.keys(objectA);
    const keysOfObjectB = R.keys(objectB);
    const equalKeys = R.intersection(keysOfObjectA, keysOfObjectB);

    if (equalKeys.length !== keysOfObjectB.length) {
        throw new Error(
            'Not all properties of objectB are available in objectA',
        );
    }

    return R.reduce(
        (accumulator, propertyName) => {
            const valueA = objectA[propertyName];
            const valueB = objectB[propertyName];

            if (!isSameValue(valueA, valueB)) {
                return R.assoc(propertyName, valueB, accumulator);
            }

            return accumulator;
        },
        {},
        equalKeys,
    );
}
