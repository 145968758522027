import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import { ArticleArrow } from '../../../components/ArticleArrow';
import DarkLink from '../../../components/DarkLink';
import buttons from '../../../../../../styles/buttons';
import TripplannerBanner from './TripplannerBanner';
import { waveMdMediaQuery } from '../../../../../../styles/waveMediaQueries';
import { getImageUrl } from '../../../../../../lib/rentalcar/getImageUrl';

const CountriesContainer = glamorous.div({
    display: 'grid',
    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
    gap: `${options.space.l}px`,
    [waveMdMediaQuery]: {
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },
});

const CountryContainer = glamorous.div({
    position: 'relative',
    height: 96,
    overflow: 'hidden',
    borderRadius: options.radii.small,
    ':hover .country-image': {
        transform: 'scale(1.03)',
    },
});

const CountryImage = glamorous.img(
    {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        transition: 'all 0.3s ease-in',
    },
    'country-image',
);

const CountryName = glamorous.div({
    position: 'absolute',
    bottom: 0,
    right: 0,
    padding: `${options.space.xs}px ${options.space.xs}px ${options.space.m}px ${options.space.m}px`,
    zIndex: 2,
    backgroundColor: '#E6A900',
    borderTopLeftRadius: 24,
    color: '#fff',
    fontSize: options.fontSizes.s,
    fontWeight: 700,
    display: 'flex',
    gap: options.space['2xs'],
});

const countryHubs = [
    {
        title: 'Spanien',
        url: '/mietwagen/erleben/spanien',
        alt: 'Spanien Mietwagen-Guide',
    },
    {
        title: 'Türkei',
        url: '/mietwagen/erleben/tuerkei',
        alt: 'Türkei Mietwagen-Guide',
    },
    {
        title: 'Portugal',
        url: '/mietwagen/erleben/portugal',
        alt: 'Portugal Mietwagen-Guide',
    },
    {
        title: 'Griechenland',
        url: '/mietwagen/erleben/griechenland',
        alt: 'Griechenland Mietwagen-Guide',
    },
];

function MietwagenExperienceDefault(context) {
    const assetsPath = context.config.assetsPath;
    const { whiteButton } = buttons;
    const ExperienceButton = glamorous.button({
        ...whiteButton,
        fontFamily: 'inherit',
        fontSize: options.fontSizes.xs,
        margin: `${options.space.l}px 0 0 0`,
        padding: `0 ${options.space.m}px`,
        cursor: 'pointer',
    });

    const utmParameter = `?utm_source=mHC&utm_medium=HCMHub&utm_campaign=ExperienceArticleClicked`;

    return h('div', [
        h(CountriesContainer, [
            countryHubs.map((country) =>
                h(
                    DarkLink,
                    {
                        href: `${country.url}${utmParameter}&utm_content=${country.title.split(' ').join('%20')}`,
                        key: country.url,
                        target: '_blank',
                    },
                    [
                        h(CountryContainer, { key: country.title }, [
                            h(CountryImage, {
                                src: getImageUrl(country.title),
                                alt: country.alt,
                            }),
                            h(CountryName, [
                                country.title,
                                h(ArticleArrow, {
                                    assetsPath,
                                    backgroundColor: 'transparent',
                                }),
                            ]),
                        ]),
                    ],
                ),
            ),
        ]),
        h(
            DarkLink,
            {
                href: `/mietwagen/erleben${utmParameter}&utm_content=General`,
                target: '_blank',
            },
            [h(ExperienceButton, 'Alle Reiseziele entdecken')],
        ),
        h(TripplannerBanner, {
            assetsPath,
            destination: undefined,
            fullWidth: true,
            articleAmount: -1,
        }),
    ]);
}

export default MietwagenExperienceDefault;
