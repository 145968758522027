import glamorous from 'glamorous';

import { pxToRem } from '../../styles/unitConverter';
import { xxxlargeDistance } from '../../styles/distances';
import {
    applyOnTablet,
    applyOnDesktop,
    applyOnDesktopAndUp,
} from '../../styles/mediaQueries';

const extraPaddingStyles = {
    boxSizing: 'border-box',
    paddingLeft: xxxlargeDistance,
    paddingRight: xxxlargeDistance,
};

const gridWidths = {
    desktop: 988,
    tablet: 768,
};

const Grid = glamorous.div(
    {
        width: '100%',
        margin: '0 auto',

        [applyOnTablet]: {
            width: pxToRem(gridWidths.tablet),
        },

        [applyOnDesktop]: {
            width: pxToRem(gridWidths.desktop),
        },
    },
    ({ extraPadding = false }) => ({
        [applyOnDesktopAndUp]: extraPadding ? extraPaddingStyles : null,
    }),
);

Grid.gridWidths = gridWidths;

export default Grid;
