import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import { ExclamationMark } from '@designsystem/icons';

import { blue90, red100 } from '../../styles/waveColors';
import { defaultFont, FONTS } from '../../styles/fonts';
import { pxToRem } from '../../styles/unitConverter';
import { xsmallDistance } from '../../styles/distances';
import Info from '../icons/Info';

const HintContainer = glamorous.div(({ containerStyle }) => ({
    padding: pxToRem(9),
    borderRadius: pxToRem(4),
    marginBottom: pxToRem(24),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    ...containerStyle,
}));

const PrefixContainer = glamorous.div({
    display: 'flex',
    padding: `${pxToRem(5)} 0`,
});

const Prefix = glamorous.h5({
    fontFamily: FONTS.secondary,
    fontWeight: 700,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(18),
    padding: `0 ${xsmallDistance}`,
    margin: '0',
});
Prefix.displayName = 'Prefix';

const ChildContainer = glamorous.div(defaultFont, {
    paddingLeft: pxToRem(23),
    textAlign: 'initial',
    fontFamily: FONTS.secondary,
});

const Icon = ({ type }) => {
    const props = {
        css: { height: pxToRem(16), width: pxToRem(16), flex: 'none' },
    };

    if (type === 'error') {
        return h(ExclamationMark, props);
    }
    return h(Info, props);
};

const backgroundColors = {
    info: blue90,
    error: red100,
};

const Hint = ({ prefix, type, children, containerStyle }) => {
    const backgroundColor = backgroundColors[type];
    const updatedContainerStyle = { ...containerStyle, backgroundColor };

    return h(HintContainer, { containerStyle: updatedContainerStyle }, [
        h(PrefixContainer, [h(Icon, { type }), h(Prefix, prefix)]),
        h(ChildContainer, [children]),
    ]);
};

Hint.defaultProps = {
    type: 'info',
};

Hint.propTypes = {
    type: PropTypes.oneOf(['info', 'error']),
    children: PropTypes.node,
    prefix: PropTypes.string,
    containerStyle: PropTypes.object,
};

export default Hint;
