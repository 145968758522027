import glamorous from 'glamorous';
import options from '@designsystem/options';

import { waveSmMediaQuery } from '../../../../../styles/waveMediaQueries';

export const HeaderContainer = glamorous.div({
    display: 'flex',
    gap: options.space.m,
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: options.space.m,
    justifyContent: 'space-between',
    [waveSmMediaQuery]: {
        alignItems: 'start',
        flexDirection: 'row',
        gap: options.space.xl,
    },
});
