import {
    getValidHcmBooking,
    useRentalcarBookings,
} from './useRentalcarBookings';
import { useUpcomingTravel } from './useUpcomingTravel';

const getHcmData = (existingHcmBooking) => {
    const hcmBookingOpt = getValidHcmBooking(existingHcmBooking);
    if (
        !hcmBookingOpt ||
        !hcmBookingOpt.bookingId ||
        !hcmBookingOpt.countryName
    ) {
        return {};
    }

    return {
        bookingId: hcmBookingOpt.bookingId,
        countryName: hcmBookingOpt.countryName,
        regionName: hcmBookingOpt.regionName,
        hubDestinationId: hcmBookingOpt.countryDestinationId,
    };
};

const getUpcomingTravelData = (upcomingTravel) => {
    const isCountryParentExisting =
        upcomingTravel && upcomingTravel.hotel && upcomingTravel.hotel.parents;
    const countryEntry = isCountryParentExisting
        ? upcomingTravel.hotel.parents.find(
              (parent) => parent.destinationType === 'COUNTRY',
          )
        : undefined;
    const regionEntry = isCountryParentExisting
        ? upcomingTravel.hotel.parents.find(
              (parent) => parent.destinationType === 'REGION',
          )
        : undefined;

    if (!countryEntry) {
        return {};
    }

    return {
        articleDestinationId: regionEntry.id,
        hubDestinationId: countryEntry.id,
        countryName: countryEntry.name,
    };
};

export const getTravelData = (context) => {
    const defaultResult = {
        countryName: undefined,
        regionName: undefined,
        articleDestinationId: undefined,
        hubDestinationId: undefined,
        bookingId: undefined,
    };

    const hcmData = getHcmData(useRentalcarBookings(context));
    const travelData = getUpcomingTravelData(useUpcomingTravel(context));

    return {
        ...defaultResult,
        ...travelData,
        ...hcmData,
    };
};
