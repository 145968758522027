import FlightInformation from '../flight/FlightInformation';
import HotelInformation from '../hotel/HotelInformation';
import InsuranceUpsell from '../InsuranceUpsell';
import PaymentInformation from '../PaymentInformation';
import SpecialRequest from '../SpecialRequest';
import TravelDocuments from '../TravelDocuments';
import TravelInformation from '../TravelInformation';
import Travellers from '../Travellers';
import TravelTourOperator from '../TravelTourOperator';
import ContractualPartner from '../ContractualPartner';
import Cancellation from '../selfservice/Cancellation';
import Rebooking from '../selfservice/Rebooking';
import FAQSection from '../selfservice/FAQ';

// order in array = order in index = order in booking details content
export default [
    TravelInformation,
    PaymentInformation,
    FlightInformation,
    HotelInformation,
    TravelTourOperator,
    ContractualPartner,
    Travellers,
    TravelDocuments,
    SpecialRequest,
    InsuranceUpsell,
    Cancellation,
    Rebooking,
    FAQSection,
];
