import options from '@designsystem/options';

import { pxToRem } from './unitConverter';

export const waveTextLBold = {
    fontSize: pxToRem(options.fontSizes.s),
    lineHeight: pxToRem(24),
    fontWeight: options.fontWeights.bold,
};

export const waveTextL = {
    fontSize: pxToRem(options.fontSizes.s),
    lineHeight: pxToRem(24),
    fontWeight: options.fontWeights.normal,
};

export const waveTextMBold = {
    fontSize: pxToRem(options.fontSizes.xs),
    lineHeight: pxToRem(21),
    fontWeight: options.fontWeights.bold,
};

export const waveTextMMedium = {
    fontSize: pxToRem(options.fontSizes.xs),
    lineHeight: pxToRem(21),
    fontWeight: options.fontWeights.mediumBold,
};

export const waveTextM = {
    fontSize: pxToRem(options.fontSizes.xs),
    lineHeight: pxToRem(21),
    fontWeight: options.fontWeights.normal,
};

export const waveTextMLabel = {
    fontSize: pxToRem(options.fontSizes.xs),
    lineHeight: pxToRem(21),
    fontWeight: options.fontWeights.normal,
    letterSpacing: options.letterSpacings.l,
};

export const waveTextSBold = {
    fontSize: pxToRem(options.fontSizes['2xs']),
    lineHeight: pxToRem(18),
    fontWeight: options.fontWeights.bold,
};

export const waveTextSMedium = {
    fontSize: pxToRem(options.fontSizes['2xs']),
    lineHeight: pxToRem(18),
    fontWeight: options.fontWeights.mediumBold,
};

export const waveTextS = {
    fontSize: pxToRem(options.fontSizes['2xs']),
    lineHeight: pxToRem(18),
    fontWeight: options.fontWeights.normal,
};

export const waveTextSLabel = {
    fontSize: pxToRem(options.fontSizes['2xs']),
    lineHeight: pxToRem(18),
    fontWeight: options.fontWeights.normal,
    letterSpacing: options.letterSpacings.l,
};

const weightBold = { fontWeight: options.fontWeights.bold };
const letterSpacingL = { letterSpacing: options.letterSpacings.l };

export const waveTextButton = [
    weightBold,
    letterSpacingL,
    { textTransform: 'uppercase' },
];
export const waveTextSButton = [waveTextS, waveTextButton];
export const waveTextMButton = [waveTextM, waveTextButton];
export const waveTextLButton = [waveTextL, waveTextButton];
