export default function graphqlFetch({ fetch, config }, body) {
    const url = `${config.pathPrefix}/mhc/api`;
    const options = {
        body: JSON.stringify(body),
        credentials: 'include',
        headers: {
            'content-type': 'application/json',
        },
        method: 'POST',
    };

    return fetch(url, options);
}
