import h from 'react-hyperscript';
import * as R from 'ramda';
import glamorous from 'glamorous';

import SunRating from './SunRating';
import ThumbsUp from '../icons/ThumbsUp';
import ThumbsDown from '../icons/ThumbsDown';
import { pxToRem } from '../../styles/unitConverter';
import colors from '../../styles/colors';
import { loudFont } from '../../styles/fonts';
import { smallDistance } from '../../styles/distances';

const { thumbsUpColor, thumbsDownColor } = colors;

const thumbsSize = 20;
const thumsbsCss = {
    width: pxToRem(thumbsSize),
    height: pxToRem(thumbsSize),
};
const thumbsUpCss = R.assoc('color', thumbsUpColor, thumsbsCss);
const thumbsDownCss = R.assoc('color', thumbsDownColor, thumsbsCss);

const TextRatingContainer = glamorous.span({
    ...loudFont,
    verticalAlign: 'top',
    marginLeft: pxToRem(12),
});
const RecommendationContainer = glamorous.span({
    verticalAlign: 'top',
    marginLeft: smallDistance,
});

const renderTextRating = (rating) => {
    const maxSuns = 6;
    return h(TextRatingContainer, [`${rating} / ${maxSuns}`]);
};

const renderRecommedation = (recommendation) => {
    return h(RecommendationContainer, [
        recommendation
            ? h(ThumbsUp, { css: thumbsUpCss })
            : h(ThumbsDown, { css: thumbsDownCss }),
    ]);
};

export default function RatingBar({ rating, recommendation }) {
    return h('div', [
        h(SunRating, { rating }),
        renderTextRating(rating),
        renderRecommedation(recommendation),
    ]);
}
