import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function Wellness({ css }) {
    return h(
        Icon,
        {
            viewBox: '0 0 17 13',
            css,
        },
        [
            h('path', {
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                fill: '#000820',
                d: oneLine`M1.03692 7.48958C1.48096 7.20714 1.99611 7.04 2.51515 6.99282L2.43216 6.07992C1.66037 6.15008 0.883179 6.43076 0.241817 6.92959L0.0205078 7.10172L0.0729681 7.37714C0.345984 8.81047 1.2107 10.2009 2.38826 11.0798C3.57592 11.9663 5.11671 12.3519 6.68209 11.676L6.31869 10.8344C5.11568 11.3539 3.91955 11.0789 2.93656 10.3452C2.02135 9.66214 1.32137 8.59862 1.03692 7.48958Z`,
            }),
            h('path', {
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                fill: '#000820',
                d: oneLine`M14.2807 6.07982C15.0525 6.14998 15.8297 6.43066 16.471 6.92949L16.6913 7.1008L16.6402 7.37511C16.368 8.83819 15.5047 10.2461 14.3246 11.1269C13.1332 12.0162 11.5883 12.384 10.0228 11.6723L10.4021 10.8378C11.605 11.3846 12.797 11.1232 13.7763 10.3923C14.6914 9.70929 15.3936 8.63042 15.6775 7.49046C15.2331 7.20743 14.7173 7.03996 14.1977 6.99272L14.2807 6.07982Z`,
            }),
            h('path', {
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                fill: '#000820',
                d: oneLine`M8.38799 0.27002L8.63143 0.422622C10.909 1.85033 12.1003 4.80494 12.1494 7.36132C12.1741 8.64717 11.9126 9.89082 11.3027 10.8288C10.6798 11.7867 9.70254 12.4057 8.38799 12.4057C7.07345 12.4057 6.09618 11.7867 5.47328 10.8288C4.86338 9.89082 4.60184 8.64717 4.62657 7.36132C4.67573 4.80494 5.86703 1.85033 8.14456 0.422622L8.38799 0.27002ZM6.24177 10.3291C6.70813 11.0463 7.40604 11.4891 8.38799 11.4891C9.36994 11.4891 10.0679 11.0463 10.5342 10.3291C11.0136 9.59186 11.2554 8.54688 11.2329 7.37894C11.1896 5.12803 10.1749 2.63664 8.38799 1.36227C6.60112 2.63664 5.58635 5.12803 5.54307 7.37894C5.5206 8.54688 5.7624 9.59186 6.24177 10.3291Z`,
            }),
            h('path', {
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                fill: '#000820',
                d: oneLine`M2.85257 1.97289L3.12968 1.98919C4.32452 2.05947 5.42229 2.61841 6.29304 3.3552L5.70092 4.05497C5.01251 3.47247 4.20226 3.0516 3.35886 2.93261C2.74174 4.1712 2.78 5.80972 3.2761 7.23511C3.53874 7.98975 3.92076 8.65575 4.36934 9.14415C4.81937 9.63412 5.31255 9.92128 5.80216 9.98248L5.68846 10.8921C4.91971 10.796 4.24105 10.3596 3.69423 9.76423C3.14596 9.1673 2.70612 8.38619 2.41036 7.53642C1.82384 5.8512 1.76812 3.7803 2.70975 2.21092L2.85257 1.97289Z`,
            }),
            h('path', {
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                fill: '#000820',
                d: oneLine`M13.8603 1.97292L14.0031 2.21095C14.9448 3.78033 14.889 5.85123 14.3025 7.53645C14.0068 8.38622 13.5669 9.16732 13.0187 9.76425C12.4718 10.3596 11.7932 10.796 11.0244 10.8921L10.9107 9.98251C11.4003 9.92131 11.8935 9.63415 12.3435 9.14417C12.7921 8.65578 13.1741 7.98977 13.4368 7.23514C13.9329 5.80975 13.9711 4.17123 13.354 2.93264C12.5106 3.05162 11.7004 3.47249 11.012 4.055L10.4198 3.35523C11.2906 2.61844 12.3884 2.0595 13.5832 1.98922L13.8603 1.97292Z`,
            }),
        ],
    );
}
