const executeUsabillaCommand = (command) => (window) => {
    try {
        window.usabilla_live(command);
    } catch (error) {
        // ignore errors
    }
};
const hideUsabillaButton = executeUsabillaCommand('hide');
const showUsabillaButton = executeUsabillaCommand('show');

export { hideUsabillaButton, showUsabillaButton };
