import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function FAQ({ css }) {
    return h(
        Icon,
        {
            viewBox: '0 0 20 20',
            css,
        },
        [
            h('path', {
                d: oneLine`M4.2998 6.00005C4.2998 5.61345 4.6132 5.30005 4.9998 5.30005H14.9998C15.3864 5.30005 15.6998
             5.61345 15.6998 6.00005C15.6998 6.38665 15.3864 6.70005 14.9998 6.70005H4.9998C4.6132 6.70005 4.2998
              6.38665 4.2998 6.00005Z`,
            }),
            h('path', {
                d: oneLine`M4.9998 9.30005C4.6132 9.30005 4.2998 9.61345 4.2998 10C4.2998 10.3866
             4.6132 10.7 4.9998 10.7H10.9998C11.3864 10.7 11.6998 10.3866 11.6998 10C11.6998
              9.61345 11.3864 9.30005 10.9998 9.30005H4.9998Z`,
            }),
            h('path', {
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                d: oneLine`M0.299805 3.00005C0.299805 1.50888 1.50864 0.300049 2.9998 0.300049H16.9998C18.491 0.300049
             19.6998 1.50888 19.6998 3.00005V13C19.6998 14.4912 18.491 15.7 16.9998 15.7H13.5232L11.3206
              18.4181C10.6402 19.2578 9.35939 19.2578 8.67902 18.4182L6.47641 15.7H2.9998C1.50864 15.7 0.299805
               14.4912 0.299805 13V3.00005ZM2.9998 1.70005C2.28183 1.70005 1.6998 2.28208 1.6998 3.00005V13C1.6998
                13.718 2.28183 14.3 2.9998 14.3H6.81015C7.02126 14.3 7.22109 14.3953 7.354 14.5593L9.76673
                 17.5367C9.88679 17.6849 10.1128 17.6849 10.2329 17.5367L12.6456 14.5593C12.7785 14.3953 12.9783
                  14.3 13.1895 14.3H16.9998C17.7178 14.3 18.2998 13.718 18.2998 13V3.00005C18.2998 2.28208
                   17.7178 1.70005 16.9998 1.70005H2.9998Z`,
            }),
        ],
    );
}
