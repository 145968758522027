import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import moment from 'moment';

import { superLoudFont } from '../../styles/fonts';
import colors from '../../styles/colors';
import {
    xsmallDistance,
    smallDistance,
    mediumDistance,
    largeDistance,
} from '../../styles/distances';
import { applyOnTabletAndUp, applyOnMobile } from '../../styles/mediaQueries';
import Hint from '../icons/Hint';
import formatDate from '../../lib/formatDate';

const {
    pictureCountBorderColor,
    primaryBrandColor,
    buttonHoverBackgroundColor,
} = colors;

const Time = glamorous.time(superLoudFont, { display: 'block' });

const MetaInfoContainer = glamorous.div({
    [applyOnMobile]: {
        textAlign: 'center',
    },
});

const PictureCount = glamorous.div(
    {
        display: 'inline-block',
        border: `1px solid ${pictureCountBorderColor}`,
        borderRadius: smallDistance,
        height: largeDistance,
        lineHeight: largeDistance,
        padding: `0 ${smallDistance}`,
        [applyOnMobile]: {
            margin: `${xsmallDistance} 0`,
        },
        [applyOnTabletAndUp]: {
            display: 'inline-flex',
            whiteSpace: 'nowrap',
            marginLeft: smallDistance,
        },
    },
    ({ rejectedPictureCount }) => {
        return rejectedPictureCount ? { paddingRight: xsmallDistance } : null;
    },
);

const Headline = glamorous.div({
    [applyOnTabletAndUp]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row-reverse',
    },
});

const iconCss = {
    height: mediumDistance,
    width: mediumDistance,
    marginLeft: xsmallDistance,
    verticalAlign: 'text-top',
    fill: primaryBrandColor,
    '&:hover': {
        fill: buttonHoverBackgroundColor,
    },
};

function formatPictureCount(pictureCount, mightBeIncomplete) {
    const appendix = mightBeIncomplete ? ' oder mehr' : '';
    const text = pictureCount === 1 ? 'Bild' : 'Bilder';

    return `${pictureCount} ${text}${appendix}`;
}

function rejectedPictureCountItems(rejectedPictureCount, topLevelDomain) {
    return rejectedPictureCount
        ? [
              ` • ${rejectedPictureCount} abgelehnt`,
              h(
                  'a',
                  {
                      href: `https://hilfe.holidaycheck.${topLevelDomain}/?faq=welche-bilder-kann-ich-hochladen`,
                      target: '_blank',
                  },
                  [h(Hint, { css: iconCss })],
              ),
          ]
        : [];
}

function AlbumMetaInfo(
    {
        timestamp,
        pictureCount,
        rejectedPictureCount,
        mightBeIncomplete,
        children,
    },
    { config },
) {
    const date = moment(timestamp).utc();

    return h(MetaInfoContainer, [
        h(Time, formatDate(date, 'MMMM Y')),
        h(Headline, [
            h(PictureCount, { rejectedPictureCount }, [
                formatPictureCount(pictureCount, mightBeIncomplete),
                ...rejectedPictureCountItems(
                    rejectedPictureCount,
                    config.topLevelDomain,
                ),
            ]),
            children,
        ]),
    ]);
}

AlbumMetaInfo.contextTypes = {
    config: PropTypes.object,
};

export default AlbumMetaInfo;
