import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import {
    waveFontL,
    waveHeadline2XSSlim,
    waveHeadlineM,
    waveHeadlineS,
    waveHeadlineXS,
} from '../../styles/waveHeadlines';
import {
    waveMdMediaQuery,
    waveSmMediaQuery,
    waveXsAndSmOnlyMediaQuery,
} from '../../styles/waveMediaQueries';
import { largeDistance } from '../../styles/distances';

const TitleContainer = glamorous.div({
    marginLeft: 'auto',
    marginRight: 'auto',
});

const TitleText = glamorous.h1({
    [waveXsAndSmOnlyMediaQuery]: {
        marginBottom: options.space.xl,
    },
    marginTop: `${options.space['3xl']}px`,
    marginBottom: options.space['2xl'],
    marginLeft: `${options.space.l}px`,
    marginRight: `${options.space.l}px`,
    ...waveHeadlineXS,
    [waveSmMediaQuery]: {
        ...waveHeadlineS,
    },
    [waveMdMediaQuery]: {
        ...waveHeadlineM,
    },
    textAlign: 'center',
});

const SubtitleText = glamorous.h2({
    textAlign: 'center',
    ...waveFontL,
    [waveSmMediaQuery]: {
        ...waveHeadline2XSSlim,
    },
    [waveMdMediaQuery]: {
        ...waveHeadline2XSSlim,
    },
});

const Diamond = glamorous.img({
    verticalAlign: 'super',
    width: largeDistance,
    height: largeDistance,
});

export default function Title({ config: { assetsPath }, subtitle }) {
    return h(TitleContainer, [
        h(TitleText, [
            'Mein HolidayCheck ',
            h('span', { style: { whiteSpace: 'nowrap' } }, [
                'Premium',
                h(Diamond, { src: `${assetsPath}/myPremium/premium-star.svg` }),
            ]),
        ]),
        subtitle && h(SubtitleText, subtitle),
    ]);
}
