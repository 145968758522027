import PropTypes from 'prop-types';
import * as R from 'ramda';
import glamorous from 'glamorous';
import h from 'react-hyperscript';
import options from '@designsystem/options';

import colors from '../../styles/colors';
import { tinyFont } from '../../styles/fonts';
import { xsmallDistance } from '../../styles/distances';
import StarIcon from '../icons/Star';
import formatNumber from '../../lib/formatNumber';

const {
    brightStarBackgroundColor,
    brightStarColor,
    darkStarColor,
    darkStarBackgroundColor,
    userLevelTextColor,
} = colors;

const getStar = (isGrey, key) => {
    const css = {
        background: isGrey
            ? darkStarBackgroundColor
            : brightStarBackgroundColor,
        borderRadius: '45px',
        width: '45px',
        height: '45px',
        display: 'inline-block',
        margin: '2px',
    };
    const Star = glamorous.div(css);
    return h(
        Star,
        { key },
        h(StarIcon, {
            css: {
                color: isGrey ? darkStarColor : brightStarColor,
                width: '25px',
                height: '25px',
                padding: '10px',
            },
        }),
    );
};

const limitForGreyStar = 15;
const calculateStars = (points) => {
    if (typeof points !== 'number') {
        return {};
    }
    const roundedPoints = Math.round(points);
    const pointsConfig = [
        {
            limit: limitForGreyStar,
            grey: true,
            stars: 1,
        },
        {
            limit: 100,
            stars: 1,
        },
        {
            limit: 200,
            stars: 2,
        },
        {
            limit: 400,
            stars: 3,
        },
        {
            limit: 1000,
            stars: 4,
        },
        {
            stars: 5,
        },
    ];

    const config = pointsConfig.find(
        ({ limit }) => (limit && roundedPoints < limit) || !limit,
    );

    return { stars: config.stars, limit: config.limit, roundedPoints };
};

const renderStarsWithPoints = (stars, limit, roundedPoints) => {
    const starsElement = R.times(
        getStar.bind(null, limit === limitForGreyStar),
        stars,
    );
    const pointsText = `${formatNumber(roundedPoints)}${limit ? ` / ${formatNumber(limit)}` : ''} Punkte`;

    const PointsText = glamorous.div(tinyFont);
    const pointsTextElement = h(PointsText, pointsText);

    return R.append(pointsTextElement, starsElement);
};

const UserPoints = (props) => {
    const { points } = props;
    const { stars, limit, roundedPoints } = calculateStars(points);

    const Wrapper = glamorous.div({
        marginTop: xsmallDistance,
        marginBottom: options.space.xs,
        textAlign: 'center',
        color: userLevelTextColor,
    });

    return h(Wrapper, [
        stars && h('div', renderStarsWithPoints(stars, limit, roundedPoints)),
    ]);
};

UserPoints.propTypes = {
    points: PropTypes.number,
};

export default UserPoints;
