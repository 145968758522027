import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function Eye(options = {}) {
    return h(Icon, options, [
        h('path', {
            d: oneLine`
        M16 25.307c-7.364 0 -13.333 -9.334 -13.333 -9.334S8.637 6.64 16 6.64c7.364 0
        13.333 9.333 13.333 9.333S23.363 25.307 16 25.307zm0 -4A5.333 5.333 0 1016
        10.64a5.333 5.333 0 000 10.667zm2.667 -5.334a2.667 2.667 0 11-5.334 0 2.667
        2.667 0 015.334 0z`,
        }),
    ]);
}
