import glamorous from 'glamorous';

import { smallDistance, mediumDistance } from '../../styles/distances';
import { applyOnMobile } from '../../styles/mediaQueries';

const Row = glamorous.div({
    paddingRight: mediumDistance,
    paddingLeft: mediumDistance,

    [applyOnMobile]: {
        paddingRight: smallDistance,
        paddingLeft: smallDistance,
    },
});

export default Row;
