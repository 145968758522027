import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function Comment(options = {}) {
    return h(Icon, options, [
        h('path', {
            d: oneLine`
        M8 22.64v6.667l7.778 -6.667H28.01a1.337 1.337 0 001.322 -1.331V6.638A1.327 1.327
        0 0028.011 5.307H3.989A1.337 1.337 0 002.667 6.638v14.67c0 .735 .592 1.332 1.322
        1.332H8zM6.667 9.307h10.666v1.333H6.667V9.307zm0 4h18.666v1.333H6.667v-1.333zm0
        4h14.666v1.333H6.667v-1.333z`,
        }),
    ]);
}
