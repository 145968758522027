import gql from 'graphql-tag';

export const clubOffers = gql`
    query {
        clubOffers {
            items {
                id
                detail {
                    price {
                        amount
                        currency
                    }
                    services {
                        summary
                    }
                    campaign {
                        subscription {
                            amount
                            currency
                        }
                        hero {
                            text
                            subText
                        }
                        timeFrame {
                            endDate
                        }
                        discountProductCode
                    }
                    description {
                        header
                        body
                        cta
                    }
                }
            }
        }
    }
`;
