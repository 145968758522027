import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import { waveTextM } from '../../../../styles/waveText';

const RatingContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const UITextM = glamorous.div({
    ...waveTextM,
});

export default function Rating({ value }) {
    const numberWithComma = value.toFixed(1).replace('.', ',');

    return h(RatingContainer, [
        h(
            UITextM,
            { style: { fontWeight: options.fontWeights.bold } },
            numberWithComma,
        ),
        '\u00A0',
        h(UITextM, '/ 6'),
    ]);
}
