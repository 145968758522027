const travelDocumentCategoryMapper = {
    OTHER: 'Sonstige',
    TRAVEL_DOCUMENT: 'Deine Reiseunterlagen',
    BOOKING_CONFIRMATION: 'Deine Reisebestätigung',
    CERTIFICATE_OF_INSURANCE: 'Dein Sicherungsschein',
    RESERVATION_CONFIRMATION: 'Deine Reservierungsbestätigung',
    CANCELLATION_CONFIRMATION: 'Deine Stornierungsbestätigung',
    ADDITIONAL_SERVICES_REQUEST: 'Anmeldung Sonderleistungen',
    TOUR_OPERATOR_INFORMATION: 'Veranstalterinformationen',
    VISA_APPLICATION: 'Dein Visaantrag',
    HOTEL_VOUCHER: 'Dein Hotelgutschein',
};

export default travelDocumentCategoryMapper;
