import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import { blue, gray90, gray20 } from '../../styles/waveColors';
import Link from '../navigation/Link';
import useBookingContext from './lib/useBookingContext';
import { pxToRem } from '../../styles/unitConverter';
import { applyOnMobile } from '../../styles/mediaQueries';
import { tinyFont, superLoudFont } from '../../styles/fonts';
import travelDocumentCategoryMapper from './lib/travelDocumentCategoryMapper';
import colors from '../../styles/colors';

const { linkHoverColor } = colors;

const FileContainer = glamorous(Link)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid ${gray90}`,
    borderRadius: pxToRem(4),
    boxSizing: 'border-box',
    padding: pxToRem(16),
    width: `calc(50% - ${pxToRem(12)})`,
    marginBottom: pxToRem(24),
    ':hover': {
        '& .file-metadata-container-cls .filename-title-cls': {
            color: linkHoverColor,
        },
    },
    [applyOnMobile]: {
        width: '100%',
        '&:not(:last-child)': {
            marginBottom: pxToRem(24),
        },
    },
});
FileContainer.displayName = 'FileContainer';

const FileMetadataContainer = glamorous.div(
    {
        wordBreak: 'break-word',
    },
    'file-metadata-container-cls',
);
FileMetadataContainer.displayName = 'FileMetadataContainer';

const FileName = glamorous.div(
    superLoudFont,
    {
        display: 'block',
        lineHeight: '21px',
        color: blue,
        fontWeight: 'bold',
    },
    'filename-title-cls',
);
FileName.displayName = 'FileName';

const FileCategory = glamorous.div(tinyFont, {
    lineHeight: pxToRem(18),
    display: 'block',
    color: gray20,
});
FileCategory.displayName = 'FileCategory';

const DownloadIcon = glamorous.img({
    verticalAlign: 'text-top',
    minHeight: pxToRem(18.75),
    marginLeft: pxToRem(10),
});
DownloadIcon.displayName = 'DownloadIcon';

const renderDownloadIcon = (assetsPath) => {
    return h(DownloadIcon, {
        src: `${assetsPath}/myBookings/common-file-download.svg`,
        alt: 'Zum Herunterladen klicken',
    });
};

const renderFileMetadata = (name, category) =>
    h(FileMetadataContainer, {}, [
        h(FileName, name),
        h(FileCategory, category),
    ]);

const BookingFile = (
    { file: { id, name, category, internalUseOnly }, bookingId },
    { config: { assetsPath } },
) => {
    if (internalUseOnly) {
        return null;
    }
    const { trackEventWithBooking } = useBookingContext();

    return h(
        FileContainer,
        {
            href: `/mhc/booking-file/${bookingId}/${id}`,
            download: true,
            onClick: () => {
                trackEventWithBooking({
                    event: 'event',
                    eventCategory: 'mybooking',
                    eventAction: 'downloadFile',
                });
            },
        },
        [
            renderFileMetadata(name, travelDocumentCategoryMapper[category]),
            renderDownloadIcon(assetsPath),
        ],
    );
};

BookingFile.propTypes = {
    file: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        internalUseOnly: PropTypes.bool.isRequired,
    }).isRequired,
    bookingId: PropTypes.string.isRequired,
};

BookingFile.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

export default BookingFile;
