import glamorous from 'glamorous';

import { black } from '../../../../styles/waveColors';

const BookingsContainer = glamorous.div({
    fontFamily: 'Montserrat',
    color: black,
});

export { BookingsContainer };
