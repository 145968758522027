import { mediumDistance as GUTTER_WIDTH } from '../../styles/distances';

const NUMBER_OF_COLUMNS = 12;

/*
 * A grid contains a set number of columns that span the available width, with a gutter between adjacent columns. In the
 * calculations, columns are assumed to be surrounded by a half-gutter on each side.
 *
 * The width of a container that spans multiple columns is calculated with this formula:
 *
 *    (width of one column) * (number of columns to span) + (width of all gutters between said number of columns)
 *
 *               ┌───┐  ┌───┐  ┌───┐  ┌───┐  ┌───┐  ┌───┐  ┌───┐  ┌───┐  ┌───┐  ┌───┐  ┌───┐  ┌───┐
 *               │   │  │   │  │   │  │   │  │   │  │   │  │   │  │   │  │   │  │   │  │   │  │   │
 *       columns │   │  │   │  │   │  │   │  │   │  │   │  │   │  │   │  │   │  │   │  │   │  │   │
 *               │   │  │   │  │   │  │   │  │   │  │   │  │   │  │   │  │   │  │   │  │   │  │   │
 *               └───┘  └───┘  └───┘  └───┘  └───┘  └───┘  └───┘  └───┘  └───┘  └───┘  └───┘  └───┘
 *              |    ↓  ↓      |                 |  |   |                                          |
 *       gutter |     ▄▄ X px  |                 |  ↓   ↓                                          |
 * column width |              ↓                 ↓  █████ (grid width - columns * gutter) / columns|
 *    n columns ↓              █████▄▄█████▄▄█████ (column width) * n + (n - 1) * gutter           ↓
 *   grid width ████████████████████████████████████████████████████████████████████████████████████ Y px
 *
 * Note that “grid width” expands beyond the columns to account for the half-gutters around the first and last column:
 *               ┌───┐  ┌───┐  ┌───┐  ┌───┐  ┌───┐  ┌───┐  ┌───┐  ┌───┐  ┌───┐  ┌───┐  ┌───┐  ┌───┐
 *               │   │  │   │  │   │  │   │  │   │  │   │  │   │  │   │  │   │  │   │  │   │  │   │
 *              ▄└───┘▄▄└───┘▄▄└───┘▄▄└───┘▄▄└───┘▄▄└───┘▄▄└───┘▄▄└───┘▄▄└───┘▄▄└───┘▄▄└───┘▄▄└───┘▄
 */

// For browsers that do not understand `calc`, we provide a fallback-value in percent. We split the grid into 12 columns
// and set the 11 gutters to a width of 2%. That leaves (100 - 11*2) percent for the twelve columns, or 6.5% each.
const fallbackColumnWidth = 6.5;
const fallbackGutterWidth = 2;
const getFallbackValue = (span) =>
    `${span * fallbackColumnWidth + (span - 1) * fallbackGutterWidth}%`;

const columnWidth = (gridWidth) =>
    `(${gridWidth} - ${NUMBER_OF_COLUMNS} * ${GUTTER_WIDTH}) / ${NUMBER_OF_COLUMNS}`;
const getCalcValue = (gridWidth, span) =>
    `calc(${columnWidth(gridWidth)} * ${span} + ${span - 1} * ${GUTTER_WIDTH})`;

export default function calculateColumnWidth(gridWidth, span) {
    return span
        ? [getFallbackValue(span), getCalcValue(gridWidth, span)]
        : ['100%'];
}
