import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function DownArrowLine(options = {}) {
    return h(Icon, options, [
        h('path', {
            d: oneLine`
        M16.02 18.163L8.541 10.686a1.34 1.34 0 00-1.887 .012l-.905 .906a1.324 1.324 0
        00-.013 1.887l9.345 9.345a1.314 1.314 0 00.93 .382 1.291 1.291 0 00.945
        -.382l9.345 -9.345a1.34 1.34 0 00-.013 -1.887l-.905 -.906a1.324 1.324 0 00-1.887
        -.012l-7.478 7.477z`,
        }),
    ]);
}
