import h from 'react-hyperscript';
import options from '@designsystem/options';
import glamorous from 'glamorous';
import { useEffect } from 'react';

import RetainMembership from './RetainMembership';
import { useCancellationContext } from '../context/CancellationContext';

const AdditionalInfoTextArea = glamorous.textarea({
    display: 'block',
    borderRadius: options.radii.small,
    borderColor: options.colors.gray,
    width: '100%',
    marginTop: options.space.xs,
});
AdditionalInfoTextArea.displayName = 'AdditionalInfoTextArea';

const CancellationButton = glamorous.button({
    display: 'block',
    textTransform: 'uppercase',
    textDecoration: 'none',
    textAlign: 'center',
    margin: `0 auto`,
    fontWeight: options.fontWeights.bold,
    fontFamily: options.fonts.default,
    color: options.colors.blue,
    border: 'none',
    background: 'none',
    cursor: 'pointer',
});

function CancellationRetainOrReject() {
    const {
        isSendingCancellationRequest,
        trackEventForPremium,
        getCancellationReason,
    } = useCancellationContext();
    useEffect(() => {
        trackEventForPremium({
            event: {
                eventCategory: 'Premium-Club',
                eventAction: 'Cancellation-Step2-Shown',
                eventLabel: getCancellationReason(),
            },
        });
    }, []);

    return h('div', [
        h(RetainMembership),
        h(
            CancellationButton,
            {
                onClick: (e) => e.currentTarget.blur(),
                type: 'submit',
                loading: isSendingCancellationRequest,
            },
            'Trotzdem kündigen',
        ),
    ]);
}

export default CancellationRetainOrReject;
