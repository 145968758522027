import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function HotelOutline({ css }) {
    return h(
        Icon,
        {
            viewBox: '0 0 13 18',
            css,
        },
        [
            h('path', {
                fill: '#000820',
                d: oneLine`M5.66357 4.95964C5.66357 5.41987 5.29049 5.79297 4.83024 5.79297C4.37001 5.79297 3.99691 5.41987 3.99691 4.95964C3.99691 4.4994 4.37001 4.1263 4.83024 4.1263C5.29049 4.1263 5.66357 4.4994 5.66357 4.95964Z`,
            }),
            h('path', {
                fill: '#000820',
                d: oneLine`M8.16357 5.79297C8.62382 5.79297 8.99691 5.41987 8.99691 4.95964C8.99691 4.4994 8.62382 4.1263 8.16357 4.1263C7.70332 4.1263 7.33024 4.4994 7.33024 4.95964C7.33024 5.41987 7.70332 5.79297 8.16357 5.79297Z`,
            }),
            h('path', {
                fill: '#000820',
                d: oneLine`M8.16357 9.1263C8.62382 9.1263 8.99691 8.75322 8.99691 8.29297C8.99691 7.83272 8.62382 7.45964 8.16357 7.45964C7.70332 7.45964 7.33024 7.83272 7.33024 8.29297C7.33024 8.75322 7.70332 9.1263 8.16357 9.1263Z`,
            }),
            h('path', {
                fill: '#000820',
                d: oneLine`M4.83024 9.1263C5.29049 9.1263 5.66357 8.75322 5.66357 8.29297C5.66357 7.83272 5.29049 7.45964 4.83024 7.45964C4.37001 7.45964 3.99691 7.83272 3.99691 8.29297C3.99691 8.75322 4.37001 9.1263 4.83024 9.1263Z`,
            }),
            h('path', {
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                fill: '#000820',
                d: oneLine`M0.663574 1.6263V17.4596H12.3302V1.6263C12.3302 1.16607 11.9572 0.792969 11.4969 0.792969H1.49691C1.03667 0.792969 0.663574 1.16607 0.663574 1.6263ZM1.83024 1.95964V16.293H4.83024V11.6263C4.83024 11.1661 5.20332 10.793 5.66357 10.793H7.33024C7.79049 10.793 8.16357 11.1661 8.16357 11.6263V16.293H11.1636V1.95964H1.83024ZM6.99691 16.293H5.99691V11.9596H6.99691V16.293Z`,
            }),
        ],
    );
}
