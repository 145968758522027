import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function Close(options = {}) {
    return h(Icon, options, [
        h('path', {
            d: oneLine`
        M15.9 13.044L10.245 7.391A1.993 1.993 0 007.414 7.388a1.998 1.998 0 00.004
        2.832l5.653 5.653 -5.653 5.653a1.993 1.993 0 00-.004 2.832 1.998 1.998 0 002.832
        -.003L15.9 18.7l5.654 5.654a1.993 1.993 0 002.832 .003 1.998 1.998 0 00-.004
        -2.832l-5.653 -5.653L24.38 10.22a1.993 1.993 0 00.004 -2.832 1.998 1.998 0
        00-2.832 .003l-5.654 5.653z`,
        }),
    ]);
}
