import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import PropTypes from 'prop-types';

import { RentalcarButton } from '../utils/RentalcarButton';
import { waveHeadline2XS } from '../../../../styles/waveHeadlines';
import { waveTextM, waveTextS } from '../../../../styles/waveText';
import {
    waveLgMediaQuery,
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../../../styles/waveMediaQueries';
import { useCallbackAvailability } from '../../../common/useCallbackAvailability';
import { PhoneFilled } from '../../../icons/Phone';
import { useOpeningHours } from '../../hooks/useOpeningHours';

const Container = glamorous.div({
    position: 'relative',
    overflow: 'hidden',
    background:
        'linear-gradient(344deg, #F0F9FF -11.49%, #FBF7EA 44.93%, #DFF3F2 87.06%)',
    marginTop: options.space['3xl'],
});

const Content = glamorous.div({
    padding: `${options.space.m}px`,
    display: 'grid',
    gridTemplateColumns: 'auto minmax(0, 1fr)',
    gridTemplateAreas: '"image title" "button button"',
    gap: options.space.m,
    [waveSmMediaQuery]: {
        rowGap: 'unset',
        gridTemplateAreas: '"title button" "image button"',
    },
    [waveMdMediaQuery]: {
        gridTemplateColumns: 'auto minmax(0, 2fr)',
        gridTemplateAreas: '"image title title" "image button button"',
    },
    [waveLgMediaQuery]: {
        justifyContent: 'space-between',
        padding: `${options.space.xl}px ${options.space['5xl']}px`,
        columnGap: options.space.xl,
    },
});

const svgElement = glamorous.svg({
    pointerEvents: 'none',
    position: 'absolute',
    width: '585px',
    height: '100%',
    left: '-42px',
    [waveSmMediaQuery]: {
        height: '469px',
        top: '-201px',
    },
});

const SvgWave = () =>
    h(
        svgElement,
        {
            xmlns: 'http://www.w3.org/2000/svg',
            width: '422',
            height: '170',
            viewBox: '0 0 422 170',
            fill: 'none',
        },
        [
            h('path', {
                d: 'M-64.0227 -89H-116.689C-158.383 -60.7955 -181.424 -15.2655 -144.485 17.3711C-107.545 50.0077 -51.9535 38.323 -33.6667 71.3625C-15.3799 104.402 -130.554 145.728 -77.5549 213.594C-21.2314 285.716 75.6885 249.857 44.9667 380H411.434C411.434 380 435.207 321.576 411.434 285.314C349.121 190.261 172.033 209.967 122.503 162.826C72.9729 115.684 140.79 88.2852 114.457 37.1143C73.4636 -42.5443 -65.1199 0.0455322 -95.8417 -22.1151C-120.419 -39.8436 -89.7461 -74.0919 -64.0227 -89Z',
                fill: '#00C1B1',
                fillOpacity: '0.08',
            }),
        ],
    );

const ContactPersonImage = glamorous.img({
    gridArea: 'image',
    borderRadius: '50%',
    border: `1px solid ${options.colors.white}`,

    width: '87px',
    height: '87px',

    [waveSmMediaQuery]: {
        width: '90px',
        height: '90px',
    },
    [waveMdMediaQuery]: {
        width: '76px',
        height: '76px',
        borderWidth: options.space['2xs'],
    },
    [waveLgMediaQuery]: {
        width: '133px',
        height: '133px',
    },
});

const ButtonBox = glamorous.div({
    gridArea: 'button',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

const ButtonGroup = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: options.space.m,
    marginBottom: options.space.m,
    [waveMdMediaQuery]: {
        flexDirection: 'row',
    },
});

const TitleContainer = glamorous.div({
    gridArea: 'title',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [waveSmMediaQuery]: {
        justifyContent: 'start',
    },

    [waveMdMediaQuery]: {
        gap: options.space['2xs'],
        marginBottom: options.space.xl,
        flexDirection: 'row',
        justifyContent: 'center',
    },
});

const TitleQuestion = glamorous.span({
    ...waveHeadline2XS,
});

const TitleExpression = glamorous.span({
    ...waveTextM,
    [waveMdMediaQuery]: {
        ...waveHeadline2XS,
    },
});

const FeesInformation = glamorous.div({
    ...waveTextS,
    color: options.colors.tertiary,
    textAlign: 'center',
});

const WrappedButton = glamorous(RentalcarButton)({
    margin: 0,
    '&:not(:disabled):hover': {
        background: 'transparent',
        color: options.colors.blue20,
    },
    '&:visited': {
        borderColor: options.colors.link,
        color: options.colors.link,
    },
});

const svcAppointmentWidgetWidgetUrl =
    'https://www.holidaycheck.de/svc/appointment-widget/widget?queue=hcm';

function ContactBox(props, context) {
    const availability = useCallbackAvailability(
        'hcm',
        { pageName: 'MyRentalcarBookings', component: 'ContactBox' },
        context,
    );

    const openingHours = useOpeningHours(context);

    return h(Container, [
        h(SvgWave),
        h(Content, [
            h(ContactPersonImage, {
                src: `${context.config.assetsPath}/hcMietwagen/contact-person.png`,
            }),
            h(TitleContainer, [
                h(TitleQuestion, 'Noch Fragen?'),
                h(TitleExpression, ' Wir beraten Dich gerne.'),
            ]),
            h(ButtonBox, [
                h(ButtonGroup, [
                    h(
                        WrappedButton,
                        {
                            href: 'tel:+498914379153',
                            target: '_blank',
                        },
                        [
                            h(PhoneFilled, {
                                css: {
                                    width: `${options.space.l}px`,
                                },
                            }),
                            h('span', '+49 89 143 79 153'),
                        ],
                    ),
                    availability !== null &&
                        availability.active &&
                        h(
                            WrappedButton,
                            {
                                href: svcAppointmentWidgetWidgetUrl,
                                target: '_blank',
                            },
                            'Termin vereinbaren',
                        ),
                ]),
                openingHours !== null &&
                    h(
                        FeesInformation,
                        `${openingHours}. Zum Ortstarif, Mobilfunk abweichend.`,
                    ),
            ]),
        ]),
    ]);
}

ContactBox.contextTypes = {
    config: PropTypes.object,
    trackEvent: PropTypes.func,
    fetch: PropTypes.func,
    getCurrentDate: PropTypes.func,
};

ContactBox.displayName = 'ContactBox';

export { ContactBox };
