import glamorous from 'glamorous';
import options from '@designsystem/options';

export default glamorous.a({
    color: options.colors.black,
    cursor: 'pointer',
    textDecoration: 'none',
    border: 0,
    width: '100%',

    '> svg': {
        verticalAlign: 'bottom',
        width: 20,
        height: 20,
    },
});
