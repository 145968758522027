import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { pxToRem } from '../../styles/unitConverter';
import { gray40, gray90, gray100 } from '../../styles/waveColors';
import { applyOnMobile } from '../../styles/mediaQueries';
import formatDate from '../../lib/formatDate';
import getTravellersCount from './lib/getTravellersCount';
import {
    findRailAndFlyIncluded,
    findPackageTransfer,
} from './lib/serviceHelpers';
import transfersMapping from './lib/transfersMapping';
import { tinyFont } from '../../styles/fonts';
import { visuallyHidden } from '../../styles/accessibility';
import singularOrPlural from './lib/singularOrPlural';

const TravelInformationContainer = glamorous.div({
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    borderTop: `1px solid ${gray90}`,
    borderBottom: `1px solid ${gray90}`,
    margin: 0,
});
TravelInformationContainer.displayName = 'TravelInformationContainer';

const Item = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: `${pxToRem(12)} 0`,

    [applyOnMobile]: {
        '&:not(:last-child)': {
            borderBottom: `1px solid ${gray100}`,
        },
    },
});
Item.displayName = 'Item';

const ItemMainRow = glamorous.div({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
});
ItemMainRow.displayName = 'ItemMainRow';

const SubItemMainRow = glamorous.div(tinyFont, {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginTop: pxToRem(4),
    marginLeft: pxToRem(28),
});
SubItemMainRow.displayName = 'SubItemMainRow';

const Description = glamorous.div(tinyFont, {
    marginTop: pxToRem(2),
    marginLeft: pxToRem(28),
    maxWidth: '75%',
    color: gray40,

    [applyOnMobile]: {
        maxWidth: 'unset',
    },
});
Description.displayName = 'Description';

const Icon = glamorous.img({
    verticalAlign: 'text-top',
    width: pxToRem(18),
    marginRight: pxToRem(10),
});
Icon.displayName = 'Icon';

const Label = glamorous.span();
Label.displayName = 'Label';

const Info = glamorous.div({
    color: gray40,
});
Info.displayName = 'Info';

const HiddenHeading = glamorous.h2(visuallyHidden);

const getTravelDurationDays = (travelDurationDays) => {
    const dayOrDays = singularOrPlural(travelDurationDays, {
        singular: 'Tag',
        plural: 'Tage',
    });
    return `${dayOrDays}`;
};

const addIncludedServices = (summaryItems, services) => {
    const railAndFlyIncluded = findRailAndFlyIncluded(services);
    const packageTransfer = findPackageTransfer(services);

    if (railAndFlyIncluded) {
        summaryItems.push({
            label: 'Zug zum Flug',
            info: 'inklusive',
            icon: 'transfer-train.svg',
        });
    }

    if (packageTransfer && transfersMapping[packageTransfer.detail.type]) {
        summaryItems.push({
            label: 'Hoteltransfer',
            info: transfersMapping[packageTransfer.detail.type],
            icon: 'transfer-bus.svg',
        });
    }

    return summaryItems;
};

const getSummaryItems = (booking) => {
    const {
        midofficeId,
        startDate,
        endDate,
        tourOperator,
        tourOperatorBookingId,
        travelDurationDays,
        services,
        travellers,
    } = booking;

    const mandatorySummaryItems = [
        {
            label: 'HolidayCheck-Buchungsnummer',
            info: `ID${midofficeId}`,
            icon: 'offer.svg',
            description:
                'Verwende diese Nummer zur Kommunikation mit HolidayCheck',
        },
        {
            label: 'An- / Abreise',
            info: `${formatDate(startDate, 'DD.MM.Y')} bis ${formatDate(endDate, 'DD.MM.Y')}`,
            icon: 'calendar-blue.svg',
            subItem: {
                label: 'Reisedauer',
                info: getTravelDurationDays(travelDurationDays),
            },
        },
    ];

    if (travellers.length) {
        mandatorySummaryItems.push({
            label: 'Gästeanzahl',
            info: getTravellersCount(travellers),
            icon: 'person.svg',
        });
    }

    if (tourOperator.name) {
        mandatorySummaryItems.push({
            label: 'Veranstalter',
            info: tourOperator.name,
            icon: 'tag.svg',
            ...(Boolean(tourOperatorBookingId) && {
                subItem: {
                    label: 'Veranstalter-Vorgangsnummer',
                    info: tourOperatorBookingId,
                    description:
                        'Verwende diese Nummer zur Kommunikation mit dem Veranstalter und für den Check-In',
                },
            }),
        });
    }

    return addIncludedServices(mandatorySummaryItems, services);
};

const TravelInformation = (
    { booking, myRef, setWillRender },
    { config: { assetsPath } },
) => {
    const summaryItems = getSummaryItems(booking);

    useEffect(() => setWillRender(true), []);

    return h(TravelInformationContainer, { innerRef: myRef }, [
        h(HiddenHeading, 'Reiseübersicht'),
        summaryItems.map(({ label, info, icon, description, subItem }, index) =>
            h(
                Item,
                {
                    itemsCount: summaryItems.length,
                    key: index,
                },
                [
                    h(ItemMainRow, [
                        h('div', [
                            h(Icon, {
                                src: `${assetsPath}/myBookings/${icon}`,
                                alt: '',
                            }),
                            h(Label, label),
                        ]),
                        h(Info, { style: { marginLeft: pxToRem(28) } }, info),
                    ]),
                    description && h(Description, description),
                    subItem &&
                        h(SubItemMainRow, [
                            h(Label, subItem.label),
                            h(Info, subItem.info),
                        ]),
                    subItem &&
                        subItem.description &&
                        h(Description, subItem.description),
                ],
            ),
        ),
    ]);
};

TravelInformation.propTypes = {
    booking: PropTypes.shape({
        midofficeId: PropTypes.string.isRequired,
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
        tourOperator: PropTypes.shape({
            name: PropTypes.string,
        }).isRequired,
        services: PropTypes.array.isRequired,
        travellers: PropTypes.array,
    }).isRequired,
    // from using `useRef` hook, `ref` prop is treated differently and would need the `forwardRef` crap
    myRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
    setWillRender: PropTypes.func.isRequired,
};

TravelInformation.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

TravelInformation.displayName = 'TravelInformation';

export default TravelInformation;
