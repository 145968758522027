import gql from 'graphql-tag';

export const userRedemptions = gql`
    query {
        userRedemptions {
            redemptions {
                uuid
                releaseStatus
                bookingId
                hcBookingUuid
                isPremiumCategory
                startDate
                endDate
                expirationDate
                cashbackPrice {
                    amount
                    currency
                }
                haveBankDataBeenProvided
            }
        }
    }
`;
