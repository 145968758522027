import glamorous from 'glamorous';
import options from '@designsystem/options';

import {
    waveLgMediaQuery,
    waveMdMediaQuery,
} from '../../../../../styles/waveMediaQueries';

export const HeaderImage = glamorous.img({
    marginTop: `-${options.space['2xl'] + options.space.xl}px`,
    width: 119,
    height: 119,
    [waveMdMediaQuery]: {
        width: 150,
        height: 150,
        marginTop: `-${options.space['5xl'] + options.space.xl}px`,
    },
    [waveLgMediaQuery]: {
        marginTop: `-${options.space['6xl'] + options.space.xl}px`,
    },
});
