import ReactAvatarEditor from 'react-avatar-editor';
import { Component, Fragment } from 'react';
import glamorous from 'glamorous';
import h from 'react-hyperscript';

import ResizeObserver from '../interactive/ResizeObserver';
import Slider from '../inputs/Slider';
import hexToRGB from '../../lib/hexToRGB';
import colors from '../../styles/colors';
import { mediumDistance } from '../../styles/distances';

const {
    avatarEditorBackgroundColor,
    avatarEditorBorderColor,
    avatarEditorUnselectableAreaColor,
} = colors;

const CUTOUT_BORDER_WIDTH_IN_PX = 3;
const VERTICAL_CUTOUT_MARGIN_IN_PX = 20;
const UNSELECTABLE_AREA_OPACITY = 0.65;
const UNSELECTABLE_AREA_COLOR_AS_RGBA = hexToRGB(
    avatarEditorUnselectableAreaColor,
).concat(UNSELECTABLE_AREA_OPACITY);

// The container always has an aspect-ratio of 16/9. The editor will resize to match its dimensions.
const Container = glamorous.div(
    {
        height: 0,
        marginBottom: mediumDistance,
        paddingTop: '56.25%',
        position: 'relative',
        touchAction: 'none',

        ':after': {
            border: `${CUTOUT_BORDER_WIDTH_IN_PX}px solid ${avatarEditorBorderColor}`,
            borderRadius: '50%',
            bottom: `${VERTICAL_CUTOUT_MARGIN_IN_PX - CUTOUT_BORDER_WIDTH_IN_PX}px`,
            content: '""',
            display: 'block',
            pointerEvents: 'none',
            position: 'absolute',
            top: `${VERTICAL_CUTOUT_MARGIN_IN_PX - CUTOUT_BORDER_WIDTH_IN_PX}px`,
        },
    },
    ({ border }) => ({
        ':after': {
            left: `${border[0] - CUTOUT_BORDER_WIDTH_IN_PX}px`,
            right: `${border[0] - CUTOUT_BORDER_WIDTH_IN_PX}px`,
        },
    }),
);

class AvatarEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            border: [0, 0],
            borderRadius: 0,
            height: 0,
            position: {
                x: 0.5,
                y: 0.5,
            },
            scale: 0,
            width: 0,
        };

        this.updateConfiguration = this.updateConfiguration.bind(this);
        this.updatePositionInState = this.updatePositionInState.bind(this);
        this.updateScale = this.updateScale.bind(this);
    }

    updatePositionInState(position) {
        this.setState({ position });
    }

    updateConfiguration(rect) {
        const size = rect.height - VERTICAL_CUTOUT_MARGIN_IN_PX * 2;

        this.setState({
            border: [(rect.width - size) / 2, VERTICAL_CUTOUT_MARGIN_IN_PX],
            borderRadius: size / 2,
            height: size,
            scale:
                this.state.scale === 0 ? rect.height / size : this.state.scale,
            width: size,
        });
    }

    updateScale(scale) {
        this.setState({
            scale,
        });
    }

    render() {
        const {
            border,
            borderRadius,
            height,
            position: { x, y },
            scale,
            width,
        } = this.state;

        const { image } = this.props;

        return h(Fragment, [
            h(ResizeObserver, { onResize: this.updateConfiguration }, [
                h(Container, { border }, [
                    h(ReactAvatarEditor, {
                        ref: this.props.editorRef,
                        border,
                        borderRadius,
                        color: UNSELECTABLE_AREA_COLOR_AS_RGBA,
                        height,
                        image,
                        onPositionChange: this.updatePositionInState,
                        position: { x, y },
                        scale,
                        style: {
                            background: avatarEditorBackgroundColor,
                            bottom: 0,
                            left: 0,
                            position: 'absolute',
                            right: 0,
                            top: 0,
                        },
                        width,
                    }),
                ]),
            ]),
            h(Slider, {
                decreaseLabel: 'verkleinern',
                increaseLabel: 'vergrößern',
                onChange: this.updateScale,
                value: scale,
            }),
        ]);
    }
}

export default AvatarEditor;
