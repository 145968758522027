import h from 'react-hyperscript';
import { Fragment } from 'react';

import Album from './Album';

export default function Albums({ albums, areAlbumsIncomplete }) {
    return h(Fragment, [
        albums.map((album, index, keys) => {
            const isLastAlbum = index === keys.length - 1;
            const albumMightBeIncomplete = isLastAlbum && areAlbumsIncomplete;

            return h(
                Album,
                Object.assign(
                    {},
                    {
                        key: index,
                        mightBeIncomplete: albumMightBeIncomplete,
                    },
                    album,
                ),
            );
        }),
    ]);
}
