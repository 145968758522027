import 'glamor/reset';
import { ApolloClient } from '@apollo/client';
import { createBrowserHistory } from 'history';

// glamorous conditionally requires `prop-types`. rollup cannot resolve this.
// Therefore, we have to expose prop-types via `React.PropTypes`.
import './glamorousPropTypesWorkaround';

import App from './components/App';
import createApp from './app';

window.app = createApp(window, App, ApolloClient, createBrowserHistory);
