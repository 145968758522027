import React from 'react';
import h from 'react-hyperscript';
import options from '@designsystem/options';
import glamorous from 'glamorous';

import Link from '../../navigation/Link';
import { waveSmMediaQuery } from '../../../styles/waveMediaQueries';

const MainBody = glamorous.div({
    marginLeft: 0,
    fontSize: options.fontSizes.s,
    [waveSmMediaQuery]: {
        marginLeft: options.space['3xl'],
        fontSize: options.fontSizes.xs,
    },
});

const TextAndBottomSpace = glamorous.div({
    maxWidth: 650,
    marginBottom: options.space['4xl'],
    [waveSmMediaQuery]: {
        marginBottom: options.space['8xl'],
    },
});

class AccountDeletionInformation extends React.PureComponent {
    render() {
        return h(MainBody, [
            h(
                'div',
                {
                    style: {
                        marginTop: options.space['3xl'],
                        marginBottom: options.space.xs,
                        fontWeight: options.fontWeights.bold,
                    },
                },
                'Konto löschen',
            ),
            h(TextAndBottomSpace, [
                'Wünschst Du die Löschung Deines Kontos inklusive Deiner' +
                    ' personenbezogenen Daten? Schreibe HolidayCheck eine E-Mail an: ',

                h(
                    Link,
                    {
                        href: 'mailto:datenschutz@holidaycheck.com',
                    },
                    'datenschutz@holidaycheck.com',
                ),
                '.',
            ]),
        ]);
    }
}

export default AccountDeletionInformation;
