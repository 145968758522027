import * as R from 'ramda';

const truncateObjectToNumberOfValues = (object, limit) =>
    Object.keys(object).reduce(
        (accumulator, key) => {
            const values = object[key];

            if (accumulator.limit > 0) {
                return R.pipe(
                    R.assoc(
                        'result',
                        R.assoc(
                            key,
                            values.slice(0, accumulator.limit),
                            accumulator.result,
                        ),
                    ),
                    R.assoc('limit', accumulator.limit - values.length),
                )(accumulator);
            }

            return R.assoc('limit', 0, accumulator);
        },
        {
            limit,
            result: {},
        },
    ).result;

export default truncateObjectToNumberOfValues;
