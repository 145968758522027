import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function RightArrowLine(options = {}) {
    return h(Icon, options, [
        h('path', {
            d: oneLine`
        M18.19 16.02l-7.477 7.478a1.34 1.34 0 00.012 1.887l.906 .905a1.324 1.324 0
        001.887 .013l9.345 -9.345a1.314 1.314 0 00.382 -.93 1.291 1.291 0 00-.382
        -.945L13.518 5.738a1.34 1.34 0 00-1.887 .013l-.906 .905a1.324 1.324 0 00-.012
        1.887l7.477 7.477z`,
        }),
    ]);
}
