import { render } from 'react-dom';
import h from 'react-hyperscript';
import { InMemoryCache } from '@apollo/client';

import createCredentialsLink from './links/createCredentialsLink';
import introspectedPossibleTypes from './queries/fragmentTypes';
import dataIdFromObject from './queries/dataIdFromObject';
import trackEvent from './lib/trackEvent';

export default function createApp(window, Component, ApolloClient) {
    const mountNodeSelector = '#app-shell';

    return {
        run(appConfig) {
            const apolloClient = new ApolloClient({
                // this link will be applied for all requests to /mhc/api
                link: createCredentialsLink({
                    uri: `${appConfig.pathPrefix}/mhc/api`,
                    credentials: 'include',
                }),
                cache: new InMemoryCache({
                    possibleTypes: introspectedPossibleTypes,
                    dataIdFromObject,
                }),
            });

            const mountNode = window.document.querySelector(mountNodeSelector);

            const props = {
                config: appConfig,
                window,
                trackEvent,
                apolloClient,
            };

            render(h(Component, props), mountNode);
        },
    };
}
