import glamorous from 'glamorous';

const HideableControls = glamorous.div(
    {
        transition: 'opacity 0.35s ease-in-out, transform 0.35s ease-in-out',
    },
    ({ isHidden }) => ({
        opacity: isHidden ? 0 : null,
    }),
);

export default HideableControls;
