import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { oneLine } from 'common-tags';
import glamorous from 'glamorous';
import { Mutation } from '@apollo/client/react/components';

import Dialog from '../../dialog/Dialog';
import ChangeParticipantDataForm from '../ChangeParticipantDataForm';
import { changeTravelParticipants as changeTravelParticipantsMutation } from '../../../mutators/travelParticipantsData';
import { wasMutationSuccessful } from '../../../lib/changeTravelParticipantsMutation';
import { SALUTATION_VALUE_OPTIONS } from '../../../lib/salutations';
import Hint from '../Hint';
import TravellerContainer from '../TravellerContainer';
import { smallDistance } from '../../../styles/distances';
import { gray90 } from '../../../styles/waveColors';
import { pxToRem } from '../../../styles/unitConverter';
import { applyOnMobile } from '../../../styles/mediaQueries';
import PostDialogSubmitContent from './PostDialogSubmitContent';
import {
    getInitialErrors,
    getErrorCount,
    isButtonDisabled,
    getTravellersArray,
    setParticipantValue,
    validateOnBlur,
} from '../lib/changeParticipantDataHelpers';
import useBookingContext from '../lib/useBookingContext';
import DialogSubmitButton from './DialogSubmitButton';
import {
    REQUESTED_TRAVELLER_CHANGES_STORAGE_KEY,
    saveNewEntityToLocalStorage,
} from '../lib/dataRequestLocalStorageHelpers';

const TravellerFormContainer = glamorous.div({
    marginBottom: smallDistance,
    border: `1px solid ${gray90}`,
    borderRadius: pxToRem(4),
});
TravellerFormContainer.displayName = 'TravellerFormContainer';

const ContentWrapper = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    marginTop: pxToRem(-11),

    [applyOnMobile]: {
        height: `calc(100vh - ${pxToRem(121)})`,
    },
});
ContentWrapper.displayName = 'ContentWrapper';

const infoMessage = oneLine`Die Bearbeitung der Reiseteilnehmer ist unverbindlich und kann je nach
Reiseveranstalter bis zu 48 Stunden dauern. Sollte die Änderung kostenlos möglich sein,
werden wir sie direkt veranlassen.`;

const changeDataPostSubmitContent = ({ success, SubmitButton }) => {
    return success
        ? h(PostDialogSubmitContent, {
              success,
              messageIconRelPath: '/myBookings/message.svg',
              responseTitle: 'Vielen Dank für Deine Anfrage',
              responseText: oneLine`Bitte beachte: die Bearbeitung der Reiseteilnehmer ist unverbindlich und
            kann je nach Reiseveranstalter bis zu 48 Stunden dauern. Sollte die Änderung kostenlos möglich sein,
            werden wir sie direkt veranlassen.`,
          })
        : h(PostDialogSubmitContent, {
              success,
              responseTitle: 'Da hat etwas nicht geklappt!',
              responseText: oneLine`Leider ist etwas schief gelaufen und die Anfrage konnte nicht gesendet werden.
            Bitte versuche es erneut.`,
              SubmitButton,
          });
};

const ChangeParticipantDataDialog = (
    { travellers, tourOperator, bookingId, closeDialog },
    { localStorage, getCurrentDate },
) => {
    const [participants, setParticipants] = useState(travellers);
    const [errors, setErrors] = useState(getInitialErrors(travellers));
    const [openState, setOpenState] = useState(travellers.map(() => false));
    const { trackEventWithBooking } = useBookingContext();
    const [hasFormBeenSubmitted, setHasFormBeenSubmitted] = useState(false);
    const errorCount = getErrorCount(errors);

    const KEY_NAME_ENTER = 'Enter';
    const KEY_NAME_SPACE = ' ';
    const SUCCESS = 'SUCCESS';

    return h(
        Mutation,
        { mutation: changeTravelParticipantsMutation },
        (changeTravelParticipants, mutationState) => {
            const onSubmit = async () => {
                trackEventWithBooking({
                    event: 'event',
                    eventCategory: 'mybooking',
                    eventAction: 'submit',
                    eventLabel: 'participantDataChange',
                    ofOrganizerSelected: tourOperator.name || tourOperator.id,
                });
                try {
                    const res = await changeTravelParticipants({
                        variables: {
                            input: {
                                bookingId,
                                travellersInput: getTravellersArray(
                                    participants,
                                    travellers,
                                ),
                            },
                        },
                    });
                    if (res.data.changeTravelParticipants === SUCCESS) {
                        saveNewEntityToLocalStorage({
                            entity: participants,
                            dateTime: getCurrentDate(),
                            bookingId,
                            storageKey: REQUESTED_TRAVELLER_CHANGES_STORAGE_KEY,
                            localStorage,
                        });
                    }
                } catch (e) {
                    // will be handled by ChangeDataPostSubmitContent
                } finally {
                    setHasFormBeenSubmitted(true);
                }
            };

            return h(
                Dialog,
                {
                    title: 'Änderungsanfrage: Reiseteilnehmer',
                    onClose: closeDialog,
                },
                [
                    !hasFormBeenSubmitted
                        ? h(ContentWrapper, [
                              h(Hint, { prefix: 'Bitte beachte' }, infoMessage),
                              participants.map((participant, index) => {
                                  return h(
                                      TravellerFormContainer,
                                      { key: `traveller-${index}` },
                                      [
                                          h(
                                              'div',
                                              {
                                                  onClick: () => {
                                                      setOpenState(
                                                          openState.map(
                                                              (val, i) => {
                                                                  return i ===
                                                                      index
                                                                      ? !val
                                                                      : val;
                                                              },
                                                          ),
                                                      );
                                                  },
                                                  onKeyDown: (e) => {
                                                      if (
                                                          e.key ===
                                                              KEY_NAME_ENTER ||
                                                          e.key ===
                                                              KEY_NAME_SPACE
                                                      ) {
                                                          setOpenState(
                                                              openState.map(
                                                                  (val, i) => {
                                                                      return i ===
                                                                          index
                                                                          ? !val
                                                                          : val;
                                                                  },
                                                              ),
                                                          );
                                                      }
                                                  },
                                                  tabIndex: 0,
                                              },
                                              h(TravellerContainer, {
                                                  traveller: participant,
                                                  index,
                                                  isOpen: openState[index],
                                              }),
                                          ),
                                          openState[index] &&
                                              h(ChangeParticipantDataForm, {
                                                  participant,
                                                  traveller: travellers[index],
                                                  setParticipantValue:
                                                      setParticipantValue(
                                                          participants,
                                                          setParticipants,
                                                      ),
                                                  index,
                                                  errors: errors[index],
                                                  validateOnBlur:
                                                      validateOnBlur({
                                                          errors,
                                                          setErrors,
                                                      }),
                                              }),
                                      ],
                                  );
                              }),
                              h(
                                  DialogSubmitButton,
                                  {
                                      disabled: isButtonDisabled({
                                          travellers,
                                          participants,
                                          errorCount,
                                      }),
                                      loading: mutationState.loading,
                                      onClick: onSubmit,
                                  },
                                  'Anfrage Absenden',
                              ),
                          ])
                        : changeDataPostSubmitContent({
                              success: wasMutationSuccessful(mutationState),
                              SubmitButton: h(
                                  DialogSubmitButton,
                                  {
                                      disabled: isButtonDisabled({
                                          travellers,
                                          participants,
                                          errorCount,
                                      }),
                                      loading: mutationState.loading,
                                      onClick: onSubmit,
                                  },
                                  'Anfrage Erneut Absenden',
                              ),
                          }),
                ],
            );
        },
    );
};
ChangeParticipantDataDialog.displayName = 'ChangeParticipantDataDialog';

ChangeParticipantDataDialog.propTypes = {
    bookingId: PropTypes.string.isRequired,
    closeDialog: PropTypes.func.isRequired,
    travellers: PropTypes.arrayOf(
        PropTypes.shape({
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            salutation: PropTypes.oneOf(SALUTATION_VALUE_OPTIONS),
            dateOfBirth: PropTypes.string,
            nationality: PropTypes.string,
        }),
    ).isRequired,
    tourOperator: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string,
    }),
};

ChangeParticipantDataDialog.contextTypes = {
    localStorage: PropTypes.object,
    getCurrentDate: PropTypes.func,
};

export default ChangeParticipantDataDialog;
