import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function ProfileEdit(options = {}) {
    return h(Icon, options, [
        h('path', {
            d: oneLine`
        M21.428 3.973H6.868c-.849 0 -1.535 .673 -1.535 1.502v20.997c0 .834 .687 1.501
        1.535 1.501h18.264c.849 0 1.535 -.672 1.535 -1.501V11.934L24
        14.601v10.706H8V6.64h10.761l2.667 -2.667zm3.01 .734l2.828 2.828 -10.37 10.371
        -2.829 -2.828L24.437 4.707zM12.653 19.321l3.3 -.472 -2.829 -2.828 -.471
        3.3zm13.67 -16.5a1.329 1.329 0 011.885 0l.945 .944a1.33 1.33 0 01-.001
        1.885l-.943 .943L25.381 3.764l.943 -.943z`,
        }),
    ]);
}
