export default (attribute, toReplace, replaceWith) => {
    return (element) => {
        const value = element.getAttribute(attribute);

        if (value) {
            const replaced = value.replace(toReplace, replaceWith);
            element.setAttribute(attribute, replaced);
        }
    };
};
