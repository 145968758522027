import gql from 'graphql-tag';

export const userProfileCard = gql`
    query {
        profile {
            givenName
            familyName
            countryName
            profilePicture {
                transformations {
                    medium
                }
            }
        }
        travelPreferencesForUser {
            vacationTypes {
                beach
                city
                divingAndSnorkeling
                longDistance
                round
                shortTrip
                skiAndHiking
                wellness
            }
        }
    }
`;

export const badgesCard = gql`
    query {
        badgesForUser(limit: 6) {
            items {
                id
                name
                description
            }
        }
    }
`;

export const reviewCard = gql`
    query {
        hotelReviewsForUser {
            items {
                title
                recommendation
                travelDate
                ratings {
                    GENERAL {
                        GENERAL
                    }
                }
                hotel {
                    id
                    name
                    parents {
                        name
                        destinationType
                    }
                }
            }
        }
        unpublishedReviewsForUser {
            items {
                title
                recommendation
                travelDate
                ratings {
                    GENERAL {
                        GENERAL
                    }
                }
                hotel {
                    id
                    name
                    parents {
                        name
                        destinationType
                    }
                }
            }
        }
    }
`;

export const voucherCard = gql`
    query {
        vouchersForUser {
            vouchers {
                status
                code
                value
                currency
                validUntil
            }
        }

        termsAndConditionsForUser {
            consent
        }
    }
`;
