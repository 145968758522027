import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { Query } from '@apollo/client/react/components';
import { Fragment, useState } from 'react';

import { applyOnMobile } from '../../styles/mediaQueries';
import { gray90 } from '../../styles/waveColors';
import { pxToRem } from '../../styles/unitConverter';
import { xxxlargeDistance } from '../../styles/distances';
import { noticeError } from '../../lib/newrelic';
import { userRedemptions } from '../../queries/userRedemptions';
import RedemptionsList from './RedemptionsList';
import ModalButton from '../navigation/ModalButton';
import BankDataFormDialog from './BankDataFormDialog';

const SummaryContainer = glamorous.div(({ withMargin }) => ({
    display: 'flex',
    borderTop: `1px solid ${gray90}`,
    marginBottom: withMargin ? pxToRem(16) : 0,
    justifyContent: 'center',
    marginLeft: xxxlargeDistance,
    marginRight: xxxlargeDistance,

    [applyOnMobile]: {
        flexWrap: 'wrap',
    },
}));
SummaryContainer.displayName = 'SummaryContainer';

const HolidayCheckPremiumBanner = glamorous.div(() => ({
    fontWeight: 700,
    color: '#fff',
    borderRadius: '4px',
    textAlign: 'center',
    padding: '0.25em',
    backgroundColor: '#7334A7',
}));
HolidayCheckPremiumBanner.displayName = 'HolidayCheckPremiumBanner';

const Redemptions = () => {
    const [isBankDataDialogOpen, setBankDataDialogOpen] = useState(false);
    const [bookingId, setBookingId] = useState('');
    const [voucherExpirationDate, setVoucherExpirationDate] =
        useState('01.01.2099');

    const dateLocale = 'de-DE';
    const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    };

    const getRedemptionStatus = (redemption) => {
        switch (redemption.releaseStatus) {
            case 'EXPIRED':
                return 'abgelaufen';
            case 'REDEEMED':
                return redemption.haveBankDataBeenProvided
                    ? 'eingelöst'
                    : h(Fragment, [
                          h(ModalButton, {
                              onClick: () => {
                                  setBookingId(redemption.bookingId);
                                  setVoucherExpirationDate(
                                      new Date(
                                          redemption.expirationDate,
                                      ).toLocaleDateString(dateLocale, options),
                                  );
                                  setBankDataDialogOpen(true);
                              },
                              text: 'Bitte Bankdaten eintragen',
                          }),
                      ]);
            case 'REVIEW':
                return 'eingelöst';
            case 'REJECTED':
                return 'ungültig';
            case 'READY':
                return 'Auszahlung in Bearbeitung';
            case 'RELEASED':
                return 'Auszahlung erfolgt';
            default:
                return '';
        }
    };

    const getRedemptionTableData = (redemptions) => {
        return redemptions
            .toSorted((r1, r2) => r2.bookingId - r1.bookingId)
            .map((redemption) => {
                const formattedStartDate = new Date(
                    redemption.startDate,
                ).toLocaleDateString(dateLocale, options);
                const formattedEndDate = new Date(
                    redemption.endDate,
                ).toLocaleDateString(dateLocale, options);
                const formattedId = `ID${redemption.bookingId}`;
                const formattedExpirationDate = new Date(
                    redemption.expirationDate,
                ).toLocaleDateString(dateLocale, options);
                const formattedValue = `${redemption.cashbackPrice.amount} ${redemption.cashbackPrice.currency}`;

                const formattedStatus = getRedemptionStatus(redemption);
                const formattedPremiumStatus = redemption.isPremiumCategory
                    ? h(HolidayCheckPremiumBanner, [], 'HolidayCheck Premium')
                    : ' ';

                return {
                    travelDates: `${formattedStartDate} - ${formattedEndDate}`,
                    bookingId: formattedId,
                    expirationDate: formattedExpirationDate,
                    voucherValue: formattedValue,
                    redemptionStatus: formattedStatus,
                    premiumStatus: formattedPremiumStatus,
                };
            });
    };

    return h(
        Query,
        { fetchPolicy: 'cache-and-network', query: userRedemptions },
        ({ loading, data, error, refetch }) => {
            if (loading || error || !data.userRedemptions) {
                if (error) {
                    noticeError(error);
                }
                return null;
            }

            const redemptions = data.userRedemptions.redemptions || [];
            return h(Fragment, [
                h(RedemptionsList, {
                    tableData: getRedemptionTableData(redemptions),
                }),
                isBankDataDialogOpen &&
                    h(BankDataFormDialog, {
                        bookingId,
                        redemptions,
                        voucherExpirationDate,
                        closeDialog: () => setBankDataDialogOpen(false),
                        queryRefetchCallback: refetch,
                    }),
            ]);
        },
    );
};

export default Redemptions;
