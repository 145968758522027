import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import {
    gray,
    gray20,
    gray40,
    black,
    gray90,
} from '../../../../styles/waveColors';
import { xsmallDistance, smallDistance } from '../../../../styles/distances';
import { tinyFont, secondaryFont } from '../../../../styles/fonts';
import { pxToRem } from '../../../../styles/unitConverter';
import { getFormattedAirportName } from '../../lib/airport';
import { getDuration, getTimeOfDay } from '../../lib/dateFormatting';

const FlightDetailsContainer = glamorous.div({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
});
FlightDetailsContainer.displayName = 'FlightDetailsContainer';

const DetailsRow = glamorous.div({
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'baseline',
});
DetailsRow.displayName = 'DetailsRow';

const Time = glamorous.div({
    ...secondaryFont.UITextMLoud,
    color: black,
    width: pxToRem(44),
    minWidth: pxToRem(44),
});
Time.displayName = 'Time';

const FlightTimeSummary = glamorous.div({
    ...tinyFont,
    color: gray20,
    margin: `${xsmallDistance} 0 ${xsmallDistance} ${pxToRem(24)}`,
});
FlightTimeSummary.displayName = 'FlightTimeSummary';

const FilledDot = glamorous.div({
    width: pxToRem(8),
    minWidth: pxToRem(8),
    height: pxToRem(8),
    backgroundColor: gray40,
    borderRadius: '100%',
    marginLeft: pxToRem(2.5),
    marginRight: smallDistance,
});
FilledDot.displayName = 'FilledDot';

const OutlinedDot = glamorous.div({
    width: pxToRem(6),
    minWidth: pxToRem(6),
    height: pxToRem(6),
    border: `1px solid ${gray40}`,
    borderRadius: '100%',
    marginLeft: pxToRem(2.5),
    marginRight: smallDistance,
});
OutlinedDot.displayName = 'OutlinedDot';

const FirstLine = glamorous.div({
    position: 'absolute',
    top: '1.2rem',
    bottom: 0,
    marginLeft: pxToRem(6),
    width: 0,
    borderLeft: `1px solid ${gray}`,
});

const SecondLine = glamorous.div({
    position: 'absolute',
    top: 0,
    bottom: '-.4rem',
    marginLeft: pxToRem(6),
    width: 0,
    borderLeft: `1px solid ${gray}`,
});

const ThirdLine = glamorous.div({
    position: 'absolute',
    top: '1.2rem',
    bottom: 0,
    width: 0,
    borderLeft: `1px dashed ${gray90}`,
    marginLeft: pxToRem(6),
});

const AirportName = glamorous.div({
    overflowWrap: 'break-word',
});

const getStopSummary = (stops) => {
    switch (stops) {
        case 0:
            return 'Direktflug';
        case 1:
            return '1 Stopp';
        default:
            return `${stops} Stopps`;
    }
};

const getFlightTimeSummary = (stops, firstFlight, lastFlight) => {
    const flightTimeSummary = [];

    if (firstFlight.date && lastFlight.date) {
        if (stops >= 0) {
            flightTimeSummary.push(getStopSummary(stops));
            flightTimeSummary.push(' • ');
        }

        flightTimeSummary.push(getDuration(firstFlight.date, lastFlight.date));
    }
    return flightTimeSummary;
};

const FlightDetails = ({
    departure,
    arrival,
    stops,
    isFirst = true,
    isLast = true,
}) => {
    return h(FlightDetailsContainer, [
        h(DetailsRow, [
            isFirst ? h(FilledDot) : h(OutlinedDot),
            departure.date && h(Time, getTimeOfDay(departure.date)),
            h(AirportName, getFormattedAirportName(departure)),
            h(FirstLine),
        ]),
        h(DetailsRow, [
            h(SecondLine),
            h(
                FlightTimeSummary,
                getFlightTimeSummary(stops, departure, arrival),
            ),
        ]),
        h(DetailsRow, [
            isLast ? h(FilledDot) : h(OutlinedDot),
            arrival.date && h(Time, getTimeOfDay(arrival.date)),
            h(AirportName, getFormattedAirportName(arrival)),
            !isLast && h(ThirdLine),
        ]),
    ]);
};

const FlightPropType = PropTypes.shape({
    date: PropTypes.string,
    airportName: PropTypes.string,
    airportCode: PropTypes.string,
});

FlightDetails.propTypes = {
    departure: FlightPropType.isRequired,
    arrival: FlightPropType.isRequired,
    stops: PropTypes.number,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
};

export default FlightDetails;
