import { useEffect, useState } from 'react';

import { fetchAllRentalCarArticles } from '../../../../fetchers/fetchRentalcarArticles';

export function useRentalcarArticles(context, articleDestinationId, bookingId) {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        (async () => {
            const response = await fetchAllRentalCarArticles(
                context,
                articleDestinationId,
                bookingId,
            );
            setArticles(response);
        })();
    }, [articleDestinationId, bookingId]);

    return articles;
}
