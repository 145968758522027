import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import {
    waveHeadline2XSSlim,
    waveHeadlineSSlim,
    waveHeadlineXSSlim,
} from '../../styles/waveHeadlines';
import { blue } from '../../styles/waveColors';
import { pxToRem } from '../../styles/unitConverter';
import PremiumCashbackItem from './PremiumCashbackItem';
import {
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../styles/waveMediaQueries';
import {
    CAR_RENTAL_CASHBACK_ITEM,
    filterByOpenAndPromotionalWithValidityCashback,
} from '../../lib/premium/cashback';
import { usePremiumTrackingContext } from './context/PremiumTrackingContext';

const PremiumCashbackContainer = glamorous.div({
    maxWidth: pxToRem(1200),
    margin: '0 auto',
    padding: `0 ${pxToRem(options.space.l)}`,
});
PremiumCashbackContainer.displayName = 'PremiumCashbackContainer';

const HeaderContainer = glamorous.div({
    textAlign: 'center',
    margin: '0 auto',
    maxWidth: '792px',
    marginBottom: options.space['5xl'],
});
HeaderContainer.displayName = 'HeaderContainer';

const Title = glamorous.h1({
    ...waveHeadline2XSSlim,
    marginTop: pxToRem(options.space['6xl']),
    [waveSmMediaQuery]: {
        ...waveHeadlineXSSlim,
    },
    [waveMdMediaQuery]: {
        ...waveHeadlineSSlim,
        marginTop: pxToRem(options.space['7xl']),
    },
    marginBottom: pxToRem(options.space['2xl']),
});
Title.displayName = 'Title';

const Link = glamorous.a({
    fontFamily: 'PT Sans',
    color: blue,
    textDecoration: 'none',
});
Link.displayName = 'Link';

const CashbackContainer = glamorous.div({
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    gap: pxToRem(options.space.l),
    [waveSmMediaQuery]: {
        gap: pxToRem(options.space.m),
    },
    [waveMdMediaQuery]: {
        gap: pxToRem(options.space.l),
    },
});
CashbackContainer.displayName = 'CashbackContainer';

export default function PremiumCashback({ activePeriod }) {
    const { trackEventForPremium } = usePremiumTrackingContext();
    if (!activePeriod) {
        return null;
    }

    const periodEndDate = activePeriod.periodEndDate;

    const cashbackWithCarRental = [
        ...activePeriod.benefits.cashback.filter(
            filterByOpenAndPromotionalWithValidityCashback,
        ),
        CAR_RENTAL_CASHBACK_ITEM,
    ];

    return h(PremiumCashbackContainer, {}, [
        h(HeaderContainer, {}, [
            h(Title, 'Meine Gutscheine'),
            h(
                'p',
                'Die Gutscheine werden bei der Buchung direkt angewandt und nach Reiserückkehr ausbezahlt. ' +
                    'Um sicherzustellen, dass Du maximal sparst, ' +
                    'lösen wir nach Möglichkeit immer den Gutschein mit dem höchsten Betrag ein.',
            ),
            h(
                Link,
                {
                    href: '/mhc/meine-gutscheine',
                    onClick: () =>
                        trackEventForPremium({
                            event: {
                                eventLabel: 'myCashback',
                                eventAction: 'Click-Link',
                            },
                        }),
                },
                'Deine bisher eingelösten Gutscheine findest Du hier.',
            ),
        ]),
        h(CashbackContainer, {}, [
            cashbackWithCarRental.map((cashbackItem, index) =>
                h(PremiumCashbackItem, {
                    cashbackItem,
                    periodEndDate,
                    key: index,
                }),
            ),
        ]),
    ]);
}
