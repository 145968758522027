const countryImages = {
    spanien: 'daac1f2d-bcf8-462c-a035-ba8ebf9e1699.png',
    italien: '65be2fd6-e0e5-40bb-970e-29b2f27720ec.jpg',
    portugal: 'ca500de1-dfe7-4d9a-8f4d-0ccc59e52c4f.jpg',
    griechenland: '18ddb061-83b3-4f39-8c1a-4b9f31d86c21.png',
    türkei: '7bbf30f9-6f68-402e-a36d-9ccd99b40065.jpg',
    zypern: '8eb5e552-3b37-4c1c-8e95-b3455426be5d.jpg',
    malta: '8559c701-d2e4-4292-ac12-80b17f4bb702.png',
};

export const getImageUrl = (country) => {
    const image =
        countryImages[country.toLowerCase()] ||
        '2fc708f3-6157-418f-9cf4-fb4ac4026522.jpg';
    return `https://www.holidaycheck.de/mietwagen/assets-image/768x420/${image}`;
};
