import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import {
    waveHeadline2XS,
    waveHeadlineXS,
} from '../../../../styles/waveHeadlines';
import { pxToRem } from '../../../../styles/unitConverter';
import { waveTextMButton } from '../../../../styles/waveText';
import useInScreen from '../../../../lib/hooks/useInScreen';
import { clubOffers } from '../../../../queries/clubOffers';
import { noticeError } from '../../../../lib/newrelic';
import { premiumGradient } from '../../../../styles/waveColors';

const PremiumLogo = glamorous.img({
    ...waveHeadline2XS,
    width: options.space['8xl'],
});

const PremiumBenefits = glamorous.ul({
    margin: 0,
    padding: 0,
    listStyle: 'none',
    '& li': {
        wordBreak: 'break-all',
        color: options.colors.purple,
        position: 'relative',
        paddingLeft: options.space.l,
        paddingBottom: options.space.s,
    },
    '& img': {
        position: 'absolute',
        top: '2px',
        width: options.space.m,
        left: '-2px',
    },
});

const OldPrice = glamorous.span({
    textDecoration: 'line-through',
});

const NewPrice = glamorous.span({
    fontWeight: options.fontWeights.bold,
});

const CtaButton = glamorous.a({
    display: 'block',
    maxWidth: '240px',
    border: `1px solid ${options.colors.purple}`,
    borderRadius: pxToRem(100),
    padding: `${options.space.xs}px ${options.space['2xs']}px`,
    textAlign: 'center',
    color: options.colors.purple,
    textDecoration: 'none',
    ...waveTextMButton,
    fontWeight: options.fontWeights.bold,

    '&:hover': {
        color: options.colors.purple,
    },
});

const TrackerComponent = ({ trackEventForPremium, children }) => {
    const [setRef] = useInScreen(() =>
        trackEventForPremium({
            event: {
                eventCategory: 'Premium-Club',
                eventAction: 'Premium-Ad-Card-shown',
            },
        }),
    );

    return h('div', { ref: setRef, height: 0 }, children);
};

const spark = (assetsPath) => {
    return h('img', {
        src: `${assetsPath}/myPremium/spark.svg`,
    });
};

const generatePriceComponent = (campaign, priceWithCurrency) => {
    let priceComponent = h('span', {}, priceWithCurrency);

    if (campaign) {
        priceComponent = h('span', {}, [
            h(OldPrice, priceWithCurrency),
            h(
                NewPrice,
                ` ${campaign.subscription.amount} ${campaign.subscription.currency}`,
            ),
        ]);
    }
    return priceComponent;
};

const PremiumContent = glamorous.div((props) => ({
    backgroundSize: 'contain',
    backgroundColor: options.colors.purple100,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
    padding: `${options.space.l}px ${options.space.xl}px`,
    display: 'flex',
    flexDirection: 'column',
    gap: `${options.space.m}px`,
    backgroundImage: props.backgroundImage,
}));

const CampaignSection = glamorous.div({
    background: premiumGradient,
    color: options.colors.white,
    padding: `${options.space.l}px ${options.space.xl}px`,
});

const Headline = glamorous.div({
    ...waveHeadlineXS,
});

const PremiumAdCard = ({ trackEventForPremium }, { config }) => {
    const { loading, error, data } = useQuery(clubOffers);

    if (error) {
        noticeError(error, {
            pageName: 'Dashboard',
            component: 'PremiumAdCard',
        });

        return null;
    }

    if (!data || loading) {
        return null;
    }

    const price = data.clubOffers.items[0].detail.price;
    const campaign = data.clubOffers.items[0].detail.campaign;

    const priceWithCurrency = `${price.amount} ${price.currency}`;

    return h(TrackerComponent, { trackEventForPremium }, [
        campaign &&
            h(CampaignSection, [
                h(Headline, campaign.hero.text),
                h(
                    'div',
                    `Verfügbar bis ${new Date(
                        campaign.timeFrame.endDate,
                    ).toLocaleDateString('de-DE', {
                        day: '2-digit',
                        month: '2-digit',
                    })}`,
                ),
            ]),
        h(
            PremiumContent,
            {
                backgroundImage: `url('${config.assetsPath}/myHolidayCheck/premium_card_bg.png')`,
            },
            [
                h(PremiumLogo, {
                    src: `${config.assetsPath}/myHolidayCheck/hc_premium.svg`,
                }),
                h('div', [
                    '150 € Geld zurück und noch viele weitere exklusive Vorteile für nur ',
                    generatePriceComponent(campaign, priceWithCurrency),
                    ' im Jahr',
                ]),
                h(
                    PremiumBenefits,
                    data.clubOffers.items[0].detail.services.map((service) =>
                        h('li', [spark(config.assetsPath), service.summary]),
                    ),
                ),
                h(
                    CtaButton,
                    {
                        href: '/premium-mitglied-werden',
                        target: '_blank',
                        onClick() {
                            trackEventForPremium({
                                event: {
                                    eventCategory: 'Premium-Club',
                                    eventAction: 'Click-Mitglied-werden',
                                    eventLabel: 'Premium-Ad-Card',
                                },
                            });
                        },
                    },
                    'JETZT MITGLIED WERDEN',
                ),
            ],
        ),
    ]);
};

export default PremiumAdCard;

PremiumAdCard.contextTypes = {
    config: PropTypes.object,
};
