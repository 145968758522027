import glamorous from 'glamorous';
import h from 'react-hyperscript';
import options from '@designsystem/options';

import { tinyFont } from '../../styles/fonts';
import Thumb from '../icons/Thumb';
import formatNumber from '../../lib/formatNumber';
import { pxToRem } from '../../styles/unitConverter';

const iconSize = 16;
const defaultLabelStyle = {
    ...tinyFont,
    color: 'white',
    marginBottom: 2,
};
const iconProps = {
    css: {
        display: 'inline-block',
        height: pxToRem(iconSize),
        marginRight: 6,
        marginLeft: `${options.space.s}px`,
        verticalAlign: 'text-top',
        width: pxToRem(iconSize),
    },
};

const LabelWithIcon = glamorous.span(defaultLabelStyle);

function AlbumItemHelpfulLabel({ helpfulCount }) {
    if (!helpfulCount) {
        return null;
    }

    return h(LabelWithIcon, { css: { fontWeight: 600 } }, [
        h(Thumb, iconProps),
        formatNumber(helpfulCount),
    ]);
}

export default AlbumItemHelpfulLabel;
