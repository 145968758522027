import { stripIndent } from 'common-tags';

import graphqlFetch from '../lib/graphqlFetch';

const fetch = async (query, context, variables = {}) => {
    const response = await graphqlFetch(context, { query, variables });

    if (!response.ok) {
        return null;
    }

    const jsonResponse = await response.json();
    return jsonResponse.data && jsonResponse.data.rentalCarHub
        ? jsonResponse.data.rentalCarHub
        : null;
};

/**
 * Fetches HCM knowledge hub data
 * @param {Object} context - Application context
 * @param {string} destinationId - Destination ID
 * @returns {Promise<Object|null>} Hub data or null if not found
 */
export function fetchHcmKnowledgeHub(context, destinationId) {
    if (!context || !destinationId) {
        return Promise.resolve(null);
    }

    const query = stripIndent`
        query RentalcarHub($destinationId: String) {
          rentalCarHub(destinationId: $destinationId) {
            name
            url
            hubIcon
          }
        }
    `;

    return fetch(query, context, { destinationId });
}
