import h from 'react-hyperscript';
import glamorous from 'glamorous';

import { smallDistance, mediumDistance } from '../styles/distances';
import colors from '../styles/colors';
import { tinyFont } from '../styles/fonts';

const { separatorColor, counterTextColor } = colors;

const CounterElement = glamorous.div(tinyFont, {
    color: counterTextColor,
    textAlign: 'center',
    fontWeight: '600',
    paddingTop: smallDistance,
    paddingBottom: smallDistance,
    marginLeft: mediumDistance,
    marginRight: mediumDistance,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: separatorColor,
});

export default function Counter(props) {
    const { count, label } = props;

    return count === 1
        ? h(CounterElement, `${count} ${label.singular}`)
        : h(CounterElement, `${count} ${label.plural}`);
}
