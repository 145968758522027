import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import options from '@designsystem/options';

import { secondaryFont } from '../../../styles/fonts';
import { pxToRem } from '../../../styles/unitConverter';
import {
    isTravelHealthInsuranceIncluded,
    isTravelHealthInsuranceBookable,
} from '../lib/serviceHelpers';
import Link from '../../navigation/Link';

const { PTSansFamily } = secondaryFont;

const ServiceContainer = glamorous.div({
    display: 'flex',
    marginTop: pxToRem(16),
    padding: `0 ${pxToRem(24)}`,
    '&:last-child': {
        paddingBottom: `${pxToRem(16)}`,
    },
    '&:not(:last-of-type):not(:first-of-type)': {
        borderTop: `1px solid ${options.colors.gray100}`,
        borderBottom: `1px solid ${options.colors.gray100}`,
        paddingTop: `${pxToRem(options.space.s)}`,
        paddingBottom: `${pxToRem(options.space.s)}`,
    },
});

const ServiceNameContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '90%',
    hyphens: 'auto',
});

const ServiceName = glamorous.h5({
    display: 'inline',
    margin: 0,
    font: 'inherit',
});
ServiceName.displayName = 'ServiceName';

const Price = glamorous.div({
    whiteSpace: 'nowrap',
});
Price.displayName = 'Price';

const Status = glamorous.div({
    textAlign: 'right',
});
Status.displayName = 'Status';

const Comment = glamorous.div(PTSansFamily, {
    color: options.colors.gray40,
    whiteSpace: 'pre-wrap',
});
Comment.displayName = 'Comment';

const PolicyNumber = glamorous.div({
    fontSize: options.fontSizes['2xs'],
    color: options.colors.white,
    background: options.colors.purple,
    borderRadius: options.radii.small,
    padding: `${pxToRem(options.space['2xs'])} ${pxToRem(options.space.xs)}`,
});

const scrollToHealthInsuranceSection = (event) => {
    event.preventDefault();

    let element = event.target;

    while (element) {
        const foundElement = element.querySelector('#versicherung');
        if (foundElement) {
            foundElement.scrollIntoView({
                behavior: 'smooth',
            });
            return;
        }
        element = element.parentElement;
    }
};

const extractComment = ({ service, startDate }) => {
    if (isTravelHealthInsuranceIncluded(service)) {
        return 'Hanse-Merkur Versicherungsnummer';
    }

    if (isTravelHealthInsuranceBookable(startDate)) {
        return [
            'Deine Versicherung kannst Du jetzt ',
            h(
                Link,
                {
                    href: '#',
                    color: options.colors.blue,
                    onClick: scrollToHealthInsuranceSection,
                },
                'kostenlos aktivieren.',
            ),
        ];
    }

    return [
        'Deine Versicherung kannst Du',
        h('b', ' 10 Tage '),
        'vor Abreise kostenlos aktivieren',
    ];
};

const extractStatus = ({ service, startDate }) => {
    if (isTravelHealthInsuranceIncluded(service))
        return [
            'aktiviert',
            service.detail.policy && h(PolicyNumber, service.detail.policy),
        ];

    if (isTravelHealthInsuranceBookable(startDate))
        return [
            h(
                Link,
                {
                    href: '#',
                    onClick: scrollToHealthInsuranceSection,
                    color: options.colors.blue,
                },
                'aktivieren',
            ),
        ];

    return 'optional';
};

const PremiumHealthInsuranceService = ({ service, startDate }) => {
    if (!service) return null;

    return h(ServiceContainer, [
        h(ServiceNameContainer, [
            h(ServiceName, service.detail.title),
            h(Comment, extractComment({ service, startDate })),
        ]),
        h(Status, extractStatus({ service, startDate })),
    ]);
};

const InsuranceServiceDetail = PropTypes.shape({
    title: PropTypes.string.isRequired,
    comment: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    status: PropTypes.string,
});

PremiumHealthInsuranceService.propTypes = {
    service: PropTypes.shape({
        type: PropTypes.string.isRequired,
        detail: PropTypes.oneOfType([InsuranceServiceDetail]).isRequired,
    }),
};

export default PremiumHealthInsuranceService;
