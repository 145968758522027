import glamorous from 'glamorous';
import options from '@designsystem/options';

import { waveTextMButton } from '../../../../styles/waveText';
import {
    waveLgMediaQuery,
    waveMdMediaQuery,
    waveMdOnlyMediaQuery,
} from '../../../../styles/waveMediaQueries';

const buttonStyle = {
    textDecoration: 'none',
    textTransform: 'uppercase',
    height: options.space['3xl'],
    width: 230,
    alignItems: 'center',

    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: options.radii.full,

    cursor: 'pointer',
    display: 'inline-flex',
    flexGrow: 0,
    flexShrink: 0,

    justifyContent: 'center',

    ...waveTextMButton,
    fontWeight: options.fontWeights.bold,

    [waveMdMediaQuery]: {
        flexBasis: 'auto',
        width: 164,
        order: 1,
    },
    [waveLgMediaQuery]: {
        width: 230,
    },
};

export const CtaButtonBlue = glamorous.a([
    {
        ...buttonStyle,
        color: options.colors.link,
        borderColor: options.colors.link,
        background: 'transparent',
        '&:not(:disabled):hover': {
            borderColor: options.colors.blue20,
            color: options.colors.blue20,
        },
        '&:focus': {
            color: options.colors.blue,
            outlineOffset: 'unset',
        },
    },
]);

export const CtaButtonWhite = glamorous.a([
    {
        ...buttonStyle,
        color: options.colors.white,
        borderColor: options.colors.white,
        background: 'transparent',
        '&:not(:disabled):hover': {
            borderColor: options.colors.white,
            color: options.colors.white,
        },
        '&:focus': {
            color: options.colors.white,
            outlineOffset: 'unset',
        },
    },
]);

export const ButtonTextDefault = glamorous.span({
    display: 'block',
    [waveMdOnlyMediaQuery]: {
        display: 'none',
    },
});

export const ButtonTextMedium = glamorous.span({
    display: 'none',
    [waveMdOnlyMediaQuery]: {
        display: 'block',
    },
});

export const HeaderTextDefault = glamorous.span({
    display: 'block',
    [waveMdOnlyMediaQuery]: {
        display: 'none',
    },
});

export const HeaderTextMedium = glamorous.span({
    display: 'none',
    [waveMdOnlyMediaQuery]: {
        display: 'block',
    },
});
