import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { xsmallDistance, smallDistance } from '../../styles/distances';
import {
    applyOnDesktopAndUp,
    applyOnTabletAndUp,
} from '../../styles/mediaQueries';
import { pxToRem } from '../../styles/unitConverter';
import AlbumItemViewsLabel from './AlbumItemViewsLabel';
import AlbumItemHelpfulLabel from './AlbumItemHelpfulLabel';
import {
    Container,
    RatioWrapper,
    StretchElement,
    Image,
} from '../gallery/GalleryThumbComponents';

const transformationWidth = 310;
const transformationHeight = 280;
const transformationRatio = transformationHeight / transformationWidth;

const Metadata = glamorous.div({
    alignItems: 'flex-end',
    backgroundImage:
        'linear-gradient(to bottom, transparent 70%, rgba(0, 0, 0, .6) 100%)',
    boxSizing: 'border-box',
    display: 'flex',
    height: '100%',
    left: 0,
    paddingBottom: pxToRem(10),
    paddingLeft: xsmallDistance,
    position: 'absolute',
    top: 0,
    width: '100%',

    [applyOnTabletAndUp]: {
        paddingBottom: smallDistance,
        paddingLeft: pxToRem(13),
    },

    [applyOnDesktopAndUp]: {
        paddingBottom: pxToRem(16),
    },
});

function AlbumItem({ picture }, { config: { mediaBaseUrl } }) {
    const transformation = `w_${transformationWidth},h_${transformationHeight},c_fill,q_auto,f_auto`;

    return h(Container, [
        h(RatioWrapper, { transformationRatio }, [
            h(StretchElement, [
                h(
                    Link,
                    {
                        to: `/mhc/meine-urlaubsbilder/${picture.id}`,
                    },
                    [
                        h(Image, {
                            src: `${mediaBaseUrl}${transformation}/ugc/images/${picture.id}`,
                            alt: picture.description || picture.title,
                        }),
                        h(Metadata, [
                            h(AlbumItemViewsLabel, picture),
                            h(AlbumItemHelpfulLabel, picture),
                        ]),
                    ],
                ),
            ]),
        ]),
    ]);
}

AlbumItem.contextTypes = {
    config: PropTypes.object,
};

export default AlbumItem;
