import gql from 'graphql-tag';

const PRICE_FIELDS = gql`
    fragment PriceFields on CustomerPrice {
        amount
        currencyCode
    }
`;

const FLIGHT_FIELDS = gql`
    ${PRICE_FIELDS}
    fragment FlightFields on Flight {
        departure {
            date
            airportCode
            airportName
        }
        arrival {
            date
            airportCode
            airportName
        }
        airlineCode
        airlineInformation {
            text
            airlineName
            onlineCheckIn {
                url
            }
            airportCheckIn {
                fee {
                    ...PriceFields
                }
            }
        }
        flightNumber
        flightFileKey
        baggageAllowance {
            freeBaggage {
                adults
                children
                infants
            }
            handBaggage {
                adults
                children
                infants
            }
            additionalBaggage
        }
    }
`;

export const bookingForUser = gql`
    ${FLIGHT_FIELDS}
    ${PRICE_FIELDS}
    query ($bookingId: String!) {
        bookingForUser(bookingId: $bookingId) {
            id
            midofficeId
            tourOperator {
                id
                name
                selfServicePortal {
                    url
                    description
                    features {
                        key
                    }
                }
            }
            tourOperatorBookingId
            tourOperatorPrice {
                amount
                currencyCode
                dueDate
                deposit {
                    amount
                    dueDate
                }
            }
            bookingType
            travelkind
            creationDate
            startDate
            endDate
            travelDurationDays
            contractualPartner {
                salutation
                firstName
                lastName
                dateOfBirth
                address {
                    zipCode
                    street
                    city
                    countryCode
                }
                email
                phoneNumbers {
                    value
                }
                specialRequest
            }
            travellers {
                firstName
                lastName
                dateOfBirth
                age
                salutation
                nationality
            }
            services {
                ... on HotelService {
                    type
                    detail {
                        id
                        name
                        checkInDate
                        checkOutDate
                        overnightStays
                        regionName
                        countryName
                        mealDescription
                        roomDescription
                        roomCount
                        reviewCalculations {
                            overall {
                                countUnarchived
                                recommendation
                            }
                        }
                        geo {
                            coordinates
                        }
                        address
                        email
                        phone
                        url
                    }
                }
                ... on FlightService {
                    type
                    detail {
                        outBound {
                            ...FlightFields
                        }
                        inBound {
                            ...FlightFields
                        }
                    }
                }
                ... on AdditionalService {
                    type
                    detail {
                        title
                        status
                        customerPrice {
                            ...PriceFields
                        }
                        comment
                    }
                }
                ... on InsuranceService {
                    type
                    detail {
                        ... on CancellationInsuranceDetail {
                            title
                            customerPrice {
                                ...PriceFields
                            }
                        }
                        ... on PremiumClubTravelHealthInsuranceDetail {
                            title
                            processingStatus: status
                            policy
                        }
                    }
                }
                ... on PackageTransferService {
                    type
                    detail {
                        type
                    }
                }
                ... on OtherService {
                    type
                }
            }
            document {
                instruction
                files {
                    id
                    name
                    internalUseOnly
                    category
                }
            }
            payment {
                type
            }
            externalSelfserviceUrl
        }
    }
`;
