export default async function resizePicture(
    dependencies,
    originalCanvas,
    width,
    height,
) {
    const { pica, window } = dependencies;
    const canvas = window.document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    return pica({ features: ['js', 'ww'] }).resize(originalCanvas, canvas, {
        alpha: true,
    });
}
