/* global IntersectionObserver */

import { useEffect, useRef, useState } from 'react';

const useInScreen = (action) => {
    const ref = useRef(null);
    const [isIntersecting, setIntersecting] = useState(false);
    const [actionExecuted, setActionExecuted] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIntersecting(entry.isIntersecting);

                if (entry.isIntersecting && !actionExecuted) {
                    action();
                    setActionExecuted(true);
                }
            },
            { threshold: 0.5 },
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [ref]);

    return [ref, isIntersecting];
};

export default useInScreen;
