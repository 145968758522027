/**
 * Copy value to the system clipboard, it works by checking if the current browser does support the clipboard API first.
 * Some legacy browsers like IE don't and so using the deprecated execCommand API is needed
 * @param value value to copy
 * @param window browser window
 * @returns {Promise<boolean|void>} true or false is the value as been copied
 */
const copyValueToClipboard = async (value, window) => {
    if ('clipboard' in window.navigator) {
        return window.navigator.clipboard.writeText(value);
    }

    return window.document.execCommand('copy', true, value);
};

export default copyValueToClipboard;
