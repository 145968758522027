import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

const Submit = glamorous.button({
    color: options.colors.white,
    backgroundColor: options.colors.blue,
    borderRadius: options.radii.full,
    padding: options.space.m,
    paddingLeft: options.space.xl,
    paddingRight: options.space.xl,
    fontFamily: options.fonts.default,
    fontSize: options.space.m,
    border: `1px solid ${options.colors.blue}`,
    fontWeight: options.fontWeights.bold,
    textTransform: 'uppercase',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    transition: 'background-color 0.1s ease-in-out',
    ':hover': {
        backgroundColor: options.colors.white,
        color: options.colors.blue,
    },
});

export default function SubmitButton({ children, isLoading, onClick, type }) {
    return h(
        Submit,
        {
            disabled: isLoading,
            onClick,
            type,
        },
        children,
    );
}
