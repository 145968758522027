export default (document, pathPrefix, navigate) => {
    const clickListeners = [];
    const prefix = `${pathPrefix}/mhc`;
    const links = document.querySelectorAll(`a[href^='${prefix}']`);

    for (let i = 0, length = links.length; i < length; i += 1) {
        const link = links[i];

        const clickListener = (event) => {
            event.preventDefault();

            const href = event.currentTarget.getAttribute('href');
            const path = href.substring(
                href.indexOf(pathPrefix) + pathPrefix.length,
            );

            navigate(path);
        };

        link.addEventListener('click', clickListener);

        clickListeners.push(clickListener);
    }

    return clickListeners;
};
