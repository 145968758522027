export default {
    RAIL_AND_FLY: 'Zug zum Flug',
    PETS: 'Haustiere',
    WHEELCHAIR_BAGGAGE: 'Barrierefrei Rollstuhl Flug',
    ADDITIONAL_BAGGAGE: 'Gepäckerhöhung',
    SPECIAL_RESERVATION: 'Sonderreservierung',
    BUSINESS_CLASS: 'Business Class',
    GOLF: 'Golf',
    ADDITIONAL_TRANSFER: 'Transfer',
    TOURIST_CARD: 'Touristenkarte',
    BARRIER_FREE_OVERALL: 'Barrierefrei Gesamt',
    PREMIUM_ECONOMY: 'Premium Economy',
    WHEELCHAIR_AIRPORT_SERVICE: 'Barrierefrei Rollstuhlservice',
    SPORTS_BAGGAGE_FLIGHT: 'Sportgepäck',
    ON_BOARD_CATERING: 'Bordverpflegung',
    PARKING: 'Parkplatz',
    TRAVEL_GUIDE: 'Reiseführer',
    SPORTS_BAGGAGE_TRANSFER: 'Sportgepäck Transfer',
    FLEX_OPTION: 'FlexOption',
    BARRIER_FREE_TRANSFER: 'Barrierefrei Transfer',
    BARRIER_FREE_ROOM: 'Barrierefrei Zimmer',
    SEAT_RESERVATION: 'Sitzplatzreservierung',
    FREE_CANCELLATION: 'Kostenlose Stornierung',
    CHEAP_CANCELLATION: 'Kostengünstige Stornierung',
    PREMIUM_DISCOUNT: 'Exklusives Premium Angebot',
    OTHER_SERVICE: 'Sonstiges',
};
