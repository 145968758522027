import h from 'react-hyperscript';
import glamorous from 'glamorous';
import * as R from 'ramda';

const IconSvg = glamorous.svg({
    display: 'inline-block',
    fill: 'currentColor',
    height: 'auto',

    ':hover': {
        fill: 'currentColor',
    },
});

export default function Icon(props) {
    const defaultViewBox = '0 0 32 32';

    return h(
        IconSvg,
        {
            viewBox: props.viewBox || defaultViewBox,
            ...R.dissoc('children', props),
        },
        props.children,
    );
}
