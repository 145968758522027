import { useEffect, useCallback, useRef, useState } from 'react';

import { calculateColumnTransforms } from './masonryUtils';

const DEBOUNCE_DELAY = 50;
const CONTAINER_HEIGHT_DELAY = 500;

const alignMasonryChild = (element, translateY) => {
    /* eslint-disable no-param-reassign */
    element.style.transform =
        translateY !== 0 ? `translateY(${translateY}px)` : '';

    if (element.style.opacity === '1') return;

    const isReadyToShow =
        element.children.length && element.children[0].innerHTML.trim() !== '';

    if (isReadyToShow) {
        element.style.transition = `opacity 0.3s ease${Math.abs(translateY) >= 1 ? ' 0.3s' : ''}`;
        element.style.opacity = '1';
    }
};

const resetMasonryChildren = (container) => {
    /* eslint-disable no-param-reassign */
    [...container.children].forEach((child) => {
        child.style.transform = '';
        child.style.opacity = '1';
    });
};

const adjustContainerHeight = (container, transforms) => {
    const maxBottom = transforms.reduce((max, { element, translateY }) => {
        const elementBottom =
            element.offsetTop + element.offsetHeight + translateY;
        return Math.max(max, elementBottom);
    }, 0);

    const targetHeight = maxBottom - container.offsetTop;
    const currentHeight = parseFloat(container.style.maxHeight) || 0;

    if (Math.abs(targetHeight - currentHeight) > 1) {
        container.style.maxHeight = `${targetHeight}px`;
    }
};

export function useMasonryGapFix({
    containerRef,
    columnCount,
    gap,
    breakpoint,
    context,
}) {
    const debounceDelayId = useRef(null);
    const containerHeightDelayId = useRef(null);
    const [isAboveBreakpoint, setIsAboveBreakpoint] = useState(
        context.window.innerWidth >= breakpoint,
    );

    const clearTimers = useCallback(() => {
        context.window.clearTimeout(debounceDelayId.current);
        context.window.clearTimeout(containerHeightDelayId.current);
    }, [context]);

    const updateLayout = useCallback(() => {
        const container = containerRef.current;
        if (!container) return;

        const transforms = calculateColumnTransforms(
            container,
            columnCount,
            gap,
            context,
        );

        transforms.forEach(({ element, translateY }) => {
            alignMasonryChild(element, translateY);
        });

        containerHeightDelayId.current = context.window.setTimeout(() => {
            adjustContainerHeight(container, transforms);
        }, CONTAINER_HEIGHT_DELAY);
    }, [columnCount, gap, containerRef, context]);

    useEffect(() => {
        const handleWindowResize = () => {
            const isCurrentlyAbove = context.window.innerWidth >= breakpoint;
            setIsAboveBreakpoint(isCurrentlyAbove);
        };

        context.window.addEventListener('resize', () => {
            handleWindowResize();
        });
        handleWindowResize(); // Initial check to set the state correctly

        return () => {
            context.window.removeEventListener('resize', handleWindowResize);
        };
    }, [context, breakpoint]);

    useEffect(() => {
        const container = containerRef.current;
        if (!container || !context.window) return;

        if (!isAboveBreakpoint) {
            resetMasonryChildren(container);
        } else {
            const triggerUpdate = () => {
                clearTimers();
                debounceDelayId.current = context.window.setTimeout(
                    updateLayout,
                    DEBOUNCE_DELAY,
                );
            };

            const resizeObserver = new context.window.ResizeObserver(
                triggerUpdate,
            );
            resizeObserver.observe(container);
            [...container.children].forEach((child) =>
                resizeObserver.observe(child),
            );

            triggerUpdate(); // Initial layout update

            // eslint-disable-next-line consistent-return
            return () => {
                resizeObserver.disconnect();
                clearTimers();
            };
        }
    }, [updateLayout, context, isAboveBreakpoint, clearTimers]);

    return null;
}
