import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { Fragment, useEffect } from 'react';

import { pxToRem } from '../../../styles/unitConverter';
import { superLoudFont, secondaryFont } from '../../../styles/fonts';
import { gray90, green20, green40, green100 } from '../../../styles/waveColors';
import formatPrice from './formatPrice';
import useBookingContext from '../lib/useBookingContext';

const { UITextLLoud } = secondaryFont;

const TravelPrice = glamorous.div({
    ...UITextLLoud,
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${pxToRem(16)} ${pxToRem(24)}`,
});
TravelPrice.displayName = 'TravelPrice';

const Price = glamorous.div();
Price.displayName = 'Price';

const PriceHeading = glamorous.h4({
    margin: 0,
    display: 'inline',
});
PriceHeading.displayName = 'PriceHeading';

const EverythingPaid = glamorous.div({
    borderTop: `1px solid ${gray90}`,
    padding: `${pxToRem(16)}`,
    backgroundColor: green100,
    color: green40,
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'left',
});
EverythingPaid.displayName = 'EverythingPaid';

const EverythingPaidText = glamorous.div({
    ...superLoudFont,
    color: green20,
    paddingLeft: pxToRem(11),
});

const Details = glamorous.div({
    borderTop: `1px solid ${gray90}`,
});
Details.displayName = 'Details';

const DetailsHeadline = glamorous.div({
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    flexWrap: 'wrap',
});

const DetailsHeadlineText = glamorous.div({
    ...UITextLLoud,
    textAlign: 'left',
    padding: `${pxToRem(16)} ${pxToRem(24)} 0 ${pxToRem(24)}`,
});

DetailsHeadlineText.displayName = 'DetailsHeadlineText';

const TourOperatorPrice = ({
    amount,
    currencyCode,
    everythingPaid,
    children,
    trackingName,
    isCancelled = false,
}) => {
    const { trackEventWithBooking } = useBookingContext();
    let priceHeading = 'Reisepreis';
    let detailsHeadlineText = 'Hinweis zur Zahlung Deiner Reise';
    if (isCancelled) {
        priceHeading = 'Stornokosten';
        detailsHeadlineText = 'Deine Reise wurde storniert';
    }

    useEffect(() => {
        if (trackingName) {
            trackEventWithBooking({
                event: 'event',
                eventCategory: 'mybooking',
                eventAction: 'tour-operator-price',
                eventLabel: trackingName,
            });
        }
    }, [trackEventWithBooking, trackingName]);

    return h(Fragment, [
        h(TravelPrice, [
            h(PriceHeading, priceHeading),
            h(Price, formatPrice(amount, currencyCode)),
        ]),
        everythingPaid
            ? h(EverythingPaid, [
                  h(EverythingPaidText, 'Deine Reise ist vollständig bezahlt!'),
              ])
            : children &&
              h(Details, [
                  h(DetailsHeadline, [
                      h(DetailsHeadlineText, detailsHeadlineText),
                  ]),
                  children,
              ]),
    ]);
};

TourOperatorPrice.propTypes = {
    amount: PropTypes.number.isRequired,
    currencyCode: PropTypes.string.isRequired,
    everythingPaid: PropTypes.bool,
    trackingName: PropTypes.string,
};

export default TourOperatorPrice;
