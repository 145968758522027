import { stripIndent } from 'common-tags';

import graphqlFetch from '../lib/graphqlFetch';
import { defaultSelection as selection } from './fetchPicture';

export default async function fetchPictures(context, offset, limit) {
    const query = stripIndent`
        query MyTravelPictures($limit: Int!, $offset: Int!) {
            picturesForUser(limit:$limit, offset:$offset) {
            items {
                ${selection}
            }
            total
        }
    }`;
    const variables = { limit, offset };

    const response = await graphqlFetch(context, { query, variables });

    if (response.ok) {
        const {
            data: { picturesForUser },
        } = await response.json();
        return picturesForUser;
    }

    return null;
}
