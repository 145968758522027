const getMutationResult = (mutationState) => {
    if (mutationState.error) {
        return 'ERROR_GENERIC';
    }
    return mutationState.data && mutationState.data.changeTravelParticipants;
};

const wasMutationSuccessful = (mutationState) =>
    getMutationResult(mutationState) === 'SUCCESS';

export { getMutationResult, wasMutationSuccessful };
