import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function Sun({ css }) {
    return h(
        Icon,
        {
            viewBox: '0 0 16 17',
            css,
        },
        [
            h('path', {
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                fill: '#FFCA3A',
                d: oneLine`M8 0.55835C7.53977 0.55835 7.16667 0.931446 7.16667 1.39168V3.1051C7.16667 3.56533 7.53977 3.93843 8 3.93843C8.46024 3.93843 8.83334 3.56533 8.83334 3.1051V1.39168C8.83334 0.931446 8.46024 0.55835 8 0.55835ZM14.3369 3.604C14.0473 3.24633 13.5225 3.19118 13.1649 3.48081L11.8333 4.5591C11.4756 4.84874 11.4205 5.37348 11.7101 5.73116C11.9997 6.08883 12.5245 6.14398 12.8822 5.85434L14.2137 4.77606C14.5714 4.48642 14.6265 3.96167 14.3369 3.604ZM15.2864 9.48419C15.7349 9.58772 16.0145 10.0352 15.9109 10.4836C15.8074 10.9321 15.3599 11.2117 14.9115 11.1081L13.242 10.7227C12.7936 10.6192 12.5139 10.1717 12.6175 9.72327C12.721 9.27483 13.1685 8.99522 13.6169 9.09876L15.2864 9.48419ZM11.5287 15.9817C11.9424 15.7799 12.1141 15.281 11.9124 14.8674L11.1613 13.3274C10.9595 12.9137 10.4606 12.7419 10.047 12.9437C9.63331 13.1454 9.46153 13.6443 9.66329 14.058L10.4144 15.598C10.6162 16.0116 11.115 16.1834 11.5287 15.9817ZM5.58561 15.598C5.38386 16.0116 4.88497 16.1834 4.47131 15.9817C4.05765 15.7799 3.88587 15.281 4.08762 14.8674L4.83873 13.3274C5.04049 12.9137 5.53938 12.7419 5.95304 12.9437C6.36669 13.1454 6.53848 13.6443 6.33672 14.058L5.58561 15.598ZM0.0890784 10.4836C0.192609 10.9321 0.640071 11.2117 1.08851 11.1081L2.75801 10.7227C3.20645 10.6192 3.48606 10.1717 3.38253 9.72327C3.279 9.27483 2.83153 8.99522 2.38309 9.09876L0.713594 9.48419C0.265153 9.58772 -0.0144524 10.0352 0.0890784 10.4836ZM1.78628 4.77606C1.42861 4.48642 1.37346 3.96167 1.6631 3.604C1.95273 3.24633 2.47748 3.19118 2.83515 3.48081L4.16672 4.5591C4.5244 4.84874 4.57955 5.37348 4.28991 5.73116C4.00028 6.08883 3.47553 6.14398 3.11786 5.85434L1.78628 4.77606ZM4.66667 8.49996C4.66667 10.3407 6.15926 11.8333 8 11.8333C9.84137 11.8333 11.3333 10.3407 11.3333 8.49996C11.3333 6.65922 9.84074 5.16663 8 5.16663C6.15864 5.16663 4.66667 6.65922 4.66667 8.49996Z`,
            }),
        ],
    );
}
