import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { Fragment } from 'react';

import { applyOnMobile } from '../../../styles/mediaQueries';
import { gray90 } from '../../../styles/waveColors';
import { pxToRem } from '../../../styles/unitConverter';
import formatDate from '../../../lib/formatDate';
import getTravellersCount from '../lib/getTravellersCount';
import singularOrPlural from '../lib/singularOrPlural';
import Item from './Item';
import formatRoomDescription from '../../../lib/formatRoomDescription';

const SummaryContainer = glamorous.div(({ withMargin }) => ({
    display: 'flex',
    borderTop: `1px solid ${gray90}`,
    marginBottom: withMargin ? pxToRem(16) : 0,

    [applyOnMobile]: {
        flexWrap: 'wrap',
    },
}));
SummaryContainer.displayName = 'SummaryContainer';

const Header = glamorous.div();
Header.displayName = Header;

const Info = glamorous.div({
    fontWeight: 'bold',
});
Info.displayName = Info;

const getStayCount = (overnightStays) => {
    const nightOrNights = singularOrPlural(overnightStays, {
        singular: 'Nacht',
        plural: 'Nächte',
    });
    return `${nightOrNights}`;
};

const getRoomCount = (roomCount, roomDescription) => {
    if (/^\d/.test(roomDescription)) {
        return `${roomCount} (${formatRoomDescription(roomDescription)})`;
    }

    return `${roomCount}x ${formatRoomDescription(roomDescription)}`;
};

const ItemStyleConfigFirstRow = {
    itemsPerRow: {
        tabletAndUp: 4,
        smartphone: 2,
    },
    marginRight: '24px',
};

const ItemStyleConfigSecondRow = {
    itemsPerRow: {
        tabletAndUp: 2,
        smartphone: 1,
    },
    marginRight: '12px',
};

const pushItem = ({ isDataPresent, label, getInfo }, items) => {
    if (isDataPresent) {
        items.push({
            label,
            info: getInfo(),
        });

        return items;
    }

    return items;
};

const getFirstRowItems = (
    { checkInDate, checkOutDate, overnightStays },
    travellers,
) => {
    const items = [];

    pushItem(
        {
            isDataPresent: Boolean(checkInDate),
            label: 'Check in',
            getInfo: formatDate.bind(null, checkInDate, 'DD.MM.Y'),
        },
        items,
    );

    pushItem(
        {
            isDataPresent: Boolean(checkOutDate),
            label: 'Check out',
            getInfo: formatDate.bind(null, checkOutDate, 'DD.MM.Y'),
        },
        items,
    );

    pushItem(
        {
            isDataPresent: Boolean(travellers.length),
            label: 'Gästeanzahl',
            getInfo: getTravellersCount.bind(null, travellers),
        },
        items,
    );

    pushItem(
        {
            isDataPresent: Boolean(overnightStays),
            label: 'Übernachtungen',
            getInfo: getStayCount.bind(null, overnightStays),
        },
        items,
    );

    return items;
};

const Summary = ({ bookingDetail, travellers }) => {
    const {
        checkInDate,
        checkOutDate,
        overnightStays,
        roomCount,
        roomDescription,
        mealDescription,
    } = bookingDetail;

    const firstRowItems = getFirstRowItems(
        { checkInDate, checkOutDate, overnightStays },
        travellers,
    );

    return h(Fragment, [
        Boolean(firstRowItems.length) &&
            h(
                SummaryContainer,
                { withMargin: true },
                firstRowItems.map(({ label, info }, index) =>
                    h(Item, {
                        key: index,
                        styleConfig: ItemStyleConfigFirstRow,
                        label,
                        info,
                    }),
                ),
            ),
        h(SummaryContainer, [
            h(Item, {
                styleConfig: ItemStyleConfigSecondRow,
                label: 'Zimmertyp',
                info: getRoomCount(roomCount, roomDescription),
            }),
            h(Item, {
                styleConfig: ItemStyleConfigSecondRow,
                label: 'Verpflegung',
                info: mealDescription,
            }),
        ]),
    ]);
};

export default Summary;
