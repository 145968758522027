import h from 'react-hyperscript';
import * as glamor from 'glamor';
import glamorous from 'glamorous';

import { loudFont, tinyFont } from '../../styles/fonts';
import { mediumDistance, smallDistance } from '../../styles/distances';
import { black, gray20, blue, blue90, gray100 } from '../../styles/waveColors';
import { applyOnTabletAndUp } from '../../styles/mediaQueries';
import AntiFakeBadge from './AntiFakeBadge';

const Container = glamorous.div({
    margin: `${mediumDistance} auto`,
    padding: smallDistance,
    border: `1px solid ${gray100}`,
    borderRadius: 4,
    maxWidth: 600,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [applyOnTabletAndUp]: {
        flexDirection: 'row',
    },
});

const antiFakeBadgeStyled = glamor.css({
    display: 'block',
    marginBottom: smallDistance,
    width: 40,
    height: 40,
    [applyOnTabletAndUp]: {
        marginBottom: 0,
        marginRight: mediumDistance,
        width: 48,
        height: 48,
    },
});

const TextContainer = glamorous.div({
    textAlign: 'center',
    [applyOnTabletAndUp]: {
        textAlign: 'left',
    },
});

const Headline = glamorous.p({
    ...loudFont,
    color: black,
    margin: '0 0 2px 0',
    textAlign: 'center',
    [applyOnTabletAndUp]: {
        textAlign: 'left',
    },
});

const SecondaryText = glamorous.span({
    ...tinyFont,
    color: gray20,
    margin: 0,
});

const Link = glamorous.a({
    ...tinyFont,
    color: blue,
    display: 'inline-block',
    textDecoration: 'none',
    borderBottom: `2px solid ${blue90}`,
});

export default () =>
    h(Container, [
        h(AntiFakeBadge, { className: antiFakeBadgeStyled }),
        h('div', [
            h(Headline, 'HolidayCheck steht für authentische Bewertungen.'),
            h(TextContainer, [
                h(
                    SecondaryText,
                    'Weitere Informationen zu unserem mehrstufigen Verifizierungsprozess gibt es ',
                ),
                h(
                    Link,
                    {
                        href: 'https://gemeinsamgegenfakebewertungen.de',
                    },
                    'hier',
                ),
            ]),
        ]),
    ]);
