import glamorous from 'glamorous';

import { secondaryFont } from '../../styles/fonts';
import { pxToRem } from '../../styles/unitConverter';

const { headline2XS } = secondaryFont;

export default glamorous.h3(headline2XS, {
    marginTop: pxToRem(40),
    marginBottom: pxToRem(24),
});
