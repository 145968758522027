import h from 'react-hyperscript';
import glamorous from 'glamorous';

import Lock from '../icons/Lock';
import colors from '../../styles/colors';
import { xsmallDistance, mediumDistance } from '../../styles/distances';
import { tinyFont } from '../../styles/fonts';

const { privateMarkerColor } = colors;

const iconStyle = {
    height: mediumDistance,
    width: mediumDistance,
};

const Container = glamorous.p(tinyFont, {
    alignItems: 'center',
    color: privateMarkerColor,
    display: 'inline-flex',
    margin: 0,
});

const Text = glamorous.span({
    display: 'block',
    marginRight: xsmallDistance,
});

export default function PrivateMarker() {
    return h(Container, [
        h(Text, 'nicht öffentlich'),
        h(Lock, {
            css: iconStyle,
        }),
    ]);
}
