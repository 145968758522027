import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import { superLoudFont, tinyFont, secondaryFont } from '../../styles/fonts';
import { applyOnMobile } from '../../styles/mediaQueries';
import { pxToRem } from '../../styles/unitConverter';
import formatDate from '../../lib/formatDate';
import numberToTwoDigitString from '../../lib/numberToTwoDigitString';
import { gray90, blue } from '../../styles/waveColors';
import {
    getSalutationText,
    SALUTATION_VALUE_OPTIONS,
} from '../../lib/salutations';
import DownArrowLine from '../icons/DownArrowLine';
import UpArrowLine from '../icons/UpArrowLine';
import { NATIONALITIES } from '../../lib/nationalityPairings';
import { assistiveTechHidden } from '../../styles/accessibility';

const { headlineXS } = secondaryFont;

const Traveller = glamorous.div(
    {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        border: `1px solid ${gray90}`,
        borderRadius: pxToRem(4),
        boxSizing: 'border-box',
        padding: pxToRem(16),
        width: `calc(50% - ${pxToRem(12)})`,
        marginBottom: pxToRem(24),
        [applyOnMobile]: {
            width: '100%',
            '&:not(:last-child)': {
                marginBottom: pxToRem(24),
            },
        },
    },
    ({ isOpen }) =>
        isOpen !== null && {
            width: '100%',
            border: 'none',
            borderRadius: 0,
            borderBottom: isOpen && `1px solid ${gray90}`,
            marginBottom: 0,
        },
);
Traveller.displayName = 'Traveller';

const TravellerData = glamorous.div({
    textAlign: 'start',
});

const Salutation = glamorous.span({});
Salutation.displayName = 'Salutation';

const Name = glamorous.span(superLoudFont);
Name.displayName = 'Name';

const PersonalData = glamorous.div(tinyFont, {
    lineHeight: pxToRem(18),
    textAlign: 'start',
});
PersonalData.displayName = 'PersonalData';

const Position = glamorous.div(headlineXS, {
    color: gray90,
    paddingRight: pxToRem(6),
    marginLeft: 'auto',
    ...assistiveTechHidden,
});
Position.displayName = 'Position';

const getPersonalData = (dateOfBirth, nationality) => {
    const data = [];
    if (dateOfBirth) {
        data.push(formatDate(dateOfBirth, 'DD.MM.Y'));
    }
    if (nationality) {
        data.push(NATIONALITIES[nationality] || nationality);
    }
    return data.join(', ');
};

const TravellerContainer = ({ traveller, index, isOpen = null }) =>
    h(Traveller, { isOpen }, [
        h(TravellerData, [
            h(Salutation, `${getSalutationText(traveller.salutation)} `),
            h(Name, `${traveller.firstName} ${traveller.lastName}`),
            h(
                PersonalData,
                getPersonalData(traveller.dateOfBirth, traveller.nationality),
            ),
        ]),
        h(Position, numberToTwoDigitString(index + 1)),
        isOpen !== null && !isOpen
            ? h(DownArrowLine, {
                  height: pxToRem(16),
                  width: pxToRem(16),
                  color: blue,
              })
            : // hidden visually but remains in DOM to prevent dialog closing on open/close
              h(DownArrowLine, { height: 0, ariaHidden: true }),
        isOpen
            ? h(UpArrowLine, {
                  height: pxToRem(16),
                  width: pxToRem(16),
                  color: blue,
              })
            : // hidden visually but remains in DOM to prevent dialog closing on open/close
              h(UpArrowLine, { height: 0, ariaHidden: true }),
    ]);

TravellerContainer.propTypes = {
    traveller: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        salutation: PropTypes.oneOf(SALUTATION_VALUE_OPTIONS),
        dateOfBirth: PropTypes.string,
        nationality: PropTypes.string,
    }).isRequired,
    index: PropTypes.number.isRequired,
    isOpen: PropTypes.bool,
};

TravellerContainer.displayName = 'TravellerContainer';

export default TravellerContainer;
