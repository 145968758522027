import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';
import { black } from '../../styles/waveColors';

export default function Info({ css }) {
    return h(
        Icon,
        {
            css,
            viewBox: '0 0 24 24',
            fillRule: 'evenodd',
            clipRule: 'evenodd',
        },
        [
            h('path', {
                d: oneLine`
        M3.4 12C3.4 7.25035 7.25035 3.4 12 3.4C16.7496 3.4 20.6 7.25035 20.6 12C20.6 
        16.7496 16.7496 20.6 12 20.6C7.25035 20.6 3.4 16.7496 3.4 12ZM12 2C6.47715 2 
        2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 
        17.5228 2 12 2ZM13.3337 8.2618C13.3337 8.99818 12.7367 9.59513 12.0003 9.59513C11.2639 
        9.59513 10.667 8.99818 10.667 8.2618C10.667 7.52542 11.2639 6.92847 12.0003 
        6.92847C12.7367 6.92847 13.3337 7.52542 13.3337 8.2618ZM10.667 12.1826V15.7381C10.667 
        16.4745 11.2639 17.0715 12.0003 17.0715C12.7367 17.0715 13.3337 16.4745 13.3337 
        15.7381V12.1826C13.3337 11.4462 12.7367 10.8493 12.0003 10.8493C11.2639 10.8493 
        10.667 11.4462 10.667 12.1826Z`,
                fill: black,
            }),
        ],
    );
}
