import glamorous from 'glamorous';
import h from 'react-hyperscript';

const ArrowContainer = glamorous.div(
    ({ backgroundColor = '#018CE2', floatRight }) => ({
        display: 'block',
        position: 'relative',
        backgroundColor,
        width: 20,
        height: 20,
        borderRadius: '50%',
        alignSelf: 'end',
        float: floatRight ? 'right' : 'none',
    }),
    'arrow-container',
);

export const ArticleArrow = ({
    assetsPath,
    backgroundColor,
    floatRight = false,
}) => {
    return h(ArrowContainer, { backgroundColor, floatRight }, [
        h('img', {
            src: `${assetsPath}/hcMietwagen/arrowRight.svg`,
        }),
    ]);
};
