import { useEffect, useState } from 'react';
import moment from 'moment';

import { fetchAllRentalCarBookings } from '../../../../fetchers/fetchRentalcarBookings';

export function useRentalcarBookings(context) {
    const [bookings, setBookings] = useState([]);

    useEffect(() => {
        (async () => {
            const response = await fetchAllRentalCarBookings(context);
            setBookings(response);
        })();
    }, []);

    return bookings;
}

export const getValidHcmBooking = (bookings) => {
    const now = moment();
    const futurePendingConfirmedBooking = bookings
        .filter((booking) => {
            const isStatusValid = ['Pending', 'Confirmed'].includes(
                booking.status,
            );
            const dropoffMoment = moment(booking.dropoffDatetime);
            const isFuture = dropoffMoment.isAfter(now);
            return isStatusValid && isFuture;
        })
        .sort((a, b) =>
            moment(a.dropoffDatetime).diff(moment(b.dropoffDatetime)),
        );
    return futurePendingConfirmedBooking[0] &&
        futurePendingConfirmedBooking[0].id &&
        futurePendingConfirmedBooking[0].pickupStation.countryName
        ? {
              bookingId: futurePendingConfirmedBooking[0].id.toString(),
              countryName:
                  futurePendingConfirmedBooking[0].pickupStation.countryName,
              regionName:
                  futurePendingConfirmedBooking[0].carOfferDestinationRegion
                      .destination,
              countryDestinationId:
                  futurePendingConfirmedBooking[0].hcDestination.countryId,
          }
        : null;
};
