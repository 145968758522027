import glamorous from 'glamorous';
import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import options from '@designsystem/options';

import { waveHeadline2XS, waveHeadlineXS } from '../../../styles/waveHeadlines';
import {
    waveTextS,
    waveTextM,
    waveTextMMedium,
} from '../../../styles/waveText';
import {
    waveSmMediaQuery,
    waveMdMediaQuery,
} from '../../../styles/waveMediaQueries';

const Title = glamorous.div({
    ...waveHeadline2XS,
    color: options.colors.white,
    marginBottom: `${options.space.xs}px`,
    [waveSmMediaQuery]: {
        ...waveHeadlineXS,
    },
});

const SubHeadline = glamorous.div({
    ...waveTextS,
    color: options.colors.white,
    textAlign: 'center',
    marginTop: `${options.space['2xs']}px`,
    marginRight: `${options.space.l}px`,
    marginBottom: `${options.space.m}px`,
    marginLeft: `${options.space.l}px`,
    [waveSmMediaQuery]: {
        marginRight: `${options.space.xl}px`,
        marginLeft: `${options.space.xl}px`,
    },
    [waveMdMediaQuery]: {
        ...waveTextM,
    },
});

const Button = glamorous.a([
    {
        ...waveTextMMedium,
        color: options.colors.white,
        borderColor: options.colors.white,
        fontWeight: options.fontWeights.bold,
        textDecoration: 'none',
        marginBottom: `${options.space.xl}px`,
        height: '40px',
        minWidth: '112px',
        alignItems: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: options.radii.full,
        cursor: 'pointer',
        display: 'inline-flex',
        flexGrow: 0,
        flexShrink: 0,
        justifyContent: 'center',
        paddingLeft: `${options.space.l}px`,
        paddingRight: `${options.space.l}px`,
        textTransform: 'uppercase',
        transition: 'all 0.3s ease',
        background: 'transparent',
        '&:not(:disabled):hover': {
            background: options.colors.gray100,
            borderColor: options.colors.gray100,
            color: options.colors.blue20,
        },
        '&:focus': {
            color: options.colors.blue,
            outlineOffset: 'unset',
        },
    },
]);

const BackgroundImage = glamorous.div(
    {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        alignItems: 'center',
        height: '320px',
        borderRadius: `${options.space.xs}px`,
        [waveMdMediaQuery]: {
            height: '400px',
        },
    },
    ({ backGroundImageLink }) => ({
        backgroundImage: backGroundImageLink,
    }),
);

const BookingsCard = (props, { config, trackEvent }) => {
    const backGroundImageLink = `url(${config.assetsPath}/myHolidayCheck/hub-booking-card-background.png)`;
    return h(BackgroundImage, { backGroundImageLink }, [
        h(Title, 'Meine Buchungen'),
        h(
            SubHeadline,
            'Hier findest Du alle Infos für Deinen gebuchten Urlaub.',
        ),
        h(
            Button,
            {
                href: '/mhc/meine-buchungen',
                onClick: () =>
                    trackEvent({
                        event: 'event',
                        eventCategory: 'mHCHub',
                        eventAction: 'gotobookings',
                        eventLabel: 'Meine Buchungen',
                    }),
            },
            'Meine Buchungen',
        ),
    ]);
};

export default BookingsCard;

BookingsCard.contextTypes = {
    config: PropTypes.object,
    trackEvent: PropTypes.func,
};
