import { Component } from 'react';
import PropTypes from 'prop-types';

class ScrollPositionSpy extends Component {
    constructor(...args) {
        super(...args);

        this.updateScrollPosition = this.updateScrollPosition.bind(this);
    }

    updateScrollPosition() {
        const currentScrollPosition = this.context.window.pageYOffset;
        this.props.onChange(currentScrollPosition);
    }

    componentDidMount() {
        this.updateScrollPosition();
        this.context.window.addEventListener(
            'scroll',
            this.updateScrollPosition,
        );
    }

    componentWillUnmount() {
        this.context.window.removeEventListener(
            'scroll',
            this.updateScrollPosition,
        );
    }

    render() {
        return this.props.children;
    }
}

ScrollPositionSpy.contextTypes = {
    window: PropTypes.object.isRequired,
};

export default ScrollPositionSpy;
