import glamorous from 'glamorous';

import colors from '../../styles/colors';
import { applyOnTabletAndUp } from '../../styles/mediaQueries';

const { albumItemPlaceholderColor } = colors;

const Container = glamorous.div({
    display: 'block',
    margin: '1px',
    width: 'calc(100% / 2 - 2px)',

    [applyOnTabletAndUp]: {
        width: 'calc(100% / 3 - 2px)',
    },
});

const Image = glamorous.img({
    height: '100%',
    width: '100%',
});

const RatioWrapper = glamorous.div(({ transformationRatio }) => ({
    background: albumItemPlaceholderColor,
    height: 0,
    paddingBottom: `${transformationRatio * 100}%`,
    position: 'relative',
}));

const StretchElement = glamorous.div({
    bottom: 0,
    height: '100%',
    left: 0,
    position: 'absolute',
    width: '100%',
});

export { Container, RatioWrapper, StretchElement, Image };
