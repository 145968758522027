import PropTypes from 'prop-types';
import h from 'react-hyperscript';
import glamorous from 'glamorous';

import { bigLoudFont } from '../../styles/fonts';
import { xxlargeDistance, mediumDistance } from '../../styles/distances';
import Type from './Type';

const Name = glamorous.div({ marginBottom: mediumDistance, ...bigLoudFont });

const Wrapper = glamorous.div({
    width: '100%',
    float: 'left',
    marginBottom: xxlargeDistance,
});

const renderType = (badges, type) => {
    const typeBadges = badges.filter((badge) => badge.typeId === type.id);
    return h(Type, { badges: typeBadges, type, key: type.id });
};

const Category = ({ category, badges }) => {
    if (badges.length === 0) {
        return null;
    }
    return h(Wrapper, [
        h(Name, category.name),
        category.types.map(renderType.bind(null, badges)),
    ]);
};

Category.propTypes = {
    category: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        types: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
            }),
        ).isRequired,
    }).isRequired,
    badges: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            typeId: PropTypes.string.isRequired,
            created: PropTypes.number,
        }),
    ).isRequired,
};

export default Category;
