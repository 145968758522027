import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import { memo } from 'react';

import BookingsCard from './dashboardCards/BookingsCard';
import UserProfileCardQuery from './dashboardCards/UserProfileCardQuery';
import BadgesCardQuery from './dashboardCards/BadgesCardQuery';
import RentalcarCard from '../rentalcarBookingCard/RentalcarCard';
import PremiumCard from './dashboardCards/PremiumCard/PremiumCard';
import ReviewCardQuery from './dashboardCards/ReviewCard/ReviewCardQuery';
import VoucherCardQuery from './dashboardCards/VoucherCard/VoucherCardQuery';
import MemberCard from './dashboardCards/MemberCard';
import MasonryLayout from './dashboardLayout/MasonryLayout';
import { waveSmMediaQuery } from '../../styles/waveMediaQueries';

const mdSideColumnWidth = 222;
const mdCenterColumnWidth = 468;
const lgSideColumnWidth = 282;
const lgCenterColumnWidth = 588;

const LEFT = 1;
const CENTER = 2;
const RIGHT = 3;

const CardContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    borderRadius: 8,
    backgroundColor: options.colors.white,
    color: options.colors.black,
    overflow: 'hidden',
});

const placeCard = (
    component,
    { column, mobileOrder, cardStyle = {}, masonryStyle = {} },
) => {
    return h(
        glamorous(CardContainer)(cardStyle),
        {
            column,
            mobileOrder,
            masonryStyle,
        },
        [h(component)],
    );
};

function Dashboard() {
    return h(
        MasonryLayout,
        {
            columns: {
                md: [mdSideColumnWidth, mdCenterColumnWidth, mdSideColumnWidth],
                lg: [lgSideColumnWidth, lgCenterColumnWidth, lgSideColumnWidth],
            },
            gap: options.space.xl,
            style: {
                flexDirection: 'row',
                flexWrap: 'wrap',
            },
        },
        [
            placeCard(UserProfileCardQuery, { column: LEFT, mobileOrder: 1 }),
            placeCard(BookingsCard, { column: CENTER, mobileOrder: 2 }),
            placeCard(RentalcarCard, { column: CENTER, mobileOrder: 3 }),
            placeCard(VoucherCardQuery, { column: RIGHT, mobileOrder: 4 }),
            placeCard(MemberCard, { column: RIGHT, mobileOrder: 5 }),
            placeCard(PremiumCard, { column: RIGHT, mobileOrder: 6 }),

            placeCard(ReviewCardQuery, {
                column: CENTER,
                mobileOrder: 7,
                masonryStyle: {
                    [waveSmMediaQuery]: {
                        flexBasis: 0,
                        flexGrow: 1,
                    },
                },
            }),

            placeCard(BadgesCardQuery, {
                column: LEFT,
                mobileOrder: 8,
                masonryStyle: {
                    [waveSmMediaQuery]: {
                        flexBasis: 0,
                        flexGrow: 1,
                    },
                },
            }),
        ],
    );
}

export default memo(Dashboard);
