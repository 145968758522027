import glamorous from 'glamorous';
import options from '@designsystem/options';
import h from 'react-hyperscript';

import FeaturedKnowledgeArticle from './FeaturedKnowledgeArticle';
import DarkLink from '../../../components/DarkLink';
import { waveMdMediaQuery } from '../../../../../../styles/waveMediaQueries';

const FeaturedArticlesContainer = glamorous.div({
    display: 'flex',
    justifyContent: 'space-between',
    gap: options.space.m,
    borderRadius: options.space.xs,
    flexDirection: 'column',
    [waveMdMediaQuery]: {
        flexDirection: 'row',
    },
});

function FeaturedKnowledgeArticles({ articles, assetsPath }) {
    if (!articles || articles.length === 0) return null;

    return h(
        FeaturedArticlesContainer,
        articles.map((article) => {
            const iconUrl = article.icon.startsWith('https://')
                ? article.icon
                : `${assetsPath}/hcMietwagen/${article.icon}.svg`;

            const utmParameter = `?utm_source=mHC&utm_medium=HCMHub&utm_campaign=KnowledgeArticleClicked&utm_content=${article.title.split(' ').join('%20')}`;

            return h(
                DarkLink,
                { href: `${article.url}${utmParameter}`, target: '_blank' },
                [
                    h(FeaturedKnowledgeArticle, {
                        title: article.title,
                        description: article.description,
                        icon: iconUrl,
                        alt: article.alt,
                    }),
                ],
            );
        }),
    );
}

export default FeaturedKnowledgeArticles;
