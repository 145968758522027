import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { useLocation } from 'react-router-dom';

import Page from './Page';
import Grid from './grid/Grid';
import Tabs from './navigation/Tabs';
import UgcContentIframe from './UgcContentIframe';
import WriteReviewCallToAction from './myReviews/WriteReviewCallToAction';
import AntiFakeInfo from './myReviews/AntiFakeInfo';
import ContentHotelReviews from './myReviews/ContentHotelReviews';
import colors from '../styles/colors';

const { defaultBackgroundColor } = colors;

const tabItems = [
    {
        label: 'Hotels',
        to: '/mhc/meine-bewertungen',
    },
    {
        label: 'Schiffe',
        to: '/mhc/meine-bewertungen?action=shipreviews',
    },
    {
        label: 'Reisetipps',
        to: '/mhc/meine-bewertungen?action=poireviews',
    },
];

const ReviewsContainer = glamorous.div({
    textAlign: 'center',
    backgroundColor: defaultBackgroundColor,
});

function MyReview() {
    const location = useLocation();
    const activeTabPath = `${location.pathname}${location.search}`;

    return h(
        Page,
        {
            pagename: '/mhc/reviews',
            headline: 'Meine Bewertungen',
        },
        [
            h(Grid, [h(WriteReviewCallToAction)]),
            h(Grid, [h(AntiFakeInfo)]),
            h(Tabs, { tabItems, activeTabPath }),
            location.search.includes('action')
                ? h(UgcContentIframe, { location })
                : h(ReviewsContainer, [h(ContentHotelReviews)]),
        ],
    );
}

export default MyReview;
