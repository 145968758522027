import h from 'react-hyperscript';

import { pxToRem } from '../../../styles/unitConverter';
import DownArrowLine from '../../icons/DownArrowLine';
import UpArrowLine from '../../icons/UpArrowLine';

const ToggleIcon = ({ isExpanded }) => {
    const Icon = !isExpanded ? DownArrowLine : UpArrowLine;

    return h(Icon, {
        css: {
            height: '1.25rem',
            verticalAlign: 'sub',
            marginLeft: pxToRem(12),
        },
    });
};

export default ToggleIcon;
