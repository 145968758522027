import h from 'react-hyperscript';
import { Query } from '@apollo/client/react/components';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import { premiumMembership } from '../../queries/premiumMembership';
import MembershipInformation from './MembershipInformation';
import MembershipContact from './MembershipContact';
import Title from './Title';
import TransitionScreen from '../navigation/TransitionScreen';
import PremiumCashback from './PremiumCashback';
import { noticeError } from '../../lib/newrelic';
import PremiumPartners from './PremiumPartners';
import { PremiumTrackingContext } from './context/PremiumTrackingContext';
import {
    extractActiveContract,
    extractActivePeriod,
} from '../../lib/premium/membership';
import { waveXsAndSmOnlyMediaQuery } from '../../styles/waveMediaQueries';
import { largeDistance, xxlargeDistance } from '../../styles/distances';
import Reminder from './Reminder';

const MemberInformationContainer = glamorous.div({
    [waveXsAndSmOnlyMediaQuery]: {
        padding: `0 ${largeDistance} !important`,
    },
    padding: `0 ${xxlargeDistance}`,
});

export default function MemberQuery(
    { config: { assetsPath } },
    { trackEvent, window },
) {
    return h(
        Query,
        { fetchPolicy: 'cache-and-network', query: premiumMembership },
        ({ loading, data, error }) => {
            if (loading) {
                return h(TransitionScreen);
            }

            const trackEventForPremium = ({ event }) => {
                if (window.hasConsentedToTrackEvents) {
                    trackEvent({
                        event: 'event',
                        eventCategory: 'Premium-Club',
                        ...event,
                        ...(data &&
                        data.premiumMembership.personAccountDetail.mhcUUID
                            ? {
                                  mhcUUID:
                                      data.premiumMembership.personAccountDetail
                                          .mhcUUID,
                              }
                            : {}),
                    });
                }
            };

            if (error) noticeError(error);

            if (
                error ||
                !extractActiveContract(
                    data.premiumMembership.subscriptionContracts,
                )
            ) {
                window.location.replace('/premium');

                return h(TransitionScreen);
            }

            const activeContract = extractActiveContract(
                data.premiumMembership.subscriptionContracts,
            );
            const activePeriod = extractActivePeriod(
                activeContract.subscriptionPeriods,
            );

            return h(Fragment, [
                h(
                    PremiumTrackingContext.Provider,
                    {
                        value: {
                            trackEventForPremium,
                        },
                    },
                    [
                        h(Title, { config: { assetsPath } }),
                        h(MemberInformationContainer, [
                            h(MembershipInformation, {
                                activeContract,
                                hasPendingTermination:
                                    activeContract.hasPendingTermination,
                                contractEndDate: activeContract.contractEndDate,
                            }),
                        ]),
                        h(Reminder, { activeContract }),
                        h(PremiumCashback, {
                            activePeriod,
                        }),
                        h(PremiumPartners, {
                            partners: activePeriod.benefits.partners,
                        }),
                        h(MembershipContact),
                    ],
                ),
            ]);
        },
    );
}

MemberQuery.contextTypes = {
    trackEvent: PropTypes.func.isRequired,
    window: PropTypes.object.isRequired,
};
