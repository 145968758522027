import PropTypes from 'prop-types';
import h from 'react-hyperscript';
import glamorous from 'glamorous';

import { smallDistance } from '../../styles/distances';
import { tinyFont, loudFont } from '../../styles/fonts';
import colors from '../../styles/colors';
import { pxToRem } from '../../styles/unitConverter';

const { linkColor, linkHoverColor } = colors;

const buildImagePath = (mediaBaseUrl, id) => `${mediaBaseUrl}badges/${id}`;
const buildFilter = (colored) => {
    return {
        filter: colored ? 'saturate(100%)' : 'saturate(0)',
        opacity: colored ? 1 : 0.25,
    };
};

const Container = glamorous.div(({ asLink }) => ({
    display: 'flex',
    alignItems: 'center',
    cursor: asLink ? 'pointer' : 'default',
}));
const Thumb = glamorous.img(({ colored }) => ({
    verticalAlign: 'middle',
    maxWidth: pxToRem(72),
    marginRight: smallDistance,
    ...buildFilter(colored),
}));
const Details = glamorous.div({
    display: 'inline-block',
    verticalAlign: 'middle',
    textAlign: 'left',
});
const Name = glamorous.div(
    loudFont,
    ({ asLink }) =>
        asLink && {
            color: linkColor,
            '&:hover': {
                color: linkHoverColor,
            },
        },
);
const Description = glamorous.div(tinyFont);

const Badge = ({ id, name, description, created, asLink }, { config }) => {
    return h(Container, { asLink }, [
        h(Thumb, {
            src: buildImagePath(config.mediaBaseUrl, id),
            alt: '',
            colored: Boolean(created),
        }),
        h(Details, [h(Name, { asLink }, name), h(Description, description)]),
    ]);
};

Badge.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    created: PropTypes.number,
    asLink: PropTypes.bool,
};

Badge.contextTypes = {
    config: PropTypes.object.isRequired,
};

export default Badge;
