const smMin = 600;
const mdMin = 1024;
const lgMin = 1280;

const xsMax = smMin - 1;
const smMax = mdMin - 1;
const mdMax = lgMin - 1;

export const waveSmMediaQuery = `@media (min-width: ${smMin}px)`;
export const waveMdMediaQuery = `@media (min-width: ${mdMin}px)`;
export const waveLgMediaQuery = `@media (min-width: ${lgMin}px)`;

export const waveXsOnlyMediaQuery = `@media (max-width: ${xsMax}px)`;
export const waveSmOnlyMediaQuery = `@media (min-width: ${smMin}px) and (max-width: ${smMax}px)`;
export const waveMdOnlyMediaQuery = `@media (min-width: ${mdMin}px) and (max-width: ${mdMax}px)`;

export const waveXsAndSmOnlyMediaQuery = `@media (max-width: ${smMax}px)`;

export const waveXsAndSmOnly = `@media (max-width: ${smMax}px)`;
