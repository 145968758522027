import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default ({ css }) =>
    h(Icon, { css }, [
        h('path', {
            d: oneLine`M16.016 2.628c4.423 0 8.008 3.615 8.008
            8.075v5.271h-2.669v-5.271c-0.157-2.886-2.426-5.406-5.339-5.406s-5.182 2.52-5.339
            5.406v5.271h-2.669v-5.271c0-4.46 3.585-8.075 8.008-8.075zM5.339 21.109v4.411c0 2.105 1.709 3.801 3.818
            3.801h13.719c2.106 0 3.818-1.702 3.818-3.801v-4.411c0-2.105-1.709-3.801-3.818-3.801h-13.719c-2.106 0-3.818
            1.702-3.818 3.801zM14.681 22.646c0-0.736 0.592-1.333 1.335-1.333 0.737 0 1.335 0.591 1.335 1.333v1.337c0
            0.736-0.592 1.333-1.335 1.333-0.737 0-1.335-0.591-1.335-1.333v-1.337z`,
        }),
    ]);
