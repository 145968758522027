import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import { pxToRem } from '../../../styles/unitConverter';

const TooltipWrapper = glamorous.div({
    display: 'block',
    textAlign: 'center',
    padding: options.space.xs,
    position: 'absolute',
    bottom: pxToRem(options.space['2xl']),
    right: `-${pxToRem(options.space.m)}`,

    borderRadius: options.radii.small,

    boxShadow: options.shadows.shallow,
    filter: `drop-shadow(${options.shadows.shallow})`,
    backgroundColor: options.colors.white,

    ':before': {
        content: '""',
        position: 'absolute',
        top: '100%',
        left: '50%',
        marginLeft: `-${pxToRem(options.space.xs)}`,
        borderWidth: `${pxToRem(options.space.xs)}`,
        borderStyle: 'solid',
        borderColor: `${options.colors.white} transparent transparent transparent`,
    },
});

const CopyToClipboardTooltip = ({ text }) => h(TooltipWrapper, text);

export default CopyToClipboardTooltip;
