import moment from 'moment';
import { stripIndent } from 'common-tags';

import graphqlFetch from '../lib/graphqlFetch';

async function getTodaysOpeningHours(context) {
    const query = stripIndent`
        query RentalCarOpeningHours {
          rentalcarOpeningHours {
            nextDate
            nextOpening {
              from1
              to1
              from2
              to2
            }
          }
        }
    `;

    try {
        const response = await graphqlFetch(context, { query });

        if (response.ok) {
            const {
                data: { rentalcarOpeningHours },
            } = await response.json();

            return rentalcarOpeningHours;
        }
    } catch (error) {
        // doing nothing
    }

    return {
        nextDate: moment().format('YYYY-MM-DD'),
        nextOpening: {},
    };
}

export { getTodaysOpeningHours };
