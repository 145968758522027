import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import DownArrowLine from '../icons/DownArrowLine';
import colors from '../../styles/colors';
import {
    xsmallDistance,
    smallDistance,
    mediumDistance,
} from '../../styles/distances';
import { defaultFont, FONTS } from '../../styles/fonts';
import { pxToRem } from '../../styles/unitConverter';
import {
    black,
    red40,
    gray,
    gray90,
    gray100,
    gray20,
} from '../../styles/waveColors';

const { inputBackgroundColor, inputHoverBorderColor } = colors;

const Fieldset = glamorous.fieldset({
    border: 0,
    margin: `0 0 ${xsmallDistance}`,
    padding: 0,
    textAlign: 'start',
});

const Label = glamorous.label({
    fontFamily: FONTS.primary,
    fontWeight: 500,
    fontSize: smallDistance,
    lineHeight: pxToRem(21),
    color: gray20,
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'space-between',
});

const ErrorMessage = glamorous.p({
    fontFamily: FONTS.primary,
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(18),
    color: red40,
    margin: `${pxToRem(8)} 0 0}`,
});

const InfoMessage = glamorous.p({
    ...defaultFont,
    fontFamily: FONTS.secondary,
    color: black,
    margin: `${pxToRem(4)} 0 0`,
});

const Arrow = glamorous.span({
    alignItems: 'center',
    pointerEvents: 'none',
    position: 'absolute',
    right: xsmallDistance,
    top: pxToRem(25),
    width: mediumDistance,
});

const Container = glamorous.div({
    position: 'relative',
});

const SelectElement = glamorous.select(
    defaultFont,
    {
        appearance: 'none',
        fontFamily: FONTS.primary,
        border: `${pxToRem(1)} solid ${gray90}`,
        borderRadius: pxToRem(4),
        boxSizing: 'border-box',
        color: black,
        background: inputBackgroundColor,
        outline: 0,
        margin: `${pxToRem(8)} 0 ${pxToRem(26)}`,
        padding: `${pxToRem(13)} ${pxToRem(16)} ${pxToRem(14)}`,
        width: '100%',
        '&:active, &:focus': {
            borderColor: inputHoverBorderColor,
        },
    },
    ({ hasError }) =>
        hasError && {
            marginBottom: 0,
            borderColor: red40,
            '&:active, &:focus': {
                borderColor: red40,
            },
        },
    ({ disabled }) =>
        disabled && {
            color: gray,
            borderColor: gray90,
            background: gray100,
            cursor: 'not-allowed',
        },
    ({ addFieldStyles }) => addFieldStyles && { ...addFieldStyles },
);

const Select = ({
    name,
    label,
    onChange,
    options,
    value,
    disabled,
    infoMessage,
    errorMessage,
}) => {
    const id = `${name}-${Math.random()}`;

    return h(Fieldset, [
        h(
            Label,
            {
                htmlFor: id,
            },
            label,
        ),
        infoMessage && h(InfoMessage, [infoMessage]),
        h(Container, [
            h(
                SelectElement,
                {
                    id,
                    name,
                    onChange: (e) => onChange(e.target.value),
                    value,
                    disabled,
                    hasError: Boolean(errorMessage),
                },
                options.map(({ value: optionValue, label: optionLabel }) =>
                    h(
                        'option',
                        {
                            value: optionValue,
                        },
                        optionLabel,
                    ),
                ),
            ),
            h(Arrow, [
                h(DownArrowLine, {
                    css: {
                        height: pxToRem(16),
                        width: pxToRem(16),
                        color: gray,
                    },
                }),
            ]),
        ]),
        errorMessage && h(ErrorMessage, [errorMessage]),
    ]);
};

Select.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    infoMessage: PropTypes.node,
    errorMessage: PropTypes.node,
};

Select.defaultProps = {
    disabled: false,
    value: '',
};

export default Select;
