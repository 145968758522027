import gql from 'graphql-tag';

export const contentPoints = gql`
    query {
        hotelReviewsForUser {
            total
        }
        poiReviewsForUser {
            total
        }
        shipReviewsForUser {
            count
        }
        videosForUser {
            total
        }
        picturesForUser {
            total
        }
    }
`;
