import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import { red90 } from '../../../styles/waveColors';
import { pxToRem } from '../../../styles/unitConverter';
import { secondaryFont, FONTS } from '../../../styles/fonts';

const { headlineXS } = secondaryFont;

const ResponseWrapper = glamorous.div(
    {
        marginTop: pxToRem(-11),
        borderRadius: pxToRem(4),
    },
    ({ success }) =>
        !success && {
            backgroundColor: red90,
            padding: `${pxToRem(16)} ${pxToRem(24)}`,
        },
);
ResponseWrapper.displayName = 'ResponseWrapper';

const ResponseTitle = glamorous.h3({
    ...headlineXS,
    lineHeight: pxToRem(29),
    fontFamily: FONTS.primary,
});

const ResponseText = glamorous.p({
    fontFamily: FONTS.secondary,
    weight: 400,
    lineHeight: pxToRem(27),
    fontSize: pxToRem(18),
});

const MessageIcon = glamorous.img({
    height: pxToRem(128),
    width: pxToRem(128),
});
MessageIcon.displayName = 'MessageIcon';

const PostDialogSubmitContent = (
    { success, messageIconRelPath, responseTitle, responseText, SubmitButton },
    { config: { assetsPath } },
) => {
    return h(ResponseWrapper, { success }, [
        messageIconRelPath &&
            h(MessageIcon, {
                src: `${assetsPath}${messageIconRelPath}`,
                alt: '',
            }),
        h(ResponseTitle, responseTitle),
        responseText && h(ResponseText, [responseText]),
        SubmitButton && SubmitButton,
    ]);
};

PostDialogSubmitContent.displayName = 'PostDialogSubmitContent';

PostDialogSubmitContent.propTypes = {
    success: PropTypes.bool.isRequired,
    messageIconRelPath: PropTypes.string,
    responseTitle: PropTypes.string.isRequired,
    responseText: PropTypes.string,
    SubmitButton: PropTypes.node,
};

PostDialogSubmitContent.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

export default PostDialogSubmitContent;
