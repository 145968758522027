import glamorous from 'glamorous';

import Grid from './Grid';
import calculateColumnWidth from './calculateColumnWidth';
import {
    applyOnTabletAndUp,
    applyOnDesktopAndUp,
} from '../../styles/mediaQueries';
import { pxToRem } from '../../styles/unitConverter';

const { gridWidths } = Grid;

export default glamorous.div(
    ({ small = 12, medium = small, large = medium }) => ({
        width: calculateColumnWidth('100%', small),

        [applyOnTabletAndUp]: {
            width: calculateColumnWidth(pxToRem(gridWidths.tablet), medium),
        },

        [applyOnDesktopAndUp]: {
            width: calculateColumnWidth(pxToRem(gridWidths.desktop), large),
        },
    }),
);
