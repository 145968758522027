import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function Single({ css }) {
    return h(
        Icon,
        {
            viewBox: '0 0 13 15',
            css,
        },
        [
            h('path', {
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                fill: '#000820',
                d: oneLine`M1.3878 0.769062C1.86771 0.289145 2.51862 0.0195312 3.19732 0.0195312C3.87603 0.0195312 4.52693 0.289145 5.00685 0.769062C5.48677 1.24898 5.75638 1.89988 5.75638 2.57859C5.75638 3.25729 5.48677 3.9082 5.00685 4.38811C4.68388 4.71108 4.28347 4.93881 3.84927 5.05322C4.43658 5.1783 4.98045 5.47075 5.41157 5.90187C5.99893 6.48923 6.32891 7.28587 6.32891 8.11652V10.4082C6.32891 10.6613 6.1237 10.8666 5.87057 10.8666H5.08302L4.79952 14.2658C4.77971 14.5033 4.58113 14.686 4.34277 14.686H2.05107C1.81271 14.686 1.61414 14.5033 1.59433 14.2658L1.31083 10.8666H0.523275C0.270144 10.8666 0.0649414 10.6613 0.0649414 10.4082V8.11652C0.0649414 7.28587 0.394917 6.48923 0.982278 5.90187C1.41351 5.47064 1.95754 5.17815 2.54502 5.05312C2.11095 4.93868 1.71068 4.711 1.3878 4.38811C0.907879 3.9082 0.638265 3.25729 0.638265 2.57859C0.638265 1.89988 0.907879 1.24898 1.3878 0.769062ZM3.19732 0.936198C2.76173 0.936198 2.34398 1.10923 2.03598 1.41724C1.72797 1.72525 1.55493 2.143 1.55493 2.57859C1.55493 3.01418 1.72797 3.43193 2.03598 3.73993C2.34398 4.04794 2.76173 4.22098 3.19732 4.22098C3.63291 4.22098 4.05066 4.04794 4.35867 3.73993C4.66668 3.43193 4.83971 3.01418 4.83971 2.57859C4.83971 2.143 4.66668 1.72525 4.35867 1.41724C4.05066 1.10923 3.63291 0.936198 3.19732 0.936198ZM3.19692 5.9012C2.60939 5.9012 2.04591 6.1346 1.63046 6.55006C1.21501 6.96551 0.981608 7.52898 0.981608 8.11652V9.94988H1.73253C1.97089 9.94988 2.16946 10.1326 2.18928 10.3701L2.47277 13.7694H3.92107L4.20457 10.3701C4.22438 10.1326 4.42296 9.94988 4.66132 9.94988H5.41224V8.11652C5.41224 7.52898 5.17884 6.96551 4.76339 6.55006C4.34794 6.1346 3.78446 5.9012 3.19692 5.9012Z`,
            }),
            h('path', {
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                fill: '#000820',
                d: oneLine`M9.10693 7.47782H11.5236C12.26 7.47782 12.8569 8.07478 12.8569 8.81116V12.5612C12.8569 13.2975 12.26 13.8945 11.5236 13.8945H9.86154C9.67278 14.4285 9.16346 14.8112 8.56478 14.8112C7.80539 14.8112 7.18978 14.1955 7.18978 13.4362C7.18978 12.8373 7.57263 12.3278 8.10693 12.1392V4.64469C8.10693 4.36855 8.33079 4.14469 8.60693 4.14469C8.88308 4.14469 9.10693 4.36855 9.10693 4.64469V7.47782ZM8.93978 13.4362C8.93978 13.6433 8.77189 13.8112 8.56478 13.8112C8.35767 13.8112 8.18978 13.6433 8.18978 13.4362C8.18978 13.2291 8.35767 13.0612 8.56478 13.0612C8.77189 13.0612 8.93978 13.2291 8.93978 13.4362ZM11.5236 12.8945H9.82897C9.6901 12.5708 9.43056 12.3112 9.10693 12.1722V8.47782H11.5236C11.7077 8.47782 11.8569 8.62706 11.8569 8.81116V12.5612C11.8569 12.7453 11.7077 12.8945 11.5236 12.8945Z`,
            }),
        ],
    );
}
