import h from 'react-hyperscript';
import glamorous from 'glamorous';

import LinkWithIcon from '../navigation/LinkWithIcon';
import CameraIcon from '../icons/Camera';
import { loudFont } from '../../styles/fonts';
import { mediumDistance } from '../../styles/distances';

const UploadLink = glamorous.div({
    marginTop: mediumDistance,
    textAlign: 'center',
});

export default () =>
    h(UploadLink, [
        h(
            LinkWithIcon,
            {
                css: loudFont,
                href: '/wcf/hotel/image',
                icon: CameraIcon,
                iconSize: mediumDistance,
            },
            'Bilder hochladen',
        ),
    ]);
