import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { useMemo, useState, useEffect } from 'react';

import Booking from './Booking';
import BookingsFilter from './BookingsFilter';
import { gray40 } from '../../styles/waveColors';
import { pxToRem } from '../../styles/unitConverter';

const BookingListContainer = glamorous.div({
    marginBottom: pxToRem(80),
});

const BookingsFilterContainer = glamorous.div({
    maxWidth: `min(calc(100% - ${pxToRem(40)}), ${pxToRem(384)})`,
    marginTop: pxToRem(24),
    marginLeft: 'auto',
    marginRight: 'auto',
});

const BookingContainer = glamorous.div({
    display: 'block',
    marginTop: pxToRem(64),
    ':first-child': {
        marginTop: pxToRem(40),
    },
});

const NoBookingsContainer = glamorous.div({
    textAlign: 'center',
    marginTop: pxToRem(80),
    marginBottom: pxToRem(80),
    '& img': {
        marginBottom: pxToRem(15),
    },
    '& div': {
        color: gray40,
        fontWeight: 'bold',
        width: pxToRem(252),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});

const renderBookings = (bookings) => {
    return bookings.map((booking, index) => {
        return h(
            BookingContainer,
            { id: `${booking.id}`, key: booking.id },
            h(Booking, {
                index,
                booking,
                alwaysShowDetails: bookings.length === 1,
            }),
        );
    });
};

const renderNoBookings = (assetsPath) => {
    return h(NoBookingsContainer, {}, [
        h('img', { src: `${assetsPath}/myBookings/no-bookings.svg` }),
        h('div', {}, 'Aktuell hast Du keine Reisen geplant.'),
    ]);
};

const splitAndSortBookings = (bookings, currentDate) => {
    const unsorted = bookings.reduce(
        (acc, booking) => {
            if (
                new Date(booking.endDate) > currentDate &&
                booking.bookingType !== 'CANCELLATION'
            ) {
                acc.futureBookings.push(booking);
            } else {
                acc.pastBookings.push(booking);
            }
            return acc;
        },
        { futureBookings: [], pastBookings: [] },
    );

    const futureBookings = unsorted.futureBookings.sort(
        (booking1, booking2) => {
            return new Date(booking1.startDate) - new Date(booking2.startDate);
        },
    );

    const pastBookings = unsorted.pastBookings.sort((booking1, booking2) => {
        return new Date(booking2.startDate) - new Date(booking1.startDate);
    });

    return {
        futureBookings,
        pastBookings,
        futureBookingsCount: futureBookings.length,
        pastBookingsCount: pastBookings.length,
    };
};

const trackCurrentList = (
    trackEvent,
    pastBookingsShown,
    futureBookingsCount,
    pastBookingsCount,
) => {
    trackEvent({
        event: 'page',
        gePageName: `/mhc/bookings/${pastBookingsShown ? 'past' : 'future'}`,
        futureBookingsCount,
        pastBookingsCount,
    });
};

const jumpToBookingId = (location) => {
    if (location.hash) {
        location.replace(String(location));
    }
};

const BookingList = (
    { bookings },
    { getCurrentDate, trackEvent, location, config: { assetsPath } },
) => {
    const {
        futureBookings,
        pastBookings,
        futureBookingsCount,
        pastBookingsCount,
    } = useMemo(
        () => splitAndSortBookings(bookings, getCurrentDate()),
        [bookings],
    );

    const [pastBookingsShown, setPastBookingsShown] = useState(
        futureBookingsCount === 0 && pastBookingsCount > 0,
    );

    useEffect(() => jumpToBookingId(location), []);
    useEffect(
        () =>
            trackCurrentList(
                trackEvent,
                pastBookingsShown,
                futureBookingsCount,
                pastBookingsCount,
            ),
        [],
    );

    if (bookings.length === 0) {
        return renderNoBookings(assetsPath);
    }

    return h(BookingListContainer, [
        h(
            BookingsFilterContainer,
            {},
            h(BookingsFilter, {
                futureBookingsCount,
                pastBookingsCount,
                pastBookingsShown,
                setPastBookingsShown: (newPastBookingsShown) => {
                    setPastBookingsShown(newPastBookingsShown);
                    trackCurrentList(
                        trackEvent,
                        newPastBookingsShown,
                        futureBookingsCount,
                        pastBookingsCount,
                    );
                },
            }),
        ),
        h(
            glamorous.div({
                margin: `0 ${pxToRem(20)}`,
            }),
            renderBookings(pastBookingsShown ? pastBookings : futureBookings),
        ),
    ]);
};

BookingList.propTypes = {
    bookings: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            startDate: PropTypes.string.isRequired,
            endDate: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

BookingList.contextTypes = {
    getCurrentDate: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

export default BookingList;
