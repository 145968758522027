import glamorous from 'glamorous';
import options from '@designsystem/options';

export const BookingListContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: `${options.space['3xl']}px`,
    marginBottom: `${options.space['6xl']}px`,
    padding: `0 ${options.space.l}px`,
});
