import h from 'react-hyperscript';
import options from '@designsystem/options';

import Icon from './Icon';

export default function FlagIcon({ children, css }) {
    const height = 20;
    const width = 25;

    return h(
        Icon,
        {
            css: {
                ...css,
                borderRadius: options.radii.small,
                display: 'block',
                height: `${height}px`,
                width: `${width}px`,
            },
            viewBox: `0 0 ${width} ${height}`,
        },
        children,
    );
}
