import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import {
    applyOnTabletAndUp,
    applyOnMobile,
} from '../../../styles/mediaQueries';
import HotelRecommendationRate from './HotelRecommendationRate';
import { black, blue } from '../../../styles/waveColors';
import { secondaryFont } from '../../../styles/fonts';
import { pxToRem } from '../../../styles/unitConverter';
import Link from '../../navigation/Link';
import { HotelReviewCalculationsType } from '../../../lib/types';
import { smallDistance } from '../../../styles/distances';
import useBookingContext from '../lib/useBookingContext';

const { UITextLLoud } = secondaryFont;

const PATH_TO_DEFAULT_IMAGE = 'myHolidayCheck/hotel-placeholder.png';
const IMAGE_WIDTH_TABLETANDUP = pxToRem(180);
const IMAGE_HEIGHT_TABLETANDUP = pxToRem(120);
const IMAGE_HEIGHT_SMARTPHONE = pxToRem(300);

const HotelContainer = glamorous.div({
    display: 'flex',
    marginBottom: pxToRem(24),

    [applyOnMobile]: {
        flexDirection: 'column',
    },
});

const HotelImage = glamorous.div(({ imageUrls, smartphoneImageUrls }) => ({
    display: 'inline-block',
    borderRadius: pxToRem(4),
    width: IMAGE_WIDTH_TABLETANDUP,
    height: IMAGE_HEIGHT_TABLETANDUP,
    backgroundRepeat: 'no-repeat',
    backgroundImage: imageUrls,
    backgroundPosition: 'center',

    [applyOnMobile]: {
        width: '100%',
        height: IMAGE_HEIGHT_SMARTPHONE,
        backgroundImage: smartphoneImageUrls,
    },
}));
HotelImage.displayName = 'HotelImage';

const HotelDetail = glamorous.div({
    [applyOnTabletAndUp]: {
        display: 'inline-block',
        marginLeft: pxToRem(24),
    },
    [applyOnMobile]: {
        marginTop: pxToRem(24),
    },
});

const HotelName = glamorous.div(UITextLLoud);
HotelName.displayName = 'HotelName';

const HotelNameHeading = glamorous.h4({ margin: 0 });
HotelNameHeading.displayName = 'HotelNameHeading';

const LinkedHotelName = glamorous.a(UITextLLoud, {
    display: 'block',
    textDecoration: 'none',
    color: black,
});
LinkedHotelName.displayName = 'LinkedHotelName';

const HotelAddress = glamorous.div();
HotelAddress.displayName = 'HotelAddress';

const renderAddress = (address) => {
    const hasAnAddress = Boolean(address);
    return hasAnAddress
        ? h(HotelAddress, address.replace(/(\r\n|\n|\r)/gm, ', '))
        : null;
};

const renderContactInfoIcon = (imgSrc, imgAlt) => {
    return h('img', {
        src: imgSrc,
        style: {
            marginRight: smallDistance,
            width: pxToRem(16),
            height: pxToRem(16),
            verticalAlign: 'text-top',
        },
        alt: imgAlt,
    });
};

const renderContactInfoLink = (url, imgSrc, text, imgAlt) => {
    return h('div', [
        renderContactInfoIcon(imgSrc, imgAlt),
        h(
            Link,
            {
                href: url,
                target: '_blank',
                css: {
                    color: blue,
                    display: 'inline-block',
                    paddingTop: pxToRem(6),
                    paddingBottom: pxToRem(6),
                },
            },
            text,
        ),
    ]);
};

const renderContactInfo = (hotel, assetsPath) => {
    return h('div', { style: { marginTop: smallDistance } }, [
        hotel.phone &&
            renderContactInfoLink(
                `tel:${hotel.phone}`,
                `${assetsPath}/myBookings/phone.svg`,
                hotel.phone,
                'Telefonnummer',
            ),
        hotel.email &&
            renderContactInfoLink(
                `mailto:${hotel.email}`,
                `${assetsPath}/myBookings/email.svg`,
                hotel.email,
                'E-Mail-Adresse',
            ),
        hotel.url &&
            renderContactInfoLink(
                hotel.url,
                `${assetsPath}/myBookings/link.svg`,
                'offizielle Hotel Homepage',
                'Homepage',
            ),
    ]);
};

const renderRoute = (trackEventWithBooking, geo) => {
    const isGeoLocationKnown = Boolean(geo);

    return isGeoLocationKnown
        ? h(
              Link,
              {
                  href:
                      'https://www.google.com/maps/dir/?api=1&destination=' +
                      `${geo.coordinates[1]}, ${geo.coordinates[0]}`,
                  target: '_blank',
                  css: { color: blue, display: 'inline-block' },
                  onClick: trackEventWithBooking.bind(null, {
                      event: 'event',
                      eventCategory: 'mybooking',
                      eventAction: 'openRoute',
                  }),
              },
              'Route berechnen',
          )
        : null;
};

const getHotelDetailItems = (hotel) => {
    const { id, name, reviewCalculations } = hotel;
    const isHCKnownHotel = Boolean(id);
    if (isHCKnownHotel) {
        return [
            h(
                LinkedHotelName,
                {
                    href: `/hi/${id}`,
                },
                h(HotelNameHeading, name),
            ),
            isHCKnownHotel &&
                reviewCalculations &&
                h(HotelRecommendationRate, {
                    hotelId: id,
                    reviewCalculations: reviewCalculations.overall,
                }),
        ];
    }
    return [h(HotelName, [h(HotelNameHeading, name)])];
};

const Hotel = ({ hotel }, { config: { assetsPath } }) => {
    const { id, address, geo: geoLocation } = hotel;
    const { trackEventWithBooking } = useBookingContext();

    return h(HotelContainer, [
        h(HotelImage, {
            imageUrls: [
                'url(https://media-cdn.holidaycheck.com/w_260,h_175,c_fill,q_auto,f_auto/ugc/images/' +
                    `${id})`,
                `url(${assetsPath}/${PATH_TO_DEFAULT_IMAGE})`,
            ].join(','),
            smartphoneImageUrls: [
                'url(https://media-cdn.holidaycheck.com/w_768,h_432,c_fill,q_auto,f_auto/ugc/images/' +
                    `${id})`,
                `url(${assetsPath}/${PATH_TO_DEFAULT_IMAGE})`,
            ].join(','),
        }),
        h(HotelDetail, [
            ...getHotelDetailItems(hotel),
            renderAddress(address),
            renderRoute(trackEventWithBooking, geoLocation),
            renderContactInfo(hotel, assetsPath),
        ]),
    ]);
};

Hotel.propTypes = {
    hotel: PropTypes.shape({
        id: PropTypes.string,
        address: PropTypes.string,
        email: PropTypes.string,
        url: PropTypes.string,
        phone: PropTypes.string,
        geo: PropTypes.shape({
            coordinates: PropTypes.arrayOf(PropTypes.number).isRequired,
        }),
        name: PropTypes.string.isRequired,
        reviewCalculations: HotelReviewCalculationsType,
    }).isRequired,
};

Hotel.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

export default Hotel;
