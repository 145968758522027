import h from 'react-hyperscript';
import options from '@designsystem/options';
import glamorous from 'glamorous';

import RadioButtonGroup from '../../inputs/RadioButtonGroup';
import { sizes, variations, WaveButton } from '../../WaveButton';
import { pxToRem } from '../../../styles/unitConverter';
import { useCancellationContext } from '../context/CancellationContext';

const CancellationOptionsStyle = glamorous.div({
    '> div': {
        border: 'none',
    },
    '> div > div': {
        border: 'none',
        padding: `0 0 ${pxToRem(options.space.l)} 0`,
    },
    '> div > div label': {
        fontWeight: options.fontWeights.mediumBold,
        fontSize: options.fontSizes.xs,
    },
});

const Title = glamorous.h3({ margin: 0 });

function CancellationReasons() {
    const {
        setSelectedCancellationReason,
        getCancellationReason,
        onReasonSubmit,
    } = useCancellationContext();
    const cancellationReason = getCancellationReason();

    return h(CancellationOptionsStyle, [
        h(Title, 'Warum möchtest Du kündigen?'),
        h(
            'p',
            'Wir wären Dir sehr dankbar, wenn Du uns den Grund Deiner Kündigung nennst. So können wir unseren Service für andere verbessern. Vielen Dank!',
        ),

        h(RadioButtonGroup, {
            isInline: false,
            name: 'cancellation-reason',
            onChange: setSelectedCancellationReason,
            options: [
                {
                    label: 'Mitgliedschaft ist zu teuer',
                    value: 'premium_too_expensive',
                },
                {
                    label: 'Keine Reise geplant',
                    value: 'no_travel_planned',
                },
                {
                    label: 'Unzufrieden mit dem Service',
                    value: 'dissatisfied_with_service',
                },
                {
                    label: 'Unzufrieden mit den Vorteilen',
                    value: 'dissatisfied_with_benefits',
                },
                {
                    label: 'Ich wollte kein Abo abschließen',
                    value: 'not_intended_to_subscribe',
                },
                {
                    label: 'Keinen Gutschein erhalten',
                    value: 'vouchers_not_redeemed',
                },
                {
                    label: 'Kein Mehrwert',
                    value: 'no_value',
                },
                {
                    label: 'Unzufrieden mit HolidayCheck',
                    value: 'dissatisfied_with_HolidayCheck',
                },
                {
                    label: 'Reise wurde storniert',
                    value: 'travel_cancelled',
                },
                { label: 'Sonstiges', value: 'other' },
            ],
            selectedValue: cancellationReason,
            disabled: false,
        }),
        h(
            WaveButton,
            {
                variant: variations.PRIMARY,
                size: sizes.S,
                onClick: onReasonSubmit,
                disabled: !cancellationReason,
                type: 'submit',
                customStyles: {
                    margin: `${pxToRem(options.space['3xl'])} auto 0 auto`,
                    display: 'block',
                },
            },
            'Weiter Zur Kündigung',
        ),
    ]);
}

export default CancellationReasons;
