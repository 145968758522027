import glamorous from 'glamorous';
import h from 'react-hyperscript';
import options from '@designsystem/options';
import PropTypes from 'prop-types';

import { SectionContainer, SectionTitle } from '../BookingDetails';
import {
    waveTextLBold,
    waveTextM,
    waveTextS,
} from '../../../../styles/waveText';
import formatPrice, {
    formatPriceShort,
} from '../../../myBookings/payment/formatPrice';
import { RetalCarBookingType } from '../../BookingPropType';

const PAYMENT_OPTIONS = {
    BankTransfer: 'Überweisung',
    OnBill: 'Rechnung',
    CreditCard: 'Kreditkarte',
    OnSite: 'vor Ort',
    Paypal: 'Paypal',
    PayDirekt: 'PayDirekt',
    AmazonPay: 'AmazonPay',
};

const getPaymentText = (paymantOption) =>
    PAYMENT_OPTIONS[paymantOption] || paymantOption;

const PaymentContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    gap: `${options.space.xl}px`,
});

const PaymentDetailsContainer = glamorous.div({
    border: `1px solid ${options.colors.gray90}`,
    borderRadius: '4px',
});

const PaymentDetailsHeadline = glamorous.div({
    padding: `${options.space.m}px`,
    borderBottom: `1px solid ${options.colors.gray90}`,
});
const PaymentDetailsHeadlineText = glamorous.span({
    ...waveTextLBold,
});

const PaymentDetailsItem = glamorous.div({
    padding: `${options.space.s}px ${options.space.m}px`,
    ':not(:last-child)': {
        borderBottom: `1px solid ${options.colors.gray100}`,
    },
});

const PaymentDetailsRow = glamorous.div({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
});

const PaymentDetailsHeadlineSubtext = glamorous.span({
    ...waveTextS,
});

const PaymentDetailsCategory = glamorous.span({
    ...waveTextM,
});

const PaymentDetailsInfo = glamorous.span({
    ...waveTextS,
    color: options.colors.tertiary,
});

const ExtraPrices = ({ extras }) => {
    const price = extras.reduce(
        (extraA, extraB) => extraA.price.amount + extraB.price.amount,
    );
    return (
        price > 0 &&
        h(
            PaymentDetailsCategory,
            { className: 'payment-extras-price' },
            formatPrice(price, extras.at(0).price.currency),
        )
    );
};

function PaymentInformation({ booking, myRef, sectionId, campaigns }) {
    const validCoupons = (booking.price.discountCoupons || [])
        .filter((coupon) => coupon.valid)
        .map((coupon) =>
            campaigns.some(
                (campaign) =>
                    campaign.code.toLowerCase() === coupon.code.toLowerCase(),
            )
                ? {
                      ...coupon,
                      code: `${formatPriceShort(coupon.worth.amount * -1, coupon.worth.currency)} für Dich`,
                  }
                : coupon,
        );

    const paymentExtraPrice = `${booking.price.extras
        .slice(0, 3)
        .map((extra) => extra.name)
        .join(', ')}${booking.price.extras.length > 3 ? ', ...' : ''}`;
    const localSupplierName = booking.carOffer.localSupplierName
        ? ` an ${booking.carOffer.localSupplierName}`
        : '';

    return h(SectionContainer, { id: sectionId, innerRef: myRef }, [
        h(PaymentContainer, [
            h(SectionTitle, 'Deine Zahlungsinformation'),
            h(PaymentDetailsContainer, [
                h(PaymentDetailsHeadline, [
                    h(PaymentDetailsRow, [
                        h(PaymentDetailsHeadlineText, 'Dein Mietwagen'),
                        h(
                            PaymentDetailsHeadlineText,
                            formatPrice(
                                booking.price.car.amount,
                                booking.price.car.currency,
                            ),
                        ),
                    ]),
                    booking.paymentType !== null &&
                        h(PaymentDetailsRow, [
                            h(PaymentDetailsHeadlineSubtext, 'Zahlungsart'),
                            h(
                                PaymentDetailsHeadlineSubtext,
                                getPaymentText(booking.paymentType),
                            ),
                        ]),
                ]),
                h(PaymentDetailsItem, { className: 'payment-price' }, [
                    h(PaymentDetailsRow, [
                        h(PaymentDetailsCategory, 'Mietpreis'),
                        h(
                            PaymentDetailsCategory,
                            formatPrice(
                                booking.carOffer.priceAmount,
                                booking.carOffer.priceCurrency,
                            ),
                        ),
                    ]),
                    h(PaymentDetailsRow, [
                        h(
                            PaymentDetailsInfo,
                            `Zahlung vor der Anmietung an ${booking.supplierName}`,
                        ),
                    ]),
                ]),
                booking.price.extras.length > 0 &&
                    h(PaymentDetailsItem, { className: 'payment-extras' }, [
                        h(PaymentDetailsRow, [
                            h(
                                PaymentDetailsCategory,
                                { className: 'payment-extras-name' },
                                paymentExtraPrice,
                            ),
                            h(ExtraPrices, { extras: booking.price.extras }),
                        ]),
                        h(PaymentDetailsRow, [
                            h(
                                PaymentDetailsInfo,
                                `Zahlung vor Ort${localSupplierName}`,
                            ),
                        ]),
                    ]),
                booking.price.other.map((other) =>
                    h(
                        PaymentDetailsItem,
                        { key: other.name, className: 'payment-other' },
                        [
                            h(PaymentDetailsRow, [
                                h(PaymentDetailsCategory, other.name),
                                h(
                                    PaymentDetailsCategory,
                                    { className: 'payment-other-price' },
                                    other.price.amount > 0
                                        ? formatPrice(
                                              other.price.amount,
                                              other.price.currency,
                                          )
                                        : 'Inklusive',
                                ),
                            ]),
                        ],
                    ),
                ),
            ]),
            validCoupons.length > 0 &&
                h(PaymentDetailsContainer, [
                    h(PaymentDetailsHeadline, [
                        h(PaymentDetailsHeadlineText, 'Deine Gutscheine'),
                    ]),
                    h(PaymentDetailsItem, { className: 'payment-coupons' }, [
                        validCoupons.map((coupon) =>
                            h(
                                PaymentDetailsRow,
                                {
                                    key: coupon.code,
                                    className: 'payment-coupons-coupon',
                                },
                                [
                                    h(PaymentDetailsCategory, coupon.code),
                                    h(
                                        PaymentDetailsCategory,
                                        `-${formatPrice(coupon.worth.amount, coupon.worth.currency)}`,
                                    ),
                                ],
                            ),
                        ),
                        h(
                            PaymentDetailsInfo,
                            'Du erhältst die Gutschrift umgehend nach Reise-Rückkehr.',
                        ),
                    ]),
                ]),
        ]),
    ]);
}

PaymentInformation.displayName = 'PaymentInformation';
PaymentInformation.propTypes = {
    sectionId: PropTypes.string.isRequired,
    myRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any }),
    ]),
    booking: RetalCarBookingType.isRequired,
};
export { PaymentInformation };
