import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import options from '@designsystem/options';
import { useMemo, useRef } from 'react';

import { useMasonryGapFix } from './useMasonryGapFix';
import {
    waveLgMediaQuery,
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../../styles/waveMediaQueries';
import {
    getColumnNo,
    groupChildrenByColumns,
    sortIntoColumns,
} from './masonryUtils';

function MasonryLayout(
    {
        columns = {
            md: [],
            lg: [],
        },
        gap = options.space.xl,
        breakpoint = 1024,
        children,
        className = '',
        style = {},
    },
    context,
) {
    const containerRef = useRef(null);

    useMasonryGapFix({
        containerRef,
        columnCount: columns.md.length,
        gap,
        breakpoint,
        context,
    });

    const MasonryContainer = useMemo(
        () =>
            glamorous.div({
                display: 'flex',
                flexDirection: 'column',
                gap: `${gap}px`,
                gridAutoFlow: 'dense',
                fontFamily: options.fonts.default,
                margin: `${options.space['3xl']}px ${options.space.l}px`,
                '& > div:has(> div:empty)': {
                    display: 'none',
                },
                [waveSmMediaQuery]: {
                    marginLeft: options.space.xl,
                    marginRight: options.space.xl,
                    ...style[waveSmMediaQuery],
                },
                [waveMdMediaQuery]: {
                    display: 'grid',
                    gridTemplateColumns: columns.md
                        .map((width) => `${width}px`)
                        .join(' '),
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: 960,
                    ...style[waveMdMediaQuery],
                },
                [waveLgMediaQuery]: {
                    gridTemplateColumns: columns.lg
                        .map((width) => `${width}px`)
                        .join(' '),
                    width: 1200,
                    ...style[waveLgMediaQuery],
                },
                ...style,
            }),
        [gap, style, columns],
    );

    const MasonryChild = ({ child, index }) => {
        const columnNo = getColumnNo(child, index, columns.md.length);
        return h(
            glamorous.div({
                order: (child.props && child.props.mobileOrder) || 1,
                display: 'flex',
                flexBasis: '100%',
                opacity: 0,
                [waveMdMediaQuery]: {
                    gridColumn: columnNo,
                    alignSelf: 'start',
                    order: 'initial',
                    willChange: 'transform',
                    ...(child.props && child.props[waveMdMediaQuery]),
                },
                ...(child.props && child.props.masonryStyle),
            }),
            {
                key: index,
                'data-masonry-column': columnNo,
                ...(child.props && child.props.dataTestKey
                    ? { 'data-test-key': child.props.dataTestKey }
                    : null),
            },
            [child],
        );
    };

    const masonryChildren = useMemo(() => {
        const columnGroups = groupChildrenByColumns(
            children,
            columns.md.length,
        );
        return sortIntoColumns(columnGroups, columns.md.length).map(
            MasonryChild,
        );
    }, [children, columns]);

    return h(
        MasonryContainer,
        {
            innerRef: containerRef,
            className,
        },
        masonryChildren,
    );
}

MasonryLayout.contextTypes = {
    window: PropTypes.object,
};

export default MasonryLayout;
