import { string, date } from 'yup';
import moment from 'moment';

const participantSchema = {
    salutation: string().required('Bitte gebe Deine Anrede an.'),
    firstName: string()
        .required('Bitte gebe Deinen Vornamen an.')
        .matches(
            '^([^0-9\\§\\$\\%\\/\\(\\)\\=\\?\\,\\_\\:;\\]\\+\\*\\~<>\\|]+)$',
            'Der Vorname des Reisenden enthält ungültige Zeichen.',
        ),
    lastName: string()
        .required('Bitte gebe Deinen Nachnamen an.')
        .matches(
            '^([^0-9\\§\\$\\%\\/\\(\\)\\=\\?\\,\\_\\:;\\]\\+\\*\\~<>\\|]+)$',
            'Der Nachname des Reisenden enthält ungültige Zeichen.',
        ),
    dateOfBirth: date()
        .transform((_, originalValue) => {
            const d = moment(originalValue, 'YYYY-MM-DD', true);
            return d.isValid() ? d.toDate() : new Date('');
        })
        .typeError('Bitte prüfe Dein Geburtsdatum.')
        .min(moment().subtract(120, 'years'), 'Bitte prüfe Dein Geburtsdatum.')
        .max(
            moment(),
            'Bitte prüfe Dein Geburtsdatum. Das Datum muss in der Vergangenheit liegen.',
        ),
};

export default participantSchema;
