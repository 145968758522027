export default (document, pathPrefix, clickListeners) => {
    const prefix = `${pathPrefix}/mhc`;
    const links = document.querySelectorAll(`a[href^='${prefix}']`);

    for (let i = 0, length = links.length; i < length; i += 1) {
        const link = links[i];

        clickListeners.forEach((clickListener) => {
            link.removeEventListener('click', clickListener);
        });
    }
};
