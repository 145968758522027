import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import ThumbIcon from '../../../icons/Thumb';

const IconContainer = glamorous.span(({ type }) => ({
    display: 'flex',
    width: `${options.space.l}px`,
    height: `${options.space.l}px`,
    backgroundColor:
        type === 'positive' ? options.colors.green : options.colors.red,
    borderTopLeftRadius: options.radii.small,
    borderBottomLeftRadius: options.radii.small,
    padding: 6,
    transform: type === 'negative' ? 'matrix(1, 0, 0, -1, 0, 0)' : 'none',
}));

const thumbIconProps = {
    width: 20,
    height: 20,
    color: options.colors.white,
};

export default function Recommendation({ type }) {
    return h(IconContainer, { type }, [
        h(ThumbIcon, { style: thumbIconProps }),
    ]);
}
