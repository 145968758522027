import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { isActiveBooking } from '../../../../common/lib/bookingHelper';
import BookingSectionHeadline from '../BookingSectionHeadline';
import Link from '../../navigation/Link';
import { secondaryFont } from '../../../styles/fonts';

const { UITextL, PTSansFamily } = secondaryFont;

const TOUROPERATOR_ALD = 'ec13e356-9c76-3f2c-95b4-8b3b195d8fb9';
const TOUROPERATOR_BENX = 'f1dfd096-af12-3ef2-8677-b734174e98f4';
const TOUROPERATOR_BXCH_CH = 'c980e654-e624-3af5-8bad-2cb1b1568334';
const TOUROPERATOR_BYE = '85e69abf-4555-35a6-bcb7-40fca7f40fd3';
const TOUROPERATOR_CMED = '511b4df9-5879-3017-bea5-3811d11415c0';
const TOUROPERATOR_EWH = 'b3ceab76-cf84-35cc-b9aa-d2080e799c27';
const TOUROPERATOR_FATV = '622472b6-f92d-32d1-9a50-3c1486824256';
const TOUROPERATOR_FER = '9f7a8980-1122-3990-b0b7-b91554442050';
const TOUROPERATOR_FIT = '4051ab4a-643a-3188-9404-ff8b168e6321';
const TOUROPERATOR_FUV = 'ecf32c0a-bd34-3b97-a3d8-00ed07fc3f41';
const TOUROPERATOR_GRUB = '13f78d71-6a36-357a-b283-c56ccc6f799e';
const TOUROPERATOR_HLX = '59dcf7f8-fba5-37a2-aece-bee365f0aa6c';
const TOUROPERATOR_HLX1 = '9e561ea5-ea28-3c6a-b511-577d7e8c97c0';
const TOUROPERATOR_ITSX = 'c2edaf6c-bd17-3ed8-abb0-4b11d7595ab7';
const TOUROPERATOR_LHH = '41c2b4dd-1543-3868-9742-73e88cdb5a04';
const TOUROPERATOR_LMX = '7c9b3938-9d47-3bf2-a2c4-615e8b0dcf4c';
const TOUROPERATOR_LMXI = '4c581666-aa2c-3aef-b7fb-5f753c559d6b';
const TOUROPERATOR_LTUR = '83464abd-e674-3512-8e0c-d9d507e066cf';
const TOUROPERATOR_LXH_CH = 'e7b7dd97-cbd5-3fe5-a439-b8ebca9d09f4';
const TOUROPERATOR_SLR_CH = '948efd24-2855-39e7-a51f-dd3be5a7a963';
const TOUROPERATOR_SLRD = 'affc727c-6827-37e5-851d-d8852b31530d';
const TOUROPERATOR_TISC = '4b5015bb-6f58-3d0f-9eac-a05a6ac66ab1';
const TOUROPERATOR_TJAX = '23e202df-7a27-344b-acb5-e5dccf80780a';
const TOUROPERATOR_TRAL = 'd2643984-8c5c-338c-80ef-f5adaf517855';
const TOUROPERATOR_XALD = 'f03af5ff-8974-3b66-891b-07f50c64dbd8';
const TOUROPERATOR_XALL = 'd2f5cff1-7252-3b19-a321-f0c1512c641d';
const TOUROPERATOR_XANE = 'a0d55118-78c9-361c-8958-d4306cf3fee2';
const TOUROPERATOR_XBU = '77e2a30d-4541-37fb-9cc7-56595643f96a';
const TOUROPERATOR_XDER = '15d74ba1-d2cb-3a63-97b7-c2ede5ac1a3e';
const TOUROPERATOR_XFIT = 'e6a76396-f454-3d6a-8bc2-7c5dbb3bd481';
const TOUROPERATOR_XHEL = 'c87c147e-a73b-3a4d-acf9-c58b8b149e15';
const TOUROPERATOR_XHEL_CH = 'b7e41e64-af24-3f45-bcda-45428ccad154';
const TOUROPERATOR_XJAH = '6d395861-0626-386b-bfe1-afc7c09a0ae3';
const TOUROPERATOR_XLMX = 'c45043c8-9c89-38af-bcf3-2443d521dfe1';
const TOUROPERATOR_XLTR = 'fb643564-9ba8-3c60-b37c-db8819e4ee7d';
const TOUROPERATOR_XMWR = '6e8e509d-04f1-36a0-a985-66cf93c10369';
const TOUROPERATOR_XTUI = '68ba5f23-4bcb-38ce-9b6b-b8850134b4cc';

const Instruction = glamorous.span(UITextL, PTSansFamily);
Instruction.displayName = 'Instruction';

const RebookingFAQLink = glamorous(Link)();

const nonRebookableTourOperators = [
    TOUROPERATOR_ALD,
    TOUROPERATOR_BENX,
    TOUROPERATOR_BXCH_CH,
    TOUROPERATOR_BYE,
    TOUROPERATOR_CMED,
    TOUROPERATOR_EWH,
    TOUROPERATOR_FATV,
    TOUROPERATOR_FER,
    TOUROPERATOR_FIT,
    TOUROPERATOR_FUV,
    TOUROPERATOR_GRUB,
    TOUROPERATOR_HLX,
    TOUROPERATOR_HLX1,
    TOUROPERATOR_ITSX,
    TOUROPERATOR_LHH,
    TOUROPERATOR_LMX,
    TOUROPERATOR_LMXI,
    TOUROPERATOR_LTUR,
    TOUROPERATOR_LXH_CH,
    TOUROPERATOR_SLR_CH,
    TOUROPERATOR_SLRD,
    TOUROPERATOR_TISC,
    TOUROPERATOR_TJAX,
    TOUROPERATOR_TRAL,
    TOUROPERATOR_XALD,
    TOUROPERATOR_XALL,
    TOUROPERATOR_XANE,
    TOUROPERATOR_XBU,
    TOUROPERATOR_XDER,
    TOUROPERATOR_XFIT,
    TOUROPERATOR_XHEL,
    TOUROPERATOR_XHEL_CH,
    TOUROPERATOR_XJAH,
    TOUROPERATOR_XLMX,
    TOUROPERATOR_XLTR,
    TOUROPERATOR_XMWR,
    TOUROPERATOR_XTUI,
];

const showRebookingHint = (tourOperatorId, bookingType) => {
    return (
        isActiveBooking(bookingType) &&
        nonRebookableTourOperators.includes(tourOperatorId)
    );
};

const Rebooking = ({ booking, myRef, setWillRender }) => {
    const { tourOperator, bookingType } = booking;
    if (!showRebookingHint(tourOperator.id, bookingType)) {
        return null;
    }

    useEffect(() => setWillRender(true), []);

    const cancellationFAQLink =
        'https://hilfe.holidaycheck.com/de/category/buchung';

    const instruction =
        'Bitte beachte, dass der Reisezeitraum Deiner Reise nicht umgebucht werden kann. ' +
        'Ausnahmen sind Umbuchungen, die nicht den Reisezeitraum betreffen. ' +
        'Bspw. Namensänderungen oder eine Umbuchung auf ein anderes Hotel, Verpflegung etc. ';

    return h(Fragment, [
        h(BookingSectionHeadline, { innerRef: myRef }, 'Urlaub umbuchen'),
        h(Instruction, [
            h('div', instruction),
            h('span', 'Details findest Du unter '),
            h(
                RebookingFAQLink,
                {
                    href: cancellationFAQLink,
                    target: '_blank',
                },
                '„Fragen und Antworten zur Reise“ – Umbuchung.',
            ),
        ]),
    ]);
};

Rebooking.propTypes = {
    booking: PropTypes.shape({
        tourOperator: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
        bookingType: PropTypes.string.isRequired,
    }).isRequired,
    // from using `useRef` hook, `ref` prop is treated differently and would need the `forwardRef` crap
    myRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
    setWillRender: PropTypes.func.isRequired,
};

Rebooking.displayName = 'Rebooking';

export default Rebooking;
