import glamorous from 'glamorous';
import options from '@designsystem/options';

import {
    waveHeadlineM,
    waveHeadlineXS,
} from '../../../../styles/waveHeadlines';
import {
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../../../styles/waveMediaQueries';

export const BookingListTitle = glamorous.h1({
    fontFamily: 'Montserrat',
    ...waveHeadlineXS,
    textAlign: 'center',
    marginBottom: `${options.space.xl}px`,
    padding: `0 ${options.space.l}px`,
    [waveMdMediaQuery]: {
        ...waveHeadlineM,
    },
    [waveSmMediaQuery]: {
        marginBottom: `${options.space['3xl']}px`,
    },
});
