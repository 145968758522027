import moment from 'moment';

export const extractFlight = (booking) =>
    booking.services.find((service) => service.type === 'FLIGHT');

const extractFlights = (booking, boundName) => {
    const flight = extractFlight(booking);
    if (!flight || !flight.detail[boundName]) {
        return [];
    }
    return flight.detail[boundName];
};

const extractLastInBoundFlight = (booking) => {
    const inBoundFlights = extractFlights(booking, 'inBound');
    return inBoundFlights[inBoundFlights.length - 1];
};

const extractFirstOutBoundFlight = (booking) => {
    const outBoundFlights = extractFlights(booking, 'outBound');
    return outBoundFlights[0];
};

export const findEndDateWithTime = (booking) => {
    const lastInBoundFlight = extractLastInBoundFlight(booking);
    if (!lastInBoundFlight || !lastInBoundFlight.arrival.date) {
        return new Date(booking.endDate);
    }
    return new Date(lastInBoundFlight.arrival.date);
};

export const findStartDateWithTime = (booking) => {
    const firstOutboundFlight = extractFirstOutBoundFlight(booking);
    if (!firstOutboundFlight || !firstOutboundFlight.departure.date) {
        return new Date(booking.startDate);
    }
    return new Date(firstOutboundFlight.departure.date);
};

export const getFormattedTimespan = (booking, formatFunction, format) => {
    return `${formatFunction(booking.startDate, format)} - ${formatFunction(booking.endDate, format)}`;
};

export const isActiveBooking = (bookingType) =>
    bookingType === 'BOOKING' || bookingType === 'ORDER';

/**
 * Checks whether the booking has been started.
 * Definition of "started" being:
 * "now" date is higher or equal than start date, ignoring the end date.
 * @param {Booking} booking
 * @returns {boolean}  true if the booking has started, independently from it having finished or not
 *                     false otherwise
 * @see https://momentjs.com/docs/#/query/is-same-or-after/
 */
export const hasStarted = (booking, currentDate) => {
    if (!currentDate) {
        throw new Error(
            'The `currentDate` parameter of the `hasStarted` function is undefined',
        );
    }
    return moment(currentDate).isSameOrAfter(
        booking.startDate,
        // granularity
        'second',
    );
};

/**
 * Checks whether the booking has ended.
 * Definition of "ended" being:
 * "now" date is higher than the end date
 * @param {Booking} booking
 * @returns {boolean}  true if the booking has ended
 * @see https://momentjs.com/docs/#/query/is-after/
 */
export const hasEnded = (booking, currentDate) => {
    if (!currentDate) {
        throw new Error(
            'The `currentDate` parameter of the `hasEnded` function is undefined',
        );
    }
    return moment(currentDate).isAfter(booking.endDate, 'second');
};

export const getFormattedMonth = (date) => moment(date).format('MMMM');
export const getFormattedYear = (date) => moment(date).format('YYYY');

/**
 * Compares the month and the year of booking startDate and endDate
 * with the month and the year of review travelDate
 *
 * Background: When subititng a review user selects a month of travel, without a date
 * which results in having travelDate equal to the first day of that month.
 *
 * @param {*} reviewTravelDate
 * @param {*} bookingStartDate
 * @param {*} bookingEndDate
 * @returns
 */
export const isReviewDateMatchingBookingDates = (
    reviewTravelDate,
    bookingStartDate,
    bookingEndDate,
) => {
    return (
        [
            getFormattedMonth(bookingStartDate),
            getFormattedMonth(bookingEndDate),
        ].includes(getFormattedMonth(reviewTravelDate)) &&
        [
            getFormattedYear(bookingStartDate),
            getFormattedYear(bookingEndDate),
        ].includes(getFormattedYear(reviewTravelDate))
    );
};
