export default (stylesheet) => {
    return (element) => {
        const styleElement = element.ownerDocument.createElement('style');
        styleElement.type = 'text/css';
        styleElement.appendChild(
            element.ownerDocument.createTextNode(stylesheet),
        );

        element.appendChild(styleElement);
    };
};
