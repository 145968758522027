import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function Camera(options = {}) {
    return h(Icon, options, [
        h('path', {
            d: oneLine`
        M8.727 8.836h1.33l1.308 -3.109c.098 -.232 .398 -.42 .657 -.42h7.957c.265 0 .565
        .203 .657 .42l1.309 3.11h6.173c.671 0 1.215 .547 1.215 1.21v14.049a1.206 1.206 0
        01-1.215 1.21H3.882a1.217 1.217 0 01-1.215 -1.21V10.047a1.206 1.206 0 011.215
        -1.21H5.09v-.724a.467 .467 0 01.475 -.453h2.686a.474 .474 0 01.475 .453v.723zM16
        22.954c3.347 0 6.06 -2.634 6.06 -5.883S19.348 11.19 16 11.19c-3.347 0 -6.06
        2.634 -6.06 5.882s2.713 5.883 6.06 5.883zm0 -1.47c2.51 0 4.545 -1.976 4.545
        -4.413S18.51 12.66 16 12.66s-4.545 1.975 -4.545 4.411S13.49 21.483 16
        21.483zm7.273 -7.942c.67 0 1.212 -.527 1.212 -1.176s-.543 -1.177 -1.212
        -1.177c-.67 0 -1.212 .527 -1.212 1.177s.542 1.176 1.212 1.176z`,
        }),
    ]);
}
