import options from '@designsystem/options';
import glamorous from 'glamorous';

export const ComponentContainer = glamorous.section({
    backgroundColor: options.colors.white,
    borderRadius: options.space.xs,
    maxWidth: 894,
    padding: `${options.space.xl}px ${options.space.l}px`,
    margin: `0 ${options.space.xl}px`,
});
