const SALUTATION_VALUES = {
    ms: 'MS',
    mr: 'MR',
    child: 'CHILD',
};

const SALUTATION_LABELS = {
    ms: 'Frau',
    mr: 'Herr',
    child: 'Kind',
};

const SALUTATION_VALUE_OPTIONS = [
    SALUTATION_VALUES.ms,
    SALUTATION_VALUES.mr,
    SALUTATION_VALUES.child,
];

const SALUTATION_OPTIONS = [
    {
        label: SALUTATION_LABELS.ms,
        value: SALUTATION_VALUES.ms,
    },
    {
        label: SALUTATION_LABELS.mr,
        value: SALUTATION_VALUES.mr,
    },
];

const getSalutationText = (salutation) => {
    switch (salutation) {
        case SALUTATION_VALUES.mr:
            return SALUTATION_LABELS.mr;
        case SALUTATION_VALUES.ms:
            return SALUTATION_LABELS.ms;
        case SALUTATION_VALUES.child:
            return SALUTATION_LABELS.child;
        default:
            return '';
    }
};

export {
    SALUTATION_VALUES,
    SALUTATION_LABELS,
    SALUTATION_VALUE_OPTIONS,
    SALUTATION_OPTIONS,
    getSalutationText,
};
