import React, { Component, Children } from 'react';

class AnimateMounting extends Component {
    constructor(...args) {
        super(...args);

        this.state = {
            show: false,
        };

        this.hideElement = this.hideElement.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.props.show !== nextProps.show ||
            this.state.show !== nextState.show
        );
    }

    componentDidUpdate(previousProps) {
        if (previousProps.show !== this.props.show) {
            this.setState({
                show: true,
            });
        }
    }

    buildProps(childCss) {
        const { animationOnMount, animationOnUnmount, show } = this.props;

        const css = {
            ...childCss,
            animation: show ? animationOnMount : animationOnUnmount,
        };

        return show
            ? {
                  css,
              }
            : {
                  css,
                  onAnimationEnd: this.hideElement,
              };
    }

    hideElement() {
        this.setState({
            show: false,
        });
    }

    render() {
        const onlyChild = Children.only(this.props.children);
        const clonedChild = React.cloneElement(
            onlyChild,
            this.buildProps(onlyChild.props.css),
        );

        return this.props.show || this.state.show ? clonedChild : null;
    }
}

export default AnimateMounting;
