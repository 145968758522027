const articleConfiguration = (hcmKnowledgeHub) => {
    return {
        featuredArticles: hcmKnowledgeHub
            ? [
                  {
                      title: `${hcmKnowledgeHub.name} wissen`,
                      description: 'Parken, Regeln, Apps, Notfall',
                      icon: hcmKnowledgeHub.hubIcon
                          ? `https://${hcmKnowledgeHub.hubIcon}`
                          : 'check',
                      url: hcmKnowledgeHub.url,
                      alt: `${hcmKnowledgeHub.name} Länderinfos`,
                  },
                  {
                      title: 'Gute Vorbereitung',
                      description: 'Tipps, Versicherung, Unfall',
                      icon: 'checklist',
                      url: '/mietwagen/wissen/gute-vorbereitung',
                      alt: 'Mietwagen Vorbereitungs-Checkliste',
                  },
              ]
            : [
                  {
                      title: 'Erfolgreich buchen',
                      description: 'Fahrzeugauswahl, Gebühren, Shuttle',
                      icon: 'check',
                      url: '/mietwagen/wissen/erfolgreich-buchen',
                      alt: 'Buchungsanleitung Mietwagen',
                  },
                  {
                      title: 'Gute Vorbereitung',
                      description: 'Tipps, Versicherung, Unfall',
                      icon: 'checklist',
                      url: '/mietwagen/wissen/gute-vorbereitung',
                      alt: 'Mietwagen Vorbereitungs-Checkliste',
                  },
              ],
        simpleArticles: hcmKnowledgeHub
            ? [
                  {
                      title: 'Vor der Anmietung',
                      url: '/mietwagen/wissen/annahme',
                  },
                  {
                      title: 'Reibungslose Rückgabe',
                      url: '/mietwagen/wissen/rueckgabe',
                  },
                  {
                      title: 'Während der Anmietung',
                      url: '/mietwagen/wissen/reibungslose-anmietung',
                  },
                  {
                      title: 'Checklisten',
                      url: '/mietwagen/wissen/checklisten',
                  },
              ]
            : [
                  {
                      title: 'Flexibel bezahlen',
                      url: '/mietwagen/wissen/flexibel-bezahlen',
                  },
                  {
                      title: 'Checklisten',
                      url: '/mietwagen/wissen/checklisten',
                  },
                  {
                      title: 'Besondere Mieten',
                      url: '/mietwagen/wissen/besondere-mieten',
                  },
                  {
                      title: 'Hotelzustellung',
                      url: '/mietwagen/wissen/hotelzustellung',
                  },
              ],
    };
};

export default articleConfiguration;
