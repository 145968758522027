import colors from './colors';
import { superLoudFont } from './fonts';
import { blue, blue20, white } from './waveColors';
import { pxToRem } from './unitConverter';

const { linkHoverColor } = colors;

const commonStyle = {
    ...superLoudFont,
    letterSpacing: pxToRem(0.4),
    textTransform: 'uppercase',
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `0 ${pxToRem(20)}`,
    height: pxToRem(40),
    borderRadius: pxToRem(100),
};

export default {
    blueButton: {
        ...superLoudFont,
        ...commonStyle,
        color: white,
        backgroundColor: blue,
        '&:hover': {
            backgroundColor: blue20,
        },
        '&:focus': {
            backgroundColor: blue20,
            outlineWidth: '2px',
            outlineOffset: '4px',
        },
    },
    whiteButton: {
        ...superLoudFont,
        ...commonStyle,
        border: `1px solid ${blue}`,
        color: blue,
        backgroundColor: white,
        '&:hover:enabled': {
            color: linkHoverColor,
            borderColor: linkHoverColor,
        },
        '&:disabled': {
            cursor: 'not-allowed',
            opacity: 0.4,
        },
        '&:focus:enabled': {
            color: linkHoverColor,
            borderColor: linkHoverColor,
            outlineWidth: '2px',
            outlineOffset: '4px',
        },
    },
};
