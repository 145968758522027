import h from 'react-hyperscript';
import React from 'react';

import TextInput from './TextInput';

const invalidInputMessage = 'Der Text enthält ungültige Sonderzeichen';
const DISALLOWED_CHARACTERS_FOR_METADATA = '!"#$%*,./:;<=>?@[\\]^`{|}~';
function validateInputString(inputString) {
    for (const char of DISALLOWED_CHARACTERS_FOR_METADATA) {
        if (inputString.indexOf(char) !== -1) {
            return false;
        }
    }
    return true;
}

class TextInputWithDefaultValidation extends React.PureComponent {
    constructor(...args) {
        super(...args);
        this.state = {
            isValid: true,
            showErrorMessage: false,
        };

        this.updateValidationState = this.updateValidationState.bind(this);
        this.updateErrorMessageState = this.updateErrorMessageState.bind(this);
    }

    updateValidationState(value, onChange) {
        const result = validateInputString(value);
        this.setState({ isValid: result });
        onChange(value);
    }

    updateErrorMessageState() {
        this.setState({ showErrorMessage: !this.state.isValid });
    }

    render() {
        const {
            name,
            label,
            placeholder,
            isPrivate,
            onChange,
            value,
            disabled = false,
        } = this.props;
        const { isValid, showErrorMessage } = this.state;
        return h(TextInput, {
            name,
            label,
            placeholder,
            isPrivate,
            onChange: (newValue) =>
                this.updateValidationState(newValue, onChange),
            onBlur: this.updateErrorMessageState,
            value: value || '',
            disabled,
            errorMessage:
                !isValid && showErrorMessage ? invalidInputMessage : null,
        });
    }
}
export default TextInputWithDefaultValidation;
