import glamorous from 'glamorous';
import h from 'react-hyperscript';
import options from '@designsystem/options';

import DarkLink from '../../../components/DarkLink';
import { waveMdMediaQuery } from '../../../../../../styles/waveMediaQueries';
import { ArticleArrow } from '../../../components/ArticleArrow';

const SimpleKnowledgeArticlesContainer = glamorous.div({
    display: 'grid',
    marginTop: options.space.l,
    columnGap: options.space.l,
    rowGap: options.space['2xs'],
    gridTemplateColumns: '1fr',
    [waveMdMediaQuery]: {
        gridTemplateColumns: '1fr 1fr',
    },
});

const SimpleKnowledgeArticle = glamorous.div({
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    fontWeight: 700,
    padding: `${options.space.s}px 0 ${options.space.xs}px`,
    color: options.colors.black,
    lineHeight: 'unset',
    transition: 'all 0.3s ease',
    '::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        width: '100%',
        borderBottom: '3px solid #CEE8FF',
    },
    ':hover': {
        '& .arrow-container': {
            backgroundColor: '#007dcb',
        },
    },
});

function SimpleKnowledgeArticles({ articles, assetsPath }) {
    if (!articles || articles.length === 0) {
        return null;
    }

    return h(
        SimpleKnowledgeArticlesContainer,
        articles.map((article) => {
            const utmParameter = `?utm_source=mHC&utm_medium=HCMHub&utm_campaign=ExperienceArticleClicked&utm_content=${article.title.split(' ').join('%20')}`;
            return h(
                DarkLink,
                { href: `${article.url}${utmParameter}`, target: '_blank' },
                [
                    h(SimpleKnowledgeArticle, [
                        article.title,
                        h(ArticleArrow, {
                            assetsPath,
                        }),
                    ]),
                ],
            );
        }),
    );
}

export default SimpleKnowledgeArticles;
