import React from 'react';
import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import moment from 'moment';

import {
    findStartDateWithTime,
    getFormattedTimespan,
} from '../../common/lib/bookingHelper';
import numberToTwoDigitString from '../lib/numberToTwoDigitString';
import { pxToRem } from '../styles/unitConverter';
import BookingStatus from './myBookings/BookingStatus';
import { white } from '../styles/waveColors';
import formatDate from '../lib/formatDate';
import { secondaryFont } from '../styles/fonts';

const { headline2XS } = secondaryFont;

const CountdownContainer = glamorous.div(({ shouldBeWhite }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    margin: `0 0 ${pxToRem(2)} 0`,
    color: shouldBeWhite ? white : 'inherit',
}));

const OnHolidays = glamorous.div(headline2XS, ({ shouldBeWhite }) => ({
    color: shouldBeWhite ? white : 'inherit',
    textAlign: 'center',
    marginBottom: pxToRem(4),
}));
OnHolidays.displayName = 'OnHolidays';

const CountContainer = glamorous.div(headline2XS, ({ shouldBeWhite }) => ({
    minWidth: pxToRem(56),
    color: shouldBeWhite ? white : 'inherit',
}));
CountContainer.displayName = 'CountContainer';

const Count = glamorous.div(headline2XS, {
    fontWeight: 800,
    display: 'inline-block',
});
Count.displayName = 'Count';

const Unit = glamorous.div({
    display: 'inline-block',
});
Unit.displayName = 'Unit';

const getCountdownValues = (startDate, nowDate) => {
    const duration = moment.duration(moment(startDate).diff(moment(nowDate)));

    return [
        {
            value: Math.floor(duration.asDays()),
            unit: 'T',
        },
        {
            value: numberToTwoDigitString(duration.hours()),
            unit: 'h',
        },
        {
            value: numberToTwoDigitString(duration.minutes()),
            unit: 'm',
        },
        {
            value: numberToTwoDigitString(duration.seconds()),
            unit: 's',
        },
    ];
};

const renderBookingStatus = (
    booking,
    shouldBeWhite,
    greeting = 'Schönen Urlaub',
    { getCurrentDate, assetsPath },
) => {
    const holidaysStartDate = findStartDateWithTime(booking);
    const values = getCountdownValues(holidaysStartDate, getCurrentDate());
    const hasHolidaysStarted = values[0].value < 0;

    if (hasHolidaysStarted) {
        return h(BookingStatus, {
            imageSrc: `${assetsPath}/myBookings/sun--solid${shouldBeWhite ? '-white' : ''}.svg`,
            element: h(OnHolidays, { shouldBeWhite }, greeting),
            text: getFormattedTimespan(booking, formatDate, 'DD.MM.Y'),
            shouldBeWhite,
        });
    }

    return h(BookingStatus, {
        imageSrc: `${assetsPath}/myBookings/waiting-time${shouldBeWhite ? '-white' : ''}.svg`,
        imageAlt: 'countdown',
        element: h(
            CountdownContainer,
            { shouldBeWhite },
            values.map(({ value, unit }, index) =>
                h(CountContainer, { key: index, shouldBeWhite }, [
                    h(Count, value),
                    h(Unit, unit),
                ]),
            ),
        ),
        text: getFormattedTimespan(booking, formatDate, 'DD.MM.Y'),
        shouldBeWhite,
    });
};

const Countdown = (
    { booking, shouldBeWhite, greeting },
    { window, getCurrentDate, config: { assetsPath } },
) => {
    const setTime = React.useState(0)[1];

    React.useEffect(() => {
        const interval = window.setInterval(
            () => setTime((time) => time + 1),
            1000,
        );

        return () => window.clearInterval(interval);
    });

    return renderBookingStatus(booking, shouldBeWhite, greeting, {
        getCurrentDate,
        assetsPath,
    });
};

Countdown.propTypes = {
    booking: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        services: PropTypes.array.isRequired,
    }).isRequired,
    greeting: PropTypes.string,
};

Countdown.contextTypes = {
    getCurrentDate: PropTypes.func.isRequired,
    window: PropTypes.object.isRequired,
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

export default Countdown;
