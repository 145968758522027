import h from 'react-hyperscript';
import { Query } from '@apollo/client/react/components';

import BadgesCard from './BadgesCard';
import { badgesCard } from '../../../queries/myHolidaycheckDashboard';

export default function BadgesCardQuery() {
    return h(
        Query,
        { fetchPolicy: 'cache-and-network', query: badgesCard },
        ({ loading, data }) => {
            if (!loading && data) {
                return h(BadgesCard, data);
            }

            return null;
        },
    );
}
