import h from 'react-hyperscript';

import FlagIcon from './FlagIcon';

export default function FlagAustria({ css }) {
    return h(
        FlagIcon,
        {
            css,
        },
        [
            h('rect', {
                fill: '#e92024',
                height: 20,
                width: 25,
            }),
            h('rect', {
                fill: '#f5f5f5',
                height: 7,
                width: 25,
                y: 6.5,
            }),
        ],
    );
}
