import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import { Fragment, useEffect } from 'react';

import BookingSectionHeadline from './BookingSectionHeadline';
import ExpandableTextContent from './ExpandableTextContent';
import Hint from './Hint';

const MAXIMUM_INITIAL_CHARACTERS = 200;

const Request = glamorous.span({
    fontSize: '16px',
    display: 'inline',
    whiteSpace: 'pre-wrap',
});
Request.displayName = 'Request';

const SpecialRequest = ({
    booking: {
        contractualPartner: { specialRequest },
    },
    myRef,
    setWillRender,
}) => {
    if (!specialRequest) {
        return null;
    }

    useEffect(() => setWillRender(true), []);

    return h(Fragment, [
        h(BookingSectionHeadline, { innerRef: myRef }, 'Deine Wünsche'),
        h(
            Hint,
            'Deine Anmerkungen sind unverbindlich und kein Vertragsbestandteil.',
        ),
        h(ExpandableTextContent, {
            eventAction: 'expandSpecialRequest',
            content: specialRequest,
            maxInitialCharacters: MAXIMUM_INITIAL_CHARACTERS,
        }),
    ]);
};

SpecialRequest.propTypes = {
    booking: PropTypes.shape({
        contractualPartner: PropTypes.shape({
            specialRequest: PropTypes.string,
        }).isRequired,
    }).isRequired,
    // from using `useRef` hook, `ref` prop is treated differently and would need the `forwardRef` crap
    myRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
    setWillRender: PropTypes.func.isRequired,
};

SpecialRequest.displayName = 'SpecialRequest';

export default SpecialRequest;
