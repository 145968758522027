import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import { ArticleArrow } from '../../../components/ArticleArrow';
import DarkLink from '../../../components/DarkLink';

const ArticleContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: options.radii.small,
    overflow: 'hidden',
    backgroundColor: '#FBF7EA',
    width: '100%',
    minHeight: 280,
    height: '100%',
    minWidth: 230,
    ':hover .article-image': {
        transform: 'scale(1.03)',
    },
    ':hover': {
        '& .arrow-container': {
            backgroundColor: '#e5ad00',
        },
    },
});

const ArticleImageContainer = glamorous.div({
    position: 'relative',
    height: 189,
    overflow: 'hidden',
    '::before': {
        content: '""',
        position: 'absolute',
        clipPath:
            'polygon(100% 100%, 0 100%, 0 78.97%, 2% 75.57%, 4% 71.88%, 6% 67.96%, 8% 63.87%, 10% 59.68%, 12% 55.45%, 14% 51.26%, 16% 47.15%, 18% 43.21%, 20% 39.49%, 22% 36.06%, 24% 32.95%, 26% 30.24%, 28% 27.94%, 30% 26.12%, 32% 24.78%, 34% 23.96%, 36% 23.67%, 38% 23.9%, 40% 24.67%, 42% 25.95%, 44% 27.72%, 46% 29.96%, 48% 32.63%, 50% 35.69%, 52% 39.1%, 54% 42.79%, 56% 46.71%, 58% 50.79%, 60% 54.98%, 62% 59.21%, 64% 63.41%, 66% 67.51%, 68% 71.45%, 70% 75.17%, 72% 78.61%, 74% 81.71%, 76% 84.43%, 78% 86.72%, 80% 88.55%, 82% 89.88%, 84% 90.7%, 86% 91%, 88% 90.76%, 90% 90%, 92% 88.72%, 94% 86.95%, 96% 84.71%, 98% 82.04%, 100% 78.97%)',
        height: '32px',
        left: '-1px',
        bottom: '0px',
        width: 'calc(100% + 2px)',
        backgroundColor: '#FBF7EA',
        zIndex: 2,
        transform: 'scaleX(-1)',
    },
});

const ArticleImage = glamorous.img(
    {
        width: '100%',
        height: 189,
        objectFit: 'cover',
        transition: 'all 0.3s ease-in',
    },
    'article-image',
);

const ExperienceShape = glamorous.img({
    position: 'absolute',
    right: 0,
    bottom: 0,
});

const ExperienceLogo = glamorous.img({
    position: 'absolute',
    right: 40,
    width: 72,
    bottom: 32,
});

const ArticleTextContainer = glamorous.div({
    padding: `${options.space.xs}px ${options.space.s}px`,
});

const ArticleTitle = glamorous.div({
    fontSize: `${options.fontSizes.s}px`,
    fontWeight: '700',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    width: '100%',
});

function MietwagenExperienceArticles({ assetsPath, link, imageUrl, headline }) {
    const utmParameter = `?utm_source=mHC&utm_medium=HCMHub&utm_campaign=ExperienceArticleClicked&utm_content=${headline.split(' ').join('%20')}`;
    return h(DarkLink, { href: `${link}${utmParameter}`, target: '_blank' }, [
        h(ArticleContainer, [
            h(ArticleImageContainer, [
                h(ArticleImage, {
                    src: imageUrl,
                    alt: headline,
                }),
                h(ExperienceShape, {
                    src: `${assetsPath}/hcMietwagen/experience/experience-article-shape.svg`,
                }),
                h(ExperienceLogo, {
                    src: `${assetsPath}/hcMietwagen/experience/experience-logo-shape-2.svg`,
                }),
            ]),
            h(ArticleTextContainer, [
                h(ArticleTitle, [headline]),
                h(ArticleArrow, {
                    assetsPath,
                    backgroundColor: '#FFC100',
                    floatRight: true,
                }),
            ]),
        ]),
    ]);
}

export default MietwagenExperienceArticles;
