import { SALUTATION_VALUES } from '../../../lib/salutations';
import participantSchema from '../ParticipantDataFormValidation';

const salutationError = 'Bitte gib Deine Anrede an.';

const isButtonDisabled = ({ travellers, participants, errorCount }) => {
    const isNoDataChanged =
        JSON.stringify(travellers) === JSON.stringify(participants);
    return isNoDataChanged || errorCount > 0;
};

const setParticipantValue =
    (participants, setParticipants) => (name, index) => (value) => {
        setParticipants(
            participants.map((participant, i) => {
                return i === index
                    ? { ...participant, [name]: value }
                    : participant;
            }),
        );
    };

const setParticipantFormErrors = ({
    errors,
    setErrors,
    name,
    index,
    value,
}) => {
    setErrors(
        errors.map((participantErrors, i) => {
            return i === index
                ? { ...participantErrors, [name]: value }
                : participantErrors;
        }),
    );
};

const validateOnBlur =
    ({ errors, setErrors }) =>
    (name, index) =>
    (value) => {
        return participantSchema[name]
            .validate(value)
            .then(() =>
                setParticipantFormErrors({
                    errors,
                    setErrors,
                    name,
                    index,
                    value: null,
                }),
            )
            .catch((e) => {
                if (e.errors) {
                    setParticipantFormErrors({
                        errors,
                        setErrors,
                        name,
                        index,
                        value: e.errors[0],
                    });
                }
            });
    };

const getInitialErrors = (travellers) => {
    return travellers.map((p) => {
        const salError =
            p.salutation === SALUTATION_VALUES.mr ||
            p.salutation === SALUTATION_VALUES.ms
                ? null
                : salutationError;
        return { salutation: salError };
    });
};

const getErrorCount = (errors) =>
    errors.map((p) => Object.values(p).filter((value) => Boolean(value))).flat()
        .length;

const getTravellersArray = (newTravellers, travellers) => {
    const travellersData = travellers.map((traveller, index) => {
        return {
            oldData: {
                salutation: traveller.salutation,
                firstName: traveller.firstName,
                lastName: traveller.lastName,
                dateOfBirth: traveller.dateOfBirth,
                nationality: traveller.nationality,
            },
            newData: {
                salutation: newTravellers[index].salutation,
                firstName: newTravellers[index].firstName,
                lastName: newTravellers[index].lastName,
                dateOfBirth: newTravellers[index].dateOfBirth,
                nationality: newTravellers[index].nationality,
            },
        };
    });

    return travellersData.filter(
        (traveller) =>
            JSON.stringify(traveller.oldData) !==
            JSON.stringify(traveller.newData),
    );
};

export {
    isButtonDisabled,
    setParticipantValue,
    setParticipantFormErrors,
    validateOnBlur,
    getInitialErrors,
    getErrorCount,
    getTravellersArray,
};
