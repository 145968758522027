import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function Hint(options = {}) {
    return h(Icon, options, [
        h('path', {
            d: oneLine`
        M16 27.973c6.627 0 12 -5.372 12 -12s-5.373 -12 -12 -12 -12 5.373 -12 12 5.373 12
        12 12zm1.872 -17.707c0 .497 -.177 .923 -.53 1.276s-.773 .53 -1.257 .53c-.484 0
        -.903 -.176 -1.257 -.53s-.53 -.779 -.53 -1.276c0 -.485 .177 -.904 .53
        -1.257s.773 -.53 1.257 -.53c.484 0 .903 .177 1.257 .53s.53 .772 .53 1.257zm1.473
        11.919c0 .079 -.017 .17 -.05 .275s-.068 .157 -.107 .157c-.406 0 -.913 -.016
        -1.522 -.05s-1.142 -.048 -1.6 -.048c-.446 0 -.937 .016 -1.473 .049s-1.002 .049
        -1.394 .049c-.04 0 -.076 -.052 -.108 -.157s-.05 -.197 -.05 -.275c0 -.065 .017
        -.15 .05 -.255s.068 -.164 .108 -.177c.484 -.052 .828 -.183 1.03 -.393s.305 -.628
        .305 -1.256v-2.946c0 -.524 -.102 -.864 -.305 -1.021s-.546 -.262 -1.03 -.314c-.04
        -.013 -.076 -.069 -.108 -.167s-.05 -.18 -.05 -.246c0 -.065 .017 -.15 .05
        -.255s.068 -.157 .108 -.157c.628 -.13 1.335 -.344 2.12 -.638s1.394 -.547 1.826
        -.756c.04 -.026 .105 -.06 .197 -.098s.17 -.06 .235 -.06c.158 0 .272 .027 .344
        .08s.102 .11 .088 .176c-.026 .458 -.058 1.015 -.098 1.67s-.059 1.203 -.059
        1.649v3.082c0 .616 .105 1.035 .315 1.257s.55 .354 1.02 .393c.04 0 .076 .056 .109
        .167s.049 .2 .049 .265z`,
        }),
    ]);
}
