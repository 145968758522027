import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import { white } from '../../styles/waveColors';
import { secondaryFont } from '../../styles/fonts';

const { UITextM } = secondaryFont;

const BookingStatusContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});
BookingStatusContainer.displayName = 'BookingStatusContainer';

const Text = glamorous.div(UITextM, ({ shouldBeWhite }) => ({
    color: shouldBeWhite ? white : 'inherit',
}));
Text.displayName = 'Text';

const BookingStatus = ({
    imageSrc,
    imageAlt,
    element,
    text,
    shouldBeWhite,
}) => {
    return h(BookingStatusContainer, [
        imageSrc && h('img', { src: imageSrc, alt: imageAlt || '' }),
        element,
        text && h(Text, { shouldBeWhite }, text),
    ]);
};

BookingStatus.propTypes = {
    imageSrc: PropTypes.string,
    imageAlt: PropTypes.string,
    element: PropTypes.node.isRequired,
    text: PropTypes.string,
};

export default BookingStatus;
