import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import options from '@designsystem/options';

import additionalServiceNames from '../../../../common/lib/additionalServiceNames';
import formatPrice from './formatPrice';
import {
    isCancellationInsurance,
    isInsuranceService,
} from '../lib/serviceHelpers';
import { secondaryFont } from '../../../styles/fonts';
import { gray40 } from '../../../styles/waveColors';
import { pxToRem } from '../../../styles/unitConverter';

const { PTSansFamily } = secondaryFont;

const ServiceContainer = glamorous.div({
    display: 'flex',
    marginTop: pxToRem(16),
    padding: `0 ${pxToRem(24)}`,
    '&:last-child': {
        paddingBottom: `${pxToRem(16)}`,
    },
    '&:not(:last-of-type):not(:first-of-type)': {
        borderTop: `1px solid ${options.colors.gray100}`,
        borderBottom: `1px solid ${options.colors.gray100}`,
        paddingTop: `${pxToRem(options.space.s)}`,
        paddingBottom: `${pxToRem(options.space.s)}`,
    },
});

const ServiceNameContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '90%',
    hyphens: 'auto',
});

const ServiceName = glamorous.h5({
    display: 'inline',
    margin: 0,
    font: 'inherit',
});
ServiceName.displayName = 'ServiceName';

const Price = glamorous.div({
    whiteSpace: 'nowrap',
});
Price.displayName = 'Price';

const Status = glamorous.div({});
Status.displayName = 'Status';

const Comment = glamorous.div(PTSansFamily, {
    color: gray40,
    whiteSpace: 'pre-wrap',
});
Comment.displayName = 'Comment';

const statusMapping = {
    WISHED: 'Angefragt',
    CANCELLED: 'Storniert',
    INCLUDED: 'Inklusive',
};

const getServiceName = (isAnInsuranceService, service) => {
    const {
        detail: { title },
    } = service;

    if (title) {
        return title;
    }

    return additionalServiceNames[service.type];
};

const renderServiceName = (isAnInsuranceService, service) => {
    const serviceName = getServiceName(isAnInsuranceService, service);

    return h(ServiceName, serviceName);
};

const renderStatus = (status) => {
    const STATUS_FOR_ZERO_PRICE = statusMapping.INCLUDED;

    return h(Status, `${statusMapping[status] || STATUS_FOR_ZERO_PRICE}`);
};

const renderPriceOrStatus = (isCancellationInsuranceService, service) => {
    const {
        detail: { customerPrice, status, cashback },
        type,
    } = service;

    if (cashback) {
        return h(Price, formatPrice(cashback.amount, cashback.currencyCode));
    }

    const validStatusForPriceDisplay = ['BOOKED', 'ORDER'];
    const validServiceForPriceDisplay = ['PREMIUM_DISCOUNT'];
    const isValidStatusForPriceDisplay =
        validStatusForPriceDisplay.includes(status);
    const isValidServiceForPriceDisplay =
        validServiceForPriceDisplay.includes(type);
    const hasPrice = Boolean(customerPrice && customerPrice.amount);
    const shouldDisplayPrice =
        isCancellationInsuranceService ||
        ((isValidStatusForPriceDisplay || isValidServiceForPriceDisplay) &&
            hasPrice);

    if (shouldDisplayPrice) {
        return h(
            Price,
            formatPrice(customerPrice.amount, customerPrice.currencyCode),
        );
    }

    return renderStatus(status);
};

const Service = ({ service }) => {
    const hasComment = Boolean(service.detail.comment);

    return h(ServiceContainer, [
        h(ServiceNameContainer, [
            renderServiceName(isInsuranceService(service), service),
            hasComment && h(Comment, service.detail.comment),
        ]),
        renderPriceOrStatus(isCancellationInsurance(service), service),
    ]);
};

const CustomerPrice = PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currencyCode: PropTypes.string.isRequired,
});

const InsuranceServiceDetail = PropTypes.shape({
    title: PropTypes.string.isRequired,
    customerPrice: CustomerPrice,
    comment: PropTypes.string,
    status: PropTypes.string,
});

const ServiceDetail = PropTypes.shape({
    status: PropTypes.string.isRequired,
    customerPrice: CustomerPrice.isRequired,
    title: PropTypes.string,
    comment: PropTypes.string,
});

const VoucherCodeDetail = PropTypes.shape({
    actionId: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    isPremiumCashback: PropTypes.bool,
    status: PropTypes.string.isRequired,
    customerPrice: CustomerPrice.isRequired,
    title: PropTypes.string,
    comment: PropTypes.string,
});

Service.propTypes = {
    service: PropTypes.shape({
        type: PropTypes.string.isRequired,
        detail: PropTypes.oneOfType([
            InsuranceServiceDetail,
            ServiceDetail,
            VoucherCodeDetail,
        ]).isRequired,
    }).isRequired,
};

export default Service;
