import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { useParams, useOutletContext, useNavigate } from 'react-router-dom';

import colors from '../../styles/colors';
import {
    smallDistance,
    mediumDistance,
    largeDistance,
    xxlargeDistance,
} from '../../styles/distances';
import { lightboxLayer, lightboxNavigationLayer } from '../../styles/layers';
import { applyOnDesktopAndUp } from '../../styles/mediaQueries';
import KeyboardEnabledCloseButton from './KeyboardEnabledCloseButton';
import HideableControl from './HideableControl';
import PictureWithMetadata from './PictureWithMetadata';
import Slider from './Slider';
import Unscrollable from '../unscrollable/Unscrollable';
import LeftArrowLine from '../icons/LeftArrowLine';
import RightArrowLine from '../icons/RightArrowLine';
import { pxToRem } from '../../styles/unitConverter';
import {
    hideUsabillaButton,
    showUsabillaButton,
} from '../../lib/changeUsabillaButtonVisibility';

const {
    lightboxNavigationArrowColor,
    lightboxNavigationArrowColorInverted,
    lightboxNavigationArrowHoverColor,
    lightboxNavigationBackgroundColor,
    lightboxNavigationButtonOutlineColor,
    lightboxNavigationButtonOutlineHoverColor,
    lightboxOverlayColor,
} = colors;

const arrowStyle = {
    fill: 'currentColor',
    height: largeDistance,
    textShadow: `0 ${pxToRem(1)} ${pxToRem(1)} rgba(0, 0, 0, 0.5)`,
    width: largeDistance,

    '&:hover': {
        fill: 'currentColor',
    },

    [applyOnDesktopAndUp]: {
        height: mediumDistance,
        width: mediumDistance,
    },
};

const NavigationButton = glamorous.button({
    alignItems: 'center',
    background: 'none',
    border: 0,
    boxSizing: 'border-box',
    color: lightboxNavigationArrowColor,
    display: 'flex',
    height: largeDistance,
    justifyContent: 'center',
    outline: 0,
    padding: 0,
    width: largeDistance,

    [applyOnDesktopAndUp]: {
        background: lightboxNavigationBackgroundColor,
        border: `${pxToRem(1)} solid ${lightboxNavigationButtonOutlineColor}`,
        borderRadius: '50%',
        color: lightboxNavigationArrowColorInverted,
        cursor: 'pointer',
        height: xxlargeDistance,
        opacity: 0.7,
        width: xxlargeDistance,

        '&:hover': {
            borderColor: lightboxNavigationButtonOutlineHoverColor,
            color: lightboxNavigationArrowHoverColor,
            opacity: 1,
        },
    },
});
NavigationButton.displayName = 'NavigationButton';

const CloseButtonContainer = glamorous(HideableControl)(
    {
        position: 'absolute',
        right: 0,
        top: 0,
    },
    ({ isHidden }) => ({
        transform: isHidden ? 'translateY(-100%)' : null,
    }),
);
CloseButtonContainer.displayName = 'CloseButtonContainer';

const LeftNavigationButtonContainer = glamorous(HideableControl)(
    ({ isHidden }) => ({
        transform: isHidden
            ? `translateX(calc(-100% - ${smallDistance}))`
            : null,
    }),
);
LeftNavigationButtonContainer.displayName = 'LeftNavigationButtonContainer';

const RightNavigationButtonContainer = glamorous(HideableControl)(
    ({ isHidden }) => ({
        transform: isHidden
            ? `translateX(calc(100% + ${smallDistance}))`
            : null,
    }),
);
RightNavigationButtonContainer.displayName = 'RightNavigationButtonContainer';

const Navigation = glamorous.nav({
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    padding: `0 ${smallDistance}`,
    position: 'relative',
    zIndex: lightboxNavigationLayer,
});

const Overlay = glamorous.div({
    background: lightboxOverlayColor,
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: lightboxLayer,

    [applyOnDesktopAndUp]: {
        cursor: 'pointer',
    },
});

const Layer = glamorous.div({
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
});
const Controls = glamorous(Layer)();

const extractPicturesFromAlbums = R.pipe(R.map(R.prop('pictures')), R.flatten);

const PictureDetailView = (props, context) => {
    const { albums } = useOutletContext();
    const [areControlsHidden, setControlsHidden] = useState(false);
    const [navigatingTo, setNavigatingTo] = useState(null);
    const { pictureId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const { window } = context;

        hideUsabillaButton(window);
        return () => showUsabillaButton(window);
    }, []);

    function getPicturesToRender() {
        const pictures = extractPicturesFromAlbums(albums || []);

        const indexOfPicture = R.findIndex(R.propEq('id', pictureId), pictures);
        const picture = pictures[indexOfPicture];
        const previousPicture = pictures[indexOfPicture - 1];
        const nextPicture = pictures[indexOfPicture + 1];

        return {
            picture: { pictureId, metadata: picture },
            previousPicture: previousPicture
                ? { pictureId: previousPicture.id, metadata: previousPicture }
                : null,
            nextPicture: nextPicture
                ? { pictureId: nextPicture.id, metadata: nextPicture }
                : null,
        };
    }

    function renderPicture(picture) {
        return picture
            ? h(PictureWithMetadata, { ...picture, areControlsHidden })
            : null;
    }

    function navigateTo(event, previousOrNext, id) {
        event.stopPropagation();

        setNavigatingTo(previousOrNext);
        navigate(`../${id}`);
    }

    const { previousPicture, picture, nextPicture } = getPicturesToRender();

    const renderedPictures = [
        renderPicture(previousPicture),
        renderPicture(picture),
        renderPicture(nextPicture),
    ];

    return h(
        Overlay,
        {
            onClick: () => setControlsHidden(!areControlsHidden),
        },
        [
            h(
                Slider,
                {
                    navigatingTo,
                    finishNavigation: () => setNavigatingTo(null),
                },
                renderedPictures,
            ),
            h(Controls, [
                h(Navigation, [
                    h(
                        LeftNavigationButtonContainer,
                        { isHidden: areControlsHidden },
                        [
                            previousPicture &&
                                h(
                                    NavigationButton,
                                    {
                                        onClick: (event) =>
                                            navigateTo(
                                                event,
                                                'previous',
                                                previousPicture.pictureId,
                                            ),
                                    },
                                    [h(LeftArrowLine, { css: arrowStyle })],
                                ),
                        ],
                    ),
                    h(
                        RightNavigationButtonContainer,
                        { isHidden: areControlsHidden },
                        [
                            nextPicture &&
                                h(
                                    NavigationButton,
                                    {
                                        onClick: (event) =>
                                            navigateTo(
                                                event,
                                                'next',
                                                nextPicture.pictureId,
                                            ),
                                    },
                                    [h(RightArrowLine, { css: arrowStyle })],
                                ),
                        ],
                    ),
                ]),
                h(
                    CloseButtonContainer,
                    {
                        isHidden: areControlsHidden,
                    },
                    [
                        h(KeyboardEnabledCloseButton, {
                            onClick: () => navigate('..', { replace: true }),
                        }),
                    ],
                ),
            ]),
            h(Unscrollable),
        ],
    );
};

PictureDetailView.contextTypes = {
    window: PropTypes.object,
};

export default PictureDetailView;
