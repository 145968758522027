const BASE_FAQ_URL = 'https://www.holidaycheck.de/hilfe';

const FAQData = {
    getFaqItems: () => {
        const listItems = [
            {
                title: 'Bezahlung',
                description:
                    'Zahlungsfristen, Zahlungsformen, Rückzahlung, Zahlungseingang, Reiseguthaben',
                link: `${BASE_FAQ_URL}/category/preis-zahlung `,
            },
            {
                title: 'Reisedokumente',
                description: 'Reiseunterlagen, Rechnung, Sicherungsschein',
                link: `${BASE_FAQ_URL}/category/buchung/reiseunterlagen-ich-habe-eine-frage-zu-meinen-reisedokumenten`,
            },
            {
                title: 'Reisebestimmungen',
                description: 'Einreiseinfos, Bestimmungen, Regelungen',
                link: `${BASE_FAQ_URL}/category/flug-einreise/einreisebestimmungen-ich-habe-eine-frage-zu-den-bestimmungen-bei-einreise `,
            },
            {
                title: 'Reiseschutz & Flextarif',
                description: 'Flextarife, Reiseversicherung',
                link: `${BASE_FAQ_URL}/category/reiseschutz-flextarif`,
            },
            {
                title: 'Umbuchung',
                description:
                    'Flug/Reisedatum, Hotel/Zimmer/Verpflegung, Änderung von Reisenden',
                link: `${BASE_FAQ_URL}/category/buchung/umbuchung-ich-mochte-meine-reise-umbuchen`,
            },
            {
                title: 'Datenänderung',
                description:
                    'Name, Adressdaten, Geburtsdatum, Nationalität, E-Mail Adresse',
                link: `${BASE_FAQ_URL}/category/buchung/personliche-daten-ich-mochte-personenbezogene-daten-korrigieren`,
            },
            {
                title: 'Reiseveranstalter',
                description:
                    'Allgemeine Geschäftsbedingungen Deines Reiseveranstalters',
                link: 'https://www.holidaycheck.de/veranstalteragb',
            },
        ];
        const otherTopic = {
            title: 'Sonstige Themen',
            description: 'Übersichtsseite aller Fragen und Antworten',
            link: BASE_FAQ_URL,
        };

        return { listItems, otherTopic };
    },

    getFaqItemsSorted: () => {
        const { listItems, otherTopic } = FAQData.getFaqItems();
        return [
            ...listItems.sort((a, b) => a.title.localeCompare(b.title)),
            otherTopic,
        ];
    },
};

export default FAQData;
