import h from 'react-hyperscript';

import FlagIcon from './FlagIcon';

export default function FlagSwitzerland({ css }) {
    return h(
        FlagIcon,
        {
            css,
        },
        [
            h('rect', {
                fill: '#e92024',
                height: 20,
                width: 25,
            }),
            h('rect', {
                fill: '#fff',
                height: 12,
                width: 4,
                x: 10.5,
                y: 4,
            }),
            h('rect', {
                fill: '#fff',
                height: 4,
                width: 12,
                x: 6.5,
                y: 8,
            }),
        ],
    );
}
