import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import ButtonLink from '../navigation/ButtonLink';
import { subheadlineFont } from '../../styles/fonts';
import {
    smallDistance,
    xlargeDistance,
    xxxlargeDistance,
} from '../../styles/distances';
import { pxToRem } from '../../styles/unitConverter';
import { applyOnMobile } from '../../styles/mediaQueries';

const NoReviews = glamorous.div({
    textAlign: 'center',
    padding: `${xxxlargeDistance} ${smallDistance}`,
});
const ReviewUploadIllustration = glamorous.img({
    marginBottom: smallDistance,

    [applyOnMobile]: {
        height: pxToRem(112),
    },
});
const CallToAction = glamorous.div(subheadlineFont, {
    margin: `0 auto ${xlargeDistance}`,
    maxWidth: pxToRem(546),
});

const NoReviewsScreen = (props, { config: { assetsPath } }) => {
    return h(NoReviews, [
        h(ReviewUploadIllustration, {
            src: `${assetsPath}/reviews/hotel-review-create.svg`,
        }),
        h(CallToAction, [
            'Schreibe jetzt Deine erste Bewertung und erzähle, wie es vor Ort wirklich ist!',
        ]),
        h(ButtonLink, { href: '/wcf' }, 'Bewertung schreiben'),
    ]);
};

NoReviewsScreen.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

export default NoReviewsScreen;
