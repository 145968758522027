import h from 'react-hyperscript';
import options from '@designsystem/options';

import Link from '../../../navigation/Link';

export const renderContactInfoIcon = (imgSrc, imgAlt) => {
    return h('img', {
        src: imgSrc,
        style: {
            marginRight: options.space.xs,
            width: 16,
            height: 16,
            verticalAlign: 'text-top',
        },
        alt: imgAlt,
    });
};

export const renderContactInfoLink = (url, imgSrc, text, imgAlt) => {
    return h('div', [
        renderContactInfoIcon(imgSrc, imgAlt),
        h(
            Link,
            {
                href: url,
                target: '_blank',
                css: {
                    color: options.colors.blue,
                    display: 'inline-block',
                    paddingTop: 6,
                    paddingBottom: 6,
                },
            },
            text,
        ),
    ]);
};
