import PropTypes from 'prop-types';
import h from 'react-hyperscript';
import glamorous from 'glamorous';

import { gray40 } from '../../../styles/waveColors';
import {
    isOverdue,
    isDepositFullyPaid,
    extractOriginalDeposit,
} from '../lib/paymentConditionsHelpers';
import { secondaryFont } from '../../../styles/fonts';
import { pxToRem } from '../../../styles/unitConverter';

const { PTSansFamily } = secondaryFont;

const PaymentStatusUpdateInfoContainer = glamorous.div(PTSansFamily, {
    textAlign: 'start',
    color: gray40,
    padding: `0 ${pxToRem(24)}`,
    margin: `${pxToRem(16)} 0`,
});
PaymentStatusUpdateInfoContainer.displayName =
    'PaymentStatusUpdateInfoContainer';

const isDepositPartlyPaid = ({ remainingDeposit }, originalDepositAmount) => {
    return remainingDeposit !== 0 && remainingDeposit < originalDepositAmount;
};

const isFinalPartlyPaid = (
    { remainingFinal, remainingDeposit, total },
    originalDepositAmount,
) => {
    return (
        remainingDeposit === 0 && remainingFinal < total - originalDepositAmount
    );
};

const isPartlyPaid = (updatedTourOperatorPrice, tourOperatorPrice) => {
    return (
        extractOriginalDeposit(tourOperatorPrice) &&
        (isDepositPartlyPaid(
            updatedTourOperatorPrice,
            tourOperatorPrice.deposit.amount,
        ) ||
            isFinalPartlyPaid(
                updatedTourOperatorPrice,
                tourOperatorPrice.deposit.amount,
            ))
    );
};

const getOverdueTextBasedOnPaymentType = (paymentType) => {
    switch (paymentType) {
        case 'CREDIT_CARD':
            return (
                'Bitte prüfe, ob Deine Kreditkarte ausreichend gedeckt ist, ' +
                'damit eine reibungslose Abbuchung erfolgen kann. '
            );
        case 'TRANSFER':
            return 'Bitte begleiche den offenen Betrag so schnell wie möglich, da die Zahlung fällig ist. ';
        default:
            return (
                'Bitte prüfe, ob Dein Konto über ausreichend Guthaben verfügt, ' +
                'damit eine reibungslose Abbuchung erfolgen kann. '
            );
    }
};

const renderNoPaymentUpdatesText = (booking) => {
    if (booking.payment.type === 'TRANSFER') {
        return (
            'Leider sind keine weiteren Informationen bezüglich des Zahlungsstatus verfügbar.' +
            ' Die wenigsten Veranstalter senden eine Zahlungsbestätigung.' +
            ' Sollte es Rückfragen seitens des Veranstalter' +
            ' geben, wirst Du kontaktiert.'
        );
    }
    return 'Leider bekommen wir von Deinem Veranstalter keine Echtzeit-Infos zum Zahlungsstatus.';
};

// eslint-disable-next-line max-statements
const renderText = (booking, updatedTourOperatorPrice, getCurrentDate) => {
    if (!updatedTourOperatorPrice) {
        return renderNoPaymentUpdatesText(booking);
    }

    const fallbackText =
        'Wir haben Deinen Veranstalter erreicht, konnten aber keine Kontobewegung feststellen.';
    if (!extractOriginalDeposit(booking.tourOperatorPrice)) {
        return fallbackText;
    }
    if (
        isOverdue({
            tourOperatorPrice: booking.tourOperatorPrice,
            updatedTourOperatorPrice,
            getCurrentDate,
        })
    ) {
        return (
            'Leider konnten wir bislang keine Zahlung feststellen. ' +
            `${getOverdueTextBasedOnPaymentType(booking.payment.type)}` +
            'Solltest Du dies inzwischen getan haben, betrachte diese Erinnerung als gegenstandslos.'
        );
    }
    if (isPartlyPaid(updatedTourOperatorPrice, booking.tourOperatorPrice)) {
        return 'Wir haben Deinen Veranstalter erreicht und Deinen Zahlungsstatus aktualisiert.';
    }
    if (
        isDepositFullyPaid(booking.tourOperatorPrice, updatedTourOperatorPrice)
    ) {
        return 'Super! Dein Veranstalter hat Deine Anzahlung vollständig erhalten.';
    }
    return fallbackText;
};

const PaymentStatusUpdateInfo = (
    { booking, updatedTourOperatorPrice },
    { getCurrentDate },
) => {
    return h(
        PaymentStatusUpdateInfoContainer,
        {},
        renderText(booking, updatedTourOperatorPrice, getCurrentDate),
    );
};

PaymentStatusUpdateInfo.propTypes = {
    booking: PropTypes.shape({
        tourOperatorPrice: PropTypes.shape({
            amount: PropTypes.number.isRequired,
            currencyCode: PropTypes.string.isRequired,
            dueDate: PropTypes.string,
            deposit: PropTypes.shape({
                amount: PropTypes.number,
                dueDate: PropTypes.string,
            }),
        }).isRequired,
        tourOperator: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
        payment: PropTypes.shape({
            type: PropTypes.oneOf([
                'TRANSFER',
                'DIRECT_DEBIT_SEPA',
                'CREDIT_CARD',
                'TRAVEL_AGENCY_CASHING', // deprecated but can't delete (old bookings support), TO-1755
                'CLARIFY_BY_PHONE',
                'IN_HOTEL',
            ]).isRequired,
        }).isRequired,
    }).isRequired,
    updatedTourOperatorPrice: PropTypes.shape({
        total: PropTypes.number.isRequired,
        remainingDeposit: PropTypes.number.isRequired,
        remainingFinal: PropTypes.number.isRequired,
    }),
};

PaymentStatusUpdateInfo.contextTypes = {
    getCurrentDate: PropTypes.func.isRequired,
};

export default PaymentStatusUpdateInfo;
