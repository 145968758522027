import h from 'react-hyperscript';
import glamorous from 'glamorous';
import ellipsize from 'ellipsize';

import TravelInfo from './TravelInfo';
import RatingBar from './RatingBar';
import { pxToRem } from '../../styles/unitConverter';
import { xsmallDistance, mediumDistance } from '../../styles/distances';
import { applyOnMobile } from '../../styles/mediaQueries';
import Link from '../navigation/Link';

const StyledReviewHeader = glamorous.div(({ hasPictures }) => ({
    width: '100%',
    display: 'inline-block',
    marginBottom: xsmallDistance,

    [applyOnMobile]: {
        position: 'relative',
        bottom: hasPictures ? '12rem' : '6rem',
    },
}));

const StyledTitleWithTravelInfoContainer = glamorous.div({
    float: 'left',

    [applyOnMobile]: {
        float: 'none',
        marginBottom: pxToRem(10),
    },
});

const StyledTitleContainer = glamorous.div({
    fontSize: pxToRem(16),
    fontWeight: 600,
    fontStyle: 'italic',
    lineHeight: 1.5,
});

const StyledRatingBarContainer = glamorous.div({
    float: 'right',

    [applyOnMobile]: {
        float: 'none',
    },
});

const GeneralTextContainer = glamorous.div({
    float: 'left',
    minHeight: pxToRem(105),

    [applyOnMobile]: {
        marginTop: mediumDistance,
        paddingBottom: mediumDistance,
        minHeight: 'inherit',
        width: '100%',
    },
});

const getTeaserText = (text) => {
    const maxCharacters = 200;
    return ellipsize(text, maxCharacters);
};

export default function Review({ review, hasPictures }) {
    const {
        title,
        travelDuration,
        travelDate,
        travelReason,
        recommendation,
        ratings: { GENERAL },
        texts,
        id,
        status,
    } = review;
    const mainText = texts ? texts.GENERAL || texts.HOTEL : null;

    return h('div', [
        h(StyledReviewHeader, { hasPictures }, [
            h(StyledTitleWithTravelInfoContainer, [
                status === 'PUBLISHED'
                    ? h(StyledTitleContainer, [
                          h(Link, { href: `/hrd/${id}` }, `${title}`),
                      ])
                    : h(StyledTitleContainer, `${title}`),
                h(TravelInfo, { travelDuration, travelDate, travelReason }),
            ]),
            h(StyledRatingBarContainer, [
                h(RatingBar, { rating: GENERAL.GENERAL, recommendation }),
            ]),
        ]),
        h(GeneralTextContainer, [getTeaserText(mainText)]),
    ]);
}
