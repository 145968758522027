import { stripIndent } from 'common-tags';
import moment from 'moment';

import graphqlFetch from '../lib/graphqlFetch';
import { noticeError } from '../lib/newrelic';

const fetch = async (query, context, variables = {}) => {
    try {
        const response = await graphqlFetch(context, { query, variables });

        if (response.ok) {
            const {
                data: { rentalCarBookingsForUser },
            } = await response.json();
            return rentalCarBookingsForUser.bookings;
        }
    } catch (e) {
        noticeError(e, {
            pageName: 'MyHolidaycheckRentalcar',
            component: 'fetchRentalcarBookings',
        });
    }

    return [];
};

const fetchSingle = async (query, context, variables = {}) => {
    try {
        const response = await graphqlFetch(context, { query, variables });

        if (response.ok) {
            const {
                data: { rentalCarBookingsForUser },
            } = await response.json();
            return rentalCarBookingsForUser.bookings[0] || null;
        }
    } catch (e) {
        noticeError(e, {
            pageName: 'MyHolidaycheckRentalcar',
            component: 'fetchRentalcarBooking',
        });
    }

    return null;
};

/**
 *
 * @param context
 * @param pickupDate Date
 * @param dropoffDate Date
 * @param bookingId Int
 * @returns {Promise<void>}
 */
export default async function (
    context,
    pickupDate = undefined,
    dropoffDate = undefined,
    bookingId = undefined,
) {
    const query = stripIndent`
        query RentalCarBookingQuery($pickupDate: String, $dropoffDate: String, $bookingId: Int) {
          rentalCarBookingsForUser(pickupDate: $pickupDate, dropoffDate: $dropoffDate, bookingId: $bookingId) {
            bookings {
              id
              status
              pickupDatetime
              dropoffDatetime
              carOfferDestination
              carOfferDestinationRegion {
                destination
                preposition
              }
              carOfferDestinationLand {
                destination
                preposition
              }
              carOfferPictureUrl
              transferType
              hcDestination {
                countryId
                regionId
                cityId
              }
            }
          }
        }
    `;

    const variables = {
        ...(pickupDate !== undefined && dropoffDate !== undefined
            ? {
                  pickupDate: moment(pickupDate).toISOString(),
                  dropoffDate: moment(dropoffDate).toISOString(),
              }
            : {}),
        bookingId,
    };

    return fetch(query, context, variables);
}

const BASEIC_QUERY = stripIndent`
            bookings {
              id
              status
              pickupDatetime
              dropoffDatetime
              carOfferDestination
              carOfferDestinationRegion {
                destination
                preposition
              }
              carOfferDestinationLand {
                destination
                preposition
              }
              pickupStation {
                address
                regionName
                regionPreposition
                countryName
                countryPreposition
              }
              dropoffStation {
                address
                regionName
                regionPreposition
                countryName
                countryPreposition
              }
              carOfferPictureUrl
              transferType
              carOffer {
                offerId
                localSupplierName
                destination
                destinationName
                dropoffDestination
                dropoffDestinationName
                priceAmount
                priceCurrency
                pictureUrl
                exampleCar
                carClass
                transmission
                seats
                doors
                airConditioning
                features
                offerPageUrl
                featurePackage
              }
              files {
                id
                name
                tpe
              }
              price {
                car {
                  amount
                  currency
                }
                other {
                  price {
                    amount
                    currency
                  }
                  name
                }
                extras {
                  name
                  price {
                    amount
                    currency
                  }
                }
                discountCoupons {
                  code
                  worth {
                    amount
                    currency
                  }
                  valid
                }
              }
              supplierName
              customerFirstName
              customerLastName
              paymentType
              hcDestination {
                countryId
                regionId
                cityId
              }
            }
`;

export function fetchRentalCarBooking(context, bookingId) {
    const query = stripIndent`
        query RentalCarBookingQuery($bookingId: Int) {
          rentalCarBookingsForUser(bookingId: $bookingId) {
            ${BASEIC_QUERY}
          }
        }`;

    return fetchSingle(query, context, {
        bookingId,
    });
}

export function fetchAllRentalCarBookings(context) {
    const query = stripIndent`
        query RentalCarBookingQuery {
          rentalCarBookingsForUser {
            ${BASEIC_QUERY}
          }
        }`;

    return fetch(query, context);
}

export function fetchAllBookingIds(context) {
    const query = stripIndent`
        query RentalCarBookingQuery {
          rentalCarBookingsForUser {
            bookings {
              id
            }
          }
        }`;

    return fetch(query, context);
}
