import moment from 'moment/moment';

export const CASHBACK_TYPES = {
    CAR_RENTAL_REGULAR: 'CAR_RENTAL_REGULAR',
    PROMOTIONAL: 'PROMOTIONAL',
    REGULAR: 'REGULAR',
};

export const CAR_RENTAL_CASHBACK_ITEM = {
    type: 'CAR_RENTAL_REGULAR',
    detail: {
        cashback: {
            amount: 100,
        },
        travelPriceThreshold: {
            amount: 490,
        },
        status: 'OPEN',
    },
};

export const filterOpenCashback = (cashbackItem) =>
    cashbackItem.detail.status === 'OPEN';

export const filterPromotionalCashbackWithValidityPeriod = (cashbackItem) =>
    !(
        cashbackItem.type === CASHBACK_TYPES.PROMOTIONAL &&
        !cashbackItem.detail.validityPeriod
    );

export const filterByOpenAndPromotionalWithValidityCashback = (cashbackItem) =>
    filterOpenCashback(cashbackItem) &&
    filterPromotionalCashbackWithValidityPeriod(cashbackItem);

const getPromotionalCashbackItemInfo = ({ validityPeriod }) => {
    return {
        cashbackItemTypeName: 'Aktionsgutschein',

        validityStartDateFormatted: moment(validityPeriod.startDate).format(
            'DD.MM',
        ),
        validityEndDateFormatted: moment(validityPeriod.endDate).format(
            'DD.MM.YYYY',
        ),

        cashbackItemRedeemTitleText: `Gutschein erst anwendbar ab ${moment(validityPeriod.startDate).format('DD.MM.YYYY')}`,
        cashbackItemRedeemText:
            'Für Pauschalreisen oder Hotelbuchungen (außer booking.com)',
    };
};

export const parseCashbackItem = ({ cashbackItem, periodEndDate }) => {
    const cashbackItemInformation = {
        cashbackItemTypeName: 'Reisegutschein',
        periodEndDateFormatted: moment(periodEndDate).format('DD.MM.YYYY'),

        cashbackItemRedeemTitleText: 'Gutschein wird automatisch angewandt',
        cashbackItemRedeemText: cashbackItem.detail.travelPriceThreshold
            ? `ab ${cashbackItem.detail.travelPriceThreshold.amount} € Reisepreis,` +
              ' für Pauschalreisen oder Hotelbuchungen (außer booking.com)'
            : '',
        cashbackItemRedeemLinkText: 'Urlaub finden',
        cashbackItemRedeemLinkHref: 'https://www.holidaycheck.com/',
    };

    if (cashbackItem.type === CASHBACK_TYPES.PROMOTIONAL) {
        return {
            ...cashbackItemInformation,
            ...getPromotionalCashbackItemInfo({
                validityPeriod: cashbackItem.detail.validityPeriod,
            }),
        };
    } else if (cashbackItem.type === CASHBACK_TYPES.CAR_RENTAL_REGULAR) {
        return {
            ...cashbackItemInformation,
            cashbackItemTypeName: 'Mietwagengutschein',

            cashbackItemRedeemText:
                'Die Gutscheinhöhe ist abhängig vom Mietwagenpreis.',
            cashbackItemRedeemTextLink: ' Die Staffel findest Du hier.',
            cashbackItemRedeemTextHref:
                'https://www.holidaycheck.de/premium/mietwagen',
            cashbackItemRedeemTitleText:
                'Nutze für die Buchung die E-Mail-Adresse Deiner Premium-Mitgliedschaft. Gutschein wird automatisch angewandt.',
            cashbackItemRedeemLinkText: 'Mietwagen finden',
            cashbackItemRedeemLinkHref: '/mietwagen',
        };
    }

    return cashbackItemInformation;
};

export const extractAvailableCashbackItem = (activePeriod) => {
    const cashback = activePeriod.benefits.cashback;
    const periodEndDate = activePeriod.periodEndDate;

    const availableCashback = cashback
        .filter(filterByOpenAndPromotionalWithValidityCashback)
        .filter((cashbackItem) => {
            if (!cashbackItem.detail.validityPeriod) return true;

            const startDate = moment(
                cashbackItem.detail.validityPeriod.startDate,
            );
            const endDate = moment(cashbackItem.detail.validityPeriod.endDate);
            const now = moment();

            return now.isBetween(startDate, endDate);
        });
    const cashbackWithCarRental = availableCashback.concat([
        CAR_RENTAL_CASHBACK_ITEM,
    ]);

    const cashbackItem = cashbackWithCarRental[0];

    return {
        cashbackItem,
        ...parseCashbackItem({
            cashbackItem,
            periodEndDate,
        }),
    };
};
