import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import { css } from 'glamor';

import { waveTextSMedium } from '../../../styles/waveText';

const Container = glamorous.div(({ isFinished }) => {
    const fade = css.keyframes({
        '0%, 90%': {
            opacity: 1,
            height: 'unset',
        },
        '100%': {
            opacity: 0,
            height: 0,
        },
    });

    return {
        height: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        opacity: 0,
        animation: `${fade} ${isFinished ? '2s' : '10s'} ease`,
    };
});

const LoadingBar = glamorous.div({
    width: '100%',
    height: options.space['2xs'],
    backgroundColor: options.colors.blue90,
    borderRadius: options.radii.full,
});

const LoadingValue = glamorous.span(({ isFinished }) => {
    const loader = css.keyframes({
        '0%': {
            width: 0,
        },
        '100%': {
            width: '100%',
        },
    });

    return {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        backgroundColor: options.colors.blue,
        position: 'relative',
        borderRadius: `${options.radii.full} 0 0 ${options.radii.full}`,
        animation: `${loader} ${isFinished ? '1s' : '10s'} linear`,
    };
});

const carSvg = glamorous.svg(() => {
    const rumble = css.keyframes({
        '0%': {
            transform: 'translateY(-1px)',
        },
        '100%': {
            transform: 'translateY(0px)',
        },
    });

    return {
        animation: `0.3s ${rumble} infinite alternate`,
    };
});

const CarContainer = glamorous.span(() => {
    return {
        position: 'absolute',
        top: -18,
        right: -5,
    };
});

const Car = h(CarContainer, [
    h(
        carSvg,
        {
            width: '31',
            height: '19',
            viewBox: '0 0 31 19',
            fill: 'none',
            xmlns: 'http://www.w3.org/2000/svg',
        },
        [
            h('path', {
                d: 'M17.355 6.76969L15.493 3.47128C15.1297 2.82765 15.3452 2.01174 15.979 1.63148C16.5773 1.27249 17.3501 1.42303 17.7699 1.98037L21.7842 7.30974C21.9903 7.58334 22.321 7.73268 22.6635 7.73633C24.3844 7.75468 29.6125 8.27622 30.4281 13.8716C30.584 14.9415 29.67 15.8131 28.5888 15.8131H26.5854L5.23286 15.813L2.20742 15.0103C1.76907 14.894 1.46387 14.4973 1.46387 14.0437V13.0959V10.344V8.24913C1.46387 7.97299 1.68772 7.74913 1.96387 7.74913H4.17886H5.40816H7.96908L7.78066 6.8716C7.6634 6.32549 8.07967 5.81035 8.63823 5.81035C8.95779 5.81035 9.25205 5.98413 9.40634 6.26397L10.2252 7.74913H12.9995H16.7832C17.2859 7.74913 17.6021 7.20742 17.355 6.76969Z',
                fill: '#00A396',
            }),
            h('path', {
                d: 'M7.96908 7.74913H5.40816H4.17886H1.96387C1.68772 7.74913 1.46387 7.97299 1.46387 8.24913V10.344V13.0959V14.0437C1.46387 14.4973 1.76907 14.894 2.20742 15.0103L5.23286 15.813L26.5854 15.8131H28.5888C29.67 15.8131 30.584 14.9415 30.4281 13.8716C29.6125 8.27622 24.3844 7.75468 22.6635 7.73633C22.321 7.73268 21.9903 7.58334 21.7842 7.30974L17.7699 1.98037C17.3501 1.42303 16.5773 1.27249 15.979 1.63148V1.63148C15.3452 2.01174 15.1297 2.82765 15.493 3.47128L17.355 6.76969C17.6021 7.20742 17.2859 7.74913 16.7832 7.74913H12.9995H10.2252M7.96908 7.74913L7.78066 6.8716C7.6634 6.32549 8.07967 5.81035 8.63823 5.81035V5.81035C8.95779 5.81035 9.25205 5.98413 9.40634 6.26397L10.2252 7.74913M7.96908 7.74913H10.2252',
                stroke: '#00A396',
            }),
            h('ellipse', {
                cx: '6.52126',
                cy: '15.5955',
                rx: '2.76149',
                ry: '2.76149',
                fill: '#3B4B66',
            }),
            h('ellipse', {
                cx: '23.9929',
                cy: '15.5955',
                rx: '2.76149',
                ry: '2.76149',
                fill: '#3B4B66',
            }),
            h('path', {
                d: 'M16.6377 10.0859H14.1835',
                stroke: 'white',
                strokeLinecap: 'round',
            }),
        ],
    ),
]);

const Skeleton = glamorous.div(({ width, height, area }) => {
    const roling = css.keyframes({
        '0%': {
            backgroundPosition: '0 0',
        },
        '100%': {
            backgroundPosition: '200% 0',
        },
    });
    return {
        gridArea: area,
        width,
        height,
        background: `linear-gradient(90deg, ${options.colors.gray100}, ${options.colors.gray90}, ${options.colors.gray100})`,
        backgroundSize: '200% 100%',
        borderRadius: options.radii.small,
        display: 'block',
        animation: `${roling} 6s ease infinite`,
    };
});

const SkeletonContainer = glamorous.div({
    display: 'grid',
    gridTemplateColumns: 'auto repeat(2, 2fr)',
    gridTemplateAreas: '"big small small" "big small2 small2"',
    rowGap: 7,
    columnGap: options.space.xs,
    marginBottom: options.space.xl + 18, // + 18 because the car
});

const Text = glamorous.div({
    marginTop: options.space['2xs'],
    textAlign: 'center',
    ...waveTextSMedium,
    color: options.colors.blue,
});

function Loading({ isFinished }) {
    return h(Container, { isFinished }, [
        h(SkeletonContainer, [
            h(Skeleton, {
                width: options.space['6xl'],
                height: '100%',
                area: 'big',
            }),
            h(Skeleton, {
                width: '100%',
                height: options.space.m,
                area: 'small',
            }),
            h(Skeleton, {
                width: '100%',
                height: options.space.m,
                area: 'small2',
            }),
        ]),
        h('div', [h(LoadingBar, [h(LoadingValue, { isFinished }, [Car])])]),
        h(Text, 'Wir checken für Dich live den besten Preis!'),
    ]);
}

export { Loading };
