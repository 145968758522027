import glamorous from 'glamorous';
import h from 'react-hyperscript';

const Iframe = glamorous.iframe(({ height }) => ({
    border: 'none',
    overflow: 'hidden',
    height,

    // work around iframe display bug in iOS on iPads, see https://stackoverflow.com/a/20142280
    minWidth: '100%',
    width: 100,
    display: 'block',
}));

export default function VariableHeightIframe({ iframeRef, height, src }) {
    return h(Iframe, {
        height,
        ref: iframeRef,
        src,
        scrolling: 'no',
    });
}
