import singularOrPlural from './singularOrPlural';

const childFilter = (items) =>
    items.filter((i) => {
        if (i.age) {
            return i.age < 18;
        }

        return i.salutation === 'CHILD';
    });

const buildTravellersCountText = (totalChildren, totalAdults) => {
    const adultsCountText = `${totalAdults} Erw.`;

    return totalChildren
        ? `${adultsCountText}` +
              `, ${singularOrPlural(totalChildren, { singular: 'Kind', plural: 'Kinder' })}`
        : `${adultsCountText}`;
};

const getTravellersCount = (travellers) => {
    const totalChildren = childFilter(travellers).length;
    const totalAdults = travellers.length - totalChildren;

    return buildTravellersCountText(totalChildren, totalAdults);
};

export default getTravellersCount;
