import h from 'react-hyperscript';
import { Query } from '@apollo/client/react/components';
import PropTypes from 'prop-types';

import Categories from './Categories';
import { categoriesData } from '../../queries/myBadges';

const CategoriesQuery = (props, { isFeatureFlagSet }) => {
    const draftBadgesEnabled = isFeatureFlagSet('draftBadgesEnabled');
    return h(
        Query,
        {
            fetchPolicy: 'cache-and-network',
            query: categoriesData,
            variables: { draftBadgesEnabled },
        },
        ({ loading, data }) => {
            return !loading && data.badgesForUser
                ? h(Categories, {
                      badgesForUser: data.badgesForUser,
                      availableBadges: data.availableBadges,
                      categories: data.badgeCategories,
                  })
                : null;
        },
    );
};

CategoriesQuery.contextTypes = {
    isFeatureFlagSet: PropTypes.func.isRequired,
};

export default CategoriesQuery;
