export default () => ({
    subsectionTitleText:
        'Wir empfehlen Dir, bereits eingecheckt am Flughafen zu erscheinen!',
    subsectionDescriptionText:
        'Nutze dazu das Online- oder Mobile-Check-In auf der Webseite Deiner Airline und' +
        ' checke bereits bequem zu Hause ein. Du kannst Deine Bordkarte anschliessend ausdrucken oder bei einigen' +
        ' Airlines auch auf Deinem Mobiltelefon speichern. Dein Gepäck gibst Du am Check-In-Schalter Deiner' +
        ' Airline am Flughafen auf. Bitte beachte, dass bei einigen Fluggesellschaften ein online Check-In' +
        ' nicht möglich ist.',
    flightKeySectionTitle: 'Information zu Filekey / Flugbuchungsnummer / PNR',
    flightKeySectionText: `Die Flugbuchungsnummer (PNR/Filekey/Buchungscode) benötigst Du bei vielen Airlines für
    den Online Check in oder für die Zubuchung von Sonderleistungen.
    Die Nummer besteht meist aus einer 5- bis 6-stelligen Buchstaben- oder Buchstaben- und Zahlenkombination.
    Du findest die Flugbuchungsnummer auf Deiner Rechnung oder in Deinen Reiseunterlagen.
    Bei einigen Airlines ist die Angabe der Veranstalter-Vorgangsnummer ausreichend.
    Diese findest Du weiter oben in Deiner Übersicht oder ebenfalls auf Deiner Rechnung.
    Bitte prüfe auf der Homepage der Airline, ob diese einen Veranstalterlogin anbietet.<br/>
    Bitte besuche für den Online Check-In die Website der jeweiligen Fluggesellschaft
    und wähle den sogenannten „Reiseveranstalter Log-in“ aus.
    Dort gibst Du Deine 6-8-stellige Veranstalter-Vorgangsnummer (nur aus Zahlen bestehend) sowie den Namen
    Deines Reiseveranstalters ein, um Deine Flüge für Sonderleistungen (Sitzplatzreservierung, Gepäckaufstockung,
    Online Check-In u. a.) verwalten zu können.<br/>
    Falls kein „Reiseveranstalter Log-in“ vorhanden ist, nutze bitte Deine Flugbuchungsnummer / PNR um einen
    Online Check-in vorzunehmen.<br/>
    Bitte beachte, dass bei einigen Charterfluggesellschaften ein Online Check-In nicht möglich ist.
    Selbstverständlich steht bei diesen dennoch der reguläre Check-In am Flughafenschalter für Dich bereit.`,
    ctaTitle: 'Zum Online-Check-In',
    ctaDescription:
        'Für den Online-Check-In benötigst Du Deinen Filekey / Flugbuchungsnummer / PNR',
});
