import React from 'react';
import glamorous from 'glamorous';
import h from 'react-hyperscript';
import * as R from 'ramda';
import options from '@designsystem/options';

import callIfFunction from '../../../lib/callIfFunction';
import Column from '../../grid/Column';
import MainCountrySelect from './cityCountrySettings/MainCountrySelect';
import Select from '../../inputs/Select';
import TextInputWithDefaultValidation from '../../inputs/TextInputWithDefaultValidation';
import {
    UUID_AUSTRIA,
    UUID_GERMANY,
    UUID_SWITZERLAND,
} from '../../../lib/countryUUIDs';

const Container = glamorous.div({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
});

const renderHalfColumnIf = (condition, children) => {
    const column = h('div', { style: { width: '100%' } }, [
        h(Column, { small: null, medium: 6, large: 6 }, children),
    ]);
    return condition && column;
};
const LandTitle = glamorous.div({
    marginBottom: options.space.xs,
    fontWeight: options.fontWeights.mediumBold,
    fontSize: options.fontSizes.s,
    color: options.colors.black,
});

const LandInformation = glamorous.div({
    marginBottom: options.space.m,
    fontSize: options.fontSizes.xs,
    maxWidth: 560,
});

const MAIN_COUNTRIES_UUIDS = [UUID_GERMANY, UUID_AUSTRIA, UUID_SWITZERLAND];

class CityCountrySetting extends React.PureComponent {
    constructor(...args) {
        super(...args);

        this.updateCountry = this.updateCountry.bind(this);
        this.updateCity = this.updateCity.bind(this);
    }

    updateCountry(countryId) {
        callIfFunction(this.props.onCountryChange, [countryId]);
    }

    updateCity(city) {
        callIfFunction(this.props.onCityChange, [city]);
    }

    render() {
        const { city, country: selectedCountryId, disabled } = this.props;
        const countries = R.defaultTo([], this.props.countries);

        const mainCountries = countries.filter(({ id }) =>
            MAIN_COUNTRIES_UUIDS.includes(id),
        );
        const otherCountries = R.difference(countries, mainCountries);

        const isOtherCountry = otherCountries
            .map(({ id }) => id)
            .includes(selectedCountryId);

        return h(Container, [
            h(Column, { small: null, medium: 6, large: 6 }, [
                h(LandTitle, 'Land'),
                h(
                    LandInformation,
                    'Indem Du uns sagst, in welchem Land und Ort Du wohnst, hilfst Du uns dabei, gefälschte Bewertungen zu erkennen.',
                ),

                mainCountries.length > 0 &&
                    h(MainCountrySelect, {
                        mainCountries,
                        otherCountryForPreselection: otherCountries[0],
                        selectedCountryId,
                        disabled,
                        onChange: this.updateCountry,
                    }),
            ]),
            renderHalfColumnIf(
                isOtherCountry,
                h(Select, {
                    name: 'country',
                    options: otherCountries
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(({ id, name }) => {
                            return {
                                label: name,
                                value: id,
                            };
                        }),
                    label: 'In welchem Land lebst Du?',
                    value: selectedCountryId,
                    disabled,
                    onChange: this.updateCountry,
                }),
            ),
            renderHalfColumnIf(
                selectedCountryId,
                h(TextInputWithDefaultValidation, {
                    label: 'Wohnort',
                    name: 'city',
                    isPrivate: false,
                    onChange: this.updateCity,
                    value: city || '',
                    disabled,
                }),
            ),
        ]);
    }
}

export default CityCountrySetting;
