import h from 'react-hyperscript';
import { oneLine } from 'common-tags';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import ContentIframe from './ContentIframe';
import Page from './Page';

const contentStylesheet = oneLine`
    #main {
        width: 100%;
        float: none;
    }
    #mhcMemorizerQuery {
        position: relative;
    }
    #mhcMemorizeForm .suggestSearch {
        margin-bottom: 276px;
    }
    @media screen and (max-width: 736px) {
        #mhcMemorizeForm .suggestSearch {
            margin-bottom: 498px;
        }
    }
`;

function MyWatchlist(props, { elementModifiers, location }) {
    const navigate = useNavigate();
    const {
        injectStylesheet,
        removeAttribute,
        removeElement,
        replaceHrefBeginning,
        setHrefFromData,
    } = elementModifiers;

    return h(
        Page,
        {
            pagename: '/mhc/watchlist',
            headline: 'Beobachtungsagent',
        },
        [
            h(ContentIframe, {
                src: `/meinBeobachtungsagent.html${location.search}`,
                callMethodForElements: {
                    '.hLItemContainer .thumbnail a, .hLItemContainer .offerPrice a':
                        [setHrefFromData],
                    '[id^=category_Link_], .hLItemContainer .thumbnail a, .hLItemContainer .offerPrice a':
                        [removeAttribute('onclick')],
                    '#content aside': [removeElement],
                    head: [injectStylesheet(contentStylesheet)],
                    'a[href]': [
                        replaceHrefBeginning(
                            '/meinBeobachtungsagent.html',
                            '/mhc/meine-beobachtungen',
                        ),
                    ],
                },
                navigate,
            }),
        ],
    );
}

MyWatchlist.contextTypes = {
    location: PropTypes.object,
    elementModifiers: PropTypes.object,
};

export default MyWatchlist;
