import h from 'react-hyperscript';
import glamorous from 'glamorous';

import Checkmark from '../icons/Checkmark';
import Hint from '../icons/Hint';
import colors from '../../styles/colors';
import {
    xsmallDistance,
    smallDistance,
    mediumDistance,
} from '../../styles/distances';

const { flashMessageErrorBackgroundColor, flashMessageSuccessBackgroundColor } =
    colors;

const isSuccess = (type) => type === 'success';
const Container = glamorous.div(
    {
        display: 'flex',
        padding: `${mediumDistance} ${mediumDistance} ${mediumDistance} ${smallDistance}`,
    },
    ({ type }) => ({
        background: isSuccess(type)
            ? flashMessageSuccessBackgroundColor
            : flashMessageErrorBackgroundColor,
    }),
);

const Text = glamorous.p({
    margin: `0 0 0 ${xsmallDistance}`,
});

export default function FlashMessage({ type, children }) {
    const icon = isSuccess(type) ? Checkmark : Hint;

    return h(Container, { type }, [
        h(icon, {
            css: {
                height: mediumDistance,
                width: mediumDistance,
                flexShrink: 0,
            },
        }),
        h(Text, children),
    ]);
}
