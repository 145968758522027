import h from 'react-hyperscript';
import glamorous from 'glamorous';
import moment from 'moment';

import colors from '../../styles/colors';
import { xsmallDistance, smallDistance } from '../../styles/distances';
import { tinyFont, loudFont, bigLoudFont } from '../../styles/fonts';
import { applyOnTabletAndUp } from '../../styles/mediaQueries';
import AlbumItemViewsLabel from '../album/AlbumItemViewsLabel';
import formatDate from '../../lib/formatDate';
import AlbumItemHelpfulLabel from '../album/AlbumItemHelpfulLabel';

const { lightboxMetadataColor } = colors;

const Container = glamorous.div({
    color: lightboxMetadataColor,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const Description = glamorous.p({
    margin: 0,

    [applyOnTabletAndUp]: {
        width: '75%',
    },
});

const Main = glamorous.main({
    marginBottom: xsmallDistance,

    [applyOnTabletAndUp]: {
        marginBottom: smallDistance,
    },
});

const Time = glamorous.time({
    ...tinyFont,
    fontWeight: loudFont.fontWeight,
});

const EntityName = glamorous.h1({
    ...bigLoudFont,
    margin: 0,
});

export default function Metadata({
    metadata: {
        description,
        entityName,
        title,
        travelDate,
        helpfulCount,
        viewCount,
        status,
    },
}) {
    const date = moment(travelDate).utc();
    return h(Container, [
        travelDate && h(Time, formatDate(date, 'MMMM Y')),
        h(Main, [
            h(EntityName, entityName),
            h(Description, title),
            description ? h(Description, description) : null,
        ]),
        h('footer', [
            h(AlbumItemViewsLabel, {
                viewCount,
                status,
            }),
            h(AlbumItemHelpfulLabel, {
                helpfulCount,
            }),
        ]),
    ]);
}
