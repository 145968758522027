import { stripIndent } from 'common-tags';

import graphqlFetch from '../lib/graphqlFetch';

const fetch = async (query, context, variables = {}) => {
    const response = await graphqlFetch(context, { query, variables });

    if (!response.ok) {
        return [];
    }

    const jsonResponse = await response.json();

    return jsonResponse.data &&
        jsonResponse.data.rentalCarArticles &&
        jsonResponse.data.rentalCarArticles.articles
        ? jsonResponse.data.rentalCarArticles.articles
        : [];
};

/**
 * Fetches all rental car articles
 * @param {Object} context - Application context
 * @param {string} [destinationId] - Optional destination ID
 * @param {string} [bookingId] - Optional booking ID
 * @returns {Promise<Array>} Array of rental car articles
 */
export function fetchAllRentalCarArticles(context, destinationId, bookingId) {
    if (!context || !(destinationId || bookingId)) {
        return Promise.resolve([]);
    }

    const query = stripIndent`
        query RentalCarArticlesQuery($destinationId: String, $bookingId: String) {
          rentalCarArticles(destinationId: $destinationId, bookingId: $bookingId) {
            articles {
              link
              imageUrl
              headline
            }
          }
        }
    `;

    return fetch(query, context, { destinationId, bookingId });
}
