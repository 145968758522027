export const getColumnNo = (child, childIndex, columnCount) =>
    (child.props && child.props.column) || (childIndex % columnCount) + 1;

export const groupChildrenByColumns = (children, columnCount) => {
    return children.reduce((groups, child, index) => {
        const columnNo = getColumnNo(child, index, columnCount);
        return {
            ...groups,
            [columnNo]: [...(groups[columnNo] || []), { child, index }],
        };
    }, {});
};

export const sortIntoColumns = (groups, numColumns) => {
    const maxLength = Math.max(
        ...Object.values(groups).map((group) => group.length),
    );
    const columnIndices = Array.from({ length: numColumns }, (_, i) => i + 1);
    const roundsToPick = Array.from({ length: maxLength });

    return roundsToPick.flatMap((_, roundIndex) =>
        columnIndices
            .map((colIndex) => {
                const columnGroup = groups[colIndex];
                return columnGroup && columnGroup[roundIndex];
            })
            .filter(Boolean),
    );
};

export const groupElementHeightsByColumn = (container, columnCount) => {
    const heightsByColumn = Array.from({ length: columnCount }, () => []);

    [...container.querySelectorAll('[data-masonry-column]')].forEach(
        (element) => {
            const column = parseInt(element.dataset.masonryColumn, 10);
            if (column && column <= columnCount) {
                heightsByColumn[column - 1].push(
                    element.offsetParent ? element.offsetHeight : 0,
                );
            }
        },
    );

    return heightsByColumn;
};

export const calculateTargetTop = (columnHeights, index, gap) =>
    columnHeights.slice(0, index).reduce((total, height) => {
        const elemHeightInclGap = height ? height + gap : 0;
        return total + elemHeightInclGap;
    }, 0);

export const getComputedTranslateY = (element, window) => {
    const transform = window.getComputedStyle(element).transform;
    if (transform && transform !== 'none') {
        const matrix = new window.DOMMatrix(transform);
        return matrix.m42; // m42 = translateY
    }
    return 0;
};

export const calculateColumnTransforms = (
    container,
    columnCount,
    gap,
    context,
) => {
    const firstColumnFirstElement = container.querySelector(
        '[data-masonry-column="1"]',
    );
    if (!firstColumnFirstElement) return null;

    const referenceTop = firstColumnFirstElement.getBoundingClientRect().top;
    const heightsByColumn = groupElementHeightsByColumn(container, columnCount);
    const transforms = [];
    const containerMarginTop =
        parseFloat(context.window.getComputedStyle(container).marginTop) || 0;

    heightsByColumn.forEach((columnHeights, columnIndex) => {
        const elements = [
            ...container.querySelectorAll(
                `[data-masonry-column="${columnIndex + 1}"]`,
            ),
        ];

        elements.forEach((element, index) => {
            const targetTop = calculateTargetTop(columnHeights, index, gap);
            const currentTop = element.getBoundingClientRect().top;
            const currentTranslateY = getComputedTranslateY(
                element,
                context.window,
            );
            const isBelowTopRow =
                currentTop > containerMarginTop + currentTranslateY;
            const translateY = isBelowTopRow
                ? targetTop - (currentTop - referenceTop) + currentTranslateY
                : 0;

            transforms.push({ element, translateY });
        });
    });

    return transforms;
};
