import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import { Fragment, useEffect } from 'react';

import { pxToRem } from '../../styles/unitConverter';
import { applyOnTabletAndUp } from '../../styles/mediaQueries';
import BookingSectionHeadline from './BookingSectionHeadline';
import LinkBox from './LinkBox';
import { FONTS, waveFontM } from '../../styles/fonts';
import getSelfServiceFeatureFromKey from './lib/getSelfServiceFeatureFromKey';
import Checkmark from '../icons/Checkmark';
import useBookingContext from './lib/useBookingContext';
import { black } from '../../styles/waveColors';

const TourOperator = glamorous.div({
    [applyOnTabletAndUp]: {
        flexBasis: '50%',
    },
});
TourOperator.displayName = 'TourOperator';

const SubHeadline = glamorous.h4({
    textTransform: 'uppercase',
    marginBottom: pxToRem(16),
    marginTop: 0,
    width: '100%',
    fontWeight: 'inherit',
});
SubHeadline.displayName = 'SubHeadline';

const TourOperatorName = glamorous.div({
    fontWeight: 'bold',
    marginBottom: pxToRem(8),
});
TourOperatorName.displayName = 'TourOperatorName';

const TourOperatorLogo = glamorous.div(({ toId }) => ({
    width: pxToRem(180),
    height: pxToRem(100),
    backgroundRepeat: 'no-repeat',
    backgroundImage:
        'url(https://www.holidaycheck.de/data/common/organizerlogo/by-uuid/' +
        `${toId}/240x100)`,
    backgroundSize: 'contain',
}));
TourOperatorLogo.displayName = 'TourOperatorLogo';

const FeatureWrapper = glamorous.div({
    display: 'flex',
    alignItems: 'center',
    fontFamily: FONTS.primary,
    color: black,
    ...waveFontM,

    ':last-child': {
        marginBottom: pxToRem(10),
    },
});
FeatureWrapper.displayName = 'FeatureWrapper';

const renderTourOperatorSelfServicePortal = (
    { id, name, selfServicePortal: { url, description, features } },
    trackEventWithBooking,
) => {
    const trackLinkClick = () => {
        trackEventWithBooking({
            event: 'event',
            eventCategory: 'mybooking',
            eventAction: 'click',
            eventLabel: 'tourOperatorSelfServicePortal',
            ofOrganizerSelected: name || id,
        });
    };

    return h(LinkBox, {
        link: url,
        title: 'Zu Deinem Reiseveranstalter',
        description: [
            features.map(({ key }) => {
                const featureText = getSelfServiceFeatureFromKey(key);
                return (
                    featureText &&
                    h(FeatureWrapper, { key }, [
                        h(Checkmark, {
                            style: {
                                height: pxToRem(14),
                                marginRight: pxToRem(10),
                            },
                            'aria-hidden': true,
                        }),
                        h('span', featureText),
                    ])
                );
            }),
            description,
        ],
        trackLinkClick,
    });
};

const TravelTourOperator = ({ booking, myRef, setWillRender }) => {
    const { name, id } = booking.tourOperator;
    useEffect(() => setWillRender(true), []);
    const { trackEventWithBooking } = useBookingContext();

    return h(Fragment, [
        h(
            BookingSectionHeadline,
            { innerRef: myRef },
            'Dein Reiseveranstalter',
        ),
        h(TourOperator, [
            name && h(TourOperatorName, name),
            h(TourOperatorLogo, { toId: id }),
        ]),
        booking.tourOperator.selfServicePortal &&
            renderTourOperatorSelfServicePortal(
                booking.tourOperator,
                trackEventWithBooking,
            ),
    ]);
};

TravelTourOperator.propTypes = {
    booking: PropTypes.shape({
        tourOperator: PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string,
            selfServicePortal: PropTypes.shape({
                url: PropTypes.string.isRequired,
                description: PropTypes.string,
                features: PropTypes.arrayOf(
                    PropTypes.shape({
                        key: PropTypes.string.isRequired,
                    }),
                ).isRequired,
            }),
        }).isRequired,
    }),
    // from using `useRef` hook, `ref` prop is treated differently and would need the `forwardRef` crap
    myRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
    setWillRender: PropTypes.func.isRequired,
};

TravelTourOperator.displayName = 'TravelTourOperator';

export default TravelTourOperator;
