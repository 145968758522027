import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import * as glamor from 'glamor';
import { useState, useEffect } from 'react';
import { oneLine } from 'common-tags';

import TextInput from '../inputs/TextInput';
import RadioButtonGroup from '../inputs/RadioButtonGroup';
import Select from '../inputs/Select';
import { NATIONALITY_OPTIONS } from '../../lib/nationalityPairings';
import {
    SALUTATION_OPTIONS,
    SALUTATION_VALUE_OPTIONS,
} from '../../lib/salutations';
import { smallDistance } from '../../styles/distances';
import { pxToRem } from '../../styles/unitConverter';
import { gray20, red40 } from '../../styles/waveColors';
import { applyOnMobile, applyOnTabletAndUp } from '../../styles/mediaQueries';
import { FONTS } from '../../styles/fonts';
import Hint from './Hint';
import { visuallyHidden } from '../../styles/accessibility';

const ChangeParticipantForm = glamorous.form({
    margin: `${smallDistance} ${smallDistance} 0`,
    transition: 'height 5s',
    textAlign: 'initial',
});

const HiddenRatioLegend = glamorous.legend(visuallyHidden);

const NameWrapper = glamorous.div({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'stretch',
});

const nameInputStyles = {
    width: '100%',
    [applyOnTabletAndUp]: {
        width: `calc(50% - ${pxToRem(8)})`,
    },
};

const ErrorMessage = glamorous.p({
    fontFamily: FONTS.primary,
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(18),
    color: red40,
    margin: `0 0 ${pxToRem(7)}`,
});

const DoBFieldset = glamorous.fieldset({
    display: 'flex',
    flexWrap: 'wrap',
    border: 'none',
    padding: 0,
    margin: 0,
});

const DoBLegend = glamorous.legend({
    fontFamily: FONTS.primary,
    fontWeight: 500,
    fontSize: smallDistance,
    lineHeight: pxToRem(21),
    color: gray20,
    marginBottom: pxToRem(8),
});

const dayMonthInputStyles = {
    width: '10%',
    marginRight: smallDistance,
    [applyOnMobile]: {
        width: pxToRem(55),
    },
};

const yearInputStyles = {
    width: '20%',
    [applyOnMobile]: {
        width: pxToRem(100),
    },
};

const revealMessage = () => {
    const scaleIn = glamor.css.keyframes({
        from: { transform: 'translateY(-10px)', opacity: 0 },
        to: { transform: 'translateY(0)', opacity: 1 },
    });
    return { animation: `${scaleIn} 350ms 1 normal ease` };
};

const hideMessage = () => {
    const scaleOut = glamor.css.keyframes({
        from: { transform: 'translateY(0)', opacity: 1 },
        to: {
            transform: 'translateY(-10px)',
            opacity: 0,
            height: 0,
            display: 'none',
        },
    });
    return { animation: `${scaleOut} 350ms 1 normal ease forwards` };
};

const HintMessage = glamorous.div(({ showHint, hasDataBeenChangedOnce }) => {
    if (hasDataBeenChangedOnce) {
        return showHint ? revealMessage : hideMessage;
    }
    return { display: 'none' };
});
HintMessage.displayName = 'HintMessage';

const PARTICIPANT_FIELDS = {
    salutation: 'salutation',
    firstName: 'firstName',
    lastName: 'lastName',
    dateOfBirth: 'dateOfBirth',
    dayOfBirth: 'dayOfBirth',
    monthOfBirth: 'monthOfBirth',
    yearOfBirth: 'yearOfBirth',
    nationality: 'nationality',
};

const getFormattedDobFieldsArray = (dob) => {
    return dob ? dob.split('-') : ['', '', ''];
};

const ChangeParticipantDataForm = ({
    participant,
    traveller,
    setParticipantValue,
    index,
    errors,
    validateOnBlur,
}) => {
    const [dayOfBirth, setDayOfBirth] = useState(
        getFormattedDobFieldsArray(participant.dateOfBirth)[2],
    );
    const [monthOfBirth, setMonthOfBirth] = useState(
        getFormattedDobFieldsArray(participant.dateOfBirth)[1],
    );
    const [yearOfBirth, setYearOfBirth] = useState(
        getFormattedDobFieldsArray(participant.dateOfBirth)[0],
    );
    const [hasDataBeenChangedOnce, setHasDataBeenChangedOnce] = useState(false);
    const [showHint, setShowHint] = useState(false);
    const hasDataChanged =
        JSON.stringify(traveller) !== JSON.stringify(participant);

    const getFormattedDob = (value, fieldName) => {
        switch (fieldName) {
            case PARTICIPANT_FIELDS.dayOfBirth: {
                const day = value.length === 1 ? `0${value}` : value;
                return `${yearOfBirth}-${monthOfBirth}-${day}`;
            }
            case PARTICIPANT_FIELDS.monthOfBirth: {
                const month = value.length === 1 ? `0${value}` : value;
                return `${yearOfBirth}-${month}-${dayOfBirth}`;
            }
            case PARTICIPANT_FIELDS.yearOfBirth: {
                return `${value}-${monthOfBirth}-${dayOfBirth}`;
            }
            default:
                return null;
        }
    };

    useEffect(() => {
        if (!hasDataBeenChangedOnce) {
            setHasDataBeenChangedOnce(hasDataChanged);
        } else {
            setShowHint(hasDataChanged);
        }
    }, [
        hasDataChanged,
        hasDataBeenChangedOnce,
        setHasDataBeenChangedOnce,
        setShowHint,
    ]);

    return h(ChangeParticipantForm, { onSubmit: (e) => e.preventDefault() }, [
        h(HintMessage, { showHint, hasDataBeenChangedOnce }, [
            h(Hint, {
                prefix: oneLine`Bitte überprüfe vor dem Absenden, ob andere Daten (Anrede, Namen, Geburtsdatum,
                    Nationalität) ebenfalls angepasst werden müssen.`,
            }),
        ]),
        h(HiddenRatioLegend, 'Anrede'),
        h(RadioButtonGroup, {
            name: PARTICIPANT_FIELDS.salutation,
            selectedValue: participant.salutation,
            onChange: setParticipantValue(PARTICIPANT_FIELDS.salutation, index),
            isInline: true,
            options: SALUTATION_OPTIONS,
            onBlur: validateOnBlur(PARTICIPANT_FIELDS.salutation, index),
            errorMessage:
                Boolean(errors) &&
                Boolean(errors.salutation) &&
                errors.salutation,
        }),
        h(NameWrapper, [
            h(TextInput, {
                label: 'Vorname',
                name: PARTICIPANT_FIELDS.firstName,
                onChange: setParticipantValue(
                    PARTICIPANT_FIELDS.firstName,
                    index,
                ),
                value: participant.firstName,
                onBlur: validateOnBlur(PARTICIPANT_FIELDS.firstName, index),
                errorMessage:
                    Boolean(errors) &&
                    Boolean(errors.firstName) &&
                    errors.firstName,
                addFieldStyles: nameInputStyles,
            }),
            h(TextInput, {
                label: 'Nachname',
                name: PARTICIPANT_FIELDS.lastName,
                onChange: setParticipantValue(
                    PARTICIPANT_FIELDS.lastName,
                    index,
                ),
                value: participant.lastName,
                onBlur: validateOnBlur(PARTICIPANT_FIELDS.lastName, index),
                errorMessage:
                    Boolean(errors) &&
                    Boolean(errors.lastName) &&
                    errors.lastName,
                addFieldStyles: nameInputStyles,
            }),
        ]),
        h(DoBLegend, 'Geburtsdatum'),
        h(DoBFieldset, [
            h(TextInput, {
                label: 'Tag',
                name: PARTICIPANT_FIELDS.dayOfBirth,
                onChange: (value) => setDayOfBirth(value),
                value: dayOfBirth,
                maxLength: 2,
                addFieldStyles: dayMonthInputStyles,
                onBlur: (value) => {
                    const dob = getFormattedDob(
                        value,
                        PARTICIPANT_FIELDS.dayOfBirth,
                    );
                    setParticipantValue(
                        PARTICIPANT_FIELDS.dateOfBirth,
                        index,
                    )(dob);
                    validateOnBlur(PARTICIPANT_FIELDS.dateOfBirth, index)(dob);
                    setDayOfBirth(getFormattedDobFieldsArray(dob)[2]);
                },
                hasError: Boolean(errors) && Boolean(errors.dateOfBirth),
            }),
            h(TextInput, {
                label: 'Monat',
                name: PARTICIPANT_FIELDS.monthOfBirth,
                onChange: (value) => setMonthOfBirth(value),
                value: monthOfBirth,
                maxLength: 2,
                addFieldStyles: dayMonthInputStyles,
                onBlur: (value) => {
                    const dob = getFormattedDob(
                        value,
                        PARTICIPANT_FIELDS.monthOfBirth,
                    );
                    setMonthOfBirth(getFormattedDobFieldsArray(dob)[1]);
                    setParticipantValue(
                        PARTICIPANT_FIELDS.dateOfBirth,
                        index,
                    )(dob);
                    validateOnBlur(PARTICIPANT_FIELDS.dateOfBirth, index)(dob);
                },
                hasError: Boolean(errors) && Boolean(errors.dateOfBirth),
            }),
            h(TextInput, {
                label: 'Jahr',
                name: PARTICIPANT_FIELDS.yearOfBirth,
                onChange: (value) => setYearOfBirth(value),
                value: yearOfBirth,
                maxLength: 4,
                addFieldStyles: yearInputStyles,
                onBlur: (value) => {
                    const dob = getFormattedDob(
                        value,
                        PARTICIPANT_FIELDS.yearOfBirth,
                    );
                    setYearOfBirth(getFormattedDobFieldsArray(dob)[0]);
                    setParticipantValue(
                        PARTICIPANT_FIELDS.dateOfBirth,
                        index,
                    )(dob);
                    validateOnBlur(PARTICIPANT_FIELDS.dateOfBirth, index)(dob);
                },
                hasError: Boolean(errors) && Boolean(errors.dateOfBirth),
            }),
        ]),
        Boolean(errors) &&
            Boolean(errors.dateOfBirth) &&
            h(ErrorMessage, errors.dateOfBirth),
        h(Select, {
            label: 'Nationalität',
            name: PARTICIPANT_FIELDS.nationality,
            onChange: setParticipantValue(
                PARTICIPANT_FIELDS.nationality,
                index,
            ),
            options: NATIONALITY_OPTIONS,
            value: participant.nationality,
        }),
    ]);
};

export default ChangeParticipantDataForm;

ChangeParticipantDataForm.propTypes = {
    participant: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        salutation: PropTypes.oneOf(SALUTATION_VALUE_OPTIONS),
        dateOfBirth: PropTypes.string,
        nationality: PropTypes.string,
    }),
    traveller: PropTypes.shape({
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        salutation: PropTypes.oneOf(SALUTATION_VALUE_OPTIONS),
        dateOfBirth: PropTypes.string,
        nationality: PropTypes.string,
    }),
    setParticipantValue: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
};
