import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import options from '@designsystem/options';
import { useQuery } from '@apollo/client';

import { pxToRem } from '../../../styles/unitConverter';
import Service from './Service';
import PremiumHealthInsuranceService from './PremiumHealthInsuranceService';
import { isHealthInsurance, isPremiumService } from '../lib/serviceHelpers';
import { waveTextL } from '../../../styles/waveText';
import PremiumLabel from '../../myHolidaycheck/PremiumLabel';
import { waveMdMediaQuery } from '../../../styles/waveMediaQueries';
import { premiumMembership } from '../../../queries/premiumMembership';
import { noticeError } from '../../../lib/newrelic';
import {
    extractValidContracts,
    extractValidPeriods,
} from '../../../lib/premium/membership';

const Subheadline = glamorous.h4({
    ...waveTextL,
    letterSpacing: options.letterSpacings.l,
    padding: `${pxToRem(options.space.m)} ${pxToRem(options.space.xl)}`,
    borderBottom: `1px solid ${options.colors.gray90}`,
    margin: 0,
    [waveMdMediaQuery]: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    },
});
Subheadline.displayName = 'Subheadline';

const PremiumBenefitsContainer = glamorous.div({
    marginTop: pxToRem(options.space['3xl']),
    border: `1px solid ${options.colors.gray90}`,
    boxSizing: 'border-box',
    borderRadius: options.radii.small,
});

const YourPremiumOfferHeader = glamorous.div({
    marginTop: pxToRem(options.space.xs),
    [waveMdMediaQuery]: {
        marginTop: '0px',
    },
});

const extractPremiumCashback = (data, bookingId) => {
    const defaultCashback = [];

    if (!data) {
        return defaultCashback;
    }

    const validContracts = extractValidContracts(
        data.premiumMembership.subscriptionContracts,
    );

    const cashbackClaimedOnBooking = validContracts.flatMap((contract) => {
        const validSubscriptionPeriods = extractValidPeriods(
            contract.subscriptionPeriods,
        );
        return validSubscriptionPeriods.flatMap((subscriptionPeriod) => {
            if (
                !subscriptionPeriod.benefits ||
                !subscriptionPeriod.benefits.cashback
            )
                return [];

            return subscriptionPeriod.benefits.cashback.filter(
                (c) => c.detail.bookingId === bookingId,
            );
        });
    });

    return cashbackClaimedOnBooking.map((c) => ({
        ...c,
        detail: {
            ...c.detail,
            title: 'Reisegutschein',
            comment: 'Du erhältst die Gutschrift umgehend nach Reise-Rückkehr.',
        },
    }));
};

const PremiumServices = ({ bookingId, services, startDate }) => {
    try {
        const { error: premiumMembershipFetchError, data } =
            useQuery(premiumMembership);
        if (premiumMembershipFetchError) {
            noticeError(premiumMembershipFetchError, {
                pageName: '/mhc/meine-buchungen',
                component: 'PremiumServices',
            });
        }

        const premiumServices = [
            ...extractPremiumCashback(data, bookingId),
            ...services.filter(isPremiumService),
        ];

        if (!premiumServices.length) {
            return null;
        }

        return h(PremiumBenefitsContainer, [
            h(Subheadline, [
                h(PremiumLabel),
                h(
                    YourPremiumOfferHeader,
                    'Deine HolidayCheck Premium Vorteile',
                ),
            ]),
            premiumServices.map((service, index) => {
                if (isHealthInsurance(service))
                    return h(PremiumHealthInsuranceService, {
                        service,
                        startDate,
                        key: index,
                    });

                return h(Service, {
                    service,
                    key: index,
                });
            }),
        ]);
    } catch (error) {
        noticeError(error.message, {
            pageName: 'MyBookings',
            component: 'PremiumServices',
        });
        return null;
    }
};

PremiumServices.propTypes = {
    bookingId: PropTypes.string.isRequired,
    services: PropTypes.array.isRequired,
};

export default PremiumServices;
