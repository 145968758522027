export default (airportOnly) => `
    Gehe am Flughafen zum Check-in Deiner Airline und checke entweder am Schalter oder am Automaten ein.
    ${
        airportOnly
            ? ''
            : ' Bitte beachte, dass Du bei einigen Airlines nur am Schalter oder nur am Automaten einchecken kannst.'
    } 
    Die Information erhältst Du am Flughafen auf den großen Info Tafeln direkt am Eingang. 
    Dein Gepäck gibst Du in jedem Fall am Check-In-Schalter Deiner Airline auf.<br/>
    Allgemein empfehlen die meisten Fluggesellschaften mindestens 2 Stunden vor dem 
    Abflug am Flughafen einzutreffen. Bezüglich eines Vorabend Check-ins findest Du die 
    Informationen ebenfalls auf der Website der jeweiligen Fluggesellschaft.`;
