import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import { Fragment, useEffect } from 'react';
import moment from 'moment';

import {
    isActiveBooking,
    hasEnded,
    extractFlight,
} from '../../../../common/lib/bookingHelper';
import Flights from './Journey';
import BookingSectionHeadline from '../BookingSectionHeadline';
import Hint from '../Hint';
import OnlineCheckin from './OnlineCheckin';
import AirportCheckin from './AirportCheckin';
import { applyOnDesktopAndUp } from '../../../styles/mediaQueries';
import { pxToRem } from '../../../styles/unitConverter';

const FlightsContainer = glamorous.div({
    display: 'flex',
    flexWrap: 'wrap',
    margin: pxToRem(-12),
});

const CheckinContainer = glamorous.div({
    marginTop: pxToRem(24),
});
CheckinContainer.displayName = 'CheckinContainer';

const FlightsBox = glamorous.div({
    flexBasis: '100%',
    [applyOnDesktopAndUp]: {
        flexBasis: '0px',
    },
    flexGrow: 1,
    margin: pxToRem(12),
});
FlightsBox.displayName = 'FlightsBox';

const hasFlightDates = (flight) => flight.departure.date && flight.arrival.date;

const haveAllFlightsDates = (flights) => flights.every(hasFlightDates);

const hasFlightServiceDates = (flightDetail) => {
    return (
        haveAllFlightsDates(flightDetail.outBound) &&
        haveAllFlightsDates(flightDetail.inBound)
    );
};

const getNextFlight = (flightDetail, currentDate) => {
    if (!flightDetail) {
        return undefined;
    }

    const outBoundFlight = flightDetail.outBound && flightDetail.outBound[0];
    const isOutBoundFlightInFuture =
        outBoundFlight &&
        moment(currentDate).isBefore(outBoundFlight.departure.date);
    const inBoundFlight = flightDetail.inBound && flightDetail.inBound[0];
    const isInBoundFlightInFuture =
        inBoundFlight &&
        moment(currentDate).isBefore(inBoundFlight.departure.date);

    if (isOutBoundFlightInFuture) {
        return outBoundFlight;
    } else if (isInBoundFlightInFuture) {
        return inBoundFlight;
    }
    return undefined;
};

const renderCheckIn = (
    { airlineInformation, flightFileKey },
    { tourOperator },
) => {
    if (!airlineInformation) {
        return null;
    }

    const { text, airlineName, onlineCheckIn, airportCheckIn } =
        airlineInformation;
    return h(CheckinContainer, [
        onlineCheckIn &&
            h(OnlineCheckin, {
                airlineName,
                link: onlineCheckIn.url,
                additionalCheckInDetails: text,
                flightFileKey,
                tourOperator,
            }),
        airportCheckIn &&
            h(AirportCheckin, {
                fee: airportCheckIn.fee,
                airportOnly: !onlineCheckIn,
            }),
    ]);
};

const FlightInformation = (
    { booking, myRef, setWillRender },
    { getCurrentDate },
) => {
    const flightService = extractFlight(booking);
    if (!flightService) {
        return null;
    }

    useEffect(() => setWillRender(true), []);

    const bookingType = booking.bookingType;
    const isBookingActive =
        bookingType &&
        isActiveBooking(bookingType) &&
        !hasEnded(booking, getCurrentDate());
    const nextFlight = isBookingActive
        ? getNextFlight(flightService.detail, getCurrentDate())
        : undefined;

    return h(Fragment, [
        h(BookingSectionHeadline, { innerRef: myRef }, 'Dein Flug'),
        hasFlightServiceDates(flightService.detail) &&
            h(
                Hint,
                { prefix: 'Maßgeblich sind die Infos auf dem Ticket!' },
                'Der Veranstalter kann die Flugzeiten jederzeit ändern. ' +
                    'Bitte überprüfe vor Deiner Abreise nochmals ganz genau Deine Reiseunterlagen und Gepäckbestimmungen.',
            ),
        h(FlightsContainer, [
            h(
                FlightsBox,
                '',
                h(Flights, {
                    flights: flightService.detail.outBound,
                    flightsName: 'Hinflug',
                    icon: 'outbound_flight.svg',
                    eventLabel: 'outBound',
                    isBookingActive,
                }),
            ),
            h(
                FlightsBox,
                '',
                h(Flights, {
                    flights: flightService.detail.inBound,
                    flightsName: 'Rückflug',
                    icon: 'inbound_flight.svg',
                    eventLabel: 'inBound',
                    isBookingActive,
                }),
            ),
        ]),
        nextFlight && renderCheckIn(nextFlight, booking),
    ]);
};

const flightTimeAndPlacePropType = PropTypes.shape({
    date: PropTypes.string,
});

const flightPropType = PropTypes.shape({
    departure: flightTimeAndPlacePropType.isRequired,
    arrival: flightTimeAndPlacePropType.isRequired,
    airlineInformation: PropTypes.shape({
        text: PropTypes.string,
        airlineName: PropTypes.string.isRequired,
        onlineCheckIn: PropTypes.shape({
            url: PropTypes.string,
        }),
        airportCheckIn: PropTypes.shape({
            fee: PropTypes.shape({
                amount: PropTypes.number,
                currencyCode: PropTypes.string,
            }),
        }),
    }),
});

FlightInformation.propTypes = {
    booking: PropTypes.shape({
        services: PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.shape({
                    type: PropTypes.oneOf(['FLIGHT']),
                    detail: PropTypes.shape({
                        outBound: PropTypes.arrayOf(flightPropType).isRequired,
                        inBound: PropTypes.arrayOf(flightPropType).isRequired,
                    }).isRequired,
                }),
                PropTypes.shape({
                    type: (props) => {
                        return props.type === 'FLIGHT' ? new Error() : null;
                    },
                }),
            ]),
        ).isRequired,
    }).isRequired,
    // from using `useRef` hook, `ref` prop is treated differently and would need the `forwardRef` crap
    myRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
    setWillRender: PropTypes.func.isRequired,
};

FlightInformation.contextTypes = {
    getCurrentDate: PropTypes.func.isRequired,
};

FlightInformation.displayName = 'FlightInformation';

export default FlightInformation;
