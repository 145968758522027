import glamorous from 'glamorous';
import h from 'react-hyperscript';
import options from '@designsystem/options';

import callIfFunction from '../../../../lib/callIfFunction';
import { waveSmMediaQuery } from '../../../../styles/waveMediaQueries';

const Button = glamorous.button({
    background: 'none',
    border: 0,
    cursor: 'pointer',
    width: '100%',
    fontFamily: options.fonts.default,
    fontWeight: options.fontWeights.mediumBold,
    fontSize: options.fontSizes.s,
    '&:disabled': {
        cursor: 'not-allowed',
    },
    alignItems: 'left',
    marginLeft: options.space.m,

    padding: `${options.space.l}px 0`,
});

const Flag = glamorous.span(
    {
        display: 'block',
        marginRight: options.space.xs,
        marginTop: -1,
    },

    ({ hideIconOnTabletAndUp }) =>
        hideIconOnTabletAndUp && {
            [waveSmMediaQuery]: {
                display: 'none',
            },
        },
);

const FlexContainer = glamorous.div({
    display: 'flex',
    alignItems: 'left',
    flexDirection: 'row',
});

const Name = glamorous.span({
    display: 'block',
    lineHeight: 1,
});

export default function CountryButton({
    hideIconOnTabletAndUp = false,
    icon,
    name,
    disabled = false,
    onClick,
}) {
    return h(
        Button,
        {
            disabled,
            type: 'button',
            onClick: (event) => {
                event.preventDefault();
                callIfFunction(onClick, []);
            },
        },
        [
            h(FlexContainer, [
                h(
                    Flag,
                    {
                        hideIconOnTabletAndUp,
                    },
                    icon,
                ),
                h(Name, name),
            ]),
        ],
    );
}
