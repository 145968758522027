import h from 'react-hyperscript';
import { Fragment } from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import Installment from './Installment';
import {
    hasOriginalDeposit,
    isDepositOverdue,
    isFinalOverdue,
    isDepositFullyPaid,
    formatInstallmentText,
    getDisplayDepositAmount,
    getDisplayFinalAmount,
} from '../lib/paymentConditionsHelpers';
import { getPaymentConditionsIntroText } from '../lib/paymentInformationTexts';
import { pxToRem } from '../../../styles/unitConverter';
import { black, gray90 } from '../../../styles/waveColors';
import { secondaryFont } from '../../../styles/fonts';
import {
    applyOnMobile,
    applyOnTabletAndUp,
} from '../../../styles/mediaQueries';
import PaymentStatusUpdateInfo from './PaymentStatusUpdateInfo';

const { PTSansFamily, UITextL } = secondaryFont;

const SPACING_BETWEEN_INSTALLMENTS = pxToRem(24);

const Installments = glamorous.div({
    [applyOnMobile]: {
        margin: `${pxToRem(16)} 0`,
        padding: `0 ${pxToRem(24)}`,
    },

    [applyOnTabletAndUp]: {
        display: 'block',
        justifyContent: 'space-between',
        padding: `0 ${pxToRem(24)}`,
        '>div': {
            flexBasis: `calc((100% - ${SPACING_BETWEEN_INSTALLMENTS}) / 2)`,
            margin: `${pxToRem(16)} 0 ${pxToRem(24)} 0`,

            '&:only-child': {
                marginRight: 'auto',
                marginLeft: 'auto',
            },
        },
    },
});
Installments.displayName = 'Installments';

const PaymentInfo = glamorous.div(PTSansFamily, UITextL, {
    textAlign: 'start',
    color: black,
    padding: `0 ${pxToRem(24)}`,
    marginBottom: pxToRem(16),
});
PaymentInfo.displayName = 'PaymentInfo';

const isAOneOffPayment = (hasDepositInBooking, updatedTourOperatorPrice) =>
    !hasDepositInBooking &&
    updatedTourOperatorPrice &&
    !(updatedTourOperatorPrice.remainingFinal < updatedTourOperatorPrice.total);

const renderInstallments = (
    tourOperatorPrice,
    updatedTourOperatorPrice,
    getCurrentDate,
) => {
    const isDepositPaymentOverdue = isDepositOverdue(
        tourOperatorPrice,
        updatedTourOperatorPrice,
        getCurrentDate,
    );
    const isFinalPaymentOverdue = isFinalOverdue(
        tourOperatorPrice,
        updatedTourOperatorPrice,
        getCurrentDate,
    );
    const hasDepositInBooking = hasOriginalDeposit(tourOperatorPrice);
    const installments = [];

    if (hasDepositInBooking) {
        installments.push({
            title: formatInstallmentText(isDepositPaymentOverdue, 'Anzahlung'),
            amount: getDisplayDepositAmount(
                tourOperatorPrice,
                updatedTourOperatorPrice,
            ),
            currencyCode: tourOperatorPrice.currencyCode,
            dueDate: tourOperatorPrice.deposit.dueDate,
            isFullyPaid: isDepositFullyPaid(
                tourOperatorPrice,
                updatedTourOperatorPrice,
            ),
            isOverdue: isDepositPaymentOverdue,
        });
    }

    installments.push({
        title: isAOneOffPayment(hasDepositInBooking, updatedTourOperatorPrice)
            ? 'Gesamtbetrag'
            : formatInstallmentText(isFinalPaymentOverdue, 'Restzahlung'),
        amount: getDisplayFinalAmount(
            tourOperatorPrice,
            updatedTourOperatorPrice,
        ),
        currencyCode: tourOperatorPrice.currencyCode,
        dueDate: tourOperatorPrice.dueDate,
        isOverdue: isFinalPaymentOverdue,
    });

    return h(Installments, [
        installments.map((installment, index) =>
            h(Installment, { ...installment, key: index }),
        ),
    ]);
};

const Divider = glamorous.hr({
    border: 0,
    borderTop: `1px solid ${gray90}`,
});

const PaymentConditions = (
    { booking, updatedTourOperatorPrice },
    { getCurrentDate },
) => {
    const { payment, tourOperator, tourOperatorPrice } = booking;
    const introText = getPaymentConditionsIntroText(
        payment.type,
        tourOperator.name,
        tourOperatorPrice,
    );

    return h(Fragment, [
        h(PaymentInfo, introText),
        h(Divider),
        renderInstallments(
            tourOperatorPrice,
            updatedTourOperatorPrice,
            getCurrentDate,
        ),
        h(Divider),
        h(PaymentStatusUpdateInfo, { booking, updatedTourOperatorPrice }),
    ]);
};

PaymentConditions.propTypes = {
    booking: PropTypes.shape({
        tourOperatorPrice: PropTypes.shape({
            amount: PropTypes.number.isRequired,
            currencyCode: PropTypes.string.isRequired,
            dueDate: PropTypes.string,
            deposit: PropTypes.shape({
                amount: PropTypes.number,
                dueDate: PropTypes.string,
            }),
        }).isRequired,
        tourOperator: PropTypes.shape({
            name: PropTypes.string,
        }).isRequired,
        payment: PropTypes.shape({
            type: PropTypes.oneOf([
                'TRANSFER',
                'DIRECT_DEBIT_SEPA',
                'CREDIT_CARD',
                'TRAVEL_AGENCY_CASHING', // deprecated but can't delete (old bookings support), TO-1755
                'CLARIFY_BY_PHONE',
                'IN_HOTEL',
            ]).isRequired,
        }),
    }).isRequired,
    updatedTourOperatorPrice: PropTypes.shape({
        total: PropTypes.number.isRequired,
        remainingDeposit: PropTypes.number.isRequired,
        remainingFinal: PropTypes.number.isRequired,
    }),
};

PaymentConditions.contextTypes = {
    getCurrentDate: PropTypes.func.isRequired,
};

export default PaymentConditions;
