import React from 'react';
import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import options from '@designsystem/options';

import RadioButtonGroup from '../../inputs/RadioButtonGroup';
import { SALUTATION_LABELS } from '../../../lib/salutations';

const Container = glamorous.fieldset({
    border: 0,
    padding: 0,
    margin: 0,
    marginTop: options.space.l,
});

const Legend = glamorous.legend({
    display: 'block',
    marginBottom: options.space.xs,
    fontFamily: options.fonts.default,
    fontWeight: 500,
    color: options.colors.gray20,
});

class SalutationSetting extends React.PureComponent {
    render() {
        const { salutation, onChange, disabled = false } = this.props;

        return h(Container, [
            h(Legend, 'Anrede'),
            h(RadioButtonGroup, {
                isInline: true,
                name: 'salutation',
                onChange,
                disabled,
                options: [
                    {
                        label: SALUTATION_LABELS.mr,
                        value: 'mr',
                    },
                    {
                        label: SALUTATION_LABELS.ms,
                        value: 'mrs',
                    },
                    {
                        label: 'Keine Angabe',
                        value: '',
                    },
                ],
                selectedValue: salutation,
            }),
        ]);
    }
}

SalutationSetting.propTypes = {
    salutation: PropTypes.oneOf(['mr', 'mrs', '']).isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

export default SalutationSetting;
