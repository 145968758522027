import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import { useState } from 'react';

import BaggageInformation from './BaggageInformation';
import ToggleIcon from '../ToggleIcon';
import { smallDistance } from '../../../../styles/distances';
import { FONTS, tinyFont } from '../../../../styles/fonts';
import { black, blue, gray } from '../../../../styles/waveColors';
import { pxToRem } from '../../../../styles/unitConverter';

const AirlineAndBaggageContainer = glamorous.div({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: smallDistance,
});
AirlineAndBaggageContainer.displayName = 'AirlineAndBaggageContainer';

const AirlineAndBaggageContent = glamorous.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '2.5rem',
});
AirlineAndBaggageContent.displayName = 'AirlineAndBaggageContent';

const AirlineInfo = glamorous.div({
    display: 'flex',
    alignItems: 'center',
    gap: smallDistance,
});
AirlineInfo.displayName = 'AirlineName';

const Airline = glamorous.div({
    ...tinyFont,
    color: black,
    '& img': {
        marginBottom: pxToRem(-2),
        marginRight: pxToRem(10),
    },
});
Airline.displayName = 'Airline';

const BaggageToggle = glamorous.button({
    color: blue,
    fontFamily: FONTS.primary,
    fontWeight: 500,
    textAlign: 'center',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
});
BaggageToggle.displayName = 'BaggageToggle';

const BaggageDottedLine = glamorous.div({
    position: 'absolute',
    top: '2rem',
    left: pxToRem(6),
    bottom: pxToRem(-19),
    width: '1px',
    borderLeft: `1px dashed ${gray}`,
});
BaggageDottedLine.displayName = 'BaggageDottedLine';

const AirlineAndBaggage = ({ flight, assetsPath }) => {
    const [isExpanded, setExpand] = useState(false);

    if (!flight.airlineCode) {
        return null;
    }
    return h(AirlineAndBaggageContainer, [
        h(AirlineAndBaggageContent, [
            h(AirlineInfo, [
                h('img', {
                    src: `${assetsPath}/myBookings/airplane.svg`,
                    alt: '',
                }),
                h(Airline, [
                    flight.airlineInformation &&
                        h('div', flight.airlineInformation.airlineName),
                    flight.airlineCode &&
                        `${flight.airlineCode} ${flight.flightNumber || ''}`,
                ]),
            ]),
            flight.baggageAllowance &&
                h(
                    BaggageToggle,
                    {
                        onClick: () => setExpand(!isExpanded),
                    },
                    [
                        h(
                            'span',
                            flight.baggageAllowance.freeBaggage
                                ? `Gepäck: ${flight.baggageAllowance.freeBaggage.adults} (p.P.)`
                                : 'Gepäck',
                        ),
                        h(ToggleIcon, { isExpanded }),
                    ],
                ),
        ]),
        isExpanded &&
            h(BaggageInformation, {
                baggageAllowance: flight.baggageAllowance,
            }),
        h(BaggageDottedLine),
    ]);
};

const flightTimeAndPlacePropType = PropTypes.shape({
    date: PropTypes.string,
    airportCode: PropTypes.string.isRequired,
    airportName: PropTypes.string,
});

AirlineAndBaggage.propTypes = {
    flight: PropTypes.shape({
        departure: flightTimeAndPlacePropType.isRequired,
        arrival: flightTimeAndPlacePropType.isRequired,
        airlineInformation: PropTypes.shape({
            airlineName: PropTypes.string.isRequired,
        }),
        airlineCode: PropTypes.string,
        flightNumber: PropTypes.string,
    }),
    assetsPath: PropTypes.string.isRequired,
};

export default AirlineAndBaggage;
