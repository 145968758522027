import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { oneLine } from 'common-tags';

import TextInput from '../inputs/TextInput';
import { pxToRem } from '../../styles/unitConverter';
import { applyOnTabletAndUp } from '../../styles/mediaQueries';
import Hint from './Hint';
import bankDataSchema from './BankDataFormValidation';

const InsertBankDataForm = glamorous.form({
    transition: 'height 5s',
    textAlign: 'initial',
});

const NameWrapper = glamorous.div({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'stretch',
});

const textInputStyles = {
    width: '100%',
    [applyOnTabletAndUp]: {
        width: `calc(50% - ${pxToRem(8)})`,
    },
};

const InfoWrapper = glamorous.div({
    paddingBottom: '20px',
});

const BANK_DATA_FIELDS = {
    accountHolder: 'accountHolder',
    iban: 'iban',
    bookingId: 'bookingId',
};

const InsertCustomerBankDataForm = ({
    bookingId,
    voucherExpirationDate,
    accountHolder,
    setAccountHolder,
    iban,
    setIban,
    errors,
    setErrors,
    showSubmitError,
}) => {
    const validateField = (name, value) => {
        return bankDataSchema[name]
            .validate(value)
            .then(() => {
                setErrors({ ...errors, [name]: null });
                return true;
            })
            .catch((e) => {
                if (e.errors) {
                    setErrors({ ...errors, [name]: e.errors[0] });
                }
                return false;
            });
    };

    return h(InsertBankDataForm, { onSubmit: (e) => e.preventDefault() }, [
        h(
            InfoWrapper,
            `Bitte teile uns Deine IBAN mit, damit wir den
        Gutscheinbetrag nach Deinem Urlaub direkt auf Dein Konto überweisen können.
        Die Angabe eines BIC bzw. einer SWIFT Nummer ist nicht erforderlich.`,
        ),
        h(
            Hint,
            {
                prefix: `Bitte beachte`,
                containerStyle: { marginTop: pxToRem(12) },
            },
            oneLine`Der Gutscheinbetrag wird
ca. 1 Woche nach Deiner Reiserückkehr überwiesen. Die Gutschrift kann ausschließlich an eine Bank
in Deutschland, in anderen EU-Ländern oder an eine Bank in der Schweiz erfolgen. Solltst Du Deine Kontodaten nicht
bis spätestens ${voucherExpirationDate} eingegeben haben, verfällt der Code.`,
        ),
        h(NameWrapper, [
            h(TextInput, {
                label: 'Kontoinhaber',
                name: BANK_DATA_FIELDS.accountHolder,
                onChange: (value) => {
                    setAccountHolder(value);
                    validateField(BANK_DATA_FIELDS.accountHolder, value);
                },
                value: accountHolder,
                onBlur: (value) =>
                    validateField(BANK_DATA_FIELDS.accountHolder, value),
                errorMessage:
                    Boolean(errors) &&
                    Boolean(errors.accountHolder) &&
                    errors.accountHolder,
                addFieldStyles: textInputStyles,
            }),
            h(TextInput, {
                label: 'IBAN',
                name: BANK_DATA_FIELDS.iban,
                onChange: (value) => {
                    setIban(value);
                    validateField(BANK_DATA_FIELDS.iban, value);
                },
                value: iban,
                onBlur: (value) => validateField(BANK_DATA_FIELDS.iban, value),
                errorMessage:
                    Boolean(errors) && Boolean(errors.iban) && errors.iban,
                addFieldStyles: textInputStyles,
            }),
        ]),
        h(NameWrapper, [
            h(TextInput, {
                label: 'HolidayCheck-Buchungsnummer',
                name: BANK_DATA_FIELDS.bookingId,
                disabled: true,
                value: bookingId,
                onChange: () => {},
                addFieldStyles: textInputStyles,
            }),
        ]),
        showSubmitError &&
            h(
                Hint,
                {
                    prefix: 'Fehler beim Speichern:',
                    type: 'error',
                },
                oneLine`Leider konnten wir Deine Bankdaten nicht speichern.
                        Bitte probiere es zu einem späteren Zeitpunkt nochmal.`,
            ),
    ]);
};

export default InsertCustomerBankDataForm;
