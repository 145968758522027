import h from 'react-hyperscript';
import glamorous from 'glamorous';
import * as R from 'ramda';

import AlbumMetaInfo from './AlbumMetaInfo';
import AlbumTitle from './AlbumTitle';
import AlbumItem from './AlbumItem';
import Row from '../grid/Row';

const Section = glamorous.section({
    paddingTop: '2.25rem',
    paddingBottom: '.25rem',
    '&:last-child': {
        paddingBottom: 0,
    },
});

const AlbumTiles = glamorous.div({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    margin: '1rem -1px 0 -1px',
});

const isType = R.propEq('entityType');

const buildHref = R.cond([
    [isType('hotel'), (e) => `/hi/${e.entityId}`],
    [isType('poi'), (e) => `/pi/${e.entityId}`],
    [isType('ship'), (e) => `/kreuzfahrten/schiff-from-mhc/${e.entityId}`],
    [isType('city'), (e) => `/dh/${e.entityId}`],
    [isType('region'), (e) => `/dh/${e.entityId}`],
    [isType('country'), (e) => `/di/${e.entityId}`],
    [isType('continent'), (e) => `/di/${e.entityId}`],
    [R.T, R.always(undefined)],
]);

const renderPictures = (pictures) =>
    pictures.map((picture) => h(AlbumItem, { picture }));

function Album({
    mightBeIncomplete = false,
    pictures,
    entityId,
    entityType,
    travelDate,
    entityName,
    pictureCount,
    rejectedPictureCount,
}) {
    return h(Row.withComponent(Section), [
        h(
            AlbumMetaInfo,
            {
                timestamp: travelDate,
                pictureCount,
                rejectedPictureCount,
                mightBeIncomplete,
            },
            [
                h(AlbumTitle, {
                    title: entityName,
                    link: entityId && buildHref({ entityId, entityType }),
                }),
            ],
        ),
        h(AlbumTiles, renderPictures(pictures)),
    ]);
}

export default Album;
