import { useParams } from 'react-router-dom';
import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { ArrowLeft } from '@designsystem/icons';
import options from '@designsystem/options';
import { Query } from '@apollo/client/react/components';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Booking from './Booking';
import { bookingForUser } from '../../queries/myBooking';
import TransitionScreen from '../navigation/TransitionScreen';
import { noticeError } from '../../lib/newrelic';
import { pxToRem } from '../../styles/unitConverter';
import { gray40 } from '../../styles/waveColors';
import Page from '../Page';
import trackEvent from '../../lib/trackEvent';

const BookingContainer = glamorous.div({
    display: 'block',
    marginTop: `${options.space['6xl']}px`,
    ':first-child': {
        marginTop: `${options.space['3xl']}px`,
    },
});

const TextButton = glamorous.a({
    textDecoration: 'none',
    color: options.colors.blue,
    display: 'flex',
    alignItems: 'center',
    marginTop: `${options.space.xl}px`,
    gap: options.space['2xs'],
    padding: `${options.space.m}px 0`,
});

const NoBookingsContainer = glamorous.div({
    textAlign: 'center',
    marginTop: pxToRem(80),
    marginBottom: pxToRem(80),
    '& img': {
        marginBottom: pxToRem(15),
    },
    '& div': {
        color: gray40,
        fontWeight: 'bold',
        width: pxToRem(252),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
});

const GoBackTextButton = () => {
    return h(TextButton, { href: '/mhc/meine-buchungen' }, [
        h(ArrowLeft),
        h('span', 'Zurück zur Buchungsliste'),
    ]);
};

const renderNoBookingFoundView = ({ assetsPath, window, bookingId }) => {
    if (window.hasConsentedToTrackEvents) {
        trackEvent({
            eventCategory: 'Premium-Club',
            eventAction: 'Booking-not-found',
            eventLabel: bookingId,
        });
    }

    return h(NoBookingsContainer, {}, [
        h('img', {
            src: `${assetsPath}/myBookings/no-bookings.svg`,
        }),
        h('div', {}, 'Die Buchung konnte nicht gefunden werden.'),
    ]);
};

const BookingView = (props, { document, window, config: { assetsPath } }) => {
    const { bookingId } = useParams();
    const [isValidBooking, setIsValidBooking] = useState(false);
    const [hasScrolledToInsuranceSection, setHasScrolledToInsuranceSection] =
        useState(false);

    useEffect(() => {
        const handleMessage = (event) => {
            if (event.data === 'tcfapi.useractioncomplete') {
                const section = document.getElementById('versicherung');

                if (section) section.scrollIntoView({ behavior: 'smooth' });
            }
        };
        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    useEffect(() => {
        if (
            !hasScrolledToInsuranceSection &&
            isValidBooking &&
            window.location.hash === '#versicherung'
        ) {
            const section = document.getElementById('versicherung');

            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
                setHasScrolledToInsuranceSection(true);
            }
        }
    }, [hasScrolledToInsuranceSection, isValidBooking]);

    return h(
        Page,
        {
            pagename: '/mhc/bookings/detail',
            headline: 'Meine Buchung',
        },
        [
            h(
                Query,
                {
                    fetchPolicy: 'cache-and-network',
                    query: bookingForUser,
                    variables: { bookingId },
                },
                ({ loading, data, error }) => {
                    if (loading) {
                        return h(TransitionScreen);
                    }

                    if (error || !data) {
                        noticeError(error && error.message, {
                            pageName: 'MyBooking',
                            component: 'BookingView',
                        });

                        return renderNoBookingFoundView({
                            assetsPath,
                            window,
                            bookingId,
                        });
                    }

                    setIsValidBooking(true);
                    return h('div', [
                        h(GoBackTextButton),
                        h(
                            BookingContainer,
                            { id: bookingId, key: bookingId },
                            h(Booking, {
                                index: 0,
                                booking: data.bookingForUser,
                                alwaysShowDetails: true,
                            }),
                        ),
                    ]);
                },
            ),
        ],
    );
};

BookingView.contextTypes = {
    document: PropTypes.object,
    window: PropTypes.object,
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

export default BookingView;
