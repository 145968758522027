import React from 'react';
import h from 'react-hyperscript';
import options from '@designsystem/options';
import { GlobeIcon } from '@designsystem/components';

import HeadingWithIcon from '../privacySettings/HeadingWithIcon';

class DataInformationHeader extends React.PureComponent {
    render() {
        return h(
            'div',
            {
                style: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                },
            },
            [
                h(
                    HeadingWithIcon,
                    {
                        icon: GlobeIcon,
                    },
                    'Deine persönlichen Daten',
                ),
                h(
                    'div',
                    { style: { fontSize: options.fontSizes.s } },
                    'Anderen Urlaubern wird nur Dein Vorname und Deine Altersgruppe angezeigt.',
                ),
            ],
        );
    }
}

export default DataInformationHeader;
