import glamorous from 'glamorous';

import { smallDistance } from '../../styles/distances';
import colors from '../../styles/colors';

const { linkColor, linkHoverColor } = colors;

export default glamorous.a({
    color: linkColor,
    cursor: 'pointer',
    lineHeight: 1,
    textDecoration: 'none',
    border: 0,
    background: 'transparent',

    ':hover': {
        color: linkHoverColor,
    },

    '> svg': {
        verticalAlign: 'bottom',
        width: smallDistance,
    },
});
