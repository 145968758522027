import h from 'react-hyperscript';

import Page from './Page';
import MietwagenHub from './myMietwagen/hub/MietwagenHub';

function MyMietwagenHub() {
    return h(
        Page,
        {
            pagename: '/mhc/mein-mietwagen',
            title: 'Meine Mietwagen',
        },
        [h(MietwagenHub)],
    );
}

export default MyMietwagenHub;
