import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import { pxToRem } from '../styles/unitConverter';
import { secondaryFont, FONTS } from '../styles/fonts';
import {
    blue,
    white,
    watermelon,
    blue20,
    watermelon40,
} from '../styles/waveColors';
import Spinner from './icons/Spinner';
import RightArrowLine from './icons/RightArrowLine';

const { UITextMLoud, UITextLLoud } = secondaryFont;

const variations = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    ACCENT: 'accent',
};

const sizes = {
    S: 's',
    M: 'm',
    L: 'l',
};

const variationStyles = {
    [variations.PRIMARY]: {
        backgroundColor: blue,
        color: white,
        borderColor: blue,
        '&:focus, &:hover, &:active': {
            '&:not([disabled])': {
                backgroundColor: blue20,
                borderColor: blue20,
            },
        },
    },
    [variations.SECONDARY]: {
        backgroundColor: white,
        color: blue,
        borderColor: blue,

        '&:focus, &:hover, &:active': {
            '&:not([disabled])': {
                color: blue20,
                borderColor: blue20,
            },
        },
    },
    [variations.ACCENT]: {
        backgroundColor: watermelon,
        color: white,
        borderColor: watermelon,

        '&:focus, &:hover, &:active': {
            '&:not([disabled])': {
                backgroundColor: watermelon40,
                borderColor: watermelon40,
            },
        },
    },
};

const sizeStyles = {
    [sizes.S]: {
        ...UITextMLoud,
        padding: `${pxToRem(10)} ${pxToRem(20)}`,
    },

    [sizes.M]: {
        ...UITextLLoud,
        padding: `${pxToRem(12)} ${pxToRem(24)}`,
    },
    [sizes.L]: {
        ...UITextLLoud,
        padding: `${pxToRem(16)} ${pxToRem(32)}`,
    },
};

const StyledButton = glamorous.button(({ variant, size, customStyles }) => ({
    textTransform: 'uppercase',
    borderRadius: pxToRem(40),
    border: '1px solid',
    fontFamily: FONTS.primary,
    transition: 'background-color 0.2s ease-in-out',

    '&:disabled': {
        cursor: 'not-allowed',
        opacity: 0.3,
    },

    ...variationStyles[variant],
    ...sizeStyles[size],
    ...customStyles,
}));

const ChildrenSpan = glamorous.span(
    ({ loading }) =>
        Boolean(loading) && {
            opacity: 0,
        },
);

const FlexContainer = glamorous.span({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

function WaveButton({
    onClick,
    type = 'button',
    disabled = false,
    size = sizes.S,
    variant = variations.PRIMARY,
    loading = false,
    withArrow = false,
    customStyles,
    children,
}) {
    return h(
        StyledButton,
        {
            onClick,
            type,
            disabled: disabled || loading,
            size,
            variant,
            customStyles,
            loading,
        },
        [
            h(FlexContainer, [
                h(ChildrenSpan, { loading }, children),
                withArrow &&
                    h(RightArrowLine, {
                        css: {
                            height: pxToRem(20),
                            marginLeft: pxToRem(8),
                            marginRight: pxToRem(-6),
                            verticalAlign: 'top',
                        },
                    }),
                h(Spinner, {
                    styles: {
                        display: loading ? 'block' : 'none',
                        position: 'absolute',
                    },
                }),
            ]),
        ],
    );
}

WaveButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf([sizes.S, sizes.M, sizes.L]),
    variant: PropTypes.oneOf([
        variations.PRIMARY,
        variations.SECONDARY,
        variations.ACCENT,
    ]),
    loading: PropTypes.bool,
    customStyles: PropTypes.object,
    children: PropTypes.string,
};

export { WaveButton, variations, sizes };
