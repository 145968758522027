import { useEffect, useState } from 'react';

import { fetchHcmKnowledgeHub } from '../../../../fetchers/fetchRentalcarHub';

export function useRentalcarHub(context, hubDestinationId) {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (!hubDestinationId) return;
        (async () => {
            const response = await fetchHcmKnowledgeHub(
                context,
                hubDestinationId,
            );
            setData(response);
        })();
    }, [hubDestinationId]);

    return data;
}
