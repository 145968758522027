import { Component } from 'react';
import PropTypes from 'prop-types';
import h from 'react-hyperscript';
import glamorous from 'glamorous';

import CloseIcon from '../icons/Close';
import colors from '../../styles/colors';
import { smallDistance, largeDistance } from '../../styles/distances';
import { applyOnTabletAndUp } from '../../styles/mediaQueries';
import { pxToRem } from '../../styles/unitConverter';

const { lightboxOverlayCloseColor } = colors;

const escapeKeyCode = 27;

const Button = glamorous.button({
    background: 'none',
    border: 0,
    boxSizing: 'content-box',
    cursor: 'pointer',
    height: pxToRem(24),
    padding: pxToRem(10),
    width: pxToRem(24),

    '&:hover': {
        color: lightboxOverlayCloseColor,
    },

    [applyOnTabletAndUp]: {
        padding: smallDistance,
        height: largeDistance,
        width: largeDistance,
    },
});

class KeyboardEnabledCloseButton extends Component {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
    }

    componentDidMount() {
        this.context.document.addEventListener('keyup', this.handleKeyUp);
    }

    componentWillUnmount() {
        this.context.document.removeEventListener('keyup', this.handleKeyUp);
    }

    close() {
        this.props.onClick();
    }

    handleKeyUp({ keyCode }) {
        if (keyCode === escapeKeyCode) {
            this.close();
        }
    }

    render() {
        return h(
            Button,
            {
                onClick: this.close,
            },
            [
                h(CloseIcon, {
                    css: {
                        display: 'block',
                        fill: lightboxOverlayCloseColor,
                        height: '100%',
                        width: '100%',
                    },
                }),
            ],
        );
    }
}

KeyboardEnabledCloseButton.contextTypes = {
    document: PropTypes.object,
};

export default KeyboardEnabledCloseButton;
