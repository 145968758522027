import h from 'react-hyperscript';
import glamorous from 'glamorous';

import Button from '../navigation/Button';
import LoadingAnimation from './LoadingAnimation';
import colors from '../../styles/colors';
import { xsmallDistance } from '../../styles/distances';
import { pxToRem } from '../../styles/unitConverter';

const { buttonHoverBackgroundColor } = colors;

const Container = glamorous.span({
    display: 'flex',
    justifyContent: 'center',
});

const IconContainer = glamorous.span({
    height: pxToRem(16),
    marginLeft: xsmallDistance,
    width: pxToRem(16),
});

const LoadingAnimationContainer = glamorous.div({
    alignItems: 'center',
    backgroundColor: buttonHoverBackgroundColor,
    bottom: 0,
    display: 'flex',
    left: 0,
    paddingTop: pxToRem(2),
    position: 'absolute',
    right: 0,
    top: 0,
});

const renderIcon = (icon) => h(IconContainer, [h(icon)]);
const renderLoadingAnimation = () =>
    h(LoadingAnimationContainer, [h(LoadingAnimation)]);

export default function LoadingButton({
    children,
    isInactive,
    isLoading,
    onClick,
    icon,
    type,
}) {
    return h(
        Button,
        {
            className: 'loadingButton',
            disabled: isLoading,
            isInactive,
            onClick,
            type,
        },
        [
            h(Container, [
                h('span', children),
                icon ? renderIcon(icon) : null,
                isLoading ? renderLoadingAnimation() : null,
            ]),
        ],
    );
}
