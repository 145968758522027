import { useState, useEffect } from 'react';

import isOnMobile from '../isOnMobile';

function useIsMobileDevice(window, checkIsMobile = isOnMobile) {
    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    return checkIsMobile(width);
}

export default useIsMobileDevice;
