import { Component } from 'react';
import PropTypes from 'prop-types';

class RedirectWithReload extends Component {
    componentDidMount() {
        this.context.location.replace(this.props.to);
    }

    render() {
        return null;
    }
}

RedirectWithReload.contextTypes = {
    location: PropTypes.object.isRequired,
};

export default RedirectWithReload;
