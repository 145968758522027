import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import PropTypes from 'prop-types';

import { SectionContainer, SectionTitle } from '../BookingDetails';
import { waveTextM } from '../../../../styles/waveText';
import { renderContactInfoLink } from './utils';
import { RetalCarBookingType } from '../../BookingPropType';

const Container = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    gap: `${options.space.xl}`,
});

const Text = glamorous.p({
    margin: 0,
    ...waveTextM,
});

function Cancelation({ myRef, sectionId }, { config: { assetsPath } }) {
    return h(SectionContainer, { id: sectionId, innerRef: myRef }, [
        h(Container, [
            h(SectionTitle, 'Mietwagen stornieren'),
            h(
                Text,
                'Du kannst Deinen Mietwagen bis 24 Stunden vor Anmietung kostenfrei stornieren. Ist Deine Anmietung in weniger als 3 Tagen, empfehlen wir Dir uns anzurufen.',
            ),
            h('div', [
                renderContactInfoLink(
                    'tel:+498914379153',
                    `${assetsPath}/myBookings/phone.svg`,
                    '+49 89 143 79 153',
                    'Telefonnummer',
                ),
                renderContactInfoLink(
                    'mailto:mietwagen@holidaycheck.com',
                    `${assetsPath}/myBookings/email.svg`,
                    'mietwagen@holidaycheck.com',
                    'E-Mail-Adresse',
                ),
            ]),
        ]),
    ]);
}

Cancelation.displayName = 'RentalcarCancellation';
Cancelation.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};
Cancelation.propTypes = {
    sectionId: PropTypes.string.isRequired,
    myRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any }),
    ]),
    booking: RetalCarBookingType.isRequired,
};
export { Cancelation };
