import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import {
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../styles/waveMediaQueries';
import { pxToRem } from '../../styles/unitConverter';
import {
    waveHeadline2XSSlim,
    waveHeadlineSSlim,
    waveHeadlineXSSlim,
} from '../../styles/waveHeadlines';
import { waveTextL } from '../../styles/waveText';
import PremiumPartner from './PremiumPartner';

const MainContainer = glamorous.div({
    maxWidth: pxToRem(1200),
    margin: '0 auto',
    padding: `0 ${pxToRem(options.space.l)}`,

    [`@media (min-width: ${options.breakpoints.s})`]: {
        padding: `0 ${pxToRem(options.space.xl)}`,
    },
    [`@media (min-width: ${options.breakpoints.m})`]: {
        padding: `0 ${pxToRem(options.space['2xl'])}`,
    },
});

const PartnerContainer = glamorous.div({
    display: 'flex',
    flexWrap: 'wrap',
    gap: `${pxToRem(options.space.xl)} 0`,
    [`@media (min-width: ${options.breakpoints.s})`]: {
        gap: `${pxToRem(options.space.xl)} ${pxToRem(options.space.m)}`,
    },
    [`@media (min-width: ${options.breakpoints.m})`]: {
        gap: `${pxToRem(options.space['2xl'])} ${pxToRem(options.space.xl)}`,
    },
});

const PremiumPartnersHeaderContainer = glamorous.div({
    maxWidth: pxToRem(770),
    marginLeft: 'auto',
    marginRight: 'auto',
    margin: `${pxToRem(options.space['6xl'])} auto ${pxToRem(options.space['3xl'])}`,

    [`@media (min-width: ${options.breakpoints.m})`]: {
        margin: `${pxToRem(options.space['7xl'])} auto ${pxToRem(options.space['5xl'])}`,
    },
});

const Title = glamorous.div({
    marginBottom: options.space.xl,
    ...waveHeadline2XSSlim,
    [waveSmMediaQuery]: {
        marginBottom: options.space['2xl'],
        ...waveHeadlineXSSlim,
    },
    [waveMdMediaQuery]: {
        ...waveHeadlineSSlim,
    },
    textAlign: 'center',
});

const Subtitle = glamorous.div({
    ...waveTextL,
    fontFamily: options.fonts.body,
    textAlign: 'center',
});

const enhanceVisualDisplay = (partner, index) =>
    index === 0 || index === 1 ? { ...partner, isHeroBenefit: true } : partner;

export default function PremiumPartners({ partners }) {
    return h(MainContainer, [
        h(PremiumPartnersHeaderContainer, [
            h(Title, 'Meine Partnervorteile'),
            h(
                Subtitle,
                'Du möchtest noch weitere Upgrades vor, während und sogar nach Deiner Reise? ' +
                    'Als Premium-Mitglied profitierst Du nicht nur von Reisegutscheinen. Wir haben für Dich auch tolle ' +
                    'Rabatte und Aktionen bei exklusiven Partnern. ',
            ),
        ]),
        h(
            PartnerContainer,
            partners.map((partner, index) =>
                h(PremiumPartner, {
                    benefit: enhanceVisualDisplay(partner, index),
                    key: index,
                }),
            ),
        ),
    ]);
}
