import { Component } from 'react';
import PropTypes from 'prop-types';
import h from 'react-hyperscript';
import glamorous from 'glamorous';
import * as glamor from 'glamor';

import Grid from '../grid/Grid';
import { overlayLayer } from '../../styles/layers';
import { smallDistance, xsmallDistance } from '../../styles/distances';
import { pxToRem, pxToMediaQueryEm } from '../../styles/unitConverter';
import colors from '../../styles/colors';
import ScrollPositionSpy from '../interactive/ScrollPositionSpy';
import BackToTopIcon from '../icons/BackToTop';
import AnimateMounting from '../interactive/AnimateMounting';

const {
    backToTopButtonBackgroundColor,
    backToTopButtonHoverBackgroundColor,
    linkColor,
} = colors;

const SCROLL_THRESHOLD_IN_PIXEL = 1200;
const hasScrolledAfterThreshold = (currentScroll) =>
    SCROLL_THRESHOLD_IN_PIXEL < currentScroll;

const slideUpFadeInAnimation = glamor.css.keyframes({
    '0%': {
        opacity: 0,
        transform: 'translate3d(0, 100px, 0)',
    },
    '100%': {
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
    },
});

const slideDownFadeOutAnimation = glamor.css.keyframes({
    '0%': {
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
    },
    '100%': {
        opacity: 0,
        transform: 'translate3d(0, 100px, 0)',
    },
});

const enterAnimation = `0.25s ease-in-out 0s 1 ${slideUpFadeInAnimation}`;
const exitAnimation = `0.25s ease-in-out 0s 1 ${slideDownFadeOutAnimation}`;

/* Media query min-width:
 *
 * +--------------+            |                                             |            +--------------+
 * | button width | + margin + |           Grid.gridWidths.desktop           | + margin + | button width |
 * +--------------+            +---------------------------------------------+            +--------------+
 */
const largeButtonWidth = 120;
const xsmallDistanceInPixel = 7;
const backToTopButtonLargeSizeMediaQuery = `@media (min-width: ${pxToMediaQueryEm(
    Grid.gridWidths.desktop + (largeButtonWidth + xsmallDistanceInPixel) * 2,
)})`;

const Button = glamorous.button({
    zIndex: overlayLayer,
    position: 'fixed',
    bottom: xsmallDistance,
    right: xsmallDistance,
    background: backToTopButtonBackgroundColor,
    color: linkColor,
    border: 'none',
    cursor: 'pointer',
    outline: 0,
    lineHeight: 1,
    fontFamily: 'inherit',
    padding: smallDistance,
    fontSize: 0,

    ':hover': {
        background: backToTopButtonHoverBackgroundColor,
    },

    [backToTopButtonLargeSizeMediaQuery]: {
        right: 'auto',
        left: '50%',
        marginLeft: `calc(${pxToRem(Grid.gridWidths.desktop / 2)} + ${xsmallDistance})`,
        fontSize: '1rem',
        padding: `${smallDistance} ${smallDistance} ${smallDistance} ${xsmallDistance}`,
        width: pxToRem(largeButtonWidth),
        boxSizing: 'border-box',
    },
});

const Content = glamorous.div({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
});

const iconStyles = {
    height: smallDistance,
};

const Label = glamorous.span({
    display: 'none',

    [backToTopButtonLargeSizeMediaQuery]: {
        display: 'block',
    },
});

class BackToTopButton extends Component {
    constructor(...args) {
        super(...args);

        this.toggleVisibility = this.toggleVisibility.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);
        this.state = { show: false };
    }

    toggleVisibility(currentScrollPosition) {
        const show = hasScrolledAfterThreshold(currentScrollPosition);

        return this.setState({ show });
    }

    scrollToTop() {
        this.context.window.scrollTo(0, 0);
    }

    renderButton() {
        return h(
            AnimateMounting,
            {
                show: this.state.show,
                animationOnMount: enterAnimation,
                animationOnUnmount: exitAnimation,
            },
            [
                h(
                    Button,
                    {
                        className: 'backToTopButton',
                        onClick: this.scrollToTop,
                    },
                    [
                        h(Content, [
                            h(BackToTopIcon, { css: iconStyles }),
                            h(Label, 'Nach oben'),
                        ]),
                    ],
                ),
            ],
        );
    }

    render() {
        const content = this.renderButton();

        return h(
            ScrollPositionSpy,
            { onChange: this.toggleVisibility },
            content,
        );
    }
}

BackToTopButton.contextTypes = {
    window: PropTypes.object.isRequired,
};

export default BackToTopButton;
