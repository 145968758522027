import glamorous from 'glamorous';
import h from 'react-hyperscript';
import options from '@designsystem/options';
import { Fragment, useRef } from 'react';

import { PaymentInformation } from './sections/PaymentInformation';
import getIndexItemTextForSection from '../../myBookings/lib/getIndexItemTextForSection';
import BookingMenu from './BookingMenu';
import { Rentalcar } from './sections/Rentalcar';
import { Files } from './sections/Files';
import { waveHeadline2XS } from '../../../styles/waveHeadlines';
import { Cancelation } from './sections/Cancelation';
import { RetalCarBookingType } from '../BookingPropType';
import {
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../../styles/waveMediaQueries';
import { ContactBox } from './sections/ContactBox';

const makeSection = (component) => ({
    component,
    indexEntryText: getIndexItemTextForSection(component.displayName),
    displayName: component.displayName,
    ref: useRef(null),
});

const DetailsContainer = glamorous.div({
    gridArea: 'content',
    margin: `0 ${options.space.xl}px`,
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${options.colors.gray90}`,
    [waveMdMediaQuery]: {
        margin: `0 ${options.space.xl}px 0 0`,
    },
});

export const SectionContainer = glamorous.div({
    paddingTop: `${options.space.xl}px`,
    [waveSmMediaQuery]: {
        paddingTop: `${options.space['3xl']}px`,
    },
});

export const SectionTitle = glamorous.h3({
    ...waveHeadline2XS,
    margin: 0,
});

const detailSections = [
    PaymentInformation,
    Rentalcar,
    Files,
    ContactBox,
    Cancelation,
];

function BookingDetails({ booking, campaigns }) {
    const sections = detailSections.map(makeSection);

    const indexEntries = [];
    const sectionEntries = [];

    for (const { component, ref, indexEntryText, displayName } of sections) {
        const sectionId = `${booking.id}-${displayName}`;
        sectionEntries.push(
            h(component, {
                booking,
                myRef: ref,
                sectionId,
                campaigns,
            }),
        );
        if (indexEntryText) {
            indexEntries.push({
                name: indexEntryText,
                myRef: ref,
                sectionId,
            });
        }
    }

    return h(Fragment, [
        h(BookingMenu, { entries: indexEntries }),
        h(DetailsContainer, sectionEntries),
    ]);
}

BookingDetails.propTypes = {
    booking: RetalCarBookingType.isRequired,
};

export default BookingDetails;
