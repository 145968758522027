import React from 'react';
import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import RadioButtonGroup from '../../inputs/RadioButtonGroup';

const Container = glamorous.fieldset({
    border: 0,
    padding: 0,
    margin: 0,
    marginTop: options.space.l,
    paddingTop: options.space.m,
});

class ProfileVisibilitySetting extends React.PureComponent {
    render() {
        const { onChange, profileVisibility, disabled = false } = this.props;

        return h(Container, [
            h(
                'div',
                {
                    style: {
                        fontWeight: options.fontWeights.bold,
                        fontSize: options.fontSizes.s,
                        color: options.colors.black,
                        marginBottom: options.space.xs,
                    },
                },
                'Wer darf Deine Profilseite aufrufen?',
            ),
            h(
                'div',
                {
                    style: {
                        color: options.colors.gray20,
                        fontSize: options.fontSizes.xs,
                        marginBottom: options.space.m,
                    },
                },
                'Ein Blick auf Deine Profilseite hilft anderen Urlaubern, Deine Beiträge besser einzuordnen.',
            ),
            h(RadioButtonGroup, {
                isInline: true,
                name: 'profile-visibility',
                onChange,
                options: [
                    {
                        label: 'Alle Urlauber',
                        value: 'everybody',
                    },
                    {
                        label: 'Niemand',
                        value: 'nobody',
                    },
                ],
                selectedValue: profileVisibility,
                disabled,
            }),
        ]);
    }
}

ProfileVisibilitySetting.propTypes = {
    profileVisibility: PropTypes.oneOf(['everybody', 'nobody']),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

export default ProfileVisibilitySetting;
