function formatRoomDescription(roomDescription) {
    return roomDescription
        .replace(/\W/g, function (str) {
            return `${str.charAt(0)} `;
        })
        .replace(/\s{2,}/g, ' ')
        .trim();
}

export default formatRoomDescription;
