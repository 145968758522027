import React from 'react';
import * as R from 'ramda';
import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import Column from '../../grid/Column';
import ClientSideLink from '../../navigation/ClientSideLink';
import { waveXsOnlyMediaQuery } from '../../../styles/waveMediaQueries';

const defaultToEmptyString = R.defaultTo('');

const Container = glamorous.div({
    marginTop: options.space.l,
    marginBottom: options.space.l,
    paddingBottom: options.space.m,
    paddingTop: options.space.m,
});

const ChangeEmailLinkBoxContent = glamorous.span({
    padding: options.space.m,
    [waveXsOnlyMediaQuery]: {
        padding: options.space.s,
    },
});

const EmailField = glamorous(ChangeEmailLinkBoxContent)({
    color: options.colors.black,
    [waveXsOnlyMediaQuery]: {
        borderBottom: `solid 1px ${options.colors.gray20}`,
    },
});

const ChangeEmailLinkBox = glamorous(ClientSideLink)(
    {
        background: options.colors.white,
        border: `solid 1px ${options.colors.gray90}`,
        borderRadius: options.radii.small,
        display: 'flex',
        justifyContent: 'space-between',
        lineHeight: options.lineHeights.m,
        [waveXsOnlyMediaQuery]: {
            textAlign: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        },

        ':hover svg': {
            background: options.colors.blue20,
            fill: options.colors.white,
        },
    },
    ({ disabled }) =>
        disabled && {
            cursor: 'not-allowed',
        },
);

function preventClick(event) {
    event.preventDefault();
}

class EmailSetting extends React.PureComponent {
    render() {
        const { email, disabled = false } = this.props;
        const extraProps = disabled ? { onClick: preventClick } : {};

        return h(Container, [
            h(Column, { small: null, medium: null, large: 6 }, [
                h(
                    'div',
                    {
                        style: {
                            fontWeight: options.fontWeights.mediumBold,
                            fontSize: options.fontSizes.s,
                            color: options.colors.black,
                            marginBottom: options.space.xs,
                        },
                    },
                    'E-Mail',
                ),
                h(
                    'div',
                    {
                        style: {
                            color: options.colors.gray20,
                            fontSize: options.fontSizes.xs,
                            marginBottom: options.space.s,
                        },
                    },
                    'An diese Adresse senden wir Dir alle Infos zu Deiner Buchung, Newsletter und Updates.',
                ),
                h(
                    ChangeEmailLinkBox,
                    {
                        to: '/mhc/mein-profil/email-aendern',
                        disabled,
                        ...extraProps,
                    },
                    [h(EmailField, defaultToEmptyString(email))],
                ),
            ]),
        ]);
    }
}

export default EmailSetting;
