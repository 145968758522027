import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import Countdown from '../Countdown';
import BookingStatus from './BookingStatus';
import BookingReviewStatus from './BookingReviewStatus/BookingReviewStatus';
import formatDate from '../../lib/formatDate';
import {
    getFormattedTimespan,
    hasEnded,
} from '../../../common/lib/bookingHelper';
import { secondaryFont } from '../../styles/fonts';
import { pxToRem } from '../../styles/unitConverter';
import { white } from '../../styles/waveColors';

const { headline2XS } = secondaryFont;

const BookingOverviewContainer = glamorous.div({
    textAlign: 'center',
});

const Status = glamorous.div(headline2XS, ({ shouldBeWhite }) => ({
    fontWeight: 'bold',
    margin: `0 0 ${pxToRem(2)} 0`,
    color: shouldBeWhite ? white : 'inherit',
}));

Status.displayName = 'Status';

const renderCancelledBookingStatus = (booking, assetsPath, shouldBeWhite) => {
    return h(BookingStatus, {
        imageSrc: `${assetsPath}/myBookings/cancelled${shouldBeWhite ? '-white' : ''}.svg`,
        element: h(Status, { shouldBeWhite }, 'Reise storniert'),
        text: getFormattedTimespan(booking, formatDate, 'DD.MM.Y'),
        shouldBeWhite,
    });
};

const renderOverview = (overviewProps) => {
    const {
        booking,
        isCancelled,
        assetsPath,
        shouldBeWhite,
        getCurrentDate,
        greeting,
    } = overviewProps;

    if (isCancelled) {
        return renderCancelledBookingStatus(booking, assetsPath, shouldBeWhite);
    }

    if (hasEnded(booking, getCurrentDate())) {
        return h(BookingReviewStatus, {
            booking,
            shouldBeWhite,
            assetsPath,
        });
    }

    return h(Countdown, { booking, shouldBeWhite, key: 123213, greeting });
};

const BookingOverview = (
    { booking, isCancelled, shouldBeWhite, greeting },
    { getCurrentDate, config: { assetsPath } },
) => {
    return h(BookingOverviewContainer, [
        renderOverview({
            booking,
            isCancelled,
            assetsPath,
            shouldBeWhite,
            getCurrentDate,
            greeting,
        }),
    ]);
};

BookingOverview.propTypes = {
    booking: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
        services: PropTypes.array.isRequired,
    }).isRequired,
    isCancelled: PropTypes.bool.isRequired,
    shouldBeWhite: PropTypes.bool.isRequired,
    greeting: PropTypes.string,
};

BookingOverview.contextTypes = {
    getCurrentDate: PropTypes.func.isRequired,
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

export default BookingOverview;
