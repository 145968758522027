import h from 'react-hyperscript';
import PropTypes from 'prop-types';

import Page from './Page';
import ProfilePictureTriggerQuery from './myHolidaycheck/ProfilePictureTriggerQuery';
import Dashboard from './myHolidaycheck/Dashboard';

function MyHolidaycheck(props, context) {
    const offerToBook = context.localStorage.getItem('offerToBook');

    if (offerToBook) {
        const parsedPath = JSON.parse(`${offerToBook}`);
        context.localStorage.removeItem('offerToBook');
        context.window.location.assign(parsedPath);

        return null;
    }

    return h(
        Page,
        {
            pagename: '/mhc/home',
            title: 'meinHolidayCheck - die ReiseCommunity',
        },
        [h(Dashboard), h(ProfilePictureTriggerQuery)],
    );
}

MyHolidaycheck.contextTypes = {
    localStorage: PropTypes.object.isRequired,
    window: PropTypes.object,
};

export default MyHolidaycheck;
