import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import { oneLine } from 'common-tags';
import { useNavigate } from 'react-router-dom';

import Page from './Page';
import ContentIframe from './ContentIframe';

const contentStylesheet = oneLine`
    #main {
        width: 100%;
        float: none;
    }

    #main > .mediaOrderRight {
        margin-top: 0;
    }

    #main > .mediaOrderLeft {
        width: auto;
    }
`;

function MyMessages(props, { location, elementModifiers }) {
    const {
        appendHiddenInput,
        injectStylesheet,
        removeAttribute,
        removeElement,
        replaceHrefBeginning,
        replaceInAttribute,
    } = elementModifiers;
    const navigate = useNavigate();

    return h(
        Page,
        {
            pagename: '/mhc/messages',
            headline: 'Meine Nachrichten',
        },
        [
            h(ContentIframe, {
                src: `/meineNachrichten.html${location.search}`,
                callMethodForElements: {
                    '#content > aside': [removeElement],
                    head: [injectStylesheet(contentStylesheet)],
                    'a[href]': [
                        replaceHrefBeginning(
                            '/meineNachrichten.html',
                            '/mhc/meine-nachrichten',
                        ),
                    ],
                    '[id^=category_Link_]': [removeAttribute('onclick')],
                    'form.mediaOrder.orderRight': [
                        appendHiddenInput('showWithinIframe', 'true'),
                    ],
                    '.mhcMessageItemSubject .defaultToggle, .mhcMessageItemSubject .defaultToggleText':
                        [
                            replaceInAttribute(
                                'onclick',
                                '/meineNachrichten.html?',
                                '/meineNachrichten.html?showWithinIframe=true&',
                            ),
                        ],
                },
                navigate,
            }),
        ],
    );
}

MyMessages.contextTypes = {
    location: PropTypes.object,
    elementModifiers: PropTypes.object,
};

export default MyMessages;
