export const extractActiveContract = (contracts) =>
    contracts.find((c) => c.contractStatus === 'Active');

export const extractExpiredContracts = (contracts) =>
    contracts.filter((c) => c.contractStatus === 'Expired');

export const extractValidContracts = (contracts) =>
    [
        extractActiveContract(contracts),
        ...extractExpiredContracts(contracts),
    ].filter(Boolean);

export const extractActivePeriod = (periods) =>
    periods.find((c) => c.periodStatus === 'Active');

export const extractFuturePeriod = (periods) =>
    periods.find((c) => c.periodStatus === 'Future');

export const extractExpiredPeriods = (periods) =>
    periods.filter((c) => c.periodStatus === 'Expired');

export const extractValidPeriods = (periods) =>
    [
        extractActivePeriod(periods),
        ...extractExpiredPeriods(periods),
        extractFuturePeriod(periods),
    ].filter(Boolean);
