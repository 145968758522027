import { useEffect, useState } from 'react';

import { fetchAllBookingIds } from '../../../fetchers/fetchRentalcarBookings';

export function useFetchBookingsAmount(context) {
    const [bookingsLength, setBookingsLength] = useState(0);

    useEffect(() => {
        (async () => {
            const response = await fetchAllBookingIds(context);
            setBookingsLength(response.length);
        })();
    }, []);

    return bookingsLength;
}
