import React from 'react';
import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import options from '@designsystem/options';
import glamorous from 'glamorous';

import Link from '../navigation/Link';
import { waveSmMediaQuery } from '../../styles/waveMediaQueries';

const MainBody = glamorous.div({
    fontSize: options.fontSizes.s,
    maxWidth: 310,
    textAlign: 'center',
    lineHeight: options.lineHeights.l,
    [waveSmMediaQuery]: {
        fontSize: options.fontSizes.xs,
    },
});
class DataProtectionNotice extends React.PureComponent {
    render() {
        const { topLevelDomain } = this.context.config;
        const dataProtectionPageUrl = `https://www.holidaycheck.${topLevelDomain}/datenschutz`;

        return h(MainBody, [
            h(
                Link,
                {
                    href: dataProtectionPageUrl,
                },
                'Informationen zum Datenschutz gemäß Art. 13 und 14 DSGVO',
            ),
        ]);
    }
}

DataProtectionNotice.contextTypes = {
    config: PropTypes.object,
};

export default DataProtectionNotice;
