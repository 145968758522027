import h from 'react-hyperscript';
import * as glamor from 'glamor';
import { oneLine } from 'common-tags';
import { default as glamorous } from 'glamorous';

import { white } from '../../styles/waveColors';
import { pxToRem } from '../../styles/unitConverter';

const loadingCirclesKeyframes = () => {
    const turn = glamor.css.keyframes({
        '0%': { transform: 'rotate(0deg)' },
        '100%': { transform: 'rotate(359deg)' },
    });

    return { animation: `${turn} 2s infinite linear` };
};

const IconSvg = glamorous.svg(
    loadingCirclesKeyframes,
    {
        height: pxToRem(24),
        width: pxToRem(24),
    },
    ({ styles }) => styles,
);

export default function Spinner({ styles }) {
    return h(
        IconSvg,
        {
            viewBox: '0 0 24 24',
            preserveAspectRatio: 'xMidYMid meet',
            xmlns: 'http://www.w3.org/2000/svg',
            styles,
        },
        [
            h('path', {
                opacity: '0.939858',
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                fill: white,
                d: oneLine`
    M12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 17.799 17.799 22.5 12 22.5C11.1716 22.5
    10.5 21.8284 10.5 21C10.5 20.1716 11.1716 19.5 12 19.5C16.1421 19.5 19.5 16.1421 19.5
    12C19.5 7.85786 16.1421 4.5 12 4.5C7.85786 4.5 4.5 7.85786 4.5 12C4.5 12.8284 3.82843
    13.5 3 13.5C2.17157 13.5 1.5 12.8284 1.5 12C1.5 6.20101 6.20101 1.5 12 1.5Z`,
            }),
        ],
    );
}
