import { stripIndent } from 'common-tags';

import graphqlFetch from '../lib/graphqlFetch';
import { defaultSelection as selection } from './fetchPicture';

export default async function fetchUnpublishedPictures(context) {
    const query = stripIndent`
        query MyTravelPicturesUnpublished {
            unpublishedPicturesForUser {
                items {
                    ${selection}
                }
                rejectedMedia {
                    travelDate
                    parent {
                        id
                        type
                    }
                    countRejected
                }
                total
            }
        }`;

    const response = await graphqlFetch(context, { query });

    if (response.ok) {
        const {
            data: { unpublishedPicturesForUser },
        } = await response.json();
        return unpublishedPicturesForUser;
    }

    return null;
}
