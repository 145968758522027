import h from 'react-hyperscript';
import glamorous from 'glamorous';
import * as R from 'ramda';

import colors from '../../styles/colors';
import { mediumDistance } from '../../styles/distances';
import { bigLoudFont } from '../../styles/fonts';
import { pxToRem } from '../../styles/unitConverter';

const {
    buttonHoverBackgroundColor,
    buttonHoverShadowColor,
    buttonHoverTextColor,
    buttonShadow,
    primaryBrandColor,
    secondaryBrandColor,
    secondaryButtonBackgroundColor,
    secondaryButtonShadowColor,
} = colors;

const StyledButton = glamorous.button(
    {
        ...bigLoudFont,

        backgroundColor: secondaryBrandColor,
        border: 0,
        borderRadius: 0,
        borderBottom: `${pxToRem(4)} solid ${buttonShadow}`,
        boxSizing: 'border-box',
        color: primaryBrandColor,
        cursor: 'pointer',
        fontFamily: 'inherit',
        minHeight: pxToRem(40),
        lineHeight: 1,
        margin: `0 ${pxToRem(10)}`,
        minWidth: pxToRem(172),
        padding: `${pxToRem(11)} ${mediumDistance} ${pxToRem(9)}`,
        position: 'relative',

        '&:focus, &:hover, &:active, &:disabled': {
            color: buttonHoverTextColor,
            backgroundColor: buttonHoverBackgroundColor,
            borderBottomColor: buttonHoverShadowColor,
        },

        '&:disabled': {
            cursor: 'not-allowed',
        },
    },
    ({ isInactive, isSecondary }) =>
        R.mergeAll([
            isInactive && {
                opacity: 0.5,

                '&:disabled': {
                    backgroundColor: secondaryBrandColor,
                    borderBottomColor: buttonShadow,
                    color: primaryBrandColor,
                },
            },

            isSecondary && {
                backgroundColor: secondaryButtonBackgroundColor,
                borderBottomColor: secondaryButtonShadowColor,
            },
        ]),
);

export default function Button({
    children,
    className,
    disabled = false,
    isInactive = false,
    isSecondary,
    onClick,
    type,
}) {
    return h(
        StyledButton,
        {
            className,
            disabled: disabled || isInactive,
            isInactive,
            isSecondary,
            onClick,
            type,
        },
        children,
    );
}
