import h from 'react-hyperscript';
import { useState, Fragment } from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import { blue, blue20 } from '../../styles/waveColors';
import { secondaryFont } from '../../styles/fonts';
import useBookingContext from './lib/useBookingContext';

const { PTSansFamily } = secondaryFont;

const Content = glamorous.span(PTSansFamily, {
    fontSize: '16px',
    display: 'inline',
    whiteSpace: 'pre-wrap',
});
Content.displayName = 'Content';

const ReadMoreButton = glamorous.button({
    color: blue,
    lineHeight: 1,
    border: 0,
    background: 'transparent',
    fontFamily: 'inherit',

    ':hover': {
        color: blue20,
    },
});

const renderReadMore = (
    content,
    maxInitialCharacters,
    handleReadMoreClick,
    showFullContent,
) => {
    const isCharactersLimitExceeded = content.length > maxInitialCharacters;

    if (isCharactersLimitExceeded && !showFullContent) {
        return h(
            ReadMoreButton,
            {
                onClick: handleReadMoreClick,
            },
            ' ... Weiter lesen',
        );
    }

    return null;
};

const ExpandableTextContent = ({
    eventAction,
    content,
    maxInitialCharacters,
}) => {
    const [showFullContent, setShowFullContent] = useState(false);
    const { trackEventWithBooking } = useBookingContext();
    const handleReadMoreClick = () => {
        setShowFullContent(!showFullContent);
        trackEventWithBooking({
            event: 'event',
            eventCategory: 'mybooking',
            eventAction,
        });
    };

    return h(Fragment, [
        h(
            Content,
            !showFullContent
                ? content.substring(0, maxInitialCharacters)
                : content,
        ),
        renderReadMore(
            content,
            maxInitialCharacters,
            handleReadMoreClick,
            showFullContent,
        ),
    ]);
};

ExpandableTextContent.prototype = {
    eventAction: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    maxInitialCharacters: PropTypes.number.isRequired,
};

export default ExpandableTextContent;
