import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { oneLine } from 'common-tags';

import colors from '../../styles/colors';

const { uploadCTABackgroundColor } = colors;

const Svg = glamorous.svg({
    display: 'inline-block',
    fill: 'currentColor',

    ':hover': {
        fill: 'currentColor',
    },
});

export default function UploadSmall() {
    return h(
        Svg,
        {
            viewBox: '0 0 72 59',
        },
        [
            h(
                'g',
                {
                    transform: oneLine`translate(-196, -935) translate(14, 336) translate(0, 57) translate(0, 528)
                translate(147, 0) translate(35, 14)`,
                    fill: 'none',
                    fillRule: 'evenodd',
                },
                [
                    h('rect', {
                        stroke: 'currentColor',
                        strokeWidth: 2,
                        x: 7,
                        y: 7,
                        width: 58,
                        height: 44,
                        rx: 4,
                    }),
                    h('rect', {
                        stroke: 'currentColor',
                        x: 10.5,
                        y: 10.5,
                        width: 51,
                        height: 37,
                        rx: 2,
                    }),
                    h('circle', {
                        fill: '#fff',
                        stroke: 'currentColor',
                        cx: 23.6231884,
                        cy: 21.7681159,
                        r: 4.63768116,
                    }),
                    h('g', [
                        h('path', {
                            fill: '#fff',
                            stroke: 'currentColor',
                            d: oneLine`M10.500001,44.952785 C10.500001,44.9766141 10.5000009,45.0061476 10.5000009,45.0431501
                        L10.500001,44.952785 Z M10.5000009,45.0431501 C10.5000008,45.0709493 10.5000007,45.102964
                        10.5000007,45.1399426 C10.5000002,45.5893336 10.5000002,45.5893336 10.5,46.4927536
                        C10.5,46.9050361 10.6482657,47.1274347 10.9192589,47.2629313 C11.0982259,47.3524147
                        11.2880849,47.3840579 11.3913043,47.3840579 L50.775361,47.384058 L50.7753623,34.6012991
                        L43.4534306,24.8277192 L31.1088394,40.9250744 L23.3391801,32.6700123 L10.5000009,45.0431501 Z`,
                        }),
                    ]),
                    h('g', [
                        h(
                            'g',
                            {
                                transform: 'translate(44.028986, 30.115942)',
                            },
                            [
                                h('circle', {
                                    fill: 'currentColor',
                                    cx: 12.9855072,
                                    cy: 12.9855072,
                                    r: 12.9855072,
                                }),
                                h('circle', {
                                    stroke: uploadCTABackgroundColor,
                                    strokeWidth: 2,
                                    cx: 12.9855072,
                                    cy: 12.9855072,
                                    r: 13.9855072,
                                }),
                            ],
                        ),
                        h('path', {
                            fill: '#fff',
                            transform: oneLine`translate(44.028986, 30.115942) translate(12.985507, 12.985507) rotate(-90)
                        translate(-12.985507, -12.985507)`,
                            d: oneLine`M12.9855072 14.8405797L7.42028986 14.8405797 7.42028986 11.1304348 12.9855072 11.1304348
                        12.9855072 8.34782609 18.5507246 12.9855072 12.9855072 17.6231884`,
                        }),
                    ]),
                ],
            ),
        ],
    );
}
