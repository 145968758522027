import glamorous from 'glamorous';
import h from 'react-hyperscript';
import * as R from 'ramda';
import options from '@designsystem/options';

import callIfFunction from '../../../../lib/callIfFunction';
import CountryButton from './CountryButton';
import FlagAustria from '../../../icons/FlagAustria';
import FlagGermany from '../../../icons/FlagGermany';
import FlagSwitzerland from '../../../icons/FlagSwitzerland';
import {
    UUID_AUSTRIA,
    UUID_GERMANY,
    UUID_SWITZERLAND,
} from '../../../../lib/countryUUIDs';

const List = glamorous.ul({
    background: options.colors.white,
    borderRadius: options.radii.small,
    display: 'flex',
    listStyle: 'none',
    flexDirection: 'column',
    padding: 0,
});

const selectedItemStyles = {
    border: `2px solid ${options.colors.blue}`,
    background: options.colors.blue100,
    '&:first-of-type': {
        marginLeft: 0,
    },
};
const Item = glamorous.li(
    {
        fontFamily: options.fonts.default,
        border: `1px solid ${options.colors.gray90}`,
        borderRadius: options.radii.small,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: options.space.s,
    },
    R.ifElse(
        R.propEq('isSelected', true),
        R.always(selectedItemStyles),
        R.always({}),
    ),
);

const countryUuidToIconMap = {
    [UUID_AUSTRIA]: h(FlagAustria),
    [UUID_GERMANY]: h(FlagGermany),
    [UUID_SWITZERLAND]: h(FlagSwitzerland),
};

const orderOfUUIDs = [UUID_GERMANY, UUID_AUSTRIA, UUID_SWITZERLAND];

const ensureMainCountryOrder = ({ id: idA }, { id: idB }) =>
    orderOfUUIDs.indexOf(idA) - orderOfUUIDs.indexOf(idB);

function renderMainCountryButtons({
    mainCountries,
    selectedCountryId,
    disabled,
    onChange,
}) {
    const sortedCountries = R.sort(ensureMainCountryOrder, mainCountries);

    return sortedCountries.map(({ id, name }) => {
        const isSelected = id === selectedCountryId;

        return h(Item, { isSelected }, [
            h(CountryButton, {
                disabled,
                icon: countryUuidToIconMap[id] || null,
                name,
                onClick() {
                    if (!isSelected) {
                        callIfFunction(onChange, [id]);
                    } else {
                        callIfFunction(onChange, [null]);
                    }
                },
            }),
        ]);
    });
}

export default function MainCountrySelect(props) {
    const {
        mainCountries,
        disabled,
        onChange,
        otherCountryForPreselection,
        selectedCountryId,
    } = props;

    if (!mainCountries) {
        return null;
    }

    const countryItems = renderMainCountryButtons(props);
    const isOtherCountry =
        selectedCountryId !== null &&
        !R.any(R.whereEq({ id: selectedCountryId }), mainCountries);

    const otherItem = h(
        Item,
        {
            isSelected: isOtherCountry,
        },
        [
            h(CountryButton, {
                disabled,
                hideIconOnTabletAndUp: true,
                icon: null,
                name: 'Alle weiteren Länder',
                onClick() {
                    if (isOtherCountry) {
                        callIfFunction(onChange, [null]);
                    } else {
                        callIfFunction(onChange, [
                            otherCountryForPreselection.id,
                        ]);
                    }
                },
            }),
        ],
    );

    return h(List, [...countryItems, otherItem]);
}
