import gql from 'graphql-tag';

export const categoriesData = gql`
    query ($draftBadgesEnabled: Boolean) {
        badgesForUser(limit: 1000) {
            items {
                id
                created
            }
        }
        availableBadges(draftBadgesEnabled: $draftBadgesEnabled) {
            items {
                id
                name
                description
                typeId
                archived
            }
        }
        badgeCategories {
            id
            name
            types {
                id
                headline
                description
                actionLink
                actionLabel
            }
        }
    }
`;
