import { useEffect, useState } from 'react';

import { fetchAllRentalCarBookings } from '../../../fetchers/fetchRentalcarBookings';

export function useRentalcarBookings(context) {
    const [bookings, setBookings] = useState([]);

    useEffect(() => {
        (async () => {
            const response = await fetchAllRentalCarBookings(context);
            setBookings(response);
        })();
    }, []);

    return bookings;
}
