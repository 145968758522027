import glamorous from 'glamorous';
import h from 'react-hyperscript';
import * as R from 'ramda';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from '@apollo/client/react/components';
import options from '@designsystem/options';

import SubmitButton from './SubmitButton';
import CityCountrySetting from './CityCountrySetting';
import ContactAllowedSetting from './ContactAllowedSetting';
import DateOfBirthSetting from './DateOfBirthSetting';
import EmailSetting from './EmailSetting';
import DataInformationHeader from './DataInformationHeader';
import AccountDeletionInformation from './AccountDeletionInformation';
import NameSetting from './NameSetting';
import ProfileVisibilitySetting from './ProfileVisibilitySetting';
import SalutationSetting from './SalutationSetting';
import MilesNumberSetting from './MilesNumberSetting';
import DataProtectionNotice from '../../legal/DataProtectionNotice';
import shallowObjectDiff from '../../../lib/shallowObjectDiff';
import FlashMessage from '../../flashMessage/FlashMessage';
import UnsavedPrompt from '../../dialog/UnsavedPrompt';
import { updateProfile as updateProfileMutation } from '../../../mutators/profile';
import {
    getMutationResult,
    isMutationDone,
    hasMutationError,
    wasMutationSuccessful,
} from '../../../lib/updateProfileMutation';
import { waveSmMediaQuery } from '../../../styles/waveMediaQueries';

const FlashMessageContainer = glamorous.div({
    display: 'flex',
    justifyContent: 'center',
    marginTop: options.space['2xl'],
});

const SectionInformation = glamorous.span({
    backgroundColor: options.colors.green100,
    color: options.colors.green40,
    borderRadius: options.radii.small,
    padding: options.space.xs,
    textTransform: 'uppercase',
    fontSize: options.fontSizes['2xs'],
    fontWeight: options.fontWeights.bold,
});

const Actions = glamorous.footer({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: options.space.m,
    marginTop: options.space['2xl'],
});

const BelowForumSections = glamorous.div({
    marginTop: options.space.xl,
    backgroundColor: options.colors.white,
    borderRadius: options.radii.small,
    boxShadow: options.shadows.raised,
    paddingBottom: options.space.l,
    [waveSmMediaQuery]: {
        marginTop: options.space['3xl'],
        paddingTop: options.space.l,
    },
});

const DataInformation = glamorous.div({
    margin: 'auto',
    marginTop: options.space['5xl'],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
});
const FormSection = glamorous.div({
    maxWidth: '90%',
    margin: 'auto',
    paddingTop: options.space.l,
});

const extractEditableSettings = R.omit(['email', 'profilePicture']);

const formatDateOfBirth = R.evolve({
    dateOfBirth: R.when(R.is(Date), (date) => date.toISOString().substr(0, 10)),
    salutation: R.when(R.equals(''), R.always(null)),
});

class SettingsForm extends Component {
    constructor(props, ...rest) {
        super(props, ...rest);

        const settings = extractEditableSettings(props.profile);

        this.state = {
            initialProfile: props.profile,
            settings,
            lastSavedSettings: settings,
            hasChanges: false,
            hasUserInteractedWithForm: false,
        };
        this.changes = {};

        this.updateSettingValue = this.updateSettingValue.bind(this);
        this.bindUpdateFunctions();

        this.submitProfileChanges = this.submitProfileChanges.bind(this);
        this.resetFormState = this.resetFormState.bind(this);
        this.blurSubmitButton = this.blurSubmitButton.bind(this);
    }

    bindUpdateFunctions() {
        const updateSettingByName = (name) => (value) =>
            this.updateSettingValue(name, value);

        this.updateSalutation = updateSettingByName('salutation');
        this.updateFamilyName = updateSettingByName('familyName');
        this.updateGivenName = updateSettingByName('givenName');
        this.updateDateOfBirth = updateSettingByName('dateOfBirth');
        this.updateCity = updateSettingByName('city');
        this.updateCountry = updateSettingByName('country');
        this.updateProfileVisibility = updateSettingByName('profileVisibility');
        this.updateContactAllowed = updateSettingByName('contactAllowed');
        this.updateMilesNumber = updateSettingByName('milesNumber');
    }

    getSettingValue(name, fallback = null) {
        return R.defaultTo(fallback, R.prop(name, this.state.settings));
    }

    resetFormState() {
        this.setState({
            lastSavedSettings: this.state.settings,
            hasChanges: false,
            hasUserInteractedWithForm: false,
        });
    }

    submitProfileChanges(event, updateProfile) {
        event.preventDefault();

        if (this.state.hasChanges) {
            updateProfile({
                variables: { input: formatDateOfBirth(this.changes) },
                update: this.resetFormState,
            });
        }
    }

    blurSubmitButton(event) {
        event.currentTarget.blur();
    }

    updateSettingValue(name, value) {
        let additionalChanges = {};

        if (name === 'country' && value === null) {
            additionalChanges = { city: null };
        }
        if (name === 'contactAllowed') {
            additionalChanges = { contactAllowed: value === 'urlaubers' };
        }

        const settings = R.mergeAll([
            this.state.settings,
            { [name]: value },
            additionalChanges,
        ]);
        this.changes = shallowObjectDiff(
            this.state.lastSavedSettings,
            settings,
        );

        this.setState({
            settings,
            hasChanges: !R.isEmpty(this.changes),
            hasUserInteractedWithForm: true,
        });
    }

    shouldRenderFlashMessage(mutationState) {
        if (!isMutationDone(mutationState)) {
            return false;
        }

        const { hasUserInteractedWithForm } = this.state;

        return (
            hasMutationError(mutationState) ||
            (wasMutationSuccessful(mutationState) && !hasUserInteractedWithForm)
        );
    }

    renderFlashMessage(mutationState) {
        if (!this.shouldRenderFlashMessage(mutationState)) {
            return null;
        }

        const { type, message } = hasMutationError(mutationState)
            ? {
                  type: 'error',
                  message:
                      'Etwas ist schiefgelaufen. ' +
                      'Bitte korrigiere falsche Eingaben und versuche es noch einmal.',
              }
            : {
                  type: 'success',
                  message: 'Deine Änderungen wurden erfolgreich gespeichert.',
              };

        return h(FlashMessageContainer, [h(FlashMessage, { type }, message)]);
    }

    render() {
        const email = R.pathOr(null, ['profile', 'email'], this.props);
        const isProfileDataLoading = R.isNil(this.state.initialProfile);

        return h(
            Mutation,
            { mutation: updateProfileMutation },
            (updateProfile, mutationState) => {
                const { loading } = mutationState;
                const disabled = isProfileDataLoading ? true : Boolean(loading);
                const formProps = {
                    noValidate: true,
                    onSubmit: (event) =>
                        this.submitProfileChanges(event, updateProfile),
                };

                return h('form', formProps, [
                    h(
                        'div',
                        {
                            style: {
                                backgroundColor: options.colors.white,
                                boxShadow: options.shadows.raised,
                                clipPath: 'inset(0px -10px -10px -10px)',
                                borderBottomLeftRadius: options.radii.small,
                                borderBottomRightRadius: options.radii.small,
                            },
                        },
                        [
                            h(FormSection, [
                                h(SectionInformation, 'Allgemeine Angaben'),
                                h(SalutationSetting, {
                                    salutation: this.getSettingValue(
                                        'salutation',
                                        '',
                                    ),
                                    onChange: this.updateSalutation,
                                    disabled,
                                }),
                                h(NameSetting, {
                                    familyName: this.getSettingValue(
                                        'familyName',
                                        '',
                                    ),
                                    givenName: this.getSettingValue(
                                        'givenName',
                                        '',
                                    ),
                                    onFamilyNameChange: this.updateFamilyName,
                                    onGivenNameChange: this.updateGivenName,
                                    disabled,
                                }),
                                h(DateOfBirthSetting, {
                                    dateOfBirth:
                                        this.getSettingValue('dateOfBirth'),
                                    onChange: this.updateDateOfBirth,
                                    disabled,
                                }),

                                h(CityCountrySetting, {
                                    city: this.getSettingValue('city'),
                                    country: this.getSettingValue('country'),
                                    countries: this.props.countries,
                                    onCityChange: this.updateCity,
                                    onCountryChange: this.updateCountry,
                                    disabled,
                                }),
                                h(MilesNumberSetting, {
                                    milesNumber:
                                        this.getSettingValue('milesNumber'),
                                    onChange: this.updateMilesNumber,
                                    initialMilesNumber:
                                        this.state.initialProfile &&
                                        this.state.initialProfile.milesNumber,
                                    disabled,
                                    mutationResult:
                                        getMutationResult(mutationState),
                                }),
                            ]),
                        ],
                    ),
                    h(BelowForumSections, [
                        h(FormSection, [
                            h(SectionInformation, 'Kontakt'),
                            h(EmailSetting, {
                                email,
                                disabled,
                            }),
                        ]),
                    ]),

                    h(BelowForumSections, [
                        h(FormSection, [
                            h(SectionInformation, 'Privatsphäre'),
                            h(ProfileVisibilitySetting, {
                                profileVisibility:
                                    this.getSettingValue('profileVisibility'),
                                onChange: this.updateProfileVisibility,
                                disabled,
                            }),
                            h(ContactAllowedSetting, {
                                contactAllowed:
                                    this.getSettingValue('contactAllowed'),
                                onChange: this.updateContactAllowed,
                                disabled,
                            }),
                        ]),
                    ]),
                    h(DataInformation, [
                        h(DataInformationHeader),
                        h(Actions, [
                            h(
                                SubmitButton,
                                {
                                    isLoading: Boolean(loading),
                                    type: 'submit',
                                    onClick: this.blurSubmitButton,
                                },
                                'Profil speichern',
                            ),
                        ]),
                        h(DataProtectionNotice),
                        this.renderFlashMessage(mutationState),
                    ]),
                    h(AccountDeletionInformation),
                    h(UnsavedPrompt, {
                        when: this.state.hasChanges,
                    }),
                ]);
            },
        );
    }
}

SettingsForm.getDerivedStateFromProps = (nextProps, prevState) => {
    if (!R.equals(nextProps.profile, prevState.initialProfile)) {
        const settings = extractEditableSettings(nextProps.profile);

        return {
            initialProfile: nextProps.profile,
            settings,
            lastSavedSettings: settings,
        };
    }

    return null;
};

SettingsForm.propTypes = {
    profile: PropTypes.object,
    countries: PropTypes.array,
};

export default SettingsForm;
