import h from 'react-hyperscript';
import { oneLine } from 'common-tags';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { useNavigate } from 'react-router-dom';

import ContentIframe from './ContentIframe';
import colors from '../styles/colors';

const { defaultBackgroundColor } = colors;

const StyledUgcContentIframe = glamorous.div({
    backgroundColor: defaultBackgroundColor,
});

const removeElementsSelector = oneLine`
    #category_Link_destinationinfos,
    #category_Link_pictures,
    #category_Link_pois,
    #content > aside,
    #main > div.box:nth-of-type(1)
`;
const contentStylesheet = oneLine`
    #main {
        width: 100%;
        float: none;
    }
    .reviewListMhc .reviewListMhcContent {
        width: calc(100% - 130px);
    }
    .listitemBox .separatorLight {
        width: 100%;
    }
    #hcContent {
        background-color: ${defaultBackgroundColor};
    }
`;

function UgcContentIframe({ location }, { elementModifiers }) {
    const navigate = useNavigate();
    const { injectStylesheet, removeElement, replaceHrefBeginning } =
        elementModifiers;

    return h(StyledUgcContentIframe, [
        h(ContentIframe, {
            src: `/meineBeitraege.html${location.search}`,
            callMethodForElements: {
                [removeElementsSelector]: [removeElement],
                head: [injectStylesheet(contentStylesheet)],
                'a[href]': [
                    replaceHrefBeginning(
                        '/meineBeitraege.html',
                        location.pathname,
                    ),
                ],
            },
            navigate,
        }),
    ]);
}

UgcContentIframe.contextTypes = {
    elementModifiers: PropTypes.object,
};

export default UgcContentIframe;
