import moment from 'moment';

/**
 * The first param should always be the current date from the global context
 * @param {*} currentDate
 * @param {*} date
 * @returns
 */
const isMoreThanTwoYearsInThePast = (currentDate, date) =>
    moment(currentDate).diff(moment(date), 'years', true) >= 2;

export default isMoreThanTwoYearsInThePast;
