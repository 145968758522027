import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { useQuery } from '@apollo/client';

import Hint from './Hint';
import FuturePeriodDiscountButton from './FuturePeriodDiscountButton';
import { clubOffers } from '../../queries/clubOffers';

const FuturePeriodOfferContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
});

const getPremiumExistingMemberOffer = ({ clubOffers: { items } }) =>
    items.find((item) => item.id === 'PREMIUM_EXISTING_MEMBER_CAMPAIGN');

export default function CampaignForMembers({ trackEventForPremium }) {
    const { data, loading, error } = useQuery(clubOffers);

    if (loading || error) return null;

    const premiumExistingMemberOffer = getPremiumExistingMemberOffer(data);

    if (!premiumExistingMemberOffer) return null;

    return h(FuturePeriodOfferContainer, [
        h(Hint, {
            type: 'premium',
            header: premiumExistingMemberOffer.detail.description.header,
            body: premiumExistingMemberOffer.detail.description.body,
        }),
        h(FuturePeriodDiscountButton, {
            trackingLabel: 'campaign-for-existing-member',
            trackEventForPremium,
            discountCode:
                premiumExistingMemberOffer.detail.campaign.discountProductCode,
            cta: premiumExistingMemberOffer.detail.description.cta,
        }),
    ]);
}
