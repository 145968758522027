import glamorous from 'glamorous';
import options from '@designsystem/options';

import { waveMdMediaQuery } from '../../../../styles/waveMediaQueries';

const ContentContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    gap: options.space.m,
    width: '100%',
    maxWidth: 894,
    margin: `0 auto ${options.space.l}px auto`,
    [waveMdMediaQuery]: {
        margin: `0 0 ${options.space.l}px 0`,
    },
});

export { ContentContainer };
