import { stripIndent } from 'common-tags';

import graphqlFetch from '../lib/graphqlFetch';
import hotelReviewData from './hotelReviewData';

export default async function fetchUnpublishedReviews(context) {
    const query = stripIndent`
        query MyReviewsUnpublished {
            unpublishedReviewsForUser {
                items {
                    ${hotelReviewData}
                }

            }
        }
    `;

    const response = await graphqlFetch(context, { query });

    if (response.ok) {
        const {
            data: { unpublishedReviewsForUser },
        } = await response.json();
        return unpublishedReviewsForUser;
    }

    return null;
}
