import React from 'react';
import h from 'react-hyperscript';
import glamorous from 'glamorous';
import * as PropTypes from 'prop-types';
import options from '@designsystem/options';

import TextInputWithDefaultValidation from '../../inputs/TextInputWithDefaultValidation';
import Column from '../../grid/Column';
import { waveSmMediaQuery } from '../../../styles/waveMediaQueries';

const LabelName = glamorous.div({
    color: options.colors.black,
    fontSize: options.fontSizes.s,
    fontWeight: options.fontWeights.mediumBold,
});

const LabelText = glamorous.div({
    color: options.colors.secondary,
    fontSize: options.fontSizes.xs,
    marginTop: options.space['2xs'],
});

const Container = glamorous.div({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',

    [waveSmMediaQuery]: {
        justifyContent: 'space-between',
    },
});

const renderHalfColumn = (children) =>
    h(
        Column,
        {
            small: null,
            medium: null,
            large: 4.5,
        },
        children,
    );

class NameSetting extends React.PureComponent {
    render() {
        const {
            givenName,
            familyName,
            onGivenNameChange,
            onFamilyNameChange,
            disabled = false,
        } = this.props;

        return h(Container, [
            renderHalfColumn([
                h(LabelName, 'Vorname'),
                h(LabelText, 'Andere Urlauber sehen nur Deinen Vornamen.'),
                h(TextInputWithDefaultValidation, {
                    name: 'givenName',
                    label: '',
                    placeholder: 'Vorname',
                    onChange: onGivenNameChange,
                    value: givenName,
                    disabled,
                }),
            ]),
            renderHalfColumn([
                h(LabelName, 'Nachname'),
                h(LabelText, 'Dein Nachname ist nur für Dich sichtbar.'),
                h(TextInputWithDefaultValidation, {
                    name: 'familyName',
                    label: '',
                    placeholder: 'Nachname',
                    onChange: onFamilyNameChange,
                    value: familyName,
                    disabled,
                }),
            ]),
        ]);
    }
}

NameSetting.propTypes = {
    givenName: PropTypes.string,
    familyName: PropTypes.string,
    onGivenNameChange: PropTypes.func,
    onFamilyNameChange: PropTypes.func,
    disabled: PropTypes.bool,
};

export default NameSetting;
