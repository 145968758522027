import glamorous from 'glamorous';
import h from 'react-hyperscript';
import options from '@designsystem/options';
import PropTypes from 'prop-types';

import { waveHeadline2XS } from '../../../styles/waveHeadlines';
import {
    waveTextS,
    waveTextSMedium,
    waveTextM,
} from '../../../styles/waveText';
import {
    waveSmMediaQuery,
    waveMdMediaQuery,
    waveMdOnlyMediaQuery,
    waveLgMediaQuery,
} from '../../../styles/waveMediaQueries';
import SkiingOutlineIcon from '../../icons/SkiingOutline';
import DivingOutlineIcon from '../../icons/DivingOutline';
import HotelOutlineIcon from '../../icons/HotelOutline';
import BeachIcon from '../../icons/Beach';
import AirplaneOutlineIcon from '../../icons/AirplaneOutline';
import SingleIcon from '../../icons/Single';
import GlobeIcon from '../../icons/Globe';
import WellnessIcon from '../../icons/Wellness';

const UserProfileCardContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    margin: `${options.space.xl}px ${options.space.l}px`,
    [waveMdMediaQuery]: {
        margin: `${options.space.xl}px ${options.space.m}px`,
    },
    [waveLgMediaQuery]: {
        margin: `${options.space.xl}px ${options.space.l}px`,
    },
});

const ProfilePictureContainer = glamorous.div({
    ...waveHeadline2XS,
    marginBottom: `${options.space.l}px`,
});

const ProfilePicture = glamorous.img({
    ...waveHeadline2XS,
    width: `${options.space['7xl']}px`,
    height: `${options.space['7xl']}px`,
    borderRadius: '50%',
});

const FullName = glamorous.span({
    ...waveHeadline2XS,
    overflowWrap: 'anywhere',
});
const Country = glamorous.span({
    ...waveTextM,
    marginTop: `${options.space['2xs']}px`,
});

const DetailsHeadline = glamorous.span({
    ...waveTextSMedium,
    display: 'flex',
    marginBottom: `${options.space.xs}px`,
});

const DetailsContainer = glamorous.div({
    marginTop: `${options.space.l}px`,
    marginBottom: `${options.space.l}px`,
});

const DetailsItemContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    [waveSmMediaQuery]: {
        flexDirection: 'row',
    },
    [waveMdMediaQuery]: {
        flexDirection: 'column',
    },
});

const DetailsItemWrapper = glamorous.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: `${options.space.xs}px`,
    [waveSmMediaQuery]: {
        marginBottom: 0,
        marginRight: `${options.space.xs}px`,
        '&:last-child': {
            marginRight: 0,
        },
    },
    [waveMdMediaQuery]: {
        marginRight: 0,
        marginBottom: `${options.space.xs}px`,
    },
    '&:last-child': {
        marginBottom: 0,
    },
});

const DetailsItemIconWrapper = glamorous.span({
    width: `${options.space.m}px`,
    height: `${options.space.m}px`,
    marginRight: `${options.space['2xs']}px`,
});
const DetailsItemTitle = glamorous.span({
    ...waveTextS,
});

const Button = glamorous.a([
    {
        ...waveTextM,
        color: options.colors.link,
        borderColor: options.colors.link,
        textDecoration: 'none',
        height: options.space['3xl'],
        width: 218,
        alignItems: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: options.radii.full,
        cursor: 'pointer',
        display: 'inline-flex',
        flexGrow: 0,
        flexShrink: 0,
        fontWeight: 'bold',
        justifyContent: 'center',
        textTransform: 'uppercase',
        transition: 'all 0.3s ease',
        background: 'transparent',
        '&:not(:disabled):hover': {
            background: options.colors.white,
            borderColor: options.colors.blue20,
            color: options.colors.blue20,
        },
        '&:focus': {
            color: options.colors.blue,
            outlineOffset: 'unset',
        },
        [waveMdMediaQuery]: {
            flexBasis: 'auto',
            width: 164,
            order: 1,
        },
        [waveLgMediaQuery]: {
            width: 218,
        },
    },
]);

const ButtonTextDefault = glamorous.span({
    display: 'block',
    [waveMdOnlyMediaQuery]: {
        display: 'none',
    },
});

const ButtonTextMedium = glamorous.span({
    display: 'none',
    [waveMdOnlyMediaQuery]: {
        display: 'block',
    },
});

const VACATION_TYPES = [
    { text: 'Ski & Wandern', id: 'skiAndHiking', icon: SkiingOutlineIcon },
    {
        text: 'Tauchen & Schnorcheln',
        id: 'divingAndSnorkeling',
        icon: DivingOutlineIcon,
    },
    { text: 'Städtetrip', id: 'city', icon: HotelOutlineIcon },
    { text: 'Strandurlaub', id: 'beach', icon: BeachIcon },
    { text: 'Fernreise', id: 'longDistance', icon: AirplaneOutlineIcon },
    { text: 'Kurzurlaub', id: 'shortTrip', icon: SingleIcon },
    { text: 'Rundreise', id: 'round', icon: GlobeIcon },
    { text: 'Wellnesss', id: 'wellness', icon: WellnessIcon },
];

function getFullName(givenName, familyName) {
    return !givenName && !familyName
        ? 'Urlauber'
        : `${givenName || ''}${givenName && familyName ? ' ' : ''}${familyName || ''}`;
}

const UserProfileCard = (
    { profile, travelPreferencesForUser },
    { trackEvent },
) => {
    const { givenName, familyName, countryName, profilePicture } = profile;
    const { vacationTypes } = travelPreferencesForUser;

    const fullName = getFullName(givenName, familyName);

    const filteredVacationTypes = VACATION_TYPES.filter(
        (type) => vacationTypes[type.id] === true,
    );

    const visibleItems = filteredVacationTypes.slice(0, 3);
    const hiddenItemsCount = filteredVacationTypes.length - 3;

    return h(UserProfileCardContainer, [
        h(ProfilePictureContainer, [
            h(ProfilePicture, {
                src: profilePicture.transformations.medium,
            }),
        ]),
        h(FullName, fullName),
        h(Country, countryName),
        filteredVacationTypes.length > 0
            ? h(DetailsContainer, [
                  h(
                      DetailsHeadline,
                      'Du magst am liebsten diese Art von Urlaub:',
                  ),
                  h(DetailsItemContainer, [
                      visibleItems.map((item, index) =>
                          h(
                              DetailsItemWrapper,
                              { key: `detailsItem-${index}` },
                              [
                                  h(DetailsItemIconWrapper, [
                                      h(
                                          item.icon,
                                          {
                                              css: {
                                                  width: '100%',
                                                  height: '100%',
                                              },
                                          },
                                          item.text,
                                      ),
                                  ]),
                                  h(DetailsItemTitle, item.text),
                              ],
                          ),
                      ),
                      hiddenItemsCount > 0 && [
                          h(DetailsItemWrapper, { key: 'hiddenItems' }, [
                              h(
                                  DetailsItemTitle,
                                  `+${hiddenItemsCount} Urlaubsarten`,
                              ),
                          ]),
                      ],
                  ]),
              ])
            : h(DetailsContainer, [
                  h(DetailsItemWrapper, { key: 'defaultDescriptionText' }, [
                      h(
                          DetailsItemTitle,
                          {
                              style: { color: options.colors.secondary },
                          },
                          `In Deinen Einstellungen kannst Du Dein Profil ausfüllen, Deine Reisevorlieben angeben und Dich für den Newsletter anmelden.`,
                      ),
                  ]),
              ]),
        h(
            Button,
            {
                href: '/mhc/mein-profil',
                onClick: () =>
                    trackEvent({
                        event: 'event',
                        eventCategory: 'mHCHub',
                        eventAction: 'gotoprofile',
                        eventLabel: 'Meine Einstellungen',
                    }),
            },
            [
                h(ButtonTextDefault, 'Meine Einstellungen'),
                h(ButtonTextMedium, 'Einstellungen'),
            ],
        ),
    ]);
};

UserProfileCard.propTypes = {
    profile: PropTypes.shape({
        givenName: PropTypes.string,
        familyName: PropTypes.string,
        countryName: PropTypes.string,
        profilePicture: PropTypes.shape({
            transformations: PropTypes.shape({
                medium: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    travelPreferencesForUser: PropTypes.shape({
        vacationTypes: PropTypes.shape({
            beach: PropTypes.bool,
            city: PropTypes.bool,
            divingAndSnorkeling: PropTypes.bool,
            longDistance: PropTypes.bool,
            round: PropTypes.bool,
            shortTrip: PropTypes.bool,
            skiAndHiking: PropTypes.bool,
            wellness: PropTypes.bool,
        }).isRequired,
    }).isRequired,
};

UserProfileCard.contextTypes = {
    trackEvent: PropTypes.func,
};

export default UserProfileCard;
