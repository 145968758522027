import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { Component } from 'react';
import PropTypes from 'prop-types';

import StyledLink from '../navigation/Link';

const InvisibleInput = glamorous.input({
    display: 'none',
});

const LabelLink = StyledLink.withComponent('label');

class ProfilePictureFileInput extends Component {
    constructor(...args) {
        super(...args);

        this.propagateSelectedFile = this.propagateSelectedFile.bind(this);
    }

    propagateSelectedFile(event) {
        event.preventDefault();

        const reader = new this.context.window.FileReader();
        reader.onload = (image) => {
            const base64 = image.target.result;

            this.props.onChange({
                profilePicture: base64,
            });
        };
        reader.readAsDataURL(event.target.files[0]);
    }

    render() {
        return h(LabelLink, [
            this.props.label,
            h(InvisibleInput, {
                type: 'file',
                accept: 'image/jpeg, image/png',
                onChange: this.propagateSelectedFile,
            }),
        ]);
    }
}

ProfilePictureFileInput.propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

ProfilePictureFileInput.contextTypes = {
    window: PropTypes.object,
};

export default ProfilePictureFileInput;
