import { Component } from 'react';
import PropTypes from 'prop-types';
import h from 'react-hyperscript';
import glamorous from 'glamorous';
import pica from 'pica/dist/pica';
import { Mutation } from '@apollo/client/react/components';
import { Navigate } from 'react-router-dom';
import options from '@designsystem/options';

import AvatarEditor from '../avatarEditor/AvatarEditor';
import DataProtectionNotice from '../legal/DataProtectionNotice';
import CancelButton from './CancelButton';
import FlashMessage from '../flashMessage/FlashMessage';
import LoadingButton from '../loading-button/LoadingButton';
import { waveXsOnlyMediaQuery } from '../../styles/waveMediaQueries';
import { updateProfile as updateProfileMutation } from '../../mutators/profile';
import {
    hasMutationError,
    wasMutationSuccessful,
} from '../../lib/updateProfileMutation';

const ButtonControl = glamorous.div({
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    alignItems: 'center',

    [waveXsOnlyMediaQuery]: {
        flexDirection: 'column',
    },
});

const CancelButtonContainer = glamorous.div({
    [waveXsOnlyMediaQuery]: {
        marginTop: options.space.s,
    },
});

const EditorContainer = glamorous.div({
    marginBottom: options.space.l,
});

const FlashMessageContainer = glamorous.div({
    marginBottom: options.space.s,
});

class ProfilePictureForm extends Component {
    constructor(...args) {
        super(...args);
        const { profilePictureStore, window } = this.context;

        this.state = {
            storeContent: profilePictureStore.get(window),
            didResizePictureFail: false,
        };
    }

    unsetPictureAndNavigate() {
        const { profilePictureStore, window } = this.context;

        profilePictureStore.unset(window);

        return h(Navigate, { to: '/mhc/mein-profil', replace: true });
    }

    async updateProfilePicture(event, updateProfile) {
        event.preventDefault();
        const { resizePicture, window } = this.context;

        if (this.editor) {
            try {
                const scaledProfilePicture = await resizePicture(
                    { pica, window },
                    this.editor.getImage(),
                    200,
                    200,
                );
                const profilePicture = scaledProfilePicture.toDataURL();

                updateProfile({ variables: { input: { profilePicture } } });
            } catch (error) {
                this.setState({ didResizePictureFail: true });
            }
        }
    }

    renderEditor() {
        const { storeContent } = this.state;

        return h(EditorContainer, [
            h(AvatarEditor, {
                editorRef: (e) => {
                    this.editor = e;
                },
                image: (storeContent && storeContent.profilePicture) || null,
            }),
        ]);
    }

    renderFlashMessage(mutationState) {
        const { didResizePictureFail } = this.state;
        const shouldRenderMessage =
            hasMutationError(mutationState) || didResizePictureFail;

        return h(FlashMessageContainer, [
            shouldRenderMessage
                ? h(
                      FlashMessage,
                      { type: 'error' },
                      'Entschuldigung, etwas ist schiefgelaufen.',
                  )
                : null,
        ]);
    }

    render() {
        return h('form', [
            this.renderEditor(),
            h(
                Mutation,
                { mutation: updateProfileMutation },
                (updateProfile, mutationState) => {
                    if (wasMutationSuccessful(mutationState)) {
                        return this.unsetPictureAndNavigate();
                    }

                    return h([
                        this.renderFlashMessage(mutationState),
                        h(DataProtectionNotice),
                        h(ButtonControl, [
                            h(
                                LoadingButton,
                                {
                                    onClick: async (event) =>
                                        this.updateProfilePicture(
                                            event,
                                            updateProfile,
                                        ),
                                    isLoading: mutationState.loading,
                                    type: 'submit',
                                },
                                ['Speichern'],
                            ),
                            h(CancelButtonContainer, [h(CancelButton)]),
                        ]),
                    ]);
                },
            ),
        ]);
    }
}

ProfilePictureForm.contextTypes = {
    fetch: PropTypes.func,
    config: PropTypes.object,
    window: PropTypes.object,
    profilePictureStore: PropTypes.object,
    resizePicture: PropTypes.func,
};

export default ProfilePictureForm;
