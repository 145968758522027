import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function UpArrowLine(options = {}) {
    return h(Icon, options, [
        h('path', {
            d: oneLine`
        M16.02 13.583L8.541 21.06a1.34 1.34 0 01-1.887 -.012l-.905 -.906a1.324 1.324 0
        01-.013 -1.887l9.345 -9.344a1.314 1.314 0 01.93 -.383 1.291 1.291 0 01.945
        .383l9.345 9.344a1.34 1.34 0 01-.013 1.887l-.905 .906a1.324 1.324 0 01-1.887
        .012l-7.478 -7.477z`,
        }),
    ]);
}
