const MIME_TYPE_JPEG = 'image/jpeg';
const MIME_TYPE_PNG = 'image/png';

const getMimeType = (dataURI) => {
    const match = dataURI.match(/^data:(.*);base64,/);

    return match ? match[1] : '';
};

const isJPEG = (dataURI) => getMimeType(dataURI) === MIME_TYPE_JPEG;
const isPNG = (dataURI) => getMimeType(dataURI) === MIME_TYPE_PNG;

export default {
    getMimeType,
    isJPEG,
    isPNG,
};
