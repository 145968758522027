/* global performance */

/* eslint-disable no-bitwise */

function v4() {
    /* eslint-disable-next-line no-restricted-syntax */
    let date = Date.now();

    if (
        typeof performance !== 'undefined' &&
        typeof performance.now === 'function'
    ) {
        date += performance.now();
    }

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (char) {
            const result = (date + Math.random() * 16) % 16 | 0;
            date = Math.floor(date / 16);
            return (char === 'x' ? result : (result & 0x3) | 0x8).toString(16);
        },
    );
}

export { v4 };
