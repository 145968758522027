import { useEffect, useState } from 'react';

import fetchUpcomingTravel from '../../../../fetchers/fetchUpcomingTravel';

export function useUpcomingTravel(context) {
    const [upcomingTravel, setUpcomingTravel] = useState([]);

    useEffect(() => {
        (async () => {
            const response = await fetchUpcomingTravel(context);
            setUpcomingTravel(response);
        })();
    }, []);

    return upcomingTravel;
}
