import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import formatDate from '../../../../lib/formatDate';
import { smallDistance, xsmallDistance } from '../../../../styles/distances';
import { tinyFont } from '../../../../styles/fonts';
import { pxToRem } from '../../../../styles/unitConverter';
import { gray100, gray90, orange40 } from '../../../../styles/waveColors';
import { getFormattedAirportName } from '../../lib/airport';
import { getDuration } from '../../lib/dateFormatting';

const StopContainer = glamorous.div({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: smallDistance,
    padding: `${xsmallDistance} 0 ${smallDistance}`,
});
StopContainer.displayName = 'StopContainer';

const StopContent = glamorous.div({
    ...tinyFont,
    padding: pxToRem(16),
    backgroundColor: gray100,
    marginLeft: pxToRem(16),
    marginRight: `-${smallDistance}`,
});
StopContent.displayName = 'StopContent';

const StopLine = glamorous.div({
    position: 'absolute',
    top: '2px',
    bottom: pxToRem(-6),
    width: 0,
    borderLeft: `1px dashed ${gray90}`,
    marginLeft: pxToRem(6),
});
StopLine.displayName = 'StopLine';

const DateInfo = glamorous.div({
    ...tinyFont,
    color: orange40,
    marginLeft: pxToRem(24),
});
DateInfo.displayName = 'DateInfo';

const isSameDay = (startDate, arrivalDate) => {
    return (
        formatDate(startDate, 'dd. Do MMMM') ===
        formatDate(arrivalDate, 'dd. Do MMMM')
    );
};

const Stop = ({ flight, nextFlight, startDate }) => {
    const airportCodes = [];
    if (nextFlight) {
        airportCodes.push(getFormattedAirportName(flight.arrival));
        if (nextFlight.departure.airportCode !== flight.arrival.airportCode) {
            airportCodes.push(getFormattedAirportName(nextFlight.departure));
        }
    }

    const hasAirportCodes = Boolean(airportCodes.length);

    return h(StopContainer, [
        hasAirportCodes && h(StopLine),
        !isSameDay(startDate, flight.arrival.date) &&
            h(
                DateInfo,
                `Ankunft ${formatDate(flight.arrival.date, 'dd. Do MMMM')}`,
            ),
        hasAirportCodes &&
            h(StopContent, [
                h(
                    'b',
                    `${getDuration(flight.arrival.date, nextFlight.departure.date)} `,
                ),
                `Aufenthalt in ${airportCodes.join(' / ')}`,
            ]),
    ]);
};

const FlightPropType = PropTypes.shape({
    date: PropTypes.string,
    airportName: PropTypes.string,
    airportCode: PropTypes.string,
});

Stop.propTypes = {
    flight: FlightPropType.isRequired,
    nextFlight: FlightPropType,
    startDate: PropTypes.string,
};

export default Stop;
