import h from 'react-hyperscript';
import glamorous from 'glamorous';
import moment from 'moment';
import options from '@designsystem/options';

import { premiumGradient, premiumGradientHover } from '../../styles/waveColors';
import {
    waveFontM,
    waveHeadline2XSSlim,
    waveHeadline3xl,
} from '../../styles/waveHeadlines';
import { pxToRem } from '../../styles/unitConverter';
import { waveTextMBold, waveTextMButton } from '../../styles/waveText';
import {
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../styles/waveMediaQueries';
import { usePremiumTrackingContext } from './context/PremiumTrackingContext';
import useInScreen from '../../lib/hooks/useInScreen';
import { CASHBACK_TYPES, parseCashbackItem } from '../../lib/premium/cashback';

const CashbackItemContainer = glamorous.div({
    boxShadow: options.shadows.raised,
    borderRadius: pxToRem(options.space.xs),
    overflow: 'hidden',
    flex: '1 0 100%',
    maxWidth: pxToRem(384),
    display: 'flex',
    flexDirection: 'column',
    [waveSmMediaQuery]: {
        flex: '1 0 40%',
    },
    [waveMdMediaQuery]: {
        flex: '1 0 33%',
    },
});

const CashbackItemValueContainer = glamorous.div(({ cashbackItemType }) => ({
    background:
        cashbackItemType === CASHBACK_TYPES.CAR_RENTAL_REGULAR
            ? `linear-gradient(90deg, ${options.colors.green} 0%, ${options.colors.green40} 100%)`
            : premiumGradient,
    color: options.colors.white,
    textAlign: 'center',
    padding: `${pxToRem(options.space.m)} ${pxToRem(options.space.m)} ${pxToRem(options.space.xl)}`,
    [waveSmMediaQuery]: {
        padding: `${pxToRem(options.space.m)} ${pxToRem(options.space.m)} ${pxToRem(options.space.l)}`,
    },
    [waveMdMediaQuery]: {
        padding: `${pxToRem(options.space.m)} ${pxToRem(options.space.xl)} ${pxToRem(options.space['2xl'])}`,
    },
}));

const CashbackItemValue = glamorous.div({
    ...waveHeadline3xl,
});

const CashbackItemPrefix = glamorous.div({
    marginTop: 'auto',
    paddingBottom: pxToRem(options.space.s),
});

const CashbackItemTypeContainer = glamorous.div({
    ...waveHeadline2XSSlim,
    marginTop: pxToRem(-4),
});

const CashbackItemAmountContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'row',
    gap: pxToRem(options.space.s),
    justifyContent: 'center',
});
CashbackItemTypeContainer.displayName = 'CashbackItemTypeContainer';

const CashbackItemValidityContainer = glamorous.div({
    marginTop: pxToRem(options.space.l),
});
CashbackItemValidityContainer.displayName = 'CashbackItemValidityContainer';

const RegularCashbackItemValidity = glamorous.div({ ...waveFontM });
RegularCashbackItemValidity.displayName = 'RegularCashbackItemValidity';

const PromotionalCashbackItemValidity = glamorous.span({
    ...waveFontM,
    fontWeight: 500,
    background: options.colors.white,
    borderRadius: pxToRem(options.space['2xs']),
    color: options.colors.purple20,
    padding: `${pxToRem(options.space['2xs'])} ${pxToRem(options.space.xs)}`,
});
PromotionalCashbackItemValidity.displayName = 'PromotionalCashbackItemValidity';

const CashbackItemRedeemInformationContainer = glamorous.div({
    textAlign: 'center',
    padding: pxToRem(options.space.xl),
    flex: 1,
    gap: pxToRem(options.space.s),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});
CashbackItemRedeemInformationContainer.displayName =
    'CashbackItemRedeemInformationContainer';

const CashbackItemRedeemInformationText = glamorous.div({ textAlign: 'left' });

const CashbackItemRedeemTitle = glamorous.div({
    ...waveTextMBold,
    textAlign: 'left',
    marginTop: 'auto',
});
CashbackItemRedeemTitle.displayName = 'CashbackItemRedeemTitle';

const CashbackItemRedeemLink = glamorous.a({
    color: options.colors.blue,
    textDecoration: 'none',
});
const CashbackItemRedeemButton = glamorous.a(({ cashbackItemType }) => ({
    background:
        cashbackItemType === CASHBACK_TYPES.CAR_RENTAL_REGULAR
            ? options.colors.green
            : premiumGradient,
    borderRadius: pxToRem(100),
    padding: `${pxToRem(options.space.xs)} ${pxToRem(options.space.m)}`,
    display: 'block',
    color: options.colors.white,
    ...waveTextMButton,
    marginTop: pxToRem(options.space.l),
    textDecoration: 'none',
    '&:hover': {
        background:
            cashbackItemType === CASHBACK_TYPES.CAR_RENTAL_REGULAR
                ? options.colors.green20
                : premiumGradientHover,
    },
}));
CashbackItemRedeemButton.displayName = 'CashbackItemRedeemButton';

export default function PremiumCashbackItem({ cashbackItem, periodEndDate }) {
    const { trackEventForPremium } = usePremiumTrackingContext();

    if (cashbackItem.detail.validityPeriod) {
        const startDate = moment(cashbackItem.detail.validityPeriod.startDate);
        const endDate = moment(cashbackItem.detail.validityPeriod.endDate);
        const now = moment();

        if (!now.isBetween(startDate, endDate)) {
            return null;
        }
    }

    const [setRef] = useInScreen(() =>
        trackEventForPremium({
            event: {
                eventAction: 'Benefit-in-View',
                eventLabel: cashbackItem.type,
            },
        }),
    );

    const {
        cashbackItemTypeName,
        validityStartDateFormatted,
        validityEndDateFormatted,
        periodEndDateFormatted,
        cashbackItemRedeemTitleText,
        cashbackItemRedeemText,
        cashbackItemRedeemTextLink,
        cashbackItemRedeemTextHref,
        cashbackItemRedeemLinkText,
        cashbackItemRedeemLinkHref,
    } = parseCashbackItem({ cashbackItem, periodEndDate });

    return h(CashbackItemContainer, { innerRef: setRef }, [
        h(CashbackItemValueContainer, { cashbackItemType: cashbackItem.type }, [
            h(CashbackItemAmountContainer, [
                cashbackItem.type === CASHBACK_TYPES.CAR_RENTAL_REGULAR &&
                    h(CashbackItemPrefix, `bis zu`),
                h(CashbackItemValue, `${cashbackItem.detail.cashback.amount}€`),
            ]),
            h(CashbackItemTypeContainer, cashbackItemTypeName),
            h(CashbackItemValidityContainer, {}, [
                cashbackItem.type === CASHBACK_TYPES.PROMOTIONAL
                    ? h(
                          PromotionalCashbackItemValidity,
                          `nur gültig von ${validityStartDateFormatted}. bis ${validityEndDateFormatted}`,
                      )
                    : h(
                          RegularCashbackItemValidity,
                          `Gültig bis ${periodEndDateFormatted}`,
                      ),
            ]),
        ]),
        h(CashbackItemRedeemInformationContainer, [
            h(CashbackItemRedeemInformationText, [
                h('span', cashbackItemRedeemText),
                h(
                    CashbackItemRedeemLink,
                    {
                        href: cashbackItemRedeemTextHref,
                    },
                    cashbackItemRedeemTextLink,
                ),
            ]),
            h(CashbackItemRedeemTitle, cashbackItemRedeemTitleText),
            h(
                CashbackItemRedeemButton,
                {
                    href: cashbackItemRedeemLinkHref,
                    cashbackItemType: cashbackItem.type,
                    onClick() {
                        trackEventForPremium({
                            event: {
                                eventAction: 'Click-Link',
                                eventLabel: cashbackItem.type,
                            },
                        });
                    },
                },
                cashbackItemRedeemLinkText,
            ),
        ]),
    ]);
}
