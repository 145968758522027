import h from 'react-hyperscript';
import glamorous from 'glamorous';

import { pxToRem } from '../../../styles/unitConverter';
import { applyOnMobile } from '../../../styles/mediaQueries';

const ItemContainer = glamorous.div(
    ({
        styleConfig: {
            itemsPerRow: { tabletAndUp, smartphone },
            marginRight,
        },
    }) => ({
        width: `calc((100% / ${tabletAndUp}) - ${marginRight})`,
        boxSizing: 'border-box',
        marginTop: pxToRem(16),
        marginRight,

        [applyOnMobile]: {
            width: `calc((100% / ${smartphone}) - ${marginRight})`,
            marginRight,
        },
    }),
);
ItemContainer.displayName = 'Item';

const Label = glamorous.div();
Label.displayName = 'Label';

const Info = glamorous.div({
    fontWeight: 'bold',
});
Info.displayName = 'Info';

const Item = ({ styleConfig, label, info }) => {
    return h(ItemContainer, { styleConfig }, [h(Label, label), h(Info, info)]);
};

export default Item;
