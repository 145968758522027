/**
 *
 * @returns {Promise<{ carSearchId: string,
 *     isNewSearch: boolean,
 *     listingUrl: string} || null>}
 * @param search {{
 *      pickup: {
 *         airportCode?: String,
 *         dateTime: string
 *     },
 *     dropoff: {
 *         airportCode?: String,
 *         dateTime: string
 *     },
 *     driverage?: number,
 *     residencyCountryCode?: String
 * }}
 *
 */
const fetchRentalcarStartSearch = async function (search, context) {
    const body = JSON.stringify(search);

    const response = await context.window.fetch(
        `${context.config.pathPrefix}/mhc/rentalcar-search`,
        {
            method: 'POST',
            body,
            credentials: 'include',
            headers: {
                'content-type': 'application/json',
            },
        },
    );

    if (response.ok) {
        return response.json();
    }

    return null;
};

export { fetchRentalcarStartSearch };
