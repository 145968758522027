import { useRef, useEffect } from 'react';
import glamorous from 'glamorous';
import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import FocusTrap from 'focus-trap-react';

import Column from '../grid/Column';
import Unscrollable from '../unscrollable/Unscrollable';
import CloseBig from '../icons/CloseBig';
import colors from '../../styles/colors';
import {
    smallDistance,
    mediumDistance,
    largeDistance,
    xlargeDistance,
} from '../../styles/distances';
import { secondaryFont } from '../../styles/fonts';
import { overlayLayer, lightboxLayer } from '../../styles/layers';
import { applyOnMobile, applyOnTabletAndUp } from '../../styles/mediaQueries';
import { pxToRem } from '../../styles/unitConverter';

const { dialogBackgroundColor, dialogBackdropColor, dialogHeaderBorderColor } =
    colors;
const { UITextLLoud } = secondaryFont;

const ModalWrapper = glamorous.div({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    position: 'fixed',
    overflowX: 'hidden',
    overflowY: 'auto',
    zIndex: overlayLayer,
    background: dialogBackdropColor,

    [applyOnTabletAndUp]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
});
ModalWrapper.displayName = 'ModalWrapper';

const CloseButton = glamorous.button({
    background: 'none',
    border: 0,
    padding: 0,
    cursor: 'pointer',
    marginLeft: 'auto',
    height: mediumDistance,
    width: mediumDistance,
});
CloseButton.displayName = 'CloseButton';

const Content = glamorous.div(({ paddingTop }) => ({
    display: 'flex',
    justifyContent: 'center',
    padding: `${paddingTop} 0 ${largeDistance}`,
    margin: `0 ${pxToRem(20)} auto`,

    [applyOnTabletAndUp]: {
        margin: `0 ${pxToRem(32)}`,
    },
}));
Content.displayName = 'Content';

const Modal = glamorous.div({
    background: dialogBackgroundColor,
    zIndex: lightboxLayer,
    textAlign: 'center',

    [applyOnMobile]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: '100vh',
        overflowY: 'auto',
    },

    [applyOnTabletAndUp]: {
        margin: `${pxToRem(93)} auto`,
        borderRadius: `4px`,
    },
});
Modal.displayName = 'Modal';

const Header = glamorous.header(
    {
        display: 'flex',
        alignItems: 'center',
        padding: `${mediumDistance} ${largeDistance} ${smallDistance}`,

        [applyOnMobile]: {
            padding: pxToRem(20),
        },
    },
    ({ title }) =>
        Boolean(title) && {
            borderBottom: `1px solid ${dialogHeaderBorderColor}`,
        },
);
Header.displayName = 'Header';

const Title = glamorous.h2({
    ...UITextLLoud,
    margin: 0,
    minHeight: `${mediumDistance}`,
});

function Dialog({ children, title, onClose, medium, large }, { document }) {
    const modalRef = useRef(null);
    const KEY_NAME_ESC = 'Escape';
    const KEY_EVENT_TYPE = 'keyup';
    const CLICK_EVENT_TYPE = 'click';

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };
        const handleEscKey = (event) => {
            if (event.key === KEY_NAME_ESC) {
                onClose();
            }
        };

        document.addEventListener(CLICK_EVENT_TYPE, handleClickOutside);
        document.addEventListener(KEY_EVENT_TYPE, handleEscKey);

        return () => {
            document.removeEventListener(CLICK_EVENT_TYPE, handleClickOutside);
            document.removeEventListener(KEY_EVENT_TYPE, handleEscKey);
        };
    }, []);

    return h(FocusTrap, [
        h('div', [
            h(ModalWrapper, [
                h(
                    Column,
                    {
                        small: null,
                        medium,
                        large,
                    },
                    [
                        h(
                            Modal,
                            {
                                innerRef: modalRef,
                                role: 'dialog',
                                'aria-modal': true,
                            },
                            [
                                h(Header, { title }, [
                                    title && h(Title, title),
                                    h(
                                        CloseButton,
                                        {
                                            onClick: onClose,
                                            ariaLabel: 'Close',
                                        },
                                        h(CloseBig),
                                    ),
                                ]),
                                h(
                                    Content,
                                    { paddingTop: title ? xlargeDistance : 0 },
                                    [
                                        h(
                                            Column,
                                            {
                                                small: null,
                                                medium: medium - 1,
                                                large: large - 1,
                                            },
                                            children,
                                        ),
                                    ],
                                ),
                            ],
                        ),
                    ],
                ),
            ]),
            h(Unscrollable),
        ]),
    ]);
}

Dialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node,
    title: PropTypes.node,
    medium: PropTypes.number,
    large: PropTypes.number,
};

Dialog.contextTypes = {
    document: PropTypes.object.isRequired,
};

Dialog.defaultProps = {
    medium: 10,
    large: 8,
};

export default Dialog;
