/* global window */

import hcTracking from '@hc/tracking';

import { v4 } from './uuid';
import { noticeError } from '../newrelic';

class Tracker {
    constructor() {
        this.pageId = v4();
        this._config = null;
        this._pageName = null;
    }

    /**
     * @param {string} eventName - the event name (ex. pageview)
     * @param {Object} [customEventData] - additional even data
     */
    track(eventName, eventData) {
        try {
            hcTracking.track(eventName, this._buildEventData(eventData));
        } catch (err) {
            noticeError(err, {
                tracker: true,
                trackerEventName: eventName,
            });
        }
    }

    setContext(config) {
        this._config = config;
    }

    updatePageId() {
        this.pageId = v4();
    }

    setPageName(pageName) {
        this._pageName = pageName;
    }

    _buildEventData(eventData) {
        const commonEventData = {
            page: {
                name: this._pageName || 'na',
                type: 'mhc',
                locale: this._config.topLevelDomain,
                env: window.ENV,
            },
            pageId: this.pageId,
        };

        return Object.assign(commonEventData, eventData);
    }
}

export default new Tracker();
