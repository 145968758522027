import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import { Query } from '@apollo/client/react/components';

import { premiumMembership } from '../../../../queries/premiumMembership';
import { extractActiveContract } from '../../../../lib/premium/membership';
import PremiumAdCard from './PremiumAdCard';
import MyPremiumCard from './MyPremiumCard';

const PremiumCard = (props, { trackEvent, window }) => {
    return h(
        Query,
        { fetchPolicy: 'cache-and-network', query: premiumMembership },
        ({ loading, data, error }) => {
            if (loading || error || !data) {
                return null;
            }

            const trackEventForPremium = ({ event }) => {
                if (window.hasConsentedToTrackEvents) {
                    trackEvent({
                        event: 'event',
                        eventCategory: 'Premium-Club',
                        ...event,
                        ...(data &&
                        data.premiumMembership.personAccountDetail.mhcUUID
                            ? {
                                  mhcUUID:
                                      data.premiumMembership.personAccountDetail
                                          .mhcUUID,
                              }
                            : {}),
                    });
                }
            };

            const activeContract = extractActiveContract(
                data.premiumMembership.subscriptionContracts,
            );

            return activeContract
                ? h(MyPremiumCard, {
                      activeContract,
                      trackEventForPremium,
                  })
                : h(PremiumAdCard, {
                      trackEventForPremium,
                  });
        },
    );
};

export default PremiumCard;

PremiumCard.contextTypes = {
    config: PropTypes.object,
    window: PropTypes.object,
    trackEvent: PropTypes.func,
};
