import { useEffect, useState } from 'react';
import moment from 'moment/moment';

import { getTodaysOpeningHours } from '../../../fetchers/fetchRentalcarOpeningHours';
import { noticeError } from '../../../lib/newrelic';

const formatTime = (timeString) =>
    moment(timeString, ['h:m:s']).format('HH:mm');

const formatOpeningHours = ({ from1, to1, from2, to2 }) => {
    const ranges = [];

    if (from1 && to1) {
        ranges.push(`${formatTime(from1)} - ${formatTime(to1)} Uhr`);
    }

    if (from2 && to2) {
        ranges.push(`${formatTime(from2)} - ${formatTime(to2)} Uhr`);
    }

    if (ranges.length === 0) {
        return 'geschlossen';
    }

    return `ab ${ranges.join(' und ')}`;
};

const formatOpening = (isToday, isTomorrow, openingTime, nextDate) => {
    let day = `${nextDate.format('dddd')}`;
    if (isToday) {
        day = 'Heute';
    } else if (isTomorrow) {
        day = 'Morgen';
    }

    if (isToday && openingTime === 'geschlossen') {
        return 'Heute geschlossen';
    }
    return `${day} ${openingTime}`;
};

/**
 *
 * @param context
 * @returns {string}
 *
 * Examples:
 *  Heute ab 10:00 - 13:00 Uhr und 15:00 - 18:00 Uhr
 *  Heute ab 10:00 - 13:00 Uhr
 *  Morgen ab 10:00 - 13:00 Uhr und 15:00 - 18:00 Uhr
 *  Morgen ab 10:00 - 13:00 Uhr
 *  Samstag ab 10:00 - 13:00 Uhr und 15:00 - 18:00 Uhr
 *  Samstag ab 10:00 - 13:00 Uhr
 *  Heute geschlossen // If there is no opening in the next 7 Days (edge case)
 */

export function useOpeningHours(context) {
    const [openingHours, setOpeningHours] = useState('Heute geschlossen');

    useEffect(() => {
        const fetchOpeningHours = async () => {
            try {
                const response = await getTodaysOpeningHours(context);
                const nextDate = moment(response.nextDate, ['YYYY-MM-DD']);
                const isToday = nextDate.isSame(moment(), 'day');
                const isTomorrow = nextDate.isSame(
                    moment().add(1, 'day'),
                    'day',
                );

                const openingTime = formatOpeningHours(response.nextOpening);

                setOpeningHours(
                    formatOpening(isToday, isTomorrow, openingTime, nextDate),
                );
            } catch (error) {
                noticeError(error && error.message, {
                    pageName: 'MyRentalcarBookings',
                    component: 'useOpeningHours',
                });
            }
        };

        fetchOpeningHours();
    }, []);

    return openingHours;
}
