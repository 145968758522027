import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import { blue90 } from '../../styles/waveColors';
import { pxToRem } from '../../styles/unitConverter';
import { xsmallDistance } from '../../styles/distances';
import { defaultFont, FONTS } from '../../styles/fonts';
import Info from '../icons/Info';

const HintContainer = glamorous.div(({ containerStyle }) => ({
    backgroundColor: blue90,
    padding: pxToRem(9),
    borderRadius: pxToRem(4),
    marginBottom: pxToRem(24),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    ...containerStyle,
}));

const PrefixContainer = glamorous.div({
    display: 'flex',
    padding: `${pxToRem(5)} 0`,
});

const Prefix = glamorous.h5({
    fontFamily: FONTS.secondary,
    fontWeight: 700,
    fontSize: pxToRem(14),
    lineHeight: pxToRem(18),
    padding: `0 ${xsmallDistance}`,
    margin: '0',
});
Prefix.displayName = 'Prefix';

const ChildContainer = glamorous.div(defaultFont, {
    paddingLeft: pxToRem(23),
    textAlign: 'initial',
    fontFamily: FONTS.secondary,
});

const Hint = ({ children, prefix, containerStyle }) => {
    return h(HintContainer, { containerStyle }, [
        h(PrefixContainer, [
            h(Info, { css: { width: pxToRem(16), flex: 'none' } }),
            h(Prefix, prefix),
        ]),
        h(ChildContainer, [children]),
    ]);
};

Hint.defaultProps = {
    prefix: 'Hinweis:',
};

Hint.propTypes = {
    children: PropTypes.node,
    prefix: PropTypes.string,
    containerStyle: PropTypes.object,
};

export default Hint;
