import glamorous from 'glamorous';
import h from 'react-hyperscript';
import * as R from 'ramda';
import PropTypes from 'prop-types';

import RadioButton from './RadioButton';
import colors from '../../styles/colors';
import {
    xsmallDistance,
    smallDistance,
    mediumDistance,
    largeDistance,
} from '../../styles/distances';
import { gray, red } from '../../styles/waveColors';
import { pxToRem } from '../../styles/unitConverter';
import { waveSmMediaQuery } from '../../styles/waveMediaQueries';

const { radioButtonGroupBackgroundColor, radioButtonGroupBorderColor } = colors;

const Body = glamorous.div(
    {
        background: radioButtonGroupBackgroundColor,
        border: `1px solid ${gray}`,
        borderRadius: xsmallDistance,
        marginBottom: smallDistance,
    },
    ({ isInline }) =>
        isInline && {
            background: 'none',
            border: 0,
            borderRadius: 0,
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            [waveSmMediaQuery]: {
                flexDirection: 'row',
            },
        },
);
Body.displayName = 'RadioButtonGroupBody';

const RadioButtonContainer = glamorous.div(
    {
        padding: mediumDistance,
    },
    ({ isInline, isLast }) =>
        R.mergeAll([
            !isInline &&
                !isLast && {
                    borderBottom: `1px solid ${radioButtonGroupBorderColor}`,
                },

            isInline && {
                marginRight: largeDistance,
                marginBottom: smallDistance,
                padding: 0,
            },
        ]),
);
RadioButtonContainer.displayName = 'RadioButtonContainer';

const ErrorMessage = glamorous.p({
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(18),
    color: red,
    margin: `${pxToRem(4)} 0 0`,
});
ErrorMessage.displayName = 'ErrorMessage';

const RadioButtonGroup = ({
    onChange,
    disabled,
    options,
    isInline,
    name,
    selectedValue,
    errorMessage,
    onBlur,
}) =>
    h(Body, { isInline }, [
        options.map((option, index) =>
            h(
                RadioButtonContainer,
                {
                    isInline,
                    isLast: index === options.length - 1,
                    key: option.label,
                },
                [
                    h(RadioButton, {
                        ...option,
                        checked: selectedValue === option.value,
                        name,
                        onChange: (e) => onChange(e.target.value),
                        disabled,
                        onBlur: onBlur
                            ? (e) => onBlur(e.target.value)
                            : undefined,
                    }),
                ],
            ),
        ),
        Boolean(errorMessage) && h(ErrorMessage, [errorMessage]),
    ]);

RadioButtonGroup.propTypes = {
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    errorMessage: PropTypes.node,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            qualifier: PropTypes.string,
            value: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    isInline: PropTypes.bool,
    name: PropTypes.string.isRequired,
    selectedValue: PropTypes.string,
    onBlur: PropTypes.func,
};

RadioButtonGroup.defaultProps = {
    disabled: false,
    isInline: false,
};

export default RadioButtonGroup;
