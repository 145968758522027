import PropTypes from 'prop-types';

import { HOLIDAYCHECK_AWARD, HOLIDAYCHECK_GOLD_AWARD } from './award';

const HotelParentType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    destinationType: PropTypes.string.isRequired,
});

const HotelAwardType = PropTypes.shape({
    category: PropTypes.oneOf([HOLIDAYCHECK_GOLD_AWARD, HOLIDAYCHECK_AWARD])
        .isRequired,
    year: PropTypes.number.isRequired,
});

const HotelReviewCalculationsType = PropTypes.shape({
    overall: PropTypes.shape({
        countUnarchived: PropTypes.number.isRequired,
        recommendation: PropTypes.number,
    }),
});

const UpcomingTravelDataType = PropTypes.shape({
    booking: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
        contractualPartner: PropTypes.shape({
            firstName: PropTypes.string.isRequired,
        }).isRequired,
        services: PropTypes.array.isRequired,
    }),
    hotel: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        stars: PropTypes.number,
        parents: PropTypes.arrayOf(HotelParentType).isRequired,
        reviewCalculations: HotelReviewCalculationsType.isRequired,
        latestAward: HotelAwardType,
        destinationImage: PropTypes.string,
    }),
});

export { UpcomingTravelDataType, HotelAwardType, HotelReviewCalculationsType };
