import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import colors from '../../../styles/colors';
import { blue, white } from '../../../styles/waveColors';
import { tinyFont, loudFont } from '../../../styles/fonts';
import { xsmallDistance, smallDistance } from '../../../styles/distances';
import { pxToRem } from '../../../styles/unitConverter';
import formatNumber from '../../../lib/formatNumber';
import ThumbsUp from '../../icons/ThumbsUp';
import Link from '../../navigation/Link';
import { HotelReviewCalculationsType } from '../../../lib/types';
import {
    HOLIDAYCHECK_AWARD,
    HOLIDAYCHECK_GOLD_AWARD,
} from '../../../lib/award';

const { defaultBackgroundColor, hotelRatingThumbColor, noRatingLabelBorder } =
    colors;

const GOLD_AWARD_COLOR =
    'radial-gradient(100% 100% at 0% 99.21%, #FCED67 0%, #D2B84E 51.91%, #D2B84E 100%)';
const AWARD_COLOR =
    'radial-gradient(115.51% 115.51% at -0.11% 99.21%, #EBEFC4 0%, #EEEFB6 0%, #F6EE8E 0%, ' +
    '#FCED67 0%, #DFDF83 4.71%, #B0C8A1 29.13%, #85B7B2 53.84%, #5FAABC 72.15%, #41A2C2 88.12%, #34A0C3 100%)';
const CONTAINER_WIDTH = pxToRem(66);
const CONTAINER_HEIGHT = pxToRem(28);

const HotelRatingWrapper = glamorous(Link)({
    color: blue,
    display: 'block',
    margin: `${smallDistance} 0`,

    '> *': {
        display: 'inline-block',
    },
});

const RatingWrapper = glamorous.span(({ latestAward }) => ({
    ...loudFont,
    color: hotelRatingThumbColor,
    background: blue,
    borderRadius: latestAward ? '4px 0px 0px 4px' : '4px',
    fontSize: smallDistance,
    lineHeight: CONTAINER_HEIGHT,
    fontWeight: '600',
    width: CONTAINER_WIDTH,
    height: CONTAINER_HEIGHT,
}));

const ThumbIconWrapper = glamorous.span({
    display: 'inline-block',
    width: '1.75rem',
    verticalAlign: 'middle',
    textAlign: 'center',
    marginRight: '0.25rem',
});

const Rating = glamorous.span();

const NoRatingLabel = glamorous.span({
    ...tinyFont,
    border: `1px solid ${noRatingLabelBorder}`,
    backgroundColor: defaultBackgroundColor,
    padding: xsmallDistance,
    margin: `${smallDistance} 0`,
    display: 'inline-block',
});

const HotelAward = glamorous.span(({ awardBackground }) => ({
    fontSize: smallDistance,
    lineHeight: CONTAINER_HEIGHT,
    fontWeight: '600',
    color: white,
    borderRadius: '0 4px 4px 0',
    width: CONTAINER_WIDTH,
    height: CONTAINER_HEIGHT,
    background: awardBackground,
    textAlign: 'center',
    textTransform: 'uppercase',
}));
HotelAward.displayName = 'HotelAward';

const ReviewCount = glamorous.span({
    marginLeft: xsmallDistance,
});

const renderHotelAward = (latestAward) => {
    if (latestAward) {
        const awardBackground =
            latestAward.category === HOLIDAYCHECK_GOLD_AWARD
                ? GOLD_AWARD_COLOR
                : AWARD_COLOR;

        return h(HotelAward, { awardBackground }, ['Award']);
    }

    return null;
};

const HotelRecommendationRate = ({
    hotelId,
    reviewCalculations,
    latestAward,
}) => {
    if (!reviewCalculations.countUnarchived > 0) {
        return h(NoRatingLabel, 'Keine Hotelbewertungen');
    }

    const rateAsPercentage = Math.round(
        reviewCalculations.recommendation * 100,
    );

    return h(HotelRatingWrapper, { href: `/hr/${hotelId}` }, [
        h(RatingWrapper, { latestAward }, [
            h(ThumbIconWrapper, [
                h(ThumbsUp, {
                    css: {
                        width: '1.5rem',
                        height: '1.5rem',
                        verticalAlign: 'text-bottom',
                    },
                }),
            ]),
            h(Rating, `${rateAsPercentage}%`),
        ]),
        renderHotelAward(latestAward),
        h(
            ReviewCount,
            `${formatNumber(reviewCalculations.countUnarchived)} Bewertungen`,
        ),
    ]);
};

HotelRecommendationRate.propTypes = {
    hotelId: PropTypes.string.isRequired,
    reviewCalculations: HotelReviewCalculationsType.isRequired,
    latestAward: PropTypes.shape({
        category: PropTypes.oneOf([HOLIDAYCHECK_GOLD_AWARD, HOLIDAYCHECK_AWARD])
            .isRequired,
    }),
};

export default HotelRecommendationRate;
