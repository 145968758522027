import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { useLocation } from 'react-router-dom';

import Page from './Page';
import Grid from './grid/Grid';
import UploadCallToAction from './myMedia/UploadCallToAction';
import colors from '../styles/colors';
import { mediumDistance } from '../styles/distances';
import { pxToRem } from '../styles/unitConverter';
import Tabs from './navigation/Tabs';
import ContentPictures from './myMedia/ContentPictures';
import UgcContentIframe from './UgcContentIframe';

const { defaultBackgroundColor } = colors;

const Content = glamorous.div({
    background: defaultBackgroundColor,
    paddingBottom: pxToRem(22),
});

const tabItems = [
    {
        label: 'Bilder',
        to: '/mhc/meine-urlaubsbilder',
    },
    {
        label: 'Videos',
        to: '/mhc/meine-urlaubsbilder?action=videos',
    },
];

const MyMedia = () => {
    const location = useLocation();
    const activeTabPath = `${location.pathname}${location.search}`;
    return h(
        Page,
        {
            pagename: '/mhc/travelpictures',
            title: 'Meine Urlaubsbilder',
            headline: 'Meine Bilder',
        },
        [
            h(
                Grid,
                {
                    style: {
                        paddingBottom: mediumDistance,
                    },
                    className: 'page-description',
                },
                h(UploadCallToAction),
            ),
            h(Tabs, { tabItems, activeTabPath }),
            h(
                Content,
                { className: 'content' },
                activeTabPath.includes('action=videos')
                    ? h(UgcContentIframe, { location })
                    : h(ContentPictures),
            ),
        ],
    );
};

export default MyMedia;
