import h from 'react-hyperscript';
import PropTypes from 'prop-types';

import { pxToRem } from '../../../styles/unitConverter';
import { WaveButton, variations, sizes } from '../../WaveButton';
import { applyOnMobile } from '../../../styles/mediaQueries';

const DialogSubmitButton = ({
    loading = false,
    disabled = false,
    onClick,
    children,
}) =>
    h(
        WaveButton,
        {
            variant: variations.PRIMARY,
            size: sizes.S,
            onClick,
            type: 'submit',
            disabled,
            loading,
            customStyles: {
                margin: `${pxToRem(24)} auto`,

                [applyOnMobile]: {
                    position: 'sticky',
                    bottom: pxToRem(16),
                    margin: 'auto auto 0',
                },
            },
        },
        children,
    );

DialogSubmitButton.displayName = 'DialogSubmitButton';

DialogSubmitButton.propTypes = {
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

export default DialogSubmitButton;
