import { stripIndent } from 'common-tags';

import graphqlFetch from '../lib/graphqlFetch';

export default async function fetchHappyTravelers(context, hotelReviewId) {
    const query = stripIndent`
        query MyHappyTravelers($hotelReviewId: String!) {
            happyTravelers(hotelReviewId:$hotelReviewId) {
                items {
                    userId
                    firstName
                    picture
                    traveledWith
                    travelDate
                    hotelReviewId
                    hotelName
                }
            }
        }
    `;
    const response = await graphqlFetch(context, {
        query,
        variables: { hotelReviewId },
    });

    if (response.ok) {
        const jsonResponse = await response.json();
        return jsonResponse.data.happyTravelers.items;
    }

    return [];
}
