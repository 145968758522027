import glamorous from 'glamorous';
import options from '@designsystem/options';

import { waveMdMediaQuery } from '../../../../styles/waveMediaQueries';

const Container = glamorous.div({
    fontFamily: 'Montserrat',
    color: options.colors.black,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [waveMdMediaQuery]: {
        flexDirection: 'row',
        marginTop: options.space['6xl'],
    },
});

export { Container };
