import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { css } from 'glamor';

import { secondaryFont } from '../../../styles/fonts';
import { pxToRem } from '../../../styles/unitConverter';
import { blue, blue90, gray40 } from '../../../styles/waveColors';

const { PTSansFamily } = secondaryFont;

const linearLoadingAnimation = css.keyframes({
    '0%': {
        left: '-50%',
        width: 0,
    },
    '50%': {
        left: '25%',
        width: '50%',
    },
    '100%': {
        left: '150%',
        width: 0,
    },
});

const LoaderContainer = glamorous.div({
    position: 'relative',
    width: '100%',
    height: pxToRem(8),
    background: blue90,
    overflow: 'hidden',
    borderRadius: pxToRem(4),
});

const Loader = glamorous.div(({ animationDelay }) => ({
    position: 'absolute',
    background: blue,
    height: '100%',
    animation: linearLoadingAnimation,
    animationDuration: '2s',
    animationDelay,
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
}));
Loader.displayName = 'Loader';

const Text = glamorous.div(PTSansFamily, {
    textAlign: 'center',
    color: gray40,
    marginTop: pxToRem(8),
});
Text.displayName = 'Text';

const LoadingTourOperatorPrice = () =>
    h('div', [
        h(LoaderContainer, [h(Loader), h(Loader, { animationDelay: '1s' })]),
        h(
            Text,
            'Wir kontaktieren gerade Deinen Veranstalter, um Deinen Zahlungsstatus zu aktualisieren …',
        ),
    ]);

export default LoadingTourOperatorPrice;
