import { Component } from 'react';
import glamorous from 'glamorous';
import h from 'react-hyperscript';
import PropTypes from 'prop-types';

const Image = glamorous.img({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    maxHeight: '100%',
    maxWidth: '100%',
});

const fallbackTransformation = 'w_1280,h_720,c_fit,q_auto,f_auto';
const pictureTransformationConfiguration = [
    {
        mediaQuery: '(max-width: 580px) and (max-height: 330px)',
        transformation: 'w_580,h_330,c_fit,q_auto,f_auto',
    },
    {
        mediaQuery: '(max-width: 1280px) and (max-height: 720px)',
        transformation: 'w_1280,h_720,c_fit,q_auto,f_auto',
    },
    {
        mediaQuery: '(max-width: 1920px) and (max-height: 1080px)',
        transformation: 'w_1920,h_1080,c_fit,q_auto,f_auto',
    },
    {
        mediaQuery: '(min-width: 1920px), (min-height: 1080px)',
        transformation: 'w_2880,h_1620,c_fit,q_auto,f_auto',
    },
];

const buildPictureUrl = (mediaBaseUrl, transformation, pictureId) => {
    return `${mediaBaseUrl}${transformation}/ugc/images/${pictureId}`;
};

const renderSource =
    (mediaBaseUrl, pictureId) =>
    ({ mediaQuery, transformation }) =>
        h('source', {
            srcSet: buildPictureUrl(mediaBaseUrl, transformation, pictureId),
            media: mediaQuery,
            key: transformation,
        });

class ResponsivePicture extends Component {
    render() {
        const { mediaBaseUrl } = this.context.config;
        const { pictureId } = this.props;

        const pictureSources = pictureTransformationConfiguration.map(
            renderSource(mediaBaseUrl, pictureId),
        );
        const fallbackPicture = h(Image, {
            src: buildPictureUrl(
                mediaBaseUrl,
                fallbackTransformation,
                pictureId,
            ),
        });

        return h('picture', { key: pictureId }, [
            ...pictureSources,
            fallbackPicture,
        ]);
    }
}

ResponsivePicture.contextTypes = {
    config: PropTypes.object,
};

export default ResponsivePicture;
