import glamorous from 'glamorous';
import h from 'react-hyperscript';
import { oneLine } from 'common-tags';
import options from '@designsystem/options';

import LoadingButton from '../loading-button/LoadingButton';
import Dialog from '../dialog/Dialog';
import Link from '../navigation/Link';

const Content = glamorous.div({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: `0 ${options.space.m}px`,
});

const Email = glamorous.strong({
    display: 'block',
    fontFamily: options.fonts.default,
    fontWeight: options.fontWeights.bold,
});
Email.displayName = 'Email';

const Paragraph = glamorous.p({
    margin: `0 0 ${options.space.m}px`,
    width: '100%',
});
Paragraph.displayName = 'Paragraph';

const SubmitButtonContainer = glamorous.div({
    margin: `${options.space.s}px 0 ${options.space.m}px`,
});

export default function ChangeEmailConfirmationDialog({
    email,
    isRequestRunning = false,
    onClose,
    onSubmit,
}) {
    const closeOrDoNothing = isRequestRunning ? () => null : onClose;

    return h(
        Dialog,
        {
            title: 'E–Mail–Adresse ändern',
            onClose: closeOrDoNothing,
        },
        [
            h(Content, [
                h(Paragraph, [
                    'Kontrolliere bitte die richtige Schreibweise der E–Mail Adresse:',
                    h(Email, email),
                ]),
                h(
                    Paragraph,
                    oneLine`Du wirst im Anschluss ausgeloggt und
             musst Dich mit Deiner neuen E-Mail-Adresse wieder einloggen.`,
                ),
                h(SubmitButtonContainer, [
                    h(
                        LoadingButton,
                        {
                            onClick: onSubmit,
                            isLoading: isRequestRunning,
                            type: 'submit',
                        },
                        'Änderung bestätigen',
                    ),
                ]),
                h(
                    Link,
                    {
                        onClick: closeOrDoNothing,
                    },
                    'Abbrechen',
                ),
            ]),
        ],
    );
}
