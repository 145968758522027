import h from 'react-hyperscript';
import * as glamor from 'glamor';
import glamorous from 'glamorous';

import colors from '../../styles/colors';

const { lightboxOverlayColor } = colors;

const Layer = glamorous.div({
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
});

const SliderContainer = glamorous(Layer)({
    background: lightboxOverlayColor,
    height: '100%',
    position: 'relative',
});

const navigationKeyframes = (navigatingTo) =>
    glamor.css.keyframes({
        '0%': {
            transform: `translateX(${navigatingTo === 'next' ? '100%' : '-100%'})`,
        },
        '100%': { transform: 'translateX(0)' },
    });

const VisibleView = glamorous.div(
    {
        display: 'flex',
        height: '100%',
        left: '-100%',
        position: 'relative',
        width: '300%',
    },
    ({ navigatingTo }) =>
        navigatingTo && {
            animation: `500ms ease ${navigationKeyframes(navigatingTo)}`,
            animationFillMode: 'forwards',
        },
);

const SlideableItem = glamorous.div({
    flexShrink: 0,
    position: 'relative',
    width: '100%',
});

const Slider = ({ children, navigatingTo, finishNavigation }) => {
    return h(SliderContainer, [
        h(
            VisibleView,
            {
                navigatingTo,
                onAnimationEnd: finishNavigation,
            },
            children.map((child) => h(SlideableItem, [child])),
        ),
    ]);
};

export default Slider;
