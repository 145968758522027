import { baseFontSize } from './fontSizes';

function pxToRem(pixel) {
    return `${pixel / baseFontSize}${pixel === 0 ? '' : 'rem'}`;
}

function pxToMediaQueryEm(pixel) {
    return `${pixel / 16}${pixel === 0 ? '' : 'em'}`;
}
export { pxToRem, pxToMediaQueryEm };
