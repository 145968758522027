import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import { Fragment, useEffect /* useState*/ } from 'react';
// import { oneLine } from 'common-tags';

import { pxToRem } from '../../styles/unitConverter';
import BookingSectionHeadline from './BookingSectionHeadline';
import { SALUTATION_VALUES, getSalutationText } from '../../lib/salutations';
// import ModalButton from '../navigation/ModalButton';
// import useBookingContext from './lib/useBookingContext';
import { gray40 } from '../../styles/waveColors';
import { tinyFont } from '../../styles/fonts';
import { hasEnded, isActiveBooking } from '../../../common/lib/bookingHelper';
// import ChangeContractualPartnerDataDialog from './rebookingDialogs/ChangeContractualPartnerDataDialog';
// import Hint from './Hint';
// import {
//     REQUESTED_PARTNER_CHANGES_STORAGE_KEY,
//     shouldDataChangeRequestMessageBeShown
// } from './lib/dataRequestLocalStorageHelpers';

const PartnerData = glamorous.div();
PartnerData.displayName = 'PartnerData';

const PartnerDataBlock = glamorous.div({
    marginBottom: pxToRem(8),
});
PartnerDataBlock.displayName = 'PartnerDataBlock';

const Name = glamorous.span({
    fontWeight: 'bold',
});

const EmailDescription = glamorous.div({
    ...tinyFont,
    lineHeight: pxToRem(18),
    color: gray40,
});

const ContractualPartner = (
    { booking, myRef, setWillRender },
    { getCurrentDate /* , localStorage*/ },
) => {
    // const tourOperator = booking.tourOperator;
    const currentDate = getCurrentDate();
    // const [ originalCP, setOriginalCP ] = useState(booking.contractualPartner);
    // const { salutation, firstName, lastName, address, phoneNumbers, email } = originalCP;
    const { salutation, firstName, lastName, address, phoneNumbers, email } =
        booking.contractualPartner;
    // const [ isContractualPartnerDialogOpen, setContractualPartnerDialogOpen ] = useState(false);
    // const { trackEventWithBooking } = useBookingContext();
    // const hintMessage = oneLine`Wir haben Deine Anfrage erhalten und werden sie nun bearbeiten.
    // Die Bearbeitung kann bis zu 48 Stunden dauern.`;

    useEffect(() => setWillRender(true), []);

    // const shouldDataChangeRequestBeShown = shouldDataChangeRequestMessageBeShown({
    //     dateTime: currentDate,
    //     bookingId: booking.id,
    //     currentEntity: originalCP,
    //     storageKey: REQUESTED_PARTNER_CHANGES_STORAGE_KEY,
    //     localStorage
    // });

    return h(Fragment, [
        h(BookingSectionHeadline, { innerRef: myRef }, 'Vertragspartner'),
        h(PartnerData, { 'data-hj-suppress': '' }, [
            h(PartnerDataBlock, [
                h('span', `${getSalutationText(salutation)}`),
                ' ',
                h(Name, `${firstName} ${lastName}`),
            ]),
            h(PartnerDataBlock, [
                h('div', address.street),
                h(
                    'div',
                    `${address.zipCode} ${address.city} ${address.countryCode || ''}`,
                ),
            ]),
            h(
                PartnerDataBlock,
                phoneNumbers.map((phoneNumber) => h('div', phoneNumber.value)),
            ),
            h(PartnerDataBlock, [
                h('div', email),
                isActiveBooking(booking.bookingType) &&
                    !hasEnded(booking, currentDate) &&
                    h(
                        EmailDescription,
                        'An diese E-Mail-Adresse senden wir Dir alle Infos zu dieser Buchung',
                    ),
            ]),
        ]),
        // isActiveBooking(booking.bookingType) && !hasEnded(booking, currentDate) && h(ModalButton, {
        //     onClick: () => {
        //         setContractualPartnerDialogOpen(true);
        //         trackEventWithBooking({
        //             event: 'event',
        //             eventCategory: 'mybooking',
        //             eventAction: 'open',
        //             eventLabel: 'contractualPartnerDataChange',
        //             ofOrganizerSelected: tourOperator.name || tourOperator.id
        //         });
        //     },
        //     text: 'Änderung E-Mail-Adresse',
        //     disabled: shouldDataChangeRequestBeShown
        // }),
        // shouldDataChangeRequestBeShown && h(Hint, {
        //     prefix: 'Bitte beachte',
        //     containerStyle: { marginTop: pxToRem(12) }
        // }, hintMessage),
        // isContractualPartnerDialogOpen &&
        // h(ChangeContractualPartnerDataDialog, {
        //     originalCP,
        //     setOriginalCP,
        //     tourOperator,
        //     bookingId: booking.id,
        //     closeDialog: () => setContractualPartnerDialogOpen(false)
        // })
    ]);
};

ContractualPartner.propTypes = {
    booking: PropTypes.shape({
        contractualPartner: PropTypes.shape({
            salutation: PropTypes.oneOf([
                SALUTATION_VALUES.mr,
                SALUTATION_VALUES.ms,
            ]),
            title: PropTypes.string,
            firstName: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
            dateOfBirth: PropTypes.string,
            address: PropTypes.shape({
                zipCode: PropTypes.string.isRequired,
                street: PropTypes.string.isRequired,
                city: PropTypes.string.isRequired,
                countryCode: PropTypes.string,
            }).isRequired,
            email: PropTypes.string.isRequired,
            phoneNumbers: PropTypes.arrayOf(
                PropTypes.shape({
                    value: PropTypes.string.isRequired,
                }),
            ).isRequired,
        }).isRequired,
    }),
    // from using `useRef` hook, `ref` prop is treated differently and would need the `forwardRef` crap
    myRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
    setWillRender: PropTypes.func.isRequired,
};

ContractualPartner.contextTypes = {
    getCurrentDate: PropTypes.func.isRequired,
    localStorage: PropTypes.object,
};

ContractualPartner.displayName = 'ContractualPartner';

export default ContractualPartner;
