import h from 'react-hyperscript';
import glamorous from 'glamorous';
import * as R from 'ramda';

import Link from './Link';
import ClientSideLink from './ClientSideLink';
import { smallDistance } from '../../styles/distances';

const linkFlexboxStyles = {
    alignItems: 'center',
    display: 'inline-flex',
};

const StyledLink = glamorous(Link)(linkFlexboxStyles);
const StyledClientSideLink = glamorous(ClientSideLink)(linkFlexboxStyles);

const IconContainer = glamorous.div(
    {
        alignContent: 'center',
        display: 'flex',
    },
    (props) => ({
        margin: props.isRightIcon ? '0 0 0 4px' : '0 4px 0 0',
    }),
);

const mergeWithCssDimensions = R.uncurryN(2, (size) =>
    R.mergeDeepLeft({
        css: {
            height: size,
            width: size,
        },
    }),
);

export default function LinkWithIcon(props) {
    const {
        children,
        css,
        icon,
        iconCss = {},
        iconProps = {},
        iconSize = smallDistance,
        isRightIcon = false,
        href,
        onClick,
        to,
    } = props;

    const iconContainer = h(
        IconContainer,
        {
            css: iconCss,
            isRightIcon,
            key: `${children}-icon`,
        },
        [h(icon, mergeWithCssDimensions(iconSize, iconProps))],
    );

    const text = h(
        'span',
        {
            key: `${children}-text`,
        },
        children,
    );

    const linkComponent = to ? StyledClientSideLink : StyledLink;
    const linkProps = R.pipe(
        R.when(R.always(href), R.assoc('href', href)),
        R.when(R.always(to), R.assoc('to', to)),
        R.when(R.always(onClick), R.assoc('onClick', onClick)),
    )({ css });

    return h(
        linkComponent,
        linkProps,
        isRightIcon ? [text, iconContainer] : [iconContainer, text],
    );
}
