import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import options from '@designsystem/options';

import formatDate from '../../lib/formatDate';
import Link from '../navigation/Link';

const Container = glamorous.div({
    display: 'flex',
    alignItems: 'center',
});
Container.displayName = 'Container';

const ProfilePicture = glamorous.img({
    width: `${options.space.xl}px`,
    height: `${options.space.xl}px`,
    borderRadius: '50%',
    objectFit: 'cover',
    marginRight: `${options.space.xs}px`,
});
ProfilePicture.displayName = 'ProfilePicture';

const traveledWithTexts = {
    COUPLE: '\xa0als Paar',
    FAMILY: '\xa0mit Familie',
    FRIENDS: '\xa0mit Freunden',
};

const HappyTraveler = ({ traveler }, context) => {
    const openProfile = (ev) => {
        ev.preventDefault();
        context.window.open(
            ev.currentTarget.href,
            'mhcprofile',
            'menubar=0,location=0,toolbar=0,status=0,resizable=0,dependent=1,scrollbars=yes,height=600,width=680;',
        );
        context.trackEvent({
            event: 'event',
            eventCategory: 'happyTravelers',
            eventAction: 'openProfile',
        });
    };

    const trackReviewLink = () => {
        context.trackEvent({
            event: 'event',
            eventCategory: 'happyTravelers',
            eventAction: 'openReview',
        });
    };

    const date = formatDate(moment(traveler.travelDate).utc(), 'MMMM Y');
    const traveledWith = traveledWithTexts[traveler.traveledWith];
    return h(Container, [
        h(ProfilePicture, {
            src: traveler.picture,
            alt: 'Profilbild',
        }),
        h(
            Link,
            {
                href: `/ui/${traveler.userId}`,
                onClick: openProfile,
            },
            traveler.firstName,
        ),
        '\xa0war',
        traveledWith,
        '\xa0im\xa0',
        date,
        '\xa0im\xa0',
        h(
            Link,
            {
                href: `/hrd/${traveler.hotelReviewId}`,
                target: '_blank',
                onClick: trackReviewLink,
            },
            traveler.hotelName,
        ),
    ]);
};

HappyTraveler.propTypes = {
    traveler: PropTypes.shape({
        userId: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        picture: PropTypes.string.isRequired,
        traveledWith: PropTypes.string,
        travelDate: PropTypes.number.isRequired,
        hotelReviewId: PropTypes.string.isRequired,
        hotelName: PropTypes.string.isRequired,
    }).isRequired,
};

HappyTraveler.contextTypes = {
    window: PropTypes.object.isRequired,
    trackEvent: PropTypes.func.isRequired,
};

export default HappyTraveler;
