const LONGFORM_REGEXP = /#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})/i;

const expandShorthand = (hex) =>
    hex
        .substring(1)
        .split('')
        .reduce((acc, value) => `${acc}${value}${value}`, '#');

const hexToDec = (hex) =>
    hex
        .match(LONGFORM_REGEXP)
        .slice(1, 4)
        .map((value) => parseInt(value, 16));

export default (hex) => hexToDec(hex.length === 4 ? expandShorthand(hex) : hex);
