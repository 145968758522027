import glamorous from 'glamorous';
import h from 'react-hyperscript';
import options from '@designsystem/options';

const Heading = glamorous.h2({
    fontSize: options.fontSizes.m,
    fontWeight: options.fontWeights.bold,
    flexGrow: 1,
    margin: 0,
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'space-between',
});

const HeadingWithIcon = glamorous.div({
    alignItems: 'center',
    display: 'flex',
    marginBottom: options.space.s,
});

const Icon = glamorous.div({
    alignItems: 'center',
    display: 'flex',
    marginRight: options.space['2xs'],
});

export default ({ children, icon }) =>
    h(HeadingWithIcon, [
        h(Icon, [
            h(icon, {
                css: {
                    height: options.space['2xl'],
                    width: options.space['2xl'],
                },
            }),
        ]),
        h(Heading, children),
    ]);
