import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import options from '@designsystem/options';

import PrivateMarker from './PrivateMarker';
import colors from '../../styles/colors';
import { xsmallDistance } from '../../styles/distances';
import { defaultFont } from '../../styles/fonts';
import { pxToRem } from '../../styles/unitConverter';
import { black, red, gray, gray90, gray100 } from '../../styles/waveColors';

const { inputBackgroundColor, inputHoverBorderColor } = colors;

const ErrorMessage = glamorous.p({
    fontFamily: options.fonts.default,
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(18),
    color: red,
    margin: `${pxToRem(8)} 0 0`,
});

const InfoMessage = glamorous.p({
    ...defaultFont,
    fontFamily: options.fonts.default,
    fontSize: options.fontSizes.xs,
    color: options.colors.gray20,
    margin: `${pxToRem(4)} 0 0`,
});

const Fieldset = glamorous.fieldset(
    {
        border: 0,
        margin: `0 0 ${xsmallDistance}`,
        padding: 0,
        textAlign: 'start',
    },
    ({ addFieldStyles }) =>
        addFieldStyles && {
            ...addFieldStyles,
        },
);

const Label = glamorous.label({
    fontFamily: options.fonts.default,
    fontWeight: 500,
    fontSize: options.fontSizes.s,
    lineHeight: pxToRem(21),
    color: options.colors.black,
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'space-between',
});

const StyledInput = glamorous.input(
    defaultFont,
    {
        fontFamily: options.fonts.default,
        border: `${pxToRem(1)} solid ${options.colors.gray90}`,
        borderRadius: pxToRem(4),
        boxSizing: 'border-box',
        color: black,
        background: inputBackgroundColor,
        outline: 0,
        margin: `${pxToRem(8)} 0 ${pxToRem(26)}`,
        padding: `${pxToRem(13)} ${pxToRem(16)} ${pxToRem(14)}`,
        width: '100%',
        '&:active, &:focus': {
            borderColor: inputHoverBorderColor,
        },
    },
    ({ hasError }) =>
        hasError && {
            marginBottom: 0,
            borderColor: red,

            '&:active, &:focus': {
                borderColor: red,
            },
        },
    ({ disabled }) =>
        disabled && {
            color: gray,
            borderColor: gray90,
            background: gray100,
            cursor: 'not-allowed',
        },
);

const TextInput = ({
    disabled,
    hasError,
    errorMessage,
    infoMessage,
    name,
    type,
    label,
    placeholder,
    value,
    isPrivate,
    onBlur,
    onChange,
    maxLength,
    addFieldStyles,
}) => {
    const id = `${name}-${Math.random()}`;

    return h(Fieldset, { addFieldStyles }, [
        h(
            Label,
            {
                htmlFor: id,
            },
            [h('span', label), isPrivate && h(PrivateMarker)],
        ),
        infoMessage && h(InfoMessage, [infoMessage]),
        h(StyledInput, {
            hasError: hasError || Boolean(errorMessage),
            id,
            name,
            placeholder,
            onChange: (e) => onChange(e.target.value),
            type,
            value,
            disabled,
            onBlur: (e) => onBlur && onBlur(e.target.value),
            maxLength,
        }),
        Boolean(errorMessage) && h(ErrorMessage, [errorMessage]),
    ]);
};

TextInput.propTypes = {
    addFieldStyles: PropTypes.object,
    disabled: PropTypes.bool,
    errorMessage: PropTypes.node,
    hasError: PropTypes.bool,
    infoMessage: PropTypes.node,
    isPrivate: PropTypes.bool,
    label: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
};

TextInput.defaultProps = {
    disabled: false,
    hasError: false,
    isPrivate: false,
    placeholder: '',
    type: 'text',
    value: '',
};

export default TextInput;
