import h from 'react-hyperscript';
import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Outlet } from 'react-router-dom';

import Albums from '../album/Albums';
import NoAlbums from '../album/NoAlbums';
import LoadingButton from '../loading-button/LoadingButton';
import DownArrowLine from '../icons/DownArrowLine';
import Grid from '../grid/Grid';
import BackToTopButton from '../navigation/BackToTopButton';
import TransitionScreen from '../navigation/TransitionScreen';
import Counter from '../Counter';
import { largeDistance } from '../../styles/distances';
import fetchPictures from '../../fetchers/fetchPictures';
import fetchUnpublishedPictures from '../../fetchers/fetchUnpublishedPictures';
import buildAlbums from '../../lib/buildAlbums';

const NUMBER_OF_PICTURES_PER_REQUEST = 100;
const NUMBER_OF_PICTURES_IN_BUFFER = 400;

class ContentPictures extends Component {
    constructor(...args) {
        super(...args);

        this.state = {
            isLoading: true,
            numberOfPicturesShown: 0,
            pictures: null,
            unpublishedPictures: null,
        };

        this.onShowMorePictures = this.onShowMorePictures.bind(this);
    }

    getNumberOfPicturesForNextPage() {
        return R.pipe(
            R.add(NUMBER_OF_PICTURES_PER_REQUEST),
            R.min(this.state.totalNumberOfPictures),
        )(this.state.numberOfPicturesShown);
    }

    onShowMorePictures() {
        const haveAllPicturesBeenLoaded =
            this.state.pictures.length === this.state.totalNumberOfPictures;

        if (haveAllPicturesBeenLoaded) {
            this.setState({
                numberOfPicturesShown: this.getNumberOfPicturesForNextPage(),
            });
        } else {
            this.loadMorePictures();
        }
    }

    async loadMorePictures() {
        const offset = this.state.pictures.length;
        const numberOfPicturesShownAfterLoad =
            this.getNumberOfPicturesForNextPage();

        this.setState({ isLoading: true });
        const { items } = await fetchPictures(
            this.context,
            offset,
            NUMBER_OF_PICTURES_PER_REQUEST,
        );

        const nextState = R.when(
            R.always(items),
            R.mergeRight({
                numberOfPicturesShown: numberOfPicturesShownAfterLoad,
                pictures: R.concat(this.state.pictures, items),
            }),
        )({ isLoading: false });
        this.setState(nextState);
    }

    renderMorePicturesButton(hasMorePicturesToShow) {
        if (hasMorePicturesToShow) {
            return h(
                Grid,
                {
                    css: {
                        marginBottom: largeDistance,
                        textAlign: 'center',
                    },
                },
                [
                    h(
                        LoadingButton,
                        {
                            isLoading: this.state.isLoading,
                            onClick: this.onShowMorePictures,
                            icon: DownArrowLine,
                        },
                        'Weitere Bilder anzeigen',
                    ),
                ],
            );
        }

        return null;
    }

    renderAlbums(albums) {
        const hasMorePicturesToShow =
            this.state.numberOfPicturesShown < this.state.totalNumberOfPictures;
        return h(Fragment, [
            h(Grid, [
                h(Counter, {
                    count: this.state.totalNumberOfPictures,
                    label: {
                        singular: 'veröffentlichtes Bild',
                        plural: 'veröffentlichte Bilder',
                    },
                }),
            ]),
            h(
                Grid,
                {
                    css: {
                        marginBottom: largeDistance,
                    },
                },
                [
                    h(Albums, {
                        albums,
                        areAlbumsIncomplete: hasMorePicturesToShow,
                    }),
                ],
            ),
            this.renderMorePicturesButton(hasMorePicturesToShow),
            h(BackToTopButton),
            h(Outlet, { context: { albums } }),
        ]);
    }

    async componentDidMount() {
        const initialRequestSize =
            NUMBER_OF_PICTURES_IN_BUFFER + NUMBER_OF_PICTURES_PER_REQUEST;

        const [pictures, unpublishedPictures] = await Promise.all([
            fetchPictures(this.context, 0, initialRequestSize),
            fetchUnpublishedPictures(this.context),
        ]);

        if (pictures && unpublishedPictures) {
            const numberOfPicturesShown = Math.min(
                NUMBER_OF_PICTURES_PER_REQUEST,
                pictures.total,
            );

            this.setState({
                isLoading: false,
                pictures: pictures.items,
                unpublishedPictures: unpublishedPictures.items,
                rejectedPictures: unpublishedPictures.rejectedMedia,
                totalNumberOfPictures: pictures.total,
                totalNumberOfUnpublishedPictures: unpublishedPictures.total,
                numberOfPicturesShown,
            });
        }
    }

    render() {
        const {
            isLoading,
            pictures,
            unpublishedPictures,
            numberOfPicturesShown,
            totalNumberOfPictures,
            totalNumberOfUnpublishedPictures,
            rejectedPictures,
        } = this.state;
        const totalNumberOfAllPictures =
            totalNumberOfPictures + totalNumberOfUnpublishedPictures;
        const albums = pictures
            ? buildAlbums(
                  pictures,
                  unpublishedPictures,
                  rejectedPictures,
                  numberOfPicturesShown,
              )
            : [];
        const content = R.cond([
            [
                R.both(R.propEq('isLoading', true), R.propEq('pictures', null)),
                () => h(TransitionScreen),
            ],
            [
                R.propSatisfies(R.lt(0), 'totalNumberOfAllPictures'),
                () => this.renderAlbums(albums),
            ],
            [R.T, () => h(NoAlbums)],
        ])({ isLoading, pictures, totalNumberOfAllPictures });

        return content;
    }
}

ContentPictures.contextTypes = {
    config: PropTypes.object,
    fetch: PropTypes.func,
};

export default ContentPictures;
