import h from 'react-hyperscript';
import options from '@designsystem/options';
import glamorous from 'glamorous';

import { applyOnTabletAndUp } from '../../styles/mediaQueries';

export const NavigationTabs = glamorous.div({
    display: 'flex',
    fontFamily: options.fonts.default,
    fontSize: options.fontSizes.xs,
    fontWeight: options.fontWeights.bold,
    gap: options.space.m,
    justifyContent: 'center',
    margin: '0 auto',
    marginBottom: options.space.xs,
    maxWidth: 305,
    [applyOnTabletAndUp]: { fontSize: options.fontSizes.s, maxWidth: 343 },
});

const NavigationLink = glamorous.a(({ to, currentPage }) => ({
    textDecoration: 'none',
    color: currentPage === to ? options.colors.black : options.colors.gray40,
    paddingBottom: options.space.m,
    borderBottom:
        currentPage === to ? `2px solid ${options.colors.link}` : 'none',
    '&:hover': {
        color: options.colors.black,
        borderBottom: `2px solid ${options.colors.link}`,
    },
}));

export const NavLink = ({ to, currentPage, children }) => {
    return h(
        NavigationLink,
        {
            to,
            currentPage,
            href: to,
        },
        children,
    );
};

const NavigationBar = ({ currentPage }) => {
    let windowtld = 'de';
    if (typeof window !== 'undefined') {
        /* eslint-env browser */
        const hostnameParts = window.location.hostname.split('.');
        windowtld = hostnameParts[hostnameParts.length - 1];
    }
    const tld = windowtld === 'localhost' ? 'de' : windowtld;
    return h(NavigationTabs, [
        h(
            NavLink,
            {
                style: { marginLeft: options.space.m },
                to: '/mhc/mein-profil',
                currentPage,
            },
            'Profil',
        ),
        h(
            NavLink,
            {
                to: `https://www.holidaycheck.${tld}/einstellungen/meine-kommunikation`,
                currentPage,
            },
            'Kommunikation',
        ),
        h(
            NavLink,

            {
                to: `https://www.holidaycheck.${tld}/einstellungen/meine-reisevorlieben`,
                currentPage,
            },
            'Reisevorlieben',
        ),
    ]);
};

export default NavigationBar;
