import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import colors from '../../styles/colors';
import { secondaryFont } from '../../styles/fonts';
import { pxToRem } from '../../styles/unitConverter';

const {
    radioButtonActiveColor,
    radioButtonBackgroundColor,
    radioButtonOutlineColor,
    radioButtonQualifierColor,
    radioButtonLabelColor,
    radioButtonLabelDisabledColor,
    radioButtonBackgroundDisabled,
    radioButtonBorderDisabled,
    radioButtonActiveColorDisabled,
} = colors;

const RadioWrapper = glamorous.div({
    ':focus-within': {
        '& label:before': {
            outline: `2px solid ${radioButtonActiveColor}`,
            outlineOffset: '4px',
        },
    },
});
/**
 * Opacity is 0 instead of "display: none" because we want to keep native keyboard/shortcut interaction
 * Width is 0 to align styled input to the start of the line
 */
const Input = glamorous.input({
    opacity: 0,
    width: 0,
});

const Label = glamorous.label(
    {
        ...secondaryFont.UIWaveText,
        paddingLeft: pxToRem(32),
        position: 'relative',
        color: radioButtonLabelColor,

        ':after,:before': {
            borderRadius: '50%',
            content: '""',
            position: 'absolute',
        },

        ':after': {
            background: radioButtonBackgroundColor,
            display: 'none',
            height: pxToRem(6),
            left: pxToRem(7),
            top: pxToRem(7),
            width: pxToRem(6),
        },

        ':before': {
            background: radioButtonBackgroundColor,
            border: `1px solid ${radioButtonOutlineColor}`,
            boxSizing: 'border-box',
            display: 'block',
            height: pxToRem(20),
            width: pxToRem(20),
            left: 0,
            top: 0,
        },

        '&:hover, &:active': {
            ':before': {
                border: `1px solid ${radioButtonActiveColor}`,
            },
        },
    },
    ({ checked }) => {
        return checked
            ? {
                  ':after': {
                      display: 'block',
                  },
                  ':before': {
                      background: radioButtonActiveColor,
                      border: `1px solid ${radioButtonActiveColor}`,
                  },
              }
            : null;
    },
    ({ disabled }) => {
        return disabled
            ? {
                  cursor: 'not-allowed',
                  color: radioButtonLabelDisabledColor,

                  ':before': {
                      background: radioButtonBackgroundDisabled,
                      border: `1px solid ${radioButtonBorderDisabled}`,
                  },
              }
            : null;
    },
    ({ checked, disabled }) => {
        return checked && disabled
            ? {
                  ':after': {
                      background: radioButtonActiveColorDisabled,
                  },
              }
            : null;
    },
);

const Qualifier = glamorous.small({
    color: radioButtonQualifierColor,
});

const RadioButton = ({
    checked,
    label,
    name,
    onChange,
    qualifier,
    value,
    disabled,
    onBlur,
}) => {
    const id = `${label}-${Math.random()}`;

    return h(RadioWrapper, [
        h(Input, {
            checked,
            id,
            name,
            onChange,
            type: 'radio',
            value,
            disabled,
            onBlur,
        }),
        h(
            Label,
            {
                checked,
                disabled,
                htmlFor: id,
            },
            [label, qualifier ? h(Qualifier, ` (${qualifier})`) : null],
        ),
    ]);
};

RadioButton.propTypes = {
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    qualifier: PropTypes.string,
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onBlur: PropTypes.func,
};

RadioButton.defaultProps = {
    disabled: false,
};

export default RadioButton;
