/**
 *
 * @param searchId
 * @params data {{numberOfAdults: number, numberOfChildren?: number, isPremiumMember: boolean}}
 * @param context
 * @returns {Promise<{
 *     offerPageUrl: string,
 *     pictureUrl: string,
 *     carClass?: string,
 *     priceAmount: number,
 *     priceAmountFull: number
 *     priceCurrency: string,
 *     destinationName?: string
 *     rentalDays: number,
 *     discountCouponApplied?: boolean
 * } || null>}
 */
const fetchRentalcarOffer = async function (searchId, data, context) {
    const body = JSON.stringify(data);

    const response = await context.window.fetch(
        `${context.config.pathPrefix}/mhc/rentalcar-search/${searchId}`,
        {
            method: 'POST',
            body,
            credentials: 'include',
            headers: {
                'content-type': 'application/json',
            },
        },
    );

    if (response.ok) {
        return response.json();
    }

    return null;
};

export { fetchRentalcarOffer };
