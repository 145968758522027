import { useEffect, Fragment } from 'react';
import glamorous from 'glamorous';
import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import options from '@designsystem/options';

import tracker from '../lib/gav4/tracker';
import { trackPageView } from '../lib/gav4/events';
import { applyOnTabletAndUp } from '../styles/mediaQueries';

const Headline = glamorous.h1({
    textAlign: 'center',
    marginTop: options.space['4xl'],
    fontSize: options.fontSizes.xl,
    fontFamily: options.fonts.default,
    fontWeight: options.fontWeights.extraBold,
    marginBottom: options.space['2xl'],

    [applyOnTabletAndUp]: {
        fontSize: options.fontSizes['3xl'],
        marginBottom: options.space['5xl'],
    },
});

const Page = ({ title, headline, pagename, children }, context) => {
    const { document, trackEvent } = context;

    useEffect(() => {
        document.title = title || headline;
    }, [document, title, headline]);

    useEffect(() => {
        tracker.setPageName(pagename);
        if (pagename) {
            trackEvent({ event: 'page', gePageName: pagename });
            trackPageView();
        }
    }, [pagename]);

    return h(Fragment, [headline && h(Headline, headline), children]);
};

Page.propTypes = {
    title: PropTypes.string,
    headline: PropTypes.string,
    pagename: PropTypes.string,
};

Page.contextTypes = {
    document: PropTypes.object,
    trackEvent: PropTypes.func,
};

export default Page;
