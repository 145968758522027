import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function Globe({ css }) {
    return h(
        Icon,
        {
            viewBox: '0 0 17 18',
            css,
        },
        [
            h('path', {
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                fill: '#000820',
                d: oneLine`M8.35628 17.4777C12.9617 17.4777 16.6896 13.7498 16.6896 9.14437C16.6896 4.53899 12.9617 0.811035 8.35628 0.811035C3.75063 0.811035 0.0229492 4.53885 0.0229492 9.14437C0.0229492 13.7499 3.75063 17.4777 8.35628 17.4777ZM15.523 9.14437C15.523 9.80837 15.4329 10.451 15.2643 11.061H11.5439C11.6399 10.4363 11.6896 9.7962 11.6896 9.14437C11.6896 8.49254 11.6399 7.85245 11.5439 7.2277H15.2643C15.4329 7.8377 15.523 8.48037 15.523 9.14437ZM6.35067 11.061H10.3619C10.4678 10.438 10.523 9.7977 10.523 9.14437C10.523 8.49104 10.4678 7.8507 10.3619 7.2277H6.35067C6.24477 7.8507 6.18962 8.49104 6.18962 9.14437C6.18962 9.7977 6.24477 10.438 6.35067 11.061ZM5.16869 11.061H1.44825C1.27967 10.451 1.18962 9.80837 1.18962 9.14437C1.18962 8.48037 1.27967 7.8377 1.44825 7.2277H5.16869C5.07272 7.85245 5.02295 8.49254 5.02295 9.14437C5.02295 9.7962 5.07272 10.4363 5.16869 11.061ZM6.61326 6.06104H10.0993C9.7312 4.75428 9.13503 3.54357 8.3562 2.47458C7.57745 3.54356 6.98128 4.75426 6.61326 6.06104ZM7.2147 2.06791C4.85329 2.4451 2.87873 3.97374 1.88375 6.06104H5.40542C5.77236 4.6123 6.39197 3.26474 7.2147 2.06791ZM5.40542 12.2277C5.77236 13.6765 6.39197 15.024 7.2147 16.2208C7.22378 16.2223 7.23278 16.2237 7.24187 16.2251C4.86844 15.8554 2.88254 14.323 1.88375 12.2277H5.40542ZM8.3562 15.8141C9.13503 14.7452 9.7312 13.5345 10.0993 12.2277H6.61327C6.98128 13.5345 7.57745 14.7452 8.3562 15.8141ZM14.8288 6.06104H11.3071C10.9401 4.61231 10.3204 3.26474 9.4977 2.06789C11.859 2.44507 13.8337 3.97376 14.8288 6.06104ZM11.3071 12.2277C10.9401 13.6765 10.3204 15.024 9.4977 16.2209C11.859 15.8437 13.8337 14.315 14.8287 12.2277H11.3071Z`,
            }),
        ],
    );
}
