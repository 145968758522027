import gql from 'graphql-tag';

export const email = gql`
    query {
        profile {
            email
        }
    }
`;

export const profileAndCountries = gql`
    query {
        profile {
            city
            contactAllowed
            country
            dateOfBirth
            email
            familyName
            givenName
            profilePicture {
                transformations {
                    medium
                }
                isFallback
            }
            profileVisibility
            salutation
            milesNumber
        }
        countries {
            id
            name
        }
    }
`;

export const profilePictureIsFallback = gql`
    query {
        profile {
            profilePicture {
                isFallback
            }
        }
    }
`;
