/* Use when element is hidden from the screen but should be accessed by assistive technologies */
const visuallyHidden = {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
};

/* Use when element is hidden only from assistive tech, not visually */
const assistiveTechHidden = {
    ariaHidden: 'true',
};

export { visuallyHidden, assistiveTechHidden };
