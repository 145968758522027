import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import Page from './Page';
import MemberQuery from './myPremium/MemberQuery';

const MyPremiumContainer = glamorous.div({
    fontFamily: 'Montserrat !important',
});

function MyPremium(props, { config: { assetsPath } }) {
    return h(MyPremiumContainer, [
        h(
            Page,
            {
                pagename: '/mhc/mein-premium',
                title: 'Mein Premium',
            },
            [h(MemberQuery, { config: { assetsPath } })],
        ),
    ]);
}

MyPremium.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

export default MyPremium;
