import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function Star(options = {}) {
    return h(Icon, options, [
        h('path', {
            d: oneLine`
        M8.935 26.457c-.664 .404 -1.055 .116 -.875 -.632l1.92 -8.01L3.693 12.458c-.59
        -.503 -.436 -.962 .334 -1.023l8.253 -.658 3.18 -7.603c.298 -.714 .784 -.71 1.081
        0l3.18 7.603 8.252 .658c.775 .062 .921 .523 .334 1.023l-6.287 5.357 1.92
        8.01c.181 .752 -.215 1.033 -.875 .632L16 22.165l-7.065 4.292z`,
        }),
    ]);
}
