import { useEffect } from 'react';
import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import { getAdvantages, getCardConfigKey, getImageName } from './config';
import { RentalcarCardContainer } from '../components/RentalcarCardContainer';
import CarWheel from '../../icons/CarWheel';
import { waveTextMMedium, waveTextS } from '../../../styles/waveText';
import {
    waveLgMediaQuery,
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../../styles/waveMediaQueries';
import { waveHeadline2XS } from '../../../styles/waveHeadlines';
import { SlimCheckmark } from '../../icons/Checkmark';
import { Offer } from './Offer';
import { Loading } from './Loading';
import { RentalcarLinkCard } from '../RentalcarLinkCard';
import { getUtmParameters } from '../RentalcarCard';
import useRentalcarSearch from '../useRentalcarSearch';
import useSearchOffer from '../useSearchOffer';
import { RentalcarButton } from '../components/RentalcarButton';

const CardContainer = glamorous(RentalcarCardContainer)({
    borderRadius: '8px',
    fontFamily: 'Montserrat',
    flexDirection: 'column-reverse',
    justifyContent: 'space-between',
    margin: 0,
    [waveMdMediaQuery]: {
        // override base style from RentalcarCardContainer
        margin: 0,
    },
    [waveLgMediaQuery]: {
        margin: 0,
        flexDirection: 'row',
    },
});

const SmallTitle = glamorous.div({
    display: 'flex',
    alignItems: 'center',
    gap: options.space.xs,
    ...waveTextMMedium,
    color: options.colors.green40,
});

const Title = glamorous.span({
    ...waveHeadline2XS,
});

const Content = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    gap: options.space.m,
    padding: options.space.xl,
    [waveMdMediaQuery]: {
        padding: `${options.space.xl}px 0 ${options.space.m}px ${options.space.l}px`,
    },
});

const Advantages = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    gap: options.space['2xs'],
});
const Advantage = glamorous.span({
    display: 'flex',
    alignItems: 'center',
    gap: options.space['2xs'],
});
const AdvantageText = glamorous.span({
    ...waveTextS,
});

const renderAdventage = (text) =>
    h(Advantage, [
        h(
            glamorous(SlimCheckmark)({
                color: options.colors.white,
                flexShrink: 0,
            }),
        ),
        h(AdvantageText, text),
    ]);

const ImageContainer = glamorous.div({
    position: 'relative',
    width: '100%',
    [waveLgMediaQuery]: {
        flex: '0 1 auto',
        width: '195px',
        height: 'auto',
    },
});

const Image = glamorous.img(({ cardType }) => ({
    width: '100%',
    maxHeight: '150px',
    height: 'auto',
    display: 'block',
    objectFit: 'cover',
    borderWidth: `0 0 1px 1px`,
    borderColor: options.colors.white,
    borderStyle: 'solid',
    borderRadius: `${options.space.xs}px ${options.space.xs}px 0 0`,
    ...(cardType !== 'default'
        ? {
              objectPosition: '50% 43%',
              [waveSmMediaQuery]: { objectPosition: '50% 34%' },
              [waveMdMediaQuery]: { objectPosition: '70% 40%' },
          }
        : {
              objectPosition: '50% 50%',
              [waveSmMediaQuery]: { objectPosition: '50% 55%' },
              [waveMdMediaQuery]: { objectPosition: '50% 65%' },
          }),
    [waveLgMediaQuery]: {
        position: 'absolute',
        maxHeight: 'unset',
        height: '100%',
        width: 'auto',
        maxWidth: '236px',
        borderRadius: options.space.xs,
        borderWidth: `0 0 0 1px`,
        transform: cardType !== 'default' ? `scaleX(-1)` : null,
    },
}));

const WaveMobile = h(
    glamorous.svg({
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 'auto',
        [waveLgMediaQuery]: {
            display: 'none',
        },
    }),
    {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 335 30',
    },
    [
        h('path', {
            d: 'M335 29.9877H334.004C329.306 29.8582 308.518 28.6648 282.011 24.3773C251.981 19.5201 217.978 11.8399 189.252 10.8706C131.692 8.92813 121.184 17.2429 87.1505 17.2429C53.1166 17.2429 0 0 0 0V29.9877H334.004C334.628 30.0049 334.968 30.0033 335 29.9877Z',
            fill: 'white',
        }),
    ],
);

const WaveDesktop = h(
    glamorous.svg({
        position: 'absolute',
        top: '-1px',
        left: '-1px',
        bottom: '-1px',
        width: 'auto',
        display: 'none',
        height: '102%',
        [waveLgMediaQuery]: {
            display: 'block',
        },
    }),
    {
        xmlns: 'http://www.w3.org/2000/svg',
        width: '63',
        height: '359',
        viewBox: '0 0 63 359',
        fill: 'none',
    },
    [
        h('path', {
            d: 'M0.0200039 2.45581L0.0187297 2.73114e-06L23.5 1.70474e-06C23.5 1.70474e-06 5.02986 44.5 13 75C20.9701 105.5 39.232 113 43.5 160.5C47.768 208 29.5 231.777 29.5 268C29.5 304.223 62.5 359 62.5 359L0.0200195 359L0.0187297 2.73114e-06C0.0187297 2.73114e-06 -0.0242465 2.48941 0.0200039 2.45581Z',
            fill: 'white',
        }),
    ],
);

const NoOfferContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    gap: options.space.m,
});

const NoOfferFoundText = glamorous.p({
    ...waveTextS,
    color: options.colors.blue,
    margin: 0,
});

const renderTitle = (advantages) => [
    h('div', [
        h(SmallTitle, [
            h(CarWheel, { css: { width: '16px' } }),
            h('span', 'Nur hier und nur für Dich'),
        ]),
        h(Title, 'Unsere Mietwagen-Empfehlung'),
    ]),
    h(Advantages, advantages.map(renderAdventage)),
];

const renderImage = (cardType, imageSource) => [
    h(ImageContainer, [
        h(Image, {
            cardType,
            src: imageSource,
        }),
        WaveMobile,
        WaveDesktop,
    ]),
];

function RentalcarOfferCard({
    hcTravel,
    context,
    showDefaultHubSearchOffer,
    utmParameter,
    eventCategory,
}) {
    const { trackEvent } = context;

    if (showDefaultHubSearchOffer) {
        return h(CardContainer, [
            h(Content, [
                h(renderTitle(getAdvantages('default'))),
                h(
                    RentalcarButton,
                    {
                        href: `/mietwagen?utm_source=mHC&utm_medium=Hub&utm_campaign=TravelNoCarNo`,
                    },
                    'Mietwagen finden',
                ),
            ]),
            h(
                renderImage(
                    'default',
                    `${context.config.assetsPath}/hcMietwagen/other.jpg`,
                ),
            ),
        ]);
    }
    const cardConfigKey = getCardConfigKey(hcTravel.booking.travellers);

    useEffect(() => {
        trackEvent({
            event: 'event',
            eventCategory,
            eventAction: `displayCard=${cardConfigKey}`,
            eventLabel: 'Rentalcar - Offercard',
        });
    }, []);

    const { search, searchData } = useRentalcarSearch(context, hcTravel);

    const { offer, isLoading } = useSearchOffer(
        search,
        hcTravel,
        false,
        context,
    );

    const advantages = getAdvantages(cardConfigKey);

    if (search) {
        return h(CardContainer, [
            h(Content, [
                h(renderTitle(advantages)),
                isLoading && h(Loading, { isFinished: !isLoading }),
                !isLoading &&
                    offer &&
                    h(Offer, {
                        cardType: cardConfigKey,
                        linkToListing: cardConfigKey === 'default',
                        hcTravel,
                        search,
                        searchData,
                        offer,
                        context,
                        utmParameter,
                    }),
                !isLoading &&
                    !offer &&
                    h(NoOfferContainer, [
                        h(
                            NoOfferFoundText,
                            'Wir haben kein aktuelles Angebot gefunden.',
                        ),
                        h(
                            RentalcarButton,
                            {
                                href: `/mietwagen?utm_source=mHC&utm_medium=Hub&utm_campaign=noOfferFoundRedirect`,
                            },
                            'Mietwagen finden',
                        ),
                    ]),
            ]),
            h(
                renderImage(
                    cardConfigKey,
                    `${context.config.assetsPath}/hcMietwagen/${getImageName(cardConfigKey)}`,
                ),
            ),
        ]);
    }

    return h(RentalcarLinkCard, {
        bubbleImageLink: `${context.config.assetsPath}/hcMietwagen/CarBubble.png`,
        carImageLink:
            '/mietwagen/assets-image/180x115/f080a9f0-f3c9-433a-bc27-8180dcd6e561.png',
        trackEvent: context.trackEvent,
        parameters: getUtmParameters(true, false),
    });
}

export { RentalcarOfferCard };
