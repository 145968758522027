import h from 'react-hyperscript';
import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import * as R from 'ramda';
import { oneLine } from 'common-tags';
import glamorous from 'glamorous';

import callMethodForElementsInDocument from '../lib/callMethodForElementsInDocument';
import VariableHeightIframe from './VariableHeightIframe';
import TransitionScreen from './navigation/TransitionScreen';

const MinHeightContainer = glamorous.div({
    minHeight: 460,
    paddingTop: '2.5rem',
    paddingBottom: '2.5rem',
});

const initialHeight = 0;

const defaultRemoveElementsSelector = oneLine`
    #hcContent > footer,
    #header-wrap > *,
    #meta
`;

const defaultStylesheet = oneLine`
    #content > .box:last-child, #content > .noticeInfo:last-child, #hcContent {
        margin-bottom: 0;
    }
    #content > .listitemBox {
        margin-top: 0;
    }
    #container, #hcContent {
        width: 100%;
    }
    #content {
        margin: 0 auto;
        width: auto;
        max-width: 988px;
        min-height: 460px;
    }

    @media screen and (max-width: 1008px) {
        #content {
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    #hcContent {
        overflow: hidden;
    }

    .tfagAd,
    object,
    #header-wrap,
    #breadcrumb {
        display: none;
    }
`;

const checkIframeReadyStateIntervalInMs = 3000;
const updateHeightIntervalInMs = 250;

class ContentIframe extends Component {
    constructor(...args) {
        super(...args);

        this.reset = this.reset.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onUnload = this.onUnload.bind(this);
        this.updateHeight = this.updateHeight.bind(this);
        this.addResizeInterval = this.addResizeInterval.bind(this);
        this.clearResizeInterval = this.clearResizeInterval.bind(this);

        this.state = {
            loading: true,
            height: initialHeight,
        };
    }

    render() {
        const { loading } = this.state;
        const hasQueryString = this.props.src.includes('?');
        const separator = hasQueryString ? '&' : '?';
        const src = `${this.props.src}${separator}showWithinIframe=true`;

        return h(MinHeightContainer, [
            loading ? h(TransitionScreen) : null,
            h(VariableHeightIframe, {
                src,
                height: this.state.height,
                iframeRef: (ref) => {
                    if (ref) {
                        this.iframeNode = ReactDOM.findDOMNode(ref);
                    }
                },
            }),
        ]);
    }

    componentDidMount() {
        this.checkReadyState();
        this.iframeNode.addEventListener('load', this.onLoad);
    }

    componentWillUnmount() {
        this.reset();
        this.iframeNode.removeEventListener('load', this.onLoad);
        this.context.window.clearTimeout(this.readyStateTimer);
    }

    checkReadyState() {
        this.readyStateTimer = this.context.window.setTimeout(() => {
            if (this.state.loading === true) {
                const iframeDocument = this.getIframeDocument();
                const readyState = iframeDocument
                    ? iframeDocument.readyState
                    : null;

                if (readyState === 'loading') {
                    this.checkReadyState();
                } else {
                    this.onLoad();
                }
            }
        }, checkIframeReadyStateIntervalInMs);
    }

    onLoad() {
        if (this.state.loading) {
            this.iframeNode.contentWindow.addEventListener(
                'unload',
                this.onUnload,
            );

            this.modifyIframeContent();

            this.updateHeight();
            this.addResizeInterval();
            this.registerClientsideNavigation();
            this.setState({ loading: false });
            this.context.window.clearTimeout(this.readyStateTimer);
        }
    }

    onUnload() {
        this.reset();
        this.checkReadyState();
    }

    modifyIframeContent() {
        this.callMethodForSelectors();
    }

    addResizeInterval() {
        this.interval = this.context.window.setInterval(
            this.updateHeight,
            updateHeightIntervalInMs,
        );
    }

    clearResizeInterval() {
        this.context.window.clearInterval(this.interval);
    }

    reset() {
        this.iframeNode.contentWindow.removeEventListener(
            'unload',
            this.onUnload,
        );

        this.clearResizeInterval();
        this.removeClientsideNavigationClickListeners();
        this.setState({
            loading: true,
            height: initialHeight,
        });
    }

    updateHeight() {
        this.setState({ height: this.getIframeContent().offsetHeight });
    }

    registerClientsideNavigation() {
        const clickListeners =
            this.context.registerClientsideNavigationInDocument(
                this.getIframeDocument(),
                this.context.config.pathPrefix,
                this.props.navigate,
            );

        this.setState({
            clientsideNavigationClickListeners: clickListeners,
        });
    }

    removeClientsideNavigationClickListeners() {
        if (this.state.clientsideNavigationClickListeners) {
            this.context.removeClientsideNavigationClickListenersFromDocument(
                this.getIframeDocument(),
                this.context.config.pathPrefix,
                this.state.clientsideNavigationClickListeners,
            );
        }
    }

    callMethodForSelectors() {
        const {
            appendBaseTag,
            injectStylesheet,
            removeElement,
            setTargetSelf,
        } = this.context.elementModifiers;

        const methodsForSelectors = R.mergeWith(
            R.concat,
            {
                'a[href*="Shadowbox"],a[href*="showPopUp"],a[href*="mhcShowBox"]':
                    [setTargetSelf],
                [defaultRemoveElementsSelector]: [removeElement],
                head: [appendBaseTag, injectStylesheet(defaultStylesheet)],
                form: [setTargetSelf],
            },
            this.props.callMethodForElements || {},
        );

        const callMethod = (callbackMethods, selector) => {
            return callMethodForElementsInDocument(
                this.getIframeDocument(),
                selector,
                callbackMethods,
            );
        };
        R.mapObjIndexed(callMethod, methodsForSelectors);
    }

    getIframeDocument() {
        return (
            this.iframeNode.contentWindow &&
            this.iframeNode.contentWindow.document
        );
    }

    getIframeContent() {
        return this.getIframeDocument().documentElement;
    }
}

ContentIframe.contextTypes = {
    config: PropTypes.object,
    registerClientsideNavigationInDocument: PropTypes.func,
    removeClientsideNavigationClickListenersFromDocument: PropTypes.func,
    window: PropTypes.object,
    elementModifiers: PropTypes.object,
};

export default ContentIframe;
