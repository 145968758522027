import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import { largeDistance } from '../../styles/distances';
import colors from '../../styles/colors';
import { extraTinyFont } from '../../styles/fonts';
import Link from '../navigation/Link';

const { footerBackgroundColor, primaryBrandColor } = colors;

const Image = glamorous.img({
    width: '100%',
    position: 'absolute',
    top: '-100%',
    left: 0,
    right: 0,
    bottom: '-100%',
    margin: 'auto',
});

const StyledPictureCount = glamorous.div(extraTinyFont, {
    width: '100%',
    height: largeDistance,
    position: 'absolute',
    lineHeight: largeDistance,
    bottom: 0,
    backgroundColor: footerBackgroundColor,
    opacity: 0.9,
    color: primaryBrandColor,
    fontWeight: 700,
});

const Picture = glamorous.picture({
    cursor: 'pointer',
});

function PicturePreview(
    { picture, totalPictures, review },
    { config: { mediaBaseUrl } },
) {
    const fallbackTransformation = 'w_220,h_164,c_fill,q_auto,f_auto';
    const transformationSettings = [
        {
            transformation: 'w_220,h_164,c_fill,q_auto,f_auto',
            mediaQuery: '(min-width: 750px)',
        },
        {
            transformation: 'w_768,h_432,c_fill,q_auto,f_auto',
            mediaQuery: '(min-width: 581px)',
        },
        {
            transformation: 'w_510,h_300,c_fill,q_auto,f_auto',
            mediaQuery: '(min-width: 331px)',
        },
        {
            transformation: 'w_260,h_175,c_fill,q_auto,f_auto',
        },
    ];
    const pictureSources = transformationSettings.map(
        ({ transformation, mediaQuery }) => {
            return h('source', {
                srcSet: `${mediaBaseUrl}${transformation}/ugc/images/${picture.id}`,
                media: mediaQuery,
            });
        },
    );

    const reviewLink =
        review.status === 'PUBLISHED'
            ? `/hrd/${review.id}`
            : `mhc/meine-urlaubsbilder/${picture.id}`;

    const fallbackPicture = h(Image, {
        src: `${mediaBaseUrl}${fallbackTransformation}/ugc/images/${picture.id}`,
    });

    const pictureCountText =
        totalPictures === 1
            ? '1 eigenes Bild'
            : `${totalPictures} eigene Bilder`;

    return h(Link, { href: reviewLink }, [
        h(Picture, [
            ...pictureSources,
            fallbackPicture,
            h(StyledPictureCount, [pictureCountText]),
        ]),
    ]);
}

PicturePreview.contextTypes = {
    config: PropTypes.object,
};

export default PicturePreview;
