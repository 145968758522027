import h from 'react-hyperscript';
import { Component } from 'react';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import options from '@designsystem/options';

import CancelButton from './CancelButton';
import DataProtectionNotice from '../legal/DataProtectionNotice';
import Column from '../grid/Column';
import ChangeEmailConfirmationDialog from './ChangeEmailConfirmationDialog';
import Link from '../navigation/Link';
import LoadingButton from '../loading-button/LoadingButton';
import RightArrowLine from '../icons/RightArrowLine';
import TextInput from '../inputs/TextInput';
import isValidEmail from '../../lib/isValidEmail';
import { applyOnTabletAndUp, applyOnMobile } from '../../styles/mediaQueries';
import { pxToRem } from '../../styles/unitConverter';
import toggleProp from '../../lib/toggleProp';

const Form = glamorous.form({
    alignItems: 'center',
    background: options.colors.gray100,
    display: 'flex',
    flexDirection: 'column',
    marginTop: pxToRem(24),
    padding: `${options.space.l}px 0 ${options.space.xl}px`,

    [applyOnTabletAndUp]: {
        paddingTop: options.space.xl,
    },
});

const InfoText = glamorous.p({
    textAlign: 'center',
    margin: `0 0 ${options.space.l}px`,
});

const Controls = glamorous.div({
    display: 'flex',
    justifyContent: 'center',
    margin: `${options.space.m}px 0`,
    flexWrap: 'wrap-reverse',
});

const SubmitButtonWrapper = glamorous.div({
    [applyOnMobile]: {
        marginBottom: options.space.s,
        flexBasis: '100%',
        textAlign: 'center',
    },
});

const isConflictingMailError = function (errorResult) {
    return errorResult === 'ERROR_CONFLICTING_EMAIL';
};

class ChangeEmailForm extends Component {
    constructor(props, ...rest) {
        super(props, ...rest);

        this.state = {
            originalEmail: props.email,
            email: props.email,
            isConfirmationOverlayVisible: false,
            mergeRequest: false,
        };

        this.toggleConfirmationOverlay =
            this.toggleConfirmationOverlay.bind(this);
        this.updateEmailValue = this.updateEmailValue.bind(this);
        this.submitEmailChange = this.submitEmailChange.bind(this);
        this.submitRequestManualMerge =
            this.submitRequestManualMerge.bind(this);
    }

    toggleConfirmationOverlay(event) {
        event.preventDefault();

        this.setState(toggleProp('isConfirmationOverlayVisible'));
    }

    updateEmailValue(email) {
        this.setState({ email });
    }

    submitEmailChange() {
        this.props.onChange({ changedEmail: this.state.email });
        this.setState(toggleProp('isConfirmationOverlayVisible'));
    }

    submitRequestManualMerge(e) {
        e.preventDefault();
        this.setState({ mergeRequest: true });
        this.props.onChange({ mergeWithEmail: this.state.email });
    }

    renderConfirmationDialog() {
        if (
            this.state.mergeRequest ||
            (!this.state.isConfirmationOverlayVisible &&
                !this.props.isRequestRunning)
        ) {
            return null;
        }

        return h(ChangeEmailConfirmationDialog, {
            email: this.state.email,
            isRequestRunning: this.props.isRequestRunning,
            onClose: this.toggleConfirmationOverlay,
            onSubmit: this.submitEmailChange,
        });
    }

    renderErrorMessage(errorResult) {
        if (isConflictingMailError(errorResult)) {
            return h([
                'Es existiert bereits ein anderer Zugang mit dieser E-Mail. Bitte klicke auf "Zusammenlegen", ',
                'falls Du Deine beiden Zugänge zusammen legen möchtest. ',
                'Unser Serviceteam meldet sich schnellstmöglich bei Dir.',
            ]);
        }

        return h([
            'Entschuldigung, etwas ist schiefgelaufen. Bitte wende Dich an ',
            h(
                Link,
                { href: 'mailto:community@holidaycheck.de' },
                'community@holidaycheck.de',
            ),
            '.',
        ]);
    }

    renderSubmitButton(isInactive, isLoading, errorResult) {
        const isMergeRequest =
            isConflictingMailError(errorResult) || this.state.mergeRequest;

        const onClick = isMergeRequest
            ? this.submitRequestManualMerge
            : this.toggleConfirmationOverlay;
        const label = isMergeRequest ? 'Zusammenlegen' : 'E–Mail speichern';

        return h(SubmitButtonWrapper, [
            h(
                LoadingButton,
                {
                    icon: RightArrowLine,
                    isInactive,
                    isLoading,
                    onClick,
                    type: 'submit',
                },
                label,
            ),
        ]);
    }

    render() {
        const { email, originalEmail } = this.state;
        const submitButtonIsActive =
            isValidEmail(email) && email !== originalEmail;
        const errorResult =
            this.props.result !== 'SUCCESS' && this.props.result;

        return h([
            h(Form, [
                h(Column, { medium: 8, large: 6.5 }, [
                    h(InfoText, [
                        h('strong', 'Achtung: '),
                        'Bist Du im Reiseforum aktiv?',
                        h('br'),
                        `Wenn Du Deine E-Mail-Adresse änderst, erhältst Du ein neues Forenprofil.
                        Du hast dann keinen Zugriff mehr auf Dein bisheriges Forenprofil und Deine Posts.
                        Auf Deine Inhalte im meinHolidayCheck Bereich hast Du weiterhin Zugriff wie bisher.`,
                    ]),
                    h(InfoText, [
                        h('strong', 'Achtung: '),
                        'Du benutzt Google, Apple oder Facebook für den Login?',
                        h('br'),
                        `Wenn Du Deine E-Mail-Adresse änderst, musst Du Dich in Zukunft mit Deiner
                        neuen E-Mail-Adresse anmelden. Ein Login über Google, Apple oder Facebook
                        in Deinem meinHolidayCheck Bereich ist nicht mehr möglich.`,
                    ]),
                    h(TextInput, {
                        errorMessage:
                            errorResult && this.renderErrorMessage(errorResult),
                        isPrivate: true,
                        label: 'E-Mail',
                        onChange: this.updateEmailValue,
                        type: 'email',
                        value: this.state.email,
                        name: 'email',
                    }),
                ]),
                h(Controls, [
                    h(CancelButton),
                    this.renderSubmitButton(
                        !submitButtonIsActive,
                        this.props.isRequestRunning,
                        errorResult,
                    ),
                ]),
                h(DataProtectionNotice),
            ]),
            this.renderConfirmationDialog(),
        ]);
    }
}

ChangeEmailForm.getDerivedStateFromProps = (props, prevState) => {
    if (props.email !== prevState.originalEmail) {
        return {
            email: props.email,
            originalEmail: props.email,
        };
    }

    return null;
};

ChangeEmailForm.propTypes = {
    isRequestRunning: PropTypes.bool.isRequired,
    result: PropTypes.string,
    email: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default ChangeEmailForm;
