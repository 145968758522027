import h from 'react-hyperscript';
import options from '@designsystem/options';
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';

import { sizes, WaveButton } from '../WaveButton';
import { pxToRem } from '../../styles/unitConverter';
import { premiumGradient, premiumGradientHover } from '../../styles/waveColors';
import RedirectWithReload from '../navigation/RedirectWithReload';
import { postFuturePeriod } from '../../mutators/premium/futurePeriod';
import { noticeError } from '../../lib/newrelic';

const checkStatus = (data, status) => data && data.postFuturePeriod === status;

const FuturePeriodDiscountButton = ({
    trackingLabel,
    trackEventForPremium,
    discountCode = 'RENEWAL_49',
    cta = 'Jetzt verlängern für 49€',
}) => {
    useEffect(() => {
        trackEventForPremium({
            event: {
                eventAction: 'Renew-with-discount-shown',
                eventLabel: trackingLabel,
            },
        });
    }, []);

    const [fetchPeriodDiscount, { data, loading, error }] =
        useMutation(postFuturePeriod);

    if (error || checkStatus(data, 'ERROR')) {
        if (error) {
            noticeError(error.message, {
                pageName: 'MyPremium',
                component: 'FuturePeriodDiscountButton',
            });
        }

        trackEventForPremium({
            event: {
                eventAction: 'Renew-with-discount-error',
                eventLabel: trackingLabel,
            },
        });

        return h(
            'p',
            { style: { color: options.colors.red } },
            'Leider ist ein Fehler aufgetreten und wir können Dir aktuell keine Verlängerung für 49€ anbieten',
        );
    }

    if (checkStatus(data, 'SUCCESS')) {
        trackEventForPremium({
            event: {
                eventAction: 'Renew-with-discount-success',
                eventLabel: trackingLabel,
            },
        });
        return h(RedirectWithReload, { to: '/mhc/mein-premium' });
    }

    return h(
        WaveButton,
        {
            size: sizes.S,
            onClick: () => {
                trackEventForPremium({
                    event: {
                        eventAction: 'Click-Renew-With-Discount',
                        eventLabel: trackingLabel,
                    },
                });
                fetchPeriodDiscount({
                    variables: {
                        input: {
                            discountCode,
                        },
                    },
                });
            },
            loading,
            customStyles: {
                margin: `${pxToRem(options.space['3xl'])} auto 0 auto`,
                padding: `${pxToRem(options.space.s)} ${pxToRem(options.space.l)}`,
                display: 'block',
                background: premiumGradient,
                border: 'none',
                fontSize: options.fontSizes.s,
                '&:hover': {
                    background: premiumGradientHover,
                },
            },
        },
        cta,
    );
};

export default FuturePeriodDiscountButton;
