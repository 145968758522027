/* eslint no-param-reassign: 0, no-empty: 0 */

const SESSION_STORAGE_KEY = '/mhc/profilePictureCache';

function set(window, profilePictureInformation) {
    window.profilePictureCache = profilePictureInformation;
    try {
        window.sessionStorage.setItem(
            SESSION_STORAGE_KEY,
            JSON.stringify(profilePictureInformation),
        );
    } catch (e) {}
}

function unset(window) {
    window.profilePictureCache = null;
    try {
        window.sessionStorage.removeItem(SESSION_STORAGE_KEY);
    } catch (e) {}
}

function get(window) {
    if (window.profilePictureCache) {
        return window.profilePictureCache;
    }
    try {
        return JSON.parse(window.sessionStorage.getItem(SESSION_STORAGE_KEY));
    } catch (e) {
        return null;
    }
}

export default { set, unset, get };
