import * as R from 'ramda';

const fallbackParent = {
    type: 'OTHER',
};

const remapPictures = (pictures) =>
    pictures.map((picture) => {
        const parent = picture.parents[0] || fallbackParent;

        return R.pipe(
            R.dissoc('parents'),
            R.assoc('entityId', parent.id),
            R.assoc('entityType', parent.type.toLowerCase()),
            R.assoc('entityName', parent.name),
        )(picture);
    });

export default remapPictures;
