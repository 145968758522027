import PropTypes from 'prop-types';
import moment from 'moment';

const DatePropType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(moment),
]);
const DestinationType = PropTypes.shape({
    destination: PropTypes.string.isRequired,
    preposition: PropTypes.string.isRequired,
});
const StationType = PropTypes.shape({
    address: PropTypes.string.isRequired,
    regionName: PropTypes.string.isRequired,
    countryName: PropTypes.string.isRequired,
});
const PriceType = PropTypes.shape({
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
});
export const RetalCarBookingType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.oneOf(['Pending', 'Confirmed', 'Failed', 'Cancelled'])
        .isRequired,
    pickupDatetime: DatePropType.isRequired,
    dropoffDatetime: DatePropType.isRequired,
    carOfferDestination: PropTypes.string.isRequired,
    carOfferDestinationRegion: DestinationType.isRequired,
    carOfferDestinationLand: DestinationType.isRequired,
    pickupStation: StationType.isRequired,
    dropoffStation: StationType.isRequired,
    carOfferPictureUrl: PropTypes.string.isRequired,
    transferType: PropTypes.oneOf(['InsideTerminal', 'Shuttle']).isRequired,
    carOffer: PropTypes.shape({
        offerId: PropTypes.string.isRequired,
        localSupplierName: PropTypes.string.isRequired,
        destination: PropTypes.string.isRequired,
        destinationName: PropTypes.string.isRequired,
        dropoffDestination: PropTypes.string.isRequired,
        dropoffDestinationName: PropTypes.string.isRequired,
        priceAmount: PropTypes.number.isRequired,
        priceCurrency: PropTypes.string.isRequired,
        pictureUrl: PropTypes.string.isRequired,
        exampleCar: PropTypes.string.isRequired,
        carClass: PropTypes.string.isRequired,
        transmission: PropTypes.string.isRequired,
        seats: PropTypes.number,
        doors: PropTypes.number,
        airConditioning: PropTypes.bool,
        features: PropTypes.arrayOf(PropTypes.string).isRequired,
        offerPageUrl: PropTypes.string.isRequired,
        featurePackage: PropTypes.string,
    }),
    files: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            tpe: PropTypes.string,
        }).isRequired,
    ).isRequired,
    price: PropTypes.shape({
        car: PriceType,
        other: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                price: PriceType,
            }),
        ).isRequired,
        extras: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                price: PriceType,
            }),
        ).isRequired,
        discountCoupons: PropTypes.arrayOf(
            PropTypes.shape({
                code: PropTypes.string.isRequired,
                worth: PriceType,
                valid: PropTypes.bool,
            }),
        ).isRequired,
    }).isRequired,
    supplierName: PropTypes.string.isRequired,
    customerFirstName: PropTypes.string.isRequired,
    customerLastName: PropTypes.string,
    paymentType: PropTypes.string,
    hcDestination: PropTypes.arrayOf(
        PropTypes.shape({
            countryId: PropTypes.string,
            regionId: PropTypes.string,
            cityId: PropTypes.string,
        }),
    ),
});
