import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import PropTypes from 'prop-types';

import { pxToRem } from '../../styles/unitConverter';
import { waveHeadline2XS } from '../../styles/waveHeadlines';
import { premiumGradient } from '../../styles/waveColors';
import { waveTextMBold, waveTextMButton } from '../../styles/waveText';
import Clipboard from '../common/Clipboard';
import useInScreen from '../../lib/hooks/useInScreen';
import { usePremiumTrackingContext } from './context/PremiumTrackingContext';

const PremiumPartnerCard = glamorous.div(({ isHeroBenefit }) => ({
    display: 'flex',
    flexDirection: 'column',
    borderRadius: options.radii.small,
    boxShadow: options.shadows.shallow,
    transition: 'box-shadow .3s',
    [`@media (min-width: ${options.breakpoints.s})`]: {
        flexBasis: '45%',
        flexGrow: 1,
    },
    [`@media (min-width: ${options.breakpoints.m})`]: {
        flexBasis: isHeroBenefit ? '45%' : '30%',
        flexGrow: 1,
    },

    '&:hover': {
        boxShadow: options.shadows.raisedOnDark,
    },
}));
const Header = glamorous.div({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
});
const Headlines = glamorous.div({
    fontSize: options.fontSizes.l,
    fontWeight: options.fontWeights.bold,
    lineHeight: pxToRem(24),
    hyphens: 'auto',
});
const ImageContainer = glamorous.img({
    width: '100%',
    willChange: 'transform',
    transition: 'transform .4s ease-out 0s',

    '&:hover': {
        transform: 'scale(1.2)',
    },
});
const Description = glamorous.div({
    margin: `${pxToRem(options.space.s)} 0`,
});
const RedeemText = glamorous.div({
    fontWeight: options.fontWeights.bold,
    margin: `${pxToRem(options.space.s)} 0`,
    ...waveTextMBold,
});
const Label = glamorous.div({
    backgroundImage: premiumGradient,
    borderRadius: `0 0 ${pxToRem(options.space['2xs'])}  ${pxToRem(options.space['2xs'])}`,
    color: options.colors.white,
    display: 'inline',
    position: 'absolute',
    left: pxToRem(options.space.m),
    padding: `${pxToRem(options.space.xs)} ${pxToRem(options.space.s)}`,
});
const HighlightedText = glamorous.div({
    ...waveHeadline2XS,
});
const Prefix = glamorous.div({});
const PictureContainer = glamorous.picture({
    overflow: 'hidden',
    borderRadius: options.radii.small,
    display: 'inline-block',
    width: '100%',
});
const TextContainer = glamorous.div({
    padding: pxToRem(options.space.m),
});
const Footer = glamorous.div({
    padding: pxToRem(options.space.m),
});
const VoucherContainer = glamorous.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    fontStyle: 'normal',
    padding: `${options.space.s}px 0`,
    ...waveTextMBold,
});
const CtaButton = glamorous.a(({ isHeroBenefit }) => ({
    display: 'block',
    border: `1px solid ${options.colors.blue}`,
    borderRadius: pxToRem(100),
    padding: `${pxToRem(options.space.xs)} ${pxToRem(options.space.m)}`,
    textAlign: 'center',
    color: options.colors.blue,
    textDecoration: 'none',
    ...waveTextMButton,

    [`@media (min-width: ${options.breakpoints.m})`]: {
        width: isHeroBenefit ? '50%' : 'inherit',
    },

    '&:hover': {
        color: options.colors.blue20,
    },
}));
CtaButton.displayName = 'CtaButton';

const getSourceSet = ({ benefit, dimension: { width, height } }) => {
    return (
        `https://www.holidaycheck.de${benefit.image}?w=${width}&h=${height}&zx=2431&zy=1621, ` +
        `https://www.holidaycheck.de${benefit.image}?w=${width * 2}&h=${height * 2}&zx=2431&zy=1621 2x`
    );
};

export default function PremiumPartner({ benefit }) {
    const { trackEventForPremium } = usePremiumTrackingContext();

    const [setRef] = useInScreen(() =>
        trackEventForPremium({
            event: {
                eventAction: 'Benefit-in-View',
                eventLabel: benefit.name,
            },
        }),
    );

    const descriptionWithBold = benefit.description.replace(
        benefit.boldword,
        `<b>${benefit.boldword}</b>`,
    );

    return h(
        PremiumPartnerCard,
        { isHeroBenefit: benefit.isHeroBenefit, innerRef: setRef },
        [
            h(Header, [
                h(PictureContainer, [
                    h('source', {
                        srcSet: getSourceSet({
                            benefit,
                            dimension: { width: 335, height: 188 },
                        }),
                        media: '(max-width:599px)',
                    }),
                    h('source', {
                        srcSet: getSourceSet({
                            benefit,
                            dimension: { width: 268, height: 192 },
                        }),
                        media: '(min-width:600px) and (max-width: 1023px)',
                    }),
                    h('source', {
                        srcSet: getSourceSet({
                            benefit,
                            dimension: { width: 386, height: 192 },
                        }),
                        media: '(min-width:1024px)',
                    }),
                    h(ImageContainer, {
                        src: getSourceSet({
                            benefit,
                            dimension: { width: 335, height: 188 },
                        }),
                    }),
                ]),
                h(Label, [
                    h(Prefix, benefit.label.prefix),
                    h(HighlightedText, benefit.label.highlightedText),
                ]),
                h(TextContainer, [
                    h(Headlines, benefit.name),
                    h(Description, {
                        dangerouslySetInnerHTML: {
                            __html: descriptionWithBold,
                        },
                    }),
                    benefit.redeemInformation.text &&
                        h(RedeemText, benefit.redeemInformation.text),
                ]),
            ]),
            h(Footer, [
                benefit.redeemInformation.coupons.map((c, i) =>
                    h(VoucherContainer, { key: i }, [
                        h('div', c.label),
                        h(Clipboard, {
                            value: c.code,
                            theme: 'PREMIUM',
                            trackClipboardClick() {
                                trackEventForPremium({
                                    event: {
                                        eventAction: 'Code-Copied',
                                        eventLabel: benefit.name,
                                    },
                                });
                            },
                        }),
                    ]),
                ),
                h(
                    CtaButton,
                    {
                        href: benefit.redeemInformation.button.url,
                        isHeroBenefit: benefit.isHeroBenefit,
                        target: '_blank',
                        onClick() {
                            trackEventForPremium({
                                event: {
                                    eventAction: 'Click-Link',
                                    eventLabel: benefit.name,
                                },
                            });
                        },
                    },
                    benefit.redeemInformation.button.label,
                ),
            ]),
        ],
    );
}

PremiumPartner.propTypes = {
    benefit: PropTypes.shape({
        redeemInformation: PropTypes.shape({
            button: PropTypes.shape({
                label: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
            }).isRequired,
            coupon: PropTypes.shape({
                code: PropTypes.string.isRequired,
                label: PropTypes.string.isRequired,
            }),
            text: PropTypes.string,
        }),
        boldword: PropTypes.string,
        label: PropTypes.shape({
            highlightedText: PropTypes.string.isRequired,
            prefix: PropTypes.string,
        }).isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        image: PropTypes.string.isRequired,
    }),
};
