import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function LeftArrowLine(options = {}) {
    return h(Icon, options, [
        h('path', {
            d: oneLine`
        M13.637 15.993l7.478 7.477a1.34 1.34 0 01-.013 1.887l-.905 .906a1.324 1.324 0
        01-1.887 .012L8.965 16.93A1.314 1.314 0 018.583 16a1.291 1.291 0 01.382
        -.944L18.31 5.71a1.34 1.34 0 011.887 .012l.905 .906a1.324 1.324 0 01.013
        1.886l-7.478 7.478z`,
        }),
    ]);
}
