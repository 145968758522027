import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import { blue, gray40, white } from '../../styles/waveColors';
import { pxToRem } from '../../styles/unitConverter';

const buttonHeight = pxToRem(40);

const fontColors = {
    selected: white,
    disabled: gray40,
    selectable: blue,
};

const borderColors = {
    selected: blue,
    disabled: gray40,
    selectable: blue,
};

const backgroundColors = {
    selected: blue,
    disabled: 'transparent',
    selectable: 'transparent',
};

const Button = ({ status }) => {
    const styles = {
        display: 'inline-block',
        width: '50%',
        textAlign: 'center',
        fontFamily: 'inherit',
        fontWeight: 'bold',
        color: fontColors[status],
        lineHeight: buttonHeight,
        letterSpacing: pxToRem(0.4),
        textTransform: 'uppercase',
        background: backgroundColors[status],
        border: `1px solid ${borderColors[status]}`,
        boxSizing: 'border-box',
    };
    if (status === 'disabled') {
        styles.cursor = 'not-allowed';
    }

    if (status === 'selected' || status === 'selectable') {
        styles[':focus'] = {
            outlineWidth: '2px',
            outlineOffset: '4px',
        };
    }
    return styles;
};

const FutureButton = glamorous.button(Button, {
    borderRadius: `${buttonHeight} 0px 0px ${buttonHeight}`,
});

const PastButton = glamorous.button(Button, {
    borderRadius: `0px ${buttonHeight} ${buttonHeight} 0px`,
});

const calculateStatus = (shown, count) => {
    if (shown) {
        return 'selected';
    }
    if (count > 0) {
        return 'selectable';
    }
    return 'disabled';
};

const BookingsFilter = ({
    futureBookingsCount,
    pastBookingsCount,
    pastBookingsShown,
    setPastBookingsShown,
}) => {
    const futureButtonStatus = calculateStatus(
        !pastBookingsShown,
        futureBookingsCount,
    );
    const pastButtonStatus = calculateStatus(
        pastBookingsShown,
        pastBookingsCount,
    );
    return [
        h(
            FutureButton,
            {
                key: 'future',
                status: futureButtonStatus,
                onClick: () =>
                    futureButtonStatus === 'selectable' &&
                    setPastBookingsShown(false),
                disabled: futureButtonStatus === 'disabled',
            },
            `Aktuelle (${futureBookingsCount})`,
        ),
        h(
            PastButton,
            {
                key: 'past',
                status: pastButtonStatus,
                onClick: () =>
                    pastButtonStatus === 'selectable' &&
                    setPastBookingsShown(true),
                disabled: pastButtonStatus === 'disabled',
            },
            `Bisherige (${pastBookingsCount})`,
        ),
    ];
};

BookingsFilter.propTypes = {
    futureBookingsCount: PropTypes.number.isRequired,
    pastBookingsCount: PropTypes.number.isRequired,
    pastBookingsShown: PropTypes.bool.isRequired,
    setPastBookingsShown: PropTypes.func.isRequired,
};

export default BookingsFilter;
