import PropTypes from 'prop-types';
import h from 'react-hyperscript';
import glamorous from 'glamorous';

import Category from './Category';
import { xxlargeDistance, xsmallDistance } from '../../styles/distances';
import ButtonLink from '../navigation/ButtonLink';
import colors from '../../styles/colors';

const { backButtonBackgroundColor, backButtonBorderColor } = colors;

const Wrapper = glamorous.div({
    overflow: 'auto',
    padding: `${xxlargeDistance} ${xsmallDistance}`,
});
const ButtonLinkContainer = glamorous.div({
    width: '100%',
    textAlign: 'center',
});

const mergeAndFilterBadges = (availableBadges, badgesForUser) => {
    return availableBadges.items
        .map((availableBadge) => {
            return {
                ...badgesForUser.items.find((b) => b.id === availableBadge.id),
                ...availableBadge,
            };
        })
        .filter((badge) => !badge.archived || badge.created);
};

const filterBadgesByCategory = (category, badges) => {
    return badges.filter((badge) =>
        Boolean(category.types.find(({ id }) => id === badge.typeId)),
    );
};

const Categories = ({ categories, badgesForUser, availableBadges }) => {
    const badges = mergeAndFilterBadges(availableBadges, badgesForUser);
    return h(Wrapper, [
        categories.map((category) => {
            return h(Category, {
                key: category.id,
                category,
                badges: filterBadgesByCategory(category, badges),
            });
        }),
        h(ButtonLinkContainer, [
            h(
                ButtonLink,
                {
                    href: '/mhc/mein-holidaycheck',
                    css: {
                        backgroundColor: backButtonBackgroundColor,
                        borderBottomColor: backButtonBorderColor,
                        padding: `${xsmallDistance} 5rem`,
                    },
                },
                'Zur Übersicht',
            ),
        ]),
    ]);
};

Categories.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.object).isRequired,
    badgesForUser: PropTypes.object.isRequired,
    availableBadges: PropTypes.object.isRequired,
};

export default Categories;
