import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function ThumbsDown(options = {}) {
    return h(Icon, options, [
        h('path', {
            d: oneLine`
        M9.284 9.324zm13.908 -.176h.006a1.886 1.886 0 011.888 1.88 1.87 1.87 0 01-.552
        1.332 1.89 1.89 0 01.797 3.133 1.868 1.868 0 011.336 1.794 1.88 1.88 0 01-1.879
        1.892h-6.023c0 .783 .633 1.253 1.58 3.134 .555 1.1 .923 5.992 -1.896 5.643
        -1.264 -.157 -.474 -1.725 -2.054 -4.075 -.023 -.035 -3.95 -5.486 -7.111
        -6.27l-3.95 -.783V9.775l2.37 -.784L9.379 7.928S11.873 6.64 12.895
        6.64h9.043a1.57 1.57 0 011.254 2.508z`,
        }),
    ]);
}
