export const GROUP_TRANSFER = 'Gruppentransfer';
const SPEED_TRANSFER = 'Schnelltransfer';
const PRIVATE_TRANSFER = 'Privattransfer';
const RENTAL_CAR = 'Mietwagen';

const transfersMapping = {
    GROUP_TRANSFER,
    SPEED_TRANSFER,
    PRIVATE_TRANSFER,
    RENTAL_CAR,
};

export default transfersMapping;
