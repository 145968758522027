import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import { pxToRem } from '../../styles/unitConverter';

const Label = glamorous.a(({ style }) => ({
    fontSize: options.space.s,
    fontWeight: '700',
    color: options.colors.white,
    backgroundColor: options.colors.purple,
    display: 'inline-block',
    padding: `${pxToRem(options.space['2xs'])} ${pxToRem(options.space.xs)}`,
    borderRadius: options.radii.small,
    textDecoration: 'none',
    ...style,
}));

const PremiumLabel = ({ style = {} }) => {
    return h(Label, { style, href: '/mhc/mein-premium' }, 'Premium-Mitglied');
};

export default PremiumLabel;
