import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import { useState } from 'react';

import ToggleIcon from './ToggleIcon';
import {
    defaultFont,
    secondaryFont,
    superLoudFont,
} from '../../../styles/fonts';
import { gray40, blue } from '../../../styles/waveColors';
import { pxToRem } from '../../../styles/unitConverter';
import useBookingContext from '../lib/useBookingContext';

const { PTSansFamily } = secondaryFont;

const Text = glamorous.p({
    ...defaultFont,
    ...PTSansFamily,
    color: gray40,
    marginTop: pxToRem(8),
    marginBottom: 0,
});
Text.displayName = 'Text';

const Toggle = glamorous.button({
    ...superLoudFont,
    color: blue,
    margin: `${pxToRem(8)} 0`,
    border: 'none',
    background: 'transparent',
    display: 'block',
    fontFamily: 'inherit',
});
Toggle.displayName = 'Toggle';

const ToggleHeading = glamorous.h4({
    display: 'inline',
    margin: 0,
});
ToggleHeading.displayName = 'ToggleHeading';

const ExpandableText = ({ title, text, eventLabel, isInitiallyExpanded }) => {
    const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);
    const { trackEventWithBooking } = useBookingContext();

    const toggleTextAndTrack = () => {
        trackEventWithBooking({
            event: 'event',
            eventCategory: 'mybooking',
            eventAction: isExpanded ? 'collapse' : 'expand',
            eventLabel,
        });

        setIsExpanded(!isExpanded);
    };

    return h('div', [
        !isInitiallyExpanded &&
            h(
                Toggle,
                {
                    onClick: () => toggleTextAndTrack(),
                    'aria-expanded': isExpanded,
                },
                [
                    h(ToggleHeading, {
                        dangerouslySetInnerHTML: { __html: title },
                    }),
                    h(ToggleIcon, { isExpanded }),
                ],
            ),
        isExpanded &&
            h(Text, {
                dangerouslySetInnerHTML: { __html: text },
            }),
    ]);
};

ExpandableText.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    eventLabel: PropTypes.string.isRequired,
    isInitiallyExpanded: PropTypes.bool,
};

ExpandableText.displayName = 'ExpandableText';

export default ExpandableText;
