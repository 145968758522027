import moment from 'moment/moment';

const getFirstInboundFlight = (travel) => {
    const flightService = travel.booking.services.find(
        (service) => service.type === 'FLIGHT',
    );

    if (
        !flightService ||
        !flightService.detail.inBound ||
        flightService.detail.inBound.length === 0
    )
        return null;

    return flightService.detail.inBound.reduce((acc, cur) =>
        moment(cur.departure.date).isBefore(acc.departure.date) ? cur : acc,
    );
};

export { getFirstInboundFlight };
