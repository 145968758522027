import h from 'react-hyperscript';
import { NavLink } from 'react-router-dom';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import Grid from '../grid/Grid';
import Row from '../grid/Row';
import { pxToRem } from '../../styles/unitConverter';
import colors from '../../styles/colors';
import { loudFont } from '../../styles/fonts';
import { applyOnMobile } from '../../styles/mediaQueries';

const {
    linkColor,
    navLinkTextDecorationColor,
    textColor,
    defaultBackgroundColor,
    lightBackgroundColor,
    lightBorderColor,
} = colors;

const List = glamorous.ul({
    listStyle: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
});

const Item = glamorous.li({
    margin: 0,
    padding: 0,
    flex: '1',
});

const StyledTabs = glamorous.div({
    backgroundColor: lightBackgroundColor,
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: lightBorderColor,
});

const StyledRow = glamorous(Row)({
    [applyOnMobile]: {
        padding: 0,
    },
});

const activeStyle = {
    color: textColor,
    backgroundColor: defaultBackgroundColor,
    boxShadow: `0 -${pxToRem(4)} 0 ${navLinkTextDecorationColor}`,
    pointerEvents: 'none',
};

const StyledNavLink = glamorous(NavLink)({
    ...loudFont,

    display: 'block',
    color: linkColor,
    textDecoration: 'none',
    textAlign: 'center',
    lineHeight: pxToRem(50),

    '&:hover': {
        color: textColor,
        backgroundColor: defaultBackgroundColor,
        boxShadow: `0 -${pxToRem(4)} 0 ${navLinkTextDecorationColor}`,
    },
});

const isActiveTab = ({ activePath, to, tabIdentifierParams }) => {
    if (activePath === to) {
        return true;
    }

    const [activePathname, activeQueryParams] = activePath.split('?');
    const [tabPathname, tabQueryParam] = to.split('?');

    if (activeQueryParams && activePathname === tabPathname) {
        if (tabQueryParam) {
            return activeQueryParams.includes(tabQueryParam);
        }
        return !tabIdentifierParams.some((param) =>
            activeQueryParams.includes(param),
        );
    }
    return false;
};

const Tabs = ({ tabItems, activeTabPath }) => {
    const tabIdentifierParams = tabItems
        .map((item) => item.to.split('?')[1])
        .filter((param) => Boolean(param));

    return h(StyledTabs, [
        h(Grid, [
            h(StyledRow, [
                h('nav', [
                    h(
                        List,
                        tabItems.map(({ to, label }) =>
                            h(Item, [
                                h(
                                    StyledNavLink,
                                    {
                                        to,
                                        className: isActiveTab({
                                            activePath: activeTabPath,
                                            to,
                                            tabIdentifierParams,
                                        })
                                            ? activeStyle
                                            : undefined,
                                    },
                                    label,
                                ),
                            ]),
                        ),
                    ),
                ]),
            ]),
        ]),
    ]);
};

Tabs.propTypes = {
    tabItems: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            to: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    activeTabPath: PropTypes.string.isRequired,
};

export default Tabs;
