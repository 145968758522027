import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import { blue, blue100, blue20, blue90 } from '../../styles/waveColors';
import { FONTS } from '../../styles/fonts';
import { pxToRem } from '../../styles/unitConverter';
import { applyOnMobile } from '../../styles/mediaQueries';
import Edit from '../icons/Edit';

const Button = glamorous.button({
    color: blue,
    fontSize: pxToRem(14),
    fontFamily: FONTS.primary,
    fontWeight: 500,
    lineHeight: pxToRem(21),
    display: 'flex',
    justifyContent: 'space-between',
    border: 0,
    background: blue100,
    borderRadius: pxToRem(4),
    padding: pxToRem(8),
    transition: 'background 0.3s ease-in-out',

    ':hover': {
        ':not([disabled])': {
            color: blue20,
            background: blue90,
            transition: 'background 0.3s ease-in-out',
        },
    },
    ':disabled': {
        opacity: '0.5',
        cursor: 'not-allowed',
    },

    '> svg': {
        margin: `0 ${pxToRem(6)} 0 ${pxToRem(8)}`,
    },

    [applyOnMobile]: {
        textAlign: 'initial',
    },
});
Button.displayName = 'Button';

const ModalButton = ({ onClick, text, disabled }) => {
    return h(
        Button,
        {
            onClick,
            type: 'button',
            disabled,
        },
        [h(Edit), h('span', text)],
    );
};

ModalButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
};

export default ModalButton;
