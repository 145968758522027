import * as R from 'ramda';

const formatNumber = R.pipe(
    R.toString,
    R.split(''),
    R.reverse,
    R.splitEvery(3),
    R.intersperse('.'),
    R.flatten,
    R.reverse,
    R.join(''),
);

export default formatNumber;
