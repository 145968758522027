import h from 'react-hyperscript';
import glamorous from 'glamorous';

import LinkWithIcon from '../navigation/LinkWithIcon';
import ProfileEditIcon from '../icons/ProfileEdit';
import { loudFont } from '../../styles/fonts';
import { mediumDistance } from '../../styles/distances';

const WriteReviewLink = glamorous.div({
    marginTop: mediumDistance,
    marginBottom: mediumDistance,
    textAlign: 'center',
});

export default () =>
    h(WriteReviewLink, [
        h(
            LinkWithIcon,
            {
                css: loudFont,
                href: '/wcf',
                icon: ProfileEditIcon,
                iconSize: mediumDistance,
            },
            'Neue Bewertung erstellen',
        ),
    ]);
