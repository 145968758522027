/* global console, newrelic, window */
/* eslint-disable no-console */

function noticeError(err, meta) {
    if (typeof window !== 'undefined') {
        if (typeof newrelic !== 'undefined') {
            newrelic.noticeError(err, meta);
        } else {
            console.warn('[newrelic]', err.name, err.message, meta);
        }
    }
}

export { noticeError };
