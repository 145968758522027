import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function BackToTop(options = {}) {
    return h(Icon, options, [
        h('path', {
            d: oneLine`
        M17.782 27.52c0 .983 -.806 1.78 -1.817 1.78 -1.003 0 -1.816 -.786 -1.816
        -1.78V15.385l-3.71 3.617a1.85 1.85 0 01-2.574 .005 1.741 1.741 0 01.005
        -2.51l6.84 -6.667a1.848 1.848 0 012.58 0l6.839 6.668a1.74 1.74 0 01.005 2.51
        1.851 1.851 0 01-2.574 -.006L17.782 15.32v12.2zM5.333 4.64c0 -1.105 .89 -2 2.007
        -2h17.32a1.999 1.999 0 012.007 2c0 1.105 -.89 2 -2.007 2H7.34a1.999 1.999 0
        01-2.007 -2z`,
        }),
    ]);
}
