import { useEffect } from 'react';
import PropTypes from 'prop-types';
import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { useLocation } from 'react-router-dom';

import tracker from './tracker';
import colors from '../../styles/colors';
import { defaultFont } from '../../styles/fonts';

const { textColor } = colors;

const Main = glamorous.main({
    WebkitFontSmoothing: 'antialiased',
    color: textColor,
    fontFamily: 'Open Sans, sans-serif',

    ...defaultFont,
});

function TrackerWrapper({ children }, { config }) {
    tracker.setContext(config);

    const location = useLocation();

    useEffect(() => {
        tracker.updatePageId();
    }, [location.pathname]);

    return h(Main, { children });
}

TrackerWrapper.contextTypes = {
    config: PropTypes.object.isRequired,
};

export default TrackerWrapper;
