import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import { pxToRem } from '../../styles/unitConverter';

const sunSize = 20;
const maxRating = 6;
const sunIconPath = 'reviews/sun.svg';
const sunGrayIconPath = 'reviews/sun-gray.svg';

const SunRatingContainer = glamorous.div(({ assetsPath }) => ({
    display: 'inline-block',
    width: pxToRem(sunSize * maxRating),
    height: pxToRem(sunSize),
    backgroundImage: `url("${assetsPath}/${sunGrayIconPath}")`,
}));
SunRatingContainer.displayName = 'SunRatingContainer';

const SunRatingOverlay = glamorous.div(({ rating, assetsPath }) => ({
    position: 'absolute',
    width: pxToRem(sunSize * rating),
    height: pxToRem(sunSize),
    backgroundImage: `url("${assetsPath}/${sunIconPath}")`,
}));
SunRatingOverlay.displayName = 'SunRatingOverlay';

const SunRating = ({ rating }, { config: { assetsPath } }) => {
    return h(SunRatingContainer, { assetsPath }, [
        h(SunRatingOverlay, { rating, assetsPath }),
    ]);
};

SunRating.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

export default SunRating;
