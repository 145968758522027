const featureNameKeyMap = {
    PRINT_INVOICE: 'Rechnung / Reisebestätigung ausdrucken',
    PAYMENT_INFORMATION: 'Zahlungsinformationen einsehen',
    CHANGE_PAYMENT_TYPE: 'Zahlungsinformationen ändern',
    PRINT_TRAVEL_DOCUMENTS: 'Reiseunterlagen drucken',
    SPECIAL_REQUESTS: 'Unverbindliche Kundenwünsche',
    REBOOKING: 'Reise umbuchen',
    BOOKING_CANCELLATION: 'Reise stornieren',
    ADDITIONAL_SERVICES: 'Zusatzleistungen buchen',
};

export default (key) => featureNameKeyMap[key];
