import { defaultDataIdFromObject } from '@apollo/client';

/*
 * Apollo uses the id field of a fragment as primary key for the inmemory cache.
 * Sadly some of our fragments have different data, but the same id, e.g. HotelDetailServiceDetail.
 * If the id is used as primary key, then all HotelDetailServiceDetail with the same id will have the same data.
 * By return null, the cache uses a unique id, which basically disables the cache for that specific type.
 */
export default (object) => {
    switch (object.__typename) {
        case 'HotelDetailServiceDetail':
            return null;
        default:
            return defaultDataIdFromObject(object);
    }
};
