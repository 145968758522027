import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import { pxToRem } from '../../styles/unitConverter';
import { applyOnMobile } from '../../styles/mediaQueries';
import { smallDistance, xlargeDistance } from '../../styles/distances';
import colors from '../../styles/colors';
import { extraTinyFont } from '../../styles/fonts';

const { uploadCTABackgroundColor, pictureUploadTextColor } = colors;

const uploadIconWidth = 65;
const uploadIconHeight = 50;

const StyledUploadText = glamorous.div(extraTinyFont, {
    fontWeight: 'bold',
    color: pictureUploadTextColor,

    [applyOnMobile]: {
        display: 'inline-block',
    },
});

const PictureUploadIcon = glamorous.img({
    width: pxToRem(uploadIconWidth),
    height: pxToRem(uploadIconHeight),

    [applyOnMobile]: {
        marginRight: smallDistance,
        verticalAlign: 'middle',
    },
});

const StyledPictureUploadContainer = glamorous.div({
    width: '100%',
    height: '100%',
    textAlign: 'center',
    backgroundColor: uploadCTABackgroundColor,
    padding: `${xlargeDistance} 0`,

    [applyOnMobile]: {
        padding: `${smallDistance} 0`,
    },
});

const StyledLink = glamorous.a({
    textDecoration: 'none',
});

const PictureUpload = ({ hotelId }, { config: { assetsPath } }) => {
    return h(
        StyledLink,
        {
            href: `/wcf/hotel/${hotelId}/image`,
        },
        [
            h(StyledPictureUploadContainer, [
                h(PictureUploadIcon, {
                    src: `${assetsPath}/reviews/img-upload-gray.svg`,
                }),
                h(StyledUploadText, ['Bilder hinzufügen']),
            ]),
        ],
    );
};

PictureUpload.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

export default PictureUpload;
