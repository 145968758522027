function calculatePoints({
    hotelReviewCount,
    pictureCount,
    videoCount,
    poiReviewCount,
    shipReviewCount,
}) {
    const contents = [
        {
            points: 10,
            count: hotelReviewCount,
        },
        {
            points: 1,
            count: pictureCount,
        },
        {
            points: 5,
            count: videoCount,
        },
        {
            points: 5,
            count: poiReviewCount,
        },
        {
            points: 10,
            count: shipReviewCount,
        },
    ];
    const existingContents = contents.filter((content) => content.count);
    const rawPoints = existingContents.reduce(
        (points, content) => points + content.points * content.count,
        0,
    );
    const factor =
        existingContents.length < 3 ? existingContents.length / 3 : 1;

    return rawPoints * factor;
}

export default calculatePoints;
