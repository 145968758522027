import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import { pxToRem } from '../../styles/unitConverter';
import { blue, gray20, gray90 } from '../../styles/waveColors';
import { secondaryFont } from '../../styles/fonts';
import colors from '../../styles/colors';

const { linkHoverColor } = colors;
const { UITextMLoud, UITextM, PTSansFamily } = secondaryFont;

const LinkBoxWrapper = glamorous.a({
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: pxToRem(8),

    marginTop: pxToRem(16),
    padding: pxToRem(16),
    border: `1px solid ${gray90}`,
    borderRadius: '4px',

    cursor: 'pointer',
    textDecoration: 'none',

    ':hover': {
        '& .title-cls': {
            color: linkHoverColor,
        },
    },
});
LinkBoxWrapper.displayName = 'LinkBoxWrapper';

const LinkBoxCTA = glamorous.div({
    display: 'flex',
    flexFlow: 'column nowrap',
});
LinkBoxCTA.displayName = 'LinkBoxCTA';

const CTATitle = glamorous.h4(
    {
        ...UITextMLoud,
        color: blue,
        margin: 0,
    },
    'title-cls',
);
CTATitle.displayName = 'CTATitle';

const CTADescription = glamorous.div({
    ...UITextM,
    ...PTSansFamily,
    color: gray20,
    marginTop: pxToRem(4),
});
CTADescription.displayName = 'CTADescription';

const ArrowIcon = glamorous.img({
    minHeight: pxToRem(16),
    color: blue,
    marginLeft: 'auto',
});
ArrowIcon.displayName = 'ArrowIcon';

const Icon = glamorous.img({
    marginRight: pxToRem(8),
    width: pxToRem(50),
    height: pxToRem(50),
});
Icon.displayName = 'Icon';

const LinkBox = (
    { link, title, description, trackLinkClick, iconUrl },
    { config: { assetsPath } },
) => {
    return h(
        LinkBoxWrapper,
        {
            target: '_blank',
            href: link,
            onClick: () => {
                if (trackLinkClick) {
                    trackLinkClick();
                }
            },
        },
        [
            iconUrl && h(Icon, { src: `${assetsPath}${iconUrl}`, alt: '' }),
            h(LinkBoxCTA, [
                h(CTATitle, title),
                description && h(CTADescription, description),
            ]),
            h(ArrowIcon, {
                src: `${assetsPath}/myBookings/link.svg`,
                alt: 'Link zu einer externen Webseite',
            }),
        ],
    );
};

LinkBox.propTypes = {
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.node,
    trackLinkClick: PropTypes.func,
    iconUrl: PropTypes.string,
};

LinkBox.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

LinkBox.displayName = 'LinkBox';

export default LinkBox;
