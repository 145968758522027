import moment from 'moment/moment';

import formatDate from '../../../lib/formatDate';
import numberToTwoDigitString from '../../../lib/numberToTwoDigitString';

const getFormattedDate = (date) => {
    return formatDate(date, 'dd. Do MMMM');
};

const getDuration = (from, to) => {
    const duration = moment.duration(
        moment.parseZone(to).diff(moment.parseZone(from)),
    );

    const hours = duration.asHours();
    const minutes = duration.asMinutes();
    if (isNaN(hours) || isNaN(minutes)) {
        return '0h 00m';
    }
    if (hours < 0 || minutes < 0) {
        return '0h 00m';
    }

    return `${Math.floor(duration.asHours())}h ${numberToTwoDigitString(duration.minutes())}m`;
};

const getTimeOfDay = (date) => moment.parseZone(date).format('H:mm');

export { getFormattedDate, getDuration, getTimeOfDay };
