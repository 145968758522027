import { useEffect, useRef, useState } from 'react';
import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import options from '@designsystem/options';

import copyValueToClipboard from '../../lib/copyValueToClipboard';
import CopyToClipboardTooltip from '../myBookings/flight/CopyToClipboardTooltip';
import { pxToRem } from '../../styles/unitConverter';
import { waveTextMBold, waveTextMMedium } from '../../styles/waveText';

const IconWrapper = glamorous.div({
    width: pxToRem(20),
    height: pxToRem(20),

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const CheckmarkIcon = glamorous.img({
    width: pxToRem(14),
    height: pxToRem(10),
});
CheckmarkIcon.displayName = 'CheckmarkIcon';

const CopyToClipboardIcon = glamorous.img({
    width: pxToRem(16),
    height: pxToRem(14),
});
CopyToClipboardIcon.displayName = 'CopyToClipboardIcon';

const CopyToClipboardButton = glamorous.button(({ theme }) => {
    const isPremiumTheme = theme === 'PREMIUM';
    return {
        ...waveTextMMedium,
        fontFamily: options.fonts.default,
        lineHeight: 1.4,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        gap: pxToRem(8),
        padding: `${pxToRem(options.space['2xs'])} ${pxToRem(options.space.xs)}`,
        border: !isPremiumTheme
            ? `${pxToRem(1)} solid ${options.colors.blue}`
            : 'none',
        borderRadius: options.radii.small,
        background: isPremiumTheme
            ? options.colors.purple100
            : options.colors.white,
    };
});
CopyToClipboardButton.displayName = 'CopyToClipboardButton';

const ValueContainer = glamorous.span({
    whiteSpace: 'nowrap',
    ...waveTextMBold,
});
ValueContainer.displayName = 'ValueContainer';

export default function Clipboard(
    { value, theme, trackClipboardClick },
    { window, config: { assetsPath } },
) {
    const [showTooltip, setShowTooltip] = useState(false);
    const timeoutRef = useRef(null);

    const handleOnClickCopy = async () => {
        await copyValueToClipboard(value, window);
        if (trackClipboardClick) {
            trackClipboardClick();
        }
        setShowTooltip(true);

        if (!showTooltip) {
            timeoutRef.current = window.setTimeout(
                () => setShowTooltip(false),
                3000,
            );
        }
    };

    useEffect(() => {
        return () => {
            return window.clearTimeout(timeoutRef.current);
        };
    }, []);

    return h(
        CopyToClipboardButton,
        {
            theme,
            onClick: handleOnClickCopy,
        },
        [
            h(ValueContainer, value),
            showTooltip && h(CopyToClipboardTooltip, { text: 'Kopiert!' }),
            showTooltip
                ? h(IconWrapper, [
                      h(CheckmarkIcon, {
                          src: `${assetsPath}/common/checkmark.svg`,
                          alt: 'Kopiert',
                      }),
                  ])
                : h(IconWrapper, [
                      h(CopyToClipboardIcon, {
                          src: `${assetsPath}/common/clipboard.svg`,
                          alt: 'Kopieren file key',
                      }),
                  ]),
        ],
    );
}

Clipboard.propTypes = {
    value: PropTypes.string.isRequired,
};

Clipboard.contextTypes = {
    window: PropTypes.object.isRequired,
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};
