import { string } from 'yup';
import { electronicFormatIBAN, isValidIBAN } from 'ibantools';

const bankDataSchema = {
    accountHolder: string()
        .required('Bitte gebe Deinen Kontoinhaber an.')
        .matches(
            '^([^0-9\\§\\$\\%\\/\\(\\)\\=\\?\\,\\_\\:;\\]\\+\\*\\~<>\\|]+)$',
            'Bitte tragen alle Namen ohne Sonderzeichen ein.',
        ),
    iban: string()
        .required('Bitte gebe Deine IBAN an.')
        .test(
            'iban',
            'Die eingegebene IBAN ist ungültig. Bitte überprüfe Deine Eingabe.',
            (value) => {
                const iban = electronicFormatIBAN(value);
                return isValidIBAN(iban);
            },
        ),
};

export default bankDataSchema;
