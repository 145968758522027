import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import BookingSectionHeadline from '../BookingSectionHeadline';
import RightArrowLine from '../../icons/RightArrowLine';
import { pxToRem } from '../../../styles/unitConverter';
import { defaultFont, secondaryFont } from '../../../styles/fonts';
import { blue, gray20 } from '../../../styles/waveColors';
import colors from '../../../styles/colors';
import { applyOnTabletAndUp } from '../../../styles/mediaQueries';
import FAQData from './FAQData';
import useBookingContext from '../lib/useBookingContext';

const { linkHoverColor, defaultBorderColor } = colors;
const { UITextLLoud, PTSansFamily } = secondaryFont;

const FAQContainer = glamorous.div({});
FAQContainer.displayName = 'FAQContainer';

const FAQList = glamorous.div({
    display: 'flex',
    flexFlow: 'column nowrap',
});
FAQList.displayName = 'FAQList';

const FAQItem = glamorous.a({
    display: 'flex',
    flexFlow: 'row nowrap',

    '&:not(:last-child)': {
        boxShadow: `inset 0px -1px 0px ${defaultBorderColor}`,
    },
    padding: `${pxToRem(7)} 0`,

    cursor: 'pointer',
    textDecoration: 'none',

    ':hover': {
        '& .title-cls, & svg': {
            color: linkHoverColor,
        },
    },
    alignItems: 'center',
});
FAQItem.displayName = 'FAQItem';

const Left = glamorous.div({
    display: 'flex',
    flexFlow: 'column nowrap',
});
Left.displayName = 'Left';

const Title = glamorous.div(
    {
        ...UITextLLoud,
        color: blue,
    },
    'title-cls',
);
Title.displayName = 'Title';

const Description = glamorous.div({
    ...defaultFont,
    ...PTSansFamily,
    color: gray20,
});
Description.displayName = 'Description';

const renderRightArrow = () => {
    const arrowCss = {
        width: pxToRem(20),
        color: blue,
        // make this element be pushed to the right (in flex containers, which is the case now)
        marginLeft: 'auto',
        // avoid arrow shrinking when a lot of text is present in the item's description
        flexShrink: 0,

        [applyOnTabletAndUp]: {
            marginRight: pxToRem(20),
        },
    };

    return h(RightArrowLine, { css: arrowCss });
};

const handleListItemClick = (faqItem, trackEventWithBooking) => {
    trackEventWithBooking({
        event: 'event',
        eventCategory: 'mybooking',
        eventAction: 'faq-list',
        eventLabel: faqItem.title,
    });
};

const renderListItem = (faqItem, trackEventWithBooking) => {
    return h(
        FAQItem,
        {
            onClick: () => handleListItemClick(faqItem, trackEventWithBooking),
            href: faqItem.link,
            target: '_blank',
        },
        [
            h(Left, [
                h(Title, faqItem.title),
                h(Description, faqItem.description),
            ]),
            renderRightArrow(),
        ],
    );
};

const renderList = (faqItems, trackEventWithBooking) => {
    return h(
        FAQList,
        faqItems.map((item) => renderListItem(item, trackEventWithBooking)),
    );
};

const FAQSection = ({ myRef, setWillRender }) => {
    const faqItems = FAQData.getFaqItemsSorted();
    const { trackEventWithBooking } = useBookingContext();

    useEffect(() => setWillRender(true), []);

    return h(FAQContainer, [
        h(
            BookingSectionHeadline,
            { innerRef: myRef },
            'Fragen und Antworten zur Reise',
        ),
        renderList(faqItems, trackEventWithBooking),
    ]);
};

FAQSection.propTypes = {
    // from using `useRef` hook, `ref` prop is treated differently and would need the `forwardRef` crap
    myRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
    setWillRender: PropTypes.func.isRequired,
};

FAQSection.displayName = 'FAQSection';

export default FAQSection;
