import h from 'react-hyperscript';
import PropTypes from 'prop-types';

import Page from './Page';
import { NavLink, NavigationTabs } from './navigation/NavigationBar';
import Redemptions from './myRedemptions/Redemptions';

function TabNavigation() {
    return h(NavigationTabs, [
        h(NavLink, { to: '/einstellungen/meine-gutscheine' }, 'Aktuelle'),
        h(
            NavLink,
            {
                to: '/mhc/meine-gutscheine',
                currentPage: '/mhc/meine-gutscheine',
            },
            'Eingelöste',
        ),
    ]);
}

function MyRedemptions() {
    return h(
        Page,
        {
            pagename: '/mhc/vouchers',
            headline: 'Meine Gutscheine',
        },
        [h(TabNavigation), h(Redemptions)],
    );
}

MyRedemptions.contextTypes = {
    elementModifiers: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

export default MyRedemptions;
