import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { useNavigate } from 'react-router-dom';
import options from '@designsystem/options';

import Column from '../grid/Column';
import FlashMessage from '../flashMessage/FlashMessage';
import LoadingButton from '../loading-button/LoadingButton';
import { applyOnTabletAndUp } from '../../styles/mediaQueries';

const Wrapper = glamorous.form({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: `${options.space.l}px 0 ${options.space.xl}px`,

    [applyOnTabletAndUp]: {
        paddingTop: options.space.xl,
    },
});

const FlashMessageContainer = glamorous.div({
    display: 'flex',
    justifyContent: 'center',
    marginTop: options.space['2xl'],
});

const Controls = glamorous.div({
    display: 'flex',
    justifyContent: 'center',
    marginTop: options.space.xl,
});

const ManualMergeRequestSuccessPage = function () {
    const navigate = useNavigate();

    return h([
        h(Wrapper, [
            h(Column, { medium: 8, large: 6 }, [
                h(FlashMessageContainer, [
                    h(
                        FlashMessage,
                        { type: 'success' },
                        'Deine Anfrage zum Zusammenlegen des Accounts wurde gesendet.',
                    ),
                ]),
                h(Controls, [
                    h(
                        LoadingButton,
                        {
                            onClick: () => navigate('/mhc/mein-profil'),
                            type: 'button',
                        },
                        'Zurück',
                    ),
                ]),
            ]),
        ]),
    ]);
};

export default ManualMergeRequestSuccessPage;
