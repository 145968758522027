import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import { InfoOutline } from '@designsystem/icons';
import { useMemo, useState } from 'react';
import moment from 'moment';

import {
    waveTextMBold,
    waveTextS,
    waveTextSBold,
} from '../../../styles/waveText';
import {
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../../styles/waveMediaQueries';
import { getLastOutboundFlight } from '../../../lib/getLastOutboundFlight';
import { formatPriceShort } from '../../myBookings/payment/formatPrice';
import { useRentalcarCampaigns } from '../useRentalcarCampaigns';
import Tooltip from './Tooltip';
import { RentalcarButton } from '../components/RentalcarButton';

const Container = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
});

const CarDisplay = glamorous.div({
    display: 'flex',
    flexDirection: 'row',
    gap: options.space.xs,
    marginBottom: options.space.l,
});

const Option = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
});

const Destination = glamorous.span({
    ...waveTextMBold,
});

const DateAndCarClass = glamorous.span({
    ...waveTextS,
    display: 'flex',
    flexDirection: 'column',
    [waveSmMediaQuery]: {
        flexDirection: 'row',
    },
});

const Pin = glamorous.span({
    display: 'none',
    margin: '0 8px',
    [waveSmMediaQuery]: {
        display: 'inline',
    },
});

const CarPriceContainer = glamorous.div(({ asColumn }) => ({
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'flex-start',
    gap: options.space.xl,
    [waveSmMediaQuery]: {
        flexDirection: 'row',
        alignItems: asColumn ? 'start' : 'end',
    },
    [waveMdMediaQuery]: {
        flexDirection: asColumn ? 'column-reverse' : 'row',
        alignItems: asColumn ? 'start' : 'end',
    },
}));

const ButtonTextDefault = glamorous.span({
    display: 'block',
});

const OfferInformation = glamorous.div({
    display: 'flex',
    alignItems: 'start',
    gap: options.space.xs,
    flexDirection: 'column',
});

const Price = glamorous.span({
    fontSize: options.fontSizes.l,
    lineHeight: `${options.space.xl}px`,
    fontWeight: 700,
    color: options.colors.blue,
    textWrap: 'nowrap',
});

const OriginalPrice = glamorous.span({
    color: options.colors.gray40,
    fontSize: options.fontSizes['2xs'],
    fontWeight: 500,
    textDecoration: 'line-through',
    textWrap: 'nowrap',
});

const Range = glamorous.span({
    color: options.colors.gray40,
    fontSize: options.fontSizes['2xs'],
    fontWeight: 500,
});

const DATE_FORMAT = 'DD.MM.YYYY';

const BadgeContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: options.space.xs,
});

const BadgeElement = glamorous.div(({ backgroundColor }) => ({
    padding: `${options.space['2xs']}px ${options.space.xs}px`,
    backgroundColor,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: options.space['2xs'],
    alignItems: 'center',
    borderRadius: options.radii.small,
}));

const BadgeIconWrapper = glamorous.span({
    position: 'relative',
});

const BadgeText = glamorous.span(({ color }) => ({
    ...waveTextSBold,
    color,
}));

const Badge = ({ text, info, backgroundColor, color }) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return h(BadgeElement, { backgroundColor }, [
        h(BadgeText, { color }, text),
        info !== null &&
            h(
                glamorous.span({
                    color,
                    height: options.space.m,
                    width: options.space.m,
                }),
                [
                    h(BadgeIconWrapper, [
                        h(InfoOutline, {
                            width: 16,
                            height: 16,
                            fill: color,
                            onMouseEnter: () => setShowTooltip(true),
                            onMouseLeave: () => setShowTooltip(false),
                        }),
                        showTooltip && h(Tooltip, { text: info, width: 200 }),
                    ]),
                ],
            ),
    ]);
};

const getWorthFromCampaign = (campaign, locale) => {
    if (!campaign) return { worth: 0, currency: 'EUR' };
    const worths = campaign.coupon.worth.filter(
        (worth) =>
            worth.domain === `${locale}-HCHECK` || worth.domain === 'ALL',
    );

    return worths[0].value.reduce(
        (maxWorth, currentWorth) => {
            return currentWorth.worth > maxWorth.worth
                ? currentWorth
                : maxWorth;
        },
        { worth: 0, currency: 'EUR' },
    );
};

const campaignToText = (campaign, locale) => {
    if (!campaign) return '';

    const { worth, currency } = getWorthFromCampaign(campaign, locale);
    if (worth === 0) return '';

    return `${formatPriceShort(worth * -1, currency)} für Dich`;
};

const PriceAndRange = glamorous.div(({ asRow }) => ({
    display: 'flex',
    flexDirection: asRow ? 'row-reverse' : 'column',
    alignItems: 'flex-start',
    ...(asRow
        ? {
              alignItems: 'center',
              gap: options.space.xs,
          }
        : {}),
}));

const CarImage = glamorous.img({
    width: 64,
    height: 'auto',
    objectFit: 'contain',
    transform: 'scaleX(-1)',
});

function Bonuses({
    couponApplied,
    campaign,
    isPremiumMember,
    premiumDiscount,
    locale,
}) {
    return h(BadgeContainer, [
        campaign &&
            couponApplied &&
            h(Badge, {
                text: campaignToText(campaign, locale),
                info: null,
                backgroundColor: options.colors.green100,
                color: options.colors.green40,
            }),
        isPremiumMember &&
            h(Badge, {
                text: `${formatPriceShort(premiumDiscount * -1, 'EUR')} Premium`,
                info: null,
                backgroundColor: options.colors.purple100,
                color: options.colors.purple40,
            }),
    ]);
}

const formatPeriod = (pickup, dropoff) =>
    pickup && dropoff
        ? `${pickup.format(DATE_FORMAT)} - ${dropoff.format(DATE_FORMAT)}`
        : '';

const formatPrice = (fromPrice, price, currency, locale) => {
    return `${fromPrice ? 'ab' : ''} ${formatPriceShort(price, currency, locale)}`.trim();
};

const useCurrentCampaign = (campaigns, offer) =>
    useMemo(() => {
        const now = moment();

        if (!offer.discountCouponApplied) return undefined;

        return campaigns
            .filter((campaign) => {
                return (
                    campaign.active &&
                    moment(campaign.start).isSameOrBefore(now) &&
                    moment(campaign.end).isSameOrAfter(now)
                );
            })
            .sort((a, b) => moment(a.start).diff(moment(b.start)))[0];
    }, [campaigns]);

const updateUrl = (window) => (url, offerCardType, utmParameter) => {
    const parsed = new window.URL(url);

    parsed.searchParams.append('mhcOfferCardType', offerCardType);
    Object.entries(utmParameter || {}).forEach(([key, value]) =>
        parsed.searchParams.set(key, value),
    );
    return parsed.toString();
};

const updateListingUrl = (window) => (url, campaign, utmParameter) => {
    const parsed = new window.URL(url);

    if (campaign) {
        parsed.searchParams.append('voucher', campaign.code);
    }

    Object.entries(utmParameter || {}).forEach(([key, value]) =>
        parsed.searchParams.set(key, value),
    );

    return parsed.toString();
};

const mapCarClass = (orig) => {
    const carClassTranslation = {
        Mini: 'Kleinstwagen',
        Economy: 'Kleinwagen',
        Compact: 'Kompaktklasse',
        Intermediate: 'Mittelklasse',
        Fullsize: 'Oberklasse',
        Premium: 'Premiumklasse',
        Luxury: 'Premiumklasse',
    };

    return carClassTranslation[orig] || orig;
};

function Offer({
    cardType,
    linkToListing,
    hcTravel,
    search,
    searchData,
    offer,
    context,
    utmParameter,
}) {
    const lastInboundFlight = getLastOutboundFlight(hcTravel);
    const { trackEvent } = context;
    const pickup = moment(searchData.pickup.datetime);
    const dropoff = moment(searchData.dropoff.datetime);
    const rentalDurationDays = Math.max(
        Math.ceil(dropoff.diff(pickup, 'hours') / 24),
        1,
    );

    const range = moment
        .duration(rentalDurationDays, 'days')
        .locale('de')
        .humanize();

    const campaigns = useRentalcarCampaigns(context);
    const currentCampaign = useCurrentCampaign(campaigns, offer);
    const hasCampaign = currentCampaign !== undefined;

    return h(Container, [
        h(CarDisplay, [
            h(CarImage, { src: offer.pictureUrl }),
            h(Option, [
                (offer.destinationName ||
                    lastInboundFlight.arrival.airportCode) &&
                    h(
                        Destination,
                        offer.destinationName ||
                            lastInboundFlight.arrival.airportCode,
                    ),
                h(DateAndCarClass, [
                    h('span', formatPeriod(pickup, dropoff)),
                    offer.carClass &&
                        h('span', [h(Pin, '•'), mapCarClass(offer.carClass)]),
                ]),
            ]),
        ]),
        h(CarPriceContainer, [
            h(
                RentalcarButton,
                {
                    onClick() {
                        trackEvent({
                            event: 'event',
                            eventCategory: 'mHCHub - Rentalcar',
                            eventAction: linkToListing
                                ? 'goToListing'
                                : 'goToOffer',
                        });
                    },
                    href: linkToListing
                        ? updateListingUrl(context.window)(
                              search.listingUrl,
                              currentCampaign,
                              utmParameter,
                          )
                        : updateUrl(context.window)(
                              offer.offerPageUrl,
                              cardType,
                              utmParameter,
                          ),
                },
                [
                    h(
                        ButtonTextDefault,
                        linkToListing ? 'Mietwagen finden' : 'Mietwagen Buchen',
                    ),
                ],
            ),
            h(OfferInformation, [
                hasCampaign &&
                    h(Bonuses, {
                        couponApplied: offer.discountCouponApplied,
                        campaign: currentCampaign,
                        isPremiumMember: false,
                        locale: context.config.locale,
                    }),
                h(PriceAndRange, { asRow: hasCampaign }, [
                    h(
                        Price,
                        formatPrice(
                            linkToListing,
                            offer.priceAmount,
                            offer.priceCurrency,
                            context.config.locale,
                        ),
                    ),
                    hasCampaign
                        ? h(
                              OriginalPrice,
                              `statt ${formatPrice(false, offer.priceAmountFull, offer.priceCurrency)}`,
                          )
                        : h(Range, `für ${range}`),
                ]),
            ]),
        ]),
    ]);
}

export { Offer };
