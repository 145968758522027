import { Component } from 'react';
import PropTypes from 'prop-types';

const setOverflowOnBody = (document, value) => {
    // eslint-disable-next-line no-param-reassign
    document.body.style.overflow = value;
};
const setHeightOnBody = (document, value) => {
    // eslint-disable-next-line no-param-reassign
    document.body.style.height = value;
};
const setWidthOnBody = (document, value) => {
    // eslint-disable-next-line no-param-reassign
    document.body.style.width = value;
};

const disableScrolling = (document) => {
    setOverflowOnBody(document, 'hidden');
    setHeightOnBody(document, '100%');
    setWidthOnBody(document, '100%');
};

const enableScrolling = (document) => {
    setOverflowOnBody(document, 'visible');
    setHeightOnBody(document, 'initial');
    setWidthOnBody(document, 'initial');
};

class Unscrollable extends Component {
    componentDidMount() {
        disableScrolling(this.context.document);
    }

    componentWillUnmount() {
        enableScrolling(this.context.document);
    }

    render() {
        return null;
    }
}

Unscrollable.contextTypes = {
    document: PropTypes.object,
};

export default Unscrollable;
