import { stripIndent } from 'common-tags';

import graphqlFetch from '../lib/graphqlFetch';

const selection = stripIndent`
    id
    title
    description
    travelDate
    parents {
        name
        type
        id
    }
    helpfulCount
    viewCount
    status`;

export default async function fetchPicture(context, pictureId) {
    const query = stripIndent`
        query PictureDetail($pictureId: String!) {
            picture(mediaId:$pictureId) {
                ${selection}
            }
        }
    `;
    const variables = { pictureId };

    const response = await graphqlFetch(context, { query, variables });

    if (response.ok) {
        const {
            data: { picture },
        } = await response.json();
        return picture;
    }

    return null;
}

export const defaultSelection = selection;
