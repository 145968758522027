import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import MietwagenKnowledgeArticle from './components/MietwagenKnowledgeArticles';
import { ComponentContainer } from '../../components/ComponentContainer';
import { HeaderContainer } from '../../components/Card/CardHeaderContainer';
import { HeaderImage } from '../../components/Card/CardHeaderImage';
import { Title } from '../../components/Card/CardTitle';
import { useRentalcarHub } from '../../utils/useRentalcarHub';
import {
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../../../../styles/waveMediaQueries';

const KnowledgeSeperatorOnlyXs = glamorous.img({
    width: 123,
    margin: `-${options.space['2xl']}px auto ${options.space.m}px ${options.space['3xl']}px`,
    [waveSmMediaQuery]: {
        display: 'none',
    },
});

const KnowledgeSeperatorMinSm = glamorous.img({
    display: 'none',
    [waveSmMediaQuery]: {
        display: 'block',
        width: 319,
        height: 49,
        margin: `-${options.space['2xl']}px auto ${options.space.m}px 145px`,
        transform: 'unset',
    },
    [waveMdMediaQuery]: {
        margin: `-${options.space['2xl']}px auto ${options.space['4xl']}px 145px`,
    },
});

function MietwagenKnowledge({ myRef, sectionId, context, hubDestinationId }) {
    const hcmKnowledgeHub = useRentalcarHub(context, hubDestinationId);

    return h('div', [
        h(KnowledgeSeperatorMinSm, {
            src: `${context.config.assetsPath}/hcMietwagen/seperator3.svg`,
        }),
        h(KnowledgeSeperatorOnlyXs, {
            src: `${context.config.assetsPath}/hcMietwagen/seperator4.svg`,
        }),
        h(ComponentContainer, { id: sectionId, innerRef: myRef }, [
            h(HeaderContainer, [
                h(HeaderImage, {
                    src: `${context.config.assetsPath}/hcMietwagen/knowledge.svg`,
                }),
                h('div', [
                    h(Title, 'Einfach vorbereitet sein'),
                    h(
                        'div',
                        'Ob vor, während oder nach Deiner Anmietung: Unsere Wissenswelt hat immer die passende Antwort.',
                    ),
                ]),
            ]),
            h(MietwagenKnowledgeArticle, { context, hcmKnowledgeHub }),
        ]),
    ]);
}

MietwagenKnowledge.displayName = 'Wissen';
MietwagenKnowledge.propTypes = {
    sectionId: PropTypes.string.isRequired,
    myRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any }),
    ]),
    context: PropTypes.any,
    articleDestinationId: PropTypes.string,
    hubDestinationId: PropTypes.string,
    bookingId: PropTypes.string,
    regionName: PropTypes.string,
};

export { MietwagenKnowledge };
