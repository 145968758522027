import h from 'react-hyperscript';
import PropTypes from 'prop-types';

import VoucherIssuedCard from './VoucherIssuedCard';
import VoucherNotIssuedCard from './VoucherNotIssuedCard';

const VoucherCard = ({ voucher }, { trackEvent }) => {
    const component = voucher.isIssued
        ? VoucherIssuedCard
        : VoucherNotIssuedCard;
    return h(component, { voucher, trackEvent });
};

export default VoucherCard;

VoucherCard.contextTypes = {
    trackEvent: PropTypes.func,
};
