import PropTypes from 'prop-types';
import { Component } from 'react';

class UnloadPrompt extends Component {
    constructor(...args) {
        super(...args);
        this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
    }

    componentDidMount() {
        this.context.window.addEventListener(
            'beforeunload',
            this.handleBeforeUnload,
        );
    }

    componentWillUnmount() {
        this.context.window.removeEventListener(
            'beforeunload',
            this.handleBeforeUnload,
        );
    }

    render() {
        return null;
    }

    handleBeforeUnload(event) {
        // this message is only used by older browsers
        // https://www.chromestatus.com/feature/5349061406228480
        event.returnValue = this.props.message; // eslint-disable-line no-param-reassign
        return this.props.message;
    }
}

UnloadPrompt.propTypes = {
    message: PropTypes.string.isRequired,
};

UnloadPrompt.contextTypes = {
    window: PropTypes.object.isRequired,
};

export default UnloadPrompt;
