import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import {
    blackWithOpacity,
    black,
    greyWithOpacity,
    gray100,
} from '../../styles/waveColors';
import { HotelReviewCalculationsType } from '../../lib/types';
import BookingOverview from './BookingOverview';
import { findFlightService } from './lib/serviceHelpers';
import { pxToRem } from '../../styles/unitConverter';

const PATH_TO_DEFAULT_IMAGE = 'myHolidayCheck/hotel-placeholder.png';

const SINGLE_GRID_AREA_NAME = 'single-area';

const HotelImageContainer = glamorous.div({
    // create a grid with only one area to be shared by both the image and its overlay (if cancelled)
    // this avoids using position `static` or `absolute` to overlap elements
    display: 'grid',
    gridTemplateAreas: `"${SINGLE_GRID_AREA_NAME}"`,

    position: 'relative',
    height: '100%',
});
HotelImageContainer.displayName = 'HotelImageContainer';

const HotelImage = glamorous.img(({ isCancelled }) => ({
    gridArea: SINGLE_GRID_AREA_NAME,

    width: '100%',
    height: '100%',
    display: 'block',
    objectFit: 'cover',
    mixBlendMode: isCancelled ? 'luminosity' : 'normal',
}));
HotelImage.displayName = 'HotelImage';

const CancelledBookingOverlay = glamorous.div({
    gridArea: SINGLE_GRID_AREA_NAME,

    backgroundImage: `linear-gradient(0deg, ${black} 0%, ${black} 100%)`,
    width: '100%',
    height: '100%',
});
CancelledBookingOverlay.displayName = 'CancelledBookingOverlay';

const FlightBookingWrapper = glamorous.div({
    background: gray100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: '100%',
    width: '100%',
});

const FlightBookingImage = glamorous.img({
    padding: pxToRem(30),
});
FlightBookingImage.displayName = 'FlightBookingImage';

const Overlays = glamorous.div(({ isCancelled }) => {
    const blackGradient15 = `linear-gradient(0deg, ${blackWithOpacity(0.15)} 0%, ${blackWithOpacity(0.15)} 100%)`;
    const blackGradient50 = `linear-gradient(0deg, ${blackWithOpacity(0.5)} 0%, transparent 50%)`;
    const greyGradient50 = `linear-gradient(0deg, ${greyWithOpacity(0.5)} 0%, ${greyWithOpacity(0.5)} 100%)`;
    const defaultBackgroundImage = `${blackGradient15}, ${blackGradient50}`;
    const backgroundImage = !isCancelled
        ? defaultBackgroundImage
        : `${defaultBackgroundImage}, ${greyGradient50}`;

    return {
        gridArea: SINGLE_GRID_AREA_NAME,

        backgroundImage,
        height: '100%',
        width: '100%',
        position: 'absolute',
    };
});
Overlays.displayName = 'Overlays';

const BookingOverviewWrapper = glamorous.div({
    gridArea: SINGLE_GRID_AREA_NAME,
    position: 'absolute',
    bottom: '10%',
    zIndex: 25,
    alignSelf: 'center',
    justifySelf: 'center',
});
BookingOverviewWrapper.displayName = 'BookingOverviewWrapper';

const getImage = ({ booking, hotel, isCancelled, assetsPath }) => {
    const defaultImage = `${assetsPath}/${PATH_TO_DEFAULT_IMAGE}`;
    const HolidayImage = h(HotelImage, {
        src: hotel
            ? `https://www.holidaycheck.de/main-photo-redirect/${hotel.detail.id}/w_1920,h_1080,c_fill,q_auto,f_auto`
            : defaultImage,
        onError: (event) => {
            const target = event.target;
            target.src = defaultImage;
        },
        isCancelled,
        alt: hotel ? `Ein Bild von ${hotel.detail.name}` : '',
    });

    if (hotel) {
        return HolidayImage;
    }
    if (findFlightService(booking.services)) {
        return h(FlightBookingWrapper, [
            h(FlightBookingImage, {
                src: `${assetsPath}/myBookings/flight.svg`,
                alt: '',
            }),
        ]);
    }
    return HolidayImage;
};

// eslint-disable-next-line complexity
function BookingHeaderImage(
    { booking, hotel, isCancelled, isMobile },
    { config: { assetsPath } },
) {
    return h(HotelImageContainer, [
        getImage({ booking, hotel, isCancelled, assetsPath }),
        h(Overlays, { isCancelled }),
        isCancelled && h(CancelledBookingOverlay),
        isMobile &&
            h(BookingOverviewWrapper, [
                h(BookingOverview, {
                    booking,
                    isCancelled,
                    shouldBeWhite: isMobile,
                }),
            ]),
    ]);
}

BookingHeaderImage.propTypes = {
    booking: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired,
        creationDate: PropTypes.string,
        services: PropTypes.array.isRequired,
    }).isRequired,
    hotel: PropTypes.shape({
        id: PropTypes.string,
        address: PropTypes.string,
        email: PropTypes.string,
        url: PropTypes.string,
        phone: PropTypes.string,
        geo: PropTypes.shape({
            coordinates: PropTypes.arrayOf(PropTypes.number).isRequired,
        }),
        reviewCalculations: HotelReviewCalculationsType,
    }),
    isCancelled: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
};

BookingHeaderImage.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

export default BookingHeaderImage;
