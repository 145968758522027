import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import FeaturedKnowledgeArticles from './FeaturedKnowledgeArticles';
import SimpleKnowledgeArticles from './SimpleKnowledgeArticles';
import buttons from '../../../../../../styles/buttons';
import DarkLink from '../../../components/DarkLink';
import articleConfiguration from './ArticleConfiguration';

const KnowledgeButton = glamorous.button({
    ...buttons.whiteButton,
    fontFamily: 'inherit',
    fontSize: options.fontSizes.xs,
    margin: `${options.space.l}px 0 0 0`,
    padding: `0 ${options.space.m}px`,
    cursor: 'pointer',
});

function MietwagenKnowledgeArticle({ context, hcmKnowledgeHub }) {
    const { featuredArticles, simpleArticles } =
        articleConfiguration(hcmKnowledgeHub);

    const utmParameter = `?utm_source=mHC&utm_medium=HCMHub&utm_campaign=KnowledgeArticleClicked&utm_content=General`;

    return h('div', [
        h(FeaturedKnowledgeArticles, {
            articles: featuredArticles,
            assetsPath: context.config.assetsPath,
        }),
        h(SimpleKnowledgeArticles, {
            articles: simpleArticles,
            assetsPath: context.config.assetsPath,
        }),
        h(
            DarkLink,
            { href: `/mietwagen/wissen${utmParameter}`, target: '_blank' },
            [h(KnowledgeButton, 'Alle Wissensthemen')],
        ),
    ]);
}

export default MietwagenKnowledgeArticle;
