import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import formatDate from '../../../lib/formatDate';
import Column from '../../grid/Column';
import { waveXsOnlyMediaQuery } from '../../../styles/waveMediaQueries';

const LabelName = glamorous.div({
    marginBottom: options.space.xs,
    fontWeight: options.fontWeights.mediumBold,
    fontSize: options.fontSizes.s,
    color: options.colors.black,
});
const ErrorMessage = glamorous.div({
    color: options.colors.red,
    margin: options.space.xs,
    fontSize: options.fontSizes['2xs'],
});
const DateInput = glamorous.input({
    fontFamily: options.fonts.default,
    width: options.space['7xl'],
    height: options.space['3xl'],
    border: `solid 1px ${options.colors.gray90}`,
    borderRadius: options.radii.small,
    padding: options.space.xs,
    paddingLeft: options.space.m,
    margin: options.space['2xs'],
    fontSize: options.fontSizes.s,
    [waveXsOnlyMediaQuery]: { width: options.space['6xl'] },
});

const LabelText = glamorous.div({
    margin: options.space['2xs'],
    marginBottom: options.space.m,
    fontSize: options.fontSizes.xs,
    maxWidth: 560,
});
const Container = glamorous.div({
    marginTop: options.space.l,
    marginBottom: options.space.l,
    paddingBottom: options.space.m,
    paddingTop: options.space.m,
});

const prependZero = (value) => {
    const strValue = String(value);
    return strValue.padStart(2, '0');
};

const minAge = 16;
const maxAge = 100;

function selectAllText(event) {
    event.target.value.replace(/\D/g, '');
    event.target.select();
}

const invalidDateMessage =
    'Bitte gib ein gültiges Geburtsdatum in dem vorgegebenen Format (TT.MM.JJJJ) ein.';

// eslint-disable-next-line complexity, max-statements
const DateOfBirthSetting = (props, { getCurrentDate, document }) => {
    const { dateOfBirth, onChange, disabled = false } = props;
    const currentDate = getCurrentDate();
    const [date, setDate] = useState({
        day: '',
        month: '',
        year: '',
    });
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
        if (dateOfBirth) {
            const [year, month, day] = dateOfBirth
                ? formatDate(dateOfBirth, 'Y-MM-DD').split('-').map(Number)
                : ['', '', ''];
            setDate({
                day: prependZero(day),
                month: prependZero(month),
                year,
            });
        }
    }, [dateOfBirth]);

    function handleKeyDown(event) {
        const isNumeric = /^[0-9]$/.test(event.key);
        const isSpecialKey = event.key.length !== 1;
        if (!isSpecialKey && !isNumeric) {
            event.preventDefault();
            event.stopPropagation();
        }
    }
    function isAgeWithinValidBoundaries(dayValue) {
        const age = moment(currentDate).diff(dayValue, 'years');

        return age >= minAge && age <= maxAge;
    }

    function isValidDate(dayValue, dateString) {
        const parts = dateString.split('-');
        const year = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1;
        const day = parseInt(parts[2], 10);
        if (
            !(
                dayValue.getFullYear() === year &&
                dayValue.getMonth() === month &&
                dayValue.getDate() === day
            )
        ) {
            return false;
        }
        return !isNaN(dayValue) && isAgeWithinValidBoundaries(dayValue);
    }
    function handleEmptyString() {
        onChange(null);
        setIsValid(true);
    }

    function handleInvalidDate() {
        onChange(null);
        setIsValid(false);
    }
    function validateDateString(dateString) {
        if (dateString === '') {
            handleEmptyString();
            return;
        }

        const dateValue = new Date(dateString);
        if (!isValidDate(dateValue, dateString)) {
            handleInvalidDate();
            return;
        }

        setIsValid(true);
        onChange(dateValue);
    }

    function convertYearValue(value) {
        if (value.length === 1) {
            if (value === '0') {
                return '200';
            }
            if (value > 2) {
                return `19${value}`;
            }
        }
        if (value.length === 2 && value !== '19' && value !== '20') {
            const currentYear = currentDate.getFullYear() - 2000;
            if (currentYear >= parseInt(value, 10)) {
                return `20${value}`;
            }
            return `19${value}`;
        }
        return value;
    }

    return h(Column, { small: null, medium: 6, large: 6 }, [
        h(Container, [
            h(LabelName, 'Geburtsdatum'),
            h(
                LabelText,
                'Andere Urlauber sehen nur Deine Altersgruppe. Als Dankeschön erhältst' +
                    ' Du zum Geburtstag eine kleine Überraschung. ',
            ),

            h('div', { id: 'date-inputs-div', disabled }, [
                h(DateInput, {
                    type: 'text',
                    id: 'day',
                    name: 'day',
                    placeholder: 'TT',
                    value: date.day,
                    style: {
                        border:
                            (date.day > 0 && date.day <= 31) || date.day === ''
                                ? `solid 1px ${options.colors.gray90}`
                                : `solid 1px ${options.colors.red}`,
                    },
                    onChange: (event) => {
                        setDate({ ...date, day: event.target.value });
                        const dateString = `${date.year}-${date.month}-${event.target.value}Z`;
                        validateDateString(dateString);
                        if (
                            parseInt(event.target.value, 10) > 3 ||
                            event.target.value.length === 2
                        ) {
                            /* eslint-disable no-undef */
                            const monthref = document.getElementById('month');
                            /* eslint-enable no-undef */

                            if (monthref) {
                                monthref.focus();
                            }
                        }
                    },
                    onClick: selectAllText,
                    onFocus: selectAllText,
                    onKeyDown: (ev) => handleKeyDown(ev),
                    onBlur: () => {
                        /* eslint-disable no-shadow */
                        setDate((date) => {
                            if (date.day.length === 1) {
                                return {
                                    ...date,
                                    day: prependZero(date.day),
                                };
                            }
                            return date;
                        });
                    },
                }),
                h(DateInput, {
                    type: 'text',
                    id: 'month',
                    name: 'month',
                    placeholder: 'MM',
                    style: {
                        border:
                            (date.month > 0 && date.month <= 12) ||
                            date.month === ''
                                ? `solid 1px ${options.colors.gray90}`
                                : `solid 1px ${options.colors.red}`,
                    },
                    onKeyDown: (ev) => handleKeyDown(ev),
                    onChange: (event) => {
                        setDate({ ...date, month: event.target.value });
                        const dateString = `${date.year}-${event.target.value}-${date.day}Z`;
                        validateDateString(dateString);
                        if (
                            parseInt(event.target.value, 10) > 1 ||
                            event.target.value.length === 2
                        ) {
                            const yearref = document.getElementById('year');

                            if (yearref) {
                                yearref.focus();
                            }
                        }
                    },
                    value: date.month,
                    onClick: selectAllText,
                    onFocus: selectAllText,
                    onBlur: () => {
                        setDate((date) => {
                            if (date.month.length === 1) {
                                return {
                                    ...date,
                                    month: prependZero(date.month),
                                };
                            }
                            return date;
                        });
                    },
                }),
                h(DateInput, {
                    type: 'text',
                    id: 'year',
                    name: 'year',
                    placeholder: 'JJJJ',
                    maxLength: '4',
                    style: {
                        border:
                            (date.year > 1920 &&
                                date.year <= currentDate.getFullYear()) ||
                            date.year === ''
                                ? `solid 1px ${options.colors.gray90}`
                                : `solid 1px ${options.colors.red}`,
                    },
                    onChange: (event) => {
                        setDate({
                            ...date,
                            year: convertYearValue(event.target.value),
                        });
                        const dateString = `${event.target.value}-${date.month}-${date.day}Z`;
                        validateDateString(dateString);
                    },
                    value: String(date.year),
                    onClick: selectAllText,
                    onFocus: selectAllText,
                    onKeyDown: (ev) => handleKeyDown(ev),
                    onBlur: (ev) => {
                        if (
                            ev.target.value === '20' ||
                            ev.target.value === '19'
                        ) {
                            setDate({ ...date, year: `20${ev.target.value}` });
                        }
                    },
                }),
                !isValid &&
                    date.day !== '' &&
                    date.month !== '' &&
                    date.year !== '' &&
                    h(ErrorMessage, invalidDateMessage),
            ]),
        ]),
    ]);
};
DateOfBirthSetting.contextTypes = {
    document: PropTypes.object,
    getCurrentDate: PropTypes.func,
};

export default DateOfBirthSetting;
