import { stripIndent } from 'common-tags';

import graphqlFetch from '../lib/graphqlFetch';

const query = stripIndent`
        query RootQuery {
          upcomingTravelForUser {
            hotel {
              parents {
                id
                name
                destinationType
              }
            }
            booking {
              travelkind
              travellers {
                salutation
                firstName
                lastName
                age
                nationality
              }
             contractualPartner {
                firstName
                lastName
                address {
                  countryCode
                }
                dateOfBirth
              }
              services {
                ... on FlightService {
                  type
                  detail {
                    outBound {
                      departure {
                        date,
                        airportCode
                      }
                      arrival {
                        date,
                        airportCode
                      }
                    }
                    inBound {
                      departure {
                        date,
                        airportCode
                      }
                      arrival {
                        date,
                        airportCode
                      }
                    }
                  }
                }
              }
              startDate
              endDate
            }
          }
        }
    `;

export default async function (context) {
    const response = await graphqlFetch(context, { query });

    if (response.ok) {
        const {
            data: { upcomingTravelForUser },
        } = await response.json();
        return upcomingTravelForUser;
    }

    return null;
}
