import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function Edit() {
    return h(Icon, { viewBox: '0 0 16 17', width: '16', height: '17' }, [
        h('path', {
            d: oneLine`
    M12.9948 1.00581C12.7214 0.73244 12.2782 0.73244 12.0048 1.00581L5.75483 7.25581C5.67799 
    7.33265 5.62009 7.42633 5.58573 7.52942L4.33573 11.2794C4.25188 11.531 4.31735 11.8083 
    4.50483 11.9958C4.69231 12.1832 4.96963 12.2487 5.22116 12.1649L8.97116 10.9149C9.07426 
    10.8805 9.16794 10.8226 9.24478 10.7458L15.4948 4.49576C15.7681 4.22239 15.7681 3.77917 
    15.4948 3.50581L12.9948 1.00581ZM6.1066 10.394L6.86163 8.12891L12.4998 2.49073L14.0099 
    4.00078L8.37168 9.63896L6.1066 10.394ZM1.82943 2.80078C0.984879 2.80078 0.299805 3.48586 
    0.299805 4.33041L0.299811 14.6711C0.299811 15.5157 0.984887 16.2008 1.82944 
    16.2008H12.1702C13.0147 16.2008 13.6998 15.5157 13.6998 14.6711V11.1823C13.6998 10.7957 
    13.3864 10.4823 12.9998 10.4823C12.6132 10.4823 12.2998 10.7957 12.2998 11.1823V14.6711C12.2998 
    14.7425 12.2415 14.8008 12.1702 14.8008H1.82944C1.75808 14.8008 1.69981 14.7425 1.69981 
    14.6711L1.6998 4.33041C1.6998 4.25905 1.75808 4.20078 1.82943 4.20078H5.31832C5.70492 4.20078 
    6.01832 3.88738 6.01832 3.50078C6.01832 3.11418 5.70492 2.80078 5.31832 2.80078H1.82943Z`,
        }),
    ]);
}
