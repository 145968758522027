import h from 'react-hyperscript';
import { Query } from '@apollo/client/react/components';

import { voucherCard } from '../../../../queries/myHolidaycheckDashboard';
import VoucherCard from './VoucherCard';
import { getActiveVoucherFromResponse } from '../../../../lib/voucher';
import VoucherTermsCard from './VoucherTermsCard';

export default function VoucherCardQuery() {
    return h(
        Query,
        { fetchPolicy: 'cache-and-network', query: voucherCard },
        ({ loading, data, error }) => {
            if (loading || error || !data) {
                return null;
            }

            if (data.termsAndConditionsForUser.consent === null) {
                return null;
            }
            if (data.termsAndConditionsForUser.consent) {
                const voucher = getActiveVoucherFromResponse(
                    data.vouchersForUser.vouchers,
                );
                return voucher ? h(VoucherCard, { voucher }) : null;
            }
            return h(VoucherTermsCard);
        },
    );
}
