import options from '@designsystem/options';

import { pxToRem } from './unitConverter';

export const waveHeadline2XS = {
    fontSize: options.fontSizes.l,
    lineHeight: pxToRem(24),
    fontWeight: options.fontWeights.extraBold,
};

export const waveHeadline2XSSlim = {
    fontSize: pxToRem(options.fontSizes.l),
    lineHeight: pxToRem(24),
    fontWeight: options.fontWeights.bold,
};

export const waveHeadlineXS = {
    fontSize: pxToRem(options.fontSizes.xl),
    lineHeight: pxToRem(29),
    fontWeight: options.fontWeights.extraBold,
};

export const waveHeadlineXSSlim = {
    fontSize: pxToRem(options.fontSizes.xl),
    lineHeight: pxToRem(29),
    fontWeight: options.fontWeights.bold,
};

export const waveHeadlineS = {
    fontSize: pxToRem(options.fontSizes['2xl']),
    lineHeight: pxToRem(40),
    fontWeight: options.fontWeights.extraBold,
};

export const waveHeadlineSSlim = {
    fontSize: pxToRem(options.fontSizes['2xl']),
    lineHeight: pxToRem(40),
    fontWeight: options.fontWeights.bold,
};

export const waveHeadlineM = {
    fontSize: pxToRem(options.fontSizes['3xl']),
    lineHeight: pxToRem(49),
    fontWeight: options.fontWeights.extraBold,
};

export const waveHeadlineL = {
    fontSize: pxToRem(options.fontSizes['4xl']),
    lineHeight: pxToRem(55),
    fontWeight: options.fontWeights.extraBold,
};

export const waveHeadlineXl = {
    fontSize: pxToRem(options.fontSizes['5xl']),
    lineHeight: pxToRem(64),
    fontWeight: options.fontWeights.extraBold,
};

export const waveHeadline2xl = {
    fontSize: pxToRem(options.fontSizes['6xl']),
    lineHeight: pxToRem(74),
    fontWeight: options.fontWeights.extraBold,
};

export const waveHeadline3xl = {
    fontSize: pxToRem(options.fontSizes['7xl']),
    lineHeight: pxToRem(88),
    fontWeight: options.fontWeights.extraBold,
};

export const waveFontL = {
    fontSize: pxToRem(options.fontSizes.s),
    lineHeight: pxToRem(24),
};

export const waveFontM = {
    fontSize: pxToRem(options.fontSizes.xs),
    lineHeight: pxToRem(21),
    fontWeight: options.fontWeights.normal,
};
