import h from 'react-hyperscript';

import Icon from './Icon';

const CarWheel = ({ css }) =>
    h(
        Icon,
        {
            viewBox: '0 0 17 17',
            css,
        },
        [
            h('path', {
                d: 'M11.4282 1.11357C4.60627 -1.53847 -1.78886 4.85588 0.86374 11.6775C1.61468 13.6028 3.14222 15.135 5.07224 15.8864C11.8942 18.5384 18.288 12.1441 15.6367 5.32245C14.8858 3.39726 13.3582 1.86497 11.4282 1.11357ZM8.25022 2.64585C10.8528 2.64585 13.0624 4.35004 13.8188 6.70249C13.9565 7.12485 13.6378 7.55212 13.1962 7.55212H11.9725C11.7118 7.55212 11.4768 7.39496 11.3742 7.15922C10.8528 5.95108 9.64945 5.10143 8.25022 5.10143C6.85099 5.10143 5.64761 5.94616 5.12627 7.15922C5.02363 7.39987 4.78726 7.55212 4.52794 7.55212H3.30428C2.86263 7.55212 2.5439 7.11993 2.68031 6.70249C3.44206 4.35004 5.64759 2.64585 8.25022 2.64585ZM3.30428 9.44783H4.52794C4.78726 9.44783 5.02363 9.60007 5.12627 9.84076C5.47068 10.6363 6.1095 11.2797 6.90501 11.6186C7.14542 11.7218 7.2967 11.9574 7.2967 12.2177V13.4407C7.2967 13.8826 6.8645 14.2019 6.44311 14.0644C4.6603 13.4897 3.25026 12.0802 2.67625 10.2974C2.53849 9.87507 2.85724 9.44292 3.30024 9.44292L3.30428 9.44783ZM9.19836 13.4455V12.2227C9.19836 11.9623 9.34963 11.7266 9.59139 11.6234C10.3869 11.2797 11.0298 10.6413 11.3688 9.84563C11.4714 9.60499 11.7078 9.45275 11.9685 9.45275H13.1908C13.6324 9.45275 13.9525 9.88493 13.8147 10.3073C13.2394 12.0901 11.8307 13.4996 10.0479 14.0741C9.62515 14.2116 9.19294 13.8925 9.19294 13.4504L9.19836 13.4455Z',
                fill: '#008278',
            }),
        ],
    );

export default CarWheel;
