const getMutationResult = (mutationState) => {
    if (mutationState.error) {
        return 'ERROR_GENERIC';
    }
    return mutationState.data && mutationState.data.updateProfile;
};

const isMutationDone = (mutationState) =>
    Boolean(mutationState.called && !mutationState.loading);

const wasMutationSuccessful = (mutationState) => {
    return ['SUCCESS_MERGE_REQUEST', 'SUCCESS'].includes(
        getMutationResult(mutationState),
    );
};

const hasMutationError = (mutationState) =>
    isMutationDone(mutationState) && !wasMutationSuccessful(mutationState);

export {
    getMutationResult,
    hasMutationError,
    wasMutationSuccessful,
    isMutationDone,
};
