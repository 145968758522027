const isFeatureFlagSet = (queryString) => (flag) => {
    if (queryString && typeof queryString === 'string') {
        const keyValuePairs = queryString
            .substring(queryString.indexOf('?') + 1)
            .split('&')
            .map((parameter) => {
                const [key, value] = parameter.split('=');

                return {
                    [key]: Boolean(parseInt(value, 10)),
                };
            });

        // merge all key-value-pairs, only keeping the latest occurrence of any given key
        const flagsObject = keyValuePairs.reduce(
            (object, item) => Object.assign(object, item),
            {},
        );

        return flagsObject[flag] || false;
    }

    return false;
};

export default isFeatureFlagSet;
