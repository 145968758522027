import h from 'react-hyperscript';
import { Query } from '@apollo/client/react/components';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import TourOperatorPrice from './TourOperatorPrice';
import LoadingTourOperatorPrice from './LoadingTourOperatorPrice';
import PaymentConditions from './PaymentConditions';
import { getUpdatedTourOperatorPrice } from '../../../queries/myBookings';
import { secondaryFont } from '../../../styles/fonts';
import { pxToRem } from '../../../styles/unitConverter';

const { PTSansFamily } = secondaryFont;

const ErrorText = glamorous.div(PTSansFamily, {
    textAlign: 'start',
    padding: `0 ${pxToRem(24)}`,
    marginBottom: pxToRem(16),
});
ErrorText.displayName = 'ErrorText';

const renderLoading = (tourOperatorPrice) =>
    h(
        TourOperatorPrice,
        {
            amount: tourOperatorPrice.amount,
            currencyCode: tourOperatorPrice.currencyCode,
        },
        h(LoadingTourOperatorPrice),
    );

const renderError = (tourOperatorPrice) =>
    h(
        TourOperatorPrice,
        {
            amount: tourOperatorPrice.amount,
            currencyCode: tourOperatorPrice.currencyCode,
            trackingName: 'error',
        },
        h(
            ErrorText,
            'Leider konnten wir Deinen Veranstalter nicht erreichen. Probiere es bitte später noch einmal, ' +
                'indem Du diese Seite neu lädst. Sollte die Zahlung nicht rechtzeitig eingegangen sein, meldet sich der ' +
                'Veranstalter bei Dir.',
        ),
    );

const renderNoUpdates = (booking) => {
    const { tourOperatorPrice } = booking;

    return h(
        TourOperatorPrice,
        {
            amount: tourOperatorPrice.amount,
            currencyCode: tourOperatorPrice.currencyCode,
            trackingName: 'not-supported',
        },
        h(PaymentConditions, { booking }),
    );
};

const isEverythingPaid = ({ remainingDeposit, remainingFinal }) =>
    remainingDeposit + remainingFinal <= 0;

const renderSuccess = (booking, updatedTourOperatorPrice) => {
    const { tourOperatorPrice } = booking;

    if (!updatedTourOperatorPrice) {
        return renderNoUpdates(booking);
    }

    if (isEverythingPaid(updatedTourOperatorPrice)) {
        return h(TourOperatorPrice, {
            amount: updatedTourOperatorPrice.total,
            currencyCode: tourOperatorPrice.currencyCode,
            everythingPaid: true,
            trackingName: 'everything-paid',
        });
    }

    return h(
        TourOperatorPrice,
        {
            amount: updatedTourOperatorPrice.total,
            currencyCode: tourOperatorPrice.currencyCode,
            trackingName: 'updated',
        },
        h(PaymentConditions, { booking, updatedTourOperatorPrice }),
    );
};

const TourOperatorPriceQuery = ({ booking }) => {
    const { id, tourOperatorPrice } = booking;

    return h(
        Query,
        {
            fetchPolicy: 'cache-and-network',
            query: getUpdatedTourOperatorPrice,
            variables: { bookingId: id },
        },
        ({ loading, error, data }) => {
            if (loading) {
                return renderLoading(tourOperatorPrice);
            }
            if (error) {
                return renderError(tourOperatorPrice);
            }

            return renderSuccess(booking, data.updatedTourOperatorPrice);
        },
    );
};

TourOperatorPriceQuery.propTypes = {
    booking: PropTypes.shape({
        id: PropTypes.string.isRequired,
        tourOperatorPrice: PropTypes.shape({
            amount: PropTypes.number.isRequired,
            currencyCode: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
};

export default TourOperatorPriceQuery;
