import formatDate from './formatDate';

function getCurrencySymbol(currency) {
    try {
        const symbol = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })
            .formatToParts(0)
            .find((p) => p.type === 'currency');
        return symbol ? symbol.value : currency;
    } catch {
        return currency;
    }
}

export function getActiveVoucherFromResponse(data) {
    if (!data || !Array.isArray(data)) {
        return undefined;
    }

    let voucher = data.find((v) => v.status === 'issued' && v.code);
    if (!voucher) {
        voucher = data.find((v) => v.status === 'not_issued');
    }
    return voucher
        ? {
              isIssued: voucher.status === 'issued',
              value: `${voucher.value}${getCurrencySymbol(voucher.currency)}`,
              validUntil: formatDate(voucher.validUntil, 'DD.MM.YYYY'),
          }
        : undefined;
}
