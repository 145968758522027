import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function ThumbsUp(options = {}) {
    return h(Icon, options, [
        h('path', {
            d: oneLine`
        M9.284 22.622zm13.908 .177h.006a1.886 1.886 0 001.888 -1.88 1.87 1.87 0 00-.552
        -1.332 1.89 1.89 0 00.797 -3.133 1.868 1.868 0 001.336 -1.795 1.88 1.88 0
        00-1.879 -1.89h-6.023c0 -.785 .633 -1.255 1.58 -3.135C20.9 8.534 21.269 3.642
        18.45 3.99c-1.264 .157 -.474 1.724 -2.054 4.075 -.023 .035 -3.95 5.486 -7.111
        6.27l-3.95 .783v7.053l2.37 .784 1.675 1.062s2.494 1.289 3.516 1.289h9.043a1.57
        1.57 0 001.254 -2.508z`,
        }),
    ]);
}
