import glamorous from 'glamorous';
import options from '@designsystem/options';
import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import { useState } from 'react';
import moment from 'moment';

import { waveTextM } from '../../../styles/waveText';
import UpArrowLine from '../../icons/UpArrowLine';
import DownArrowLine from '../../icons/DownArrowLine';
import buttons from '../../../styles/buttons';
import BookingDetails from './BookingDetails';
import BookingOverview from '../../myBookings/BookingOverview';
import useIsMobileDevice from '../../../lib/hooks/useIsMobileDevice';
import { RetalCarBookingType } from '../BookingPropType';
import BookingStatus from '../../myBookings/BookingStatus';
import { waveHeadline2XS } from '../../../styles/waveHeadlines';
import {
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../../styles/waveMediaQueries';

const { whiteButton } = buttons;

const borderRadiusDesktop = `${options.space.m}px`;
const borderRadiusMobile = `${options.space['2xs']}px`;

const Container = glamorous.section(({ showDetails }) => ({
    backgroundColor: options.colors.white,
    display: 'flex',
    flexDirection: 'column',
    boxShadow: options.shadows.shallow,
    borderRadius: borderRadiusMobile,
    paddingBottom: showDetails ? options.space.l : 0,
    width: '100%',

    [waveMdMediaQuery]: {
        paddingBottom: showDetails ? options.space['3xl'] : 0,
        display: 'grid',
        borderRadius: borderRadiusDesktop,
        maxWidth: 1200,
        gap: `${options.space.xl}px`,
        gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 2fr)',
        gridTemplateAreas: showDetails
            ? '"image overview" "sidebar content" ". content" "contact contact"'
            : '"image overview"',
    },
}));

const DestinationImageWrapper = glamorous.div(({ showDetails }) => ({
    overflow: 'hidden',
    position: 'relative',
    borderRadius: `${borderRadiusMobile} ${borderRadiusMobile} 0 0`,
    [waveMdMediaQuery]: {
        borderRadius: showDetails
            ? `${borderRadiusDesktop} 0 0 0`
            : `${borderRadiusDesktop} 0 0 ${borderRadiusDesktop}`,
    },
}));

const DestinationImage = glamorous.img(
    {
        gridArea: 'image',
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        transform: 'rotateY(180deg)',
    },
    ({ isCancelled }) => isCancelled && { filter: 'grayscale(1)' },
);

const DestinationInfoContainer = glamorous.div({
    width: '100%',
    gridArea: 'overview',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'flex-start',
    padding: `${options.space.xl}px ${options.space.xl}px`,
    boxSizing: 'border-box',
    height: '100%',
    [waveMdMediaQuery]: {
        paddingLeft: 0,
    },
});

const DestinationName = glamorous.h2({
    fontSize: options.fontSizes['2xl'],
    fontWeight: options.fontWeights.extraBold,
    lineHeight: '40px',
    margin: 0,
    marginBottom: `${options.space['2xs']}px`,
});

const DestinationRegion = glamorous.span({
    ...waveTextM,
});

const LocationImage = glamorous.img({
    marginRight: 8,
    verticalAlign: 'text-top',
});

const ShortInfoContainer = glamorous.div({
    display: 'block',
    [waveSmMediaQuery]: {
        width: '100%',
        display: 'grid',
        alignItems: 'flex-end',
        gridTemplateColumns: '2fr 1fr',
    },
});

const BookingAndActionContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
});

const OpenButton = glamorous.button({
    ...whiteButton,
    fontFamily: 'inherit',
    fontSize: 14,
    margin: `${options.space.s}px 0 0 0`,
    maxWidth: 225,
    boxSizing: 'content-box',
    height: 42,
    padding: `0 ${options.space.m}px`,
});

const ButtonText = glamorous.span({
    marginInline: 'auto',
});

const BookingOverviewWrapper = glamorous.div({
    position: 'absolute',
    bottom: '10%',
    zIndex: 25,
    alignSelf: 'center',
    justifySelf: 'center',
    margin: 'auto',
    left: 0,
    right: 0,
});

const CancelledBookingOverlay = glamorous.div({
    backgroundImage: `linear-gradient(0deg, ${options.colors.black} 0%, ${options.colors.black} 100%)`,
    width: '100%',
    height: '100%',
});

const arrowProps = {
    width: 20,
    height: 20,
    css: { marginInline: 'auto' },
};

const Overlays = glamorous.div(({ isCancelled }) => {
    const lightBlackWithOpacity = `${options.colors.black}26`; // black with 15% opacity
    const blackWithOpacity = `${options.colors.black}80`; // black with 50% opacity
    const greyWithOpacity = `${options.colors.gray40}80`; // gray with 50% opacity

    const blackGradient15 = `linear-gradient(0deg, ${lightBlackWithOpacity} 0%, ${lightBlackWithOpacity} 100%)`;
    const blackGradient50 = `linear-gradient(0deg, ${blackWithOpacity} 0%, transparent 50%)`;
    const greyGradient50 = `linear-gradient(0deg, ${greyWithOpacity} 0%, ${greyWithOpacity} 100%)`;
    const defaultBackgroundImage = `${blackGradient15}, ${blackGradient50}`;
    const backgroundImage = !isCancelled
        ? defaultBackgroundImage
        : `${defaultBackgroundImage}, ${greyGradient50}`;

    return {
        top: 0,
        backgroundImage,
        height: '100%',
        width: '100%',
        position: 'absolute',
    };
});

const OnHolidays = glamorous.div(waveHeadline2XS, ({ shouldBeWhite }) => ({
    color: shouldBeWhite ? options.colors.white : 'inherit',
    textAlign: 'center',
    marginBottom: options.space['2xs'],
}));

const renderOverview = ({
    pickupDatetime,
    dropoffDatetime,
    isCancelled,
    isMobile,
    assetsPath,
}) => {
    const dropoffMoment = moment(dropoffDatetime);
    const pickupMoment = moment(pickupDatetime);
    if (dropoffMoment.isBefore(moment()) && !isCancelled) {
        const pickup = pickupMoment.format('DD.MM.yyyy');
        const dropoff = moment(dropoffDatetime).format('DD.MM.yyyy');

        return h(BookingStatus, {
            imageSrc: `${assetsPath}/myBookings/sun--solid${isMobile ? '-white' : ''}.svg`,
            element: h(
                OnHolidays,
                { shouldBeWhite: isMobile },
                pickupMoment.format('MMMM yyyy'),
            ),
            text: `${pickup} - ${dropoff}`,
            shouldBeWhite: isMobile,
        });
    }

    return h(BookingOverview, {
        booking: {
            startDate: pickupDatetime,
            endDate: dropoffDatetime,
            services: [],
        },
        isCancelled,
        shouldBeWhite: isMobile,
        greeting: 'Schöne Fahrt',
    });
};

function MietwagenBooking(
    { isDefaultOpen, booking, titleImage, campaigns },
    { window, config: { assetsPath } },
) {
    const [showDetails, setShowDetails] = useState(isDefaultOpen || false);

    const toggleCard = () =>
        setShowDetails((previousBoolean) => !previousBoolean);

    const isMobile = useIsMobileDevice(window, (width) => width < 600);

    const isCancelled = ['Cancelled', 'Failed'].includes(booking.status);

    const bookingOverView = renderOverview({
        pickupDatetime: booking.pickupDatetime,
        dropoffDatetime: booking.dropoffDatetime,
        isCancelled,
        isMobile,
        assetsPath,
    });

    return h(Container, { showDetails }, [
        h(DestinationImageWrapper, { showDetails }, [
            h(DestinationImage, { src: titleImage, isCancelled }),
            h(Overlays, { isCancelled }),
            isCancelled && h(CancelledBookingOverlay),
            isMobile && h(BookingOverviewWrapper, [bookingOverView]),
        ]),
        h(DestinationInfoContainer, [
            h('div', [
                h(DestinationName, `Mietwagen ${booking.carOfferDestination}`),
                h(DestinationRegion, [
                    h(LocationImage, {
                        src: `${assetsPath}/myBookings/location--solid.svg`,
                        alt: 'Standort',
                    }),
                    h('span', [
                        `${booking.carOfferDestinationRegion.destination}, ${booking.carOfferDestinationLand.destination}`,
                    ]),
                ]),
            ]),
            h(ShortInfoContainer, [
                h(BookingAndActionContainer, [
                    h(
                        glamorous.span({
                            ...waveTextM,
                        }),
                        `Mietwagen-Buchungsnummer: ${booking.id}`,
                    ),
                    h(
                        OpenButton,
                        {
                            className: 'openbutton',
                            type: 'button',
                            onClick: toggleCard,
                        },
                        [
                            h(ButtonText, [
                                showDetails
                                    ? 'Mietwagen verbergen'
                                    : 'Mietwagen anzeigen',
                            ]),
                            showDetails
                                ? h(UpArrowLine, arrowProps)
                                : h(DownArrowLine, arrowProps),
                        ],
                    ),
                ]),
                !isMobile && bookingOverView,
            ]),
        ]),
        showDetails && h(BookingDetails, { booking, campaigns }),
    ]);
}

MietwagenBooking.propTypes = {
    booking: RetalCarBookingType.isRequired,
    isDefaultOpen: PropTypes.bool,
    titleImage: PropTypes.string,
};

MietwagenBooking.contextTypes = {
    config: PropTypes.object.isRequired,
    window: PropTypes.object.isRequired,
};

export default MietwagenBooking;
