import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import {
    waveLgMediaQuery,
    waveMdMediaQuery,
} from '../../../../styles/waveMediaQueries';
import Link from '../../../navigation/Link';

const SideMenu = glamorous.div({
    '&:empty': {
        display: 'none',
    },
    backgroundColor: options.colors.white,
    color: options.colors.black,
    marginBottom: options.space['3xl'],
    position: 'sticky',
    top: 0,
    zIndex: 5,
    [waveMdMediaQuery]: {
        borderRadius: 8,
        padding: options.space.l,
        minWidth: 182,
        maxWidth: 242,
        position: 'sticky',
        top: options.space.xl,
        height: 'fit-content',
        marginLeft: options.space.xl,
        marginBottom: 'unset',
    },
    [waveLgMediaQuery]: {
        width: 242,
    },
});

const MenuEntries = glamorous.div({
    display: 'flex',
    columnGap: options.space['2xl'],
    flexBasis: '100%',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    padding: `0 ${options.space.xl}px`,
    [waveMdMediaQuery]: {
        padding: 0,
        flexDirection: 'column',
        flexBasis: 'auto',
        gap: options.space.m,
        flexWrap: 'no-wrap',
        overflowX: 'unset',
    },
});

const MenuTitle = glamorous.h1({
    margin: `${options.space.xl}px ${options.space.xs}px ${options.space.xs}px ${options.space.l}px`,
    fontSize: options.fontSizes.l,
    fontWeight: options.fontWeights.extraBold,
    lineHeight: options.lineHeights.m,
    [waveMdMediaQuery]: {
        margin: `0 0 ${options.space.m}px 0`,
    },
});

const MenuLinkLabel = glamorous.div((props) => ({
    padding: `${options.space.l}px 0`,
    borderBottom: props.isActive ? '2px solid #0E55CD' : 'unset',
    color: props.isActive ? '#000820' : '#556685',
    transition: 'color 0.3s ease',
    fontWeight: options.fontWeights.bold,
    [waveMdMediaQuery]: {
        fontWeight: options.fontWeights.mediumBold,
        borderBottom: 'unset',
        color: 'unset',
        padding: 0,
    },
}));

function MenuItem({ name, href, myRef, isActive, window }) {
    return h(
        Link,
        {
            href,
            onClick(e) {
                e.preventDefault();
                const element = myRef.current;
                const elementPosition = element.getBoundingClientRect().top;
                const isMobile =
                    window.matchMedia('(max-width: 767px)').matches;
                const offset = isMobile ? 150 : 70;
                const offsetPosition =
                    elementPosition + window.pageYOffset - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
            },
        },
        [h(MenuLinkLabel, { isActive }, name)],
    );
}

export function Menu({ entries }, { window }) {
    const [activeSection, setActiveSection] = useState(null);

    useEffect(() => {
        if (
            typeof window === 'undefined' ||
            !('IntersectionObserver' in window)
        ) {
            return () => {};
        }

        const observers = entries.map((entry) => {
            const observer = new window.IntersectionObserver(
                ([intersection]) => {
                    if (intersection.isIntersecting) {
                        setActiveSection(entry.name);
                    }
                },
                {
                    threshold: 0.5,
                    rootMargin: '-50px 0px -50px 0px',
                },
            );

            if (entry.myRef.current) {
                observer.observe(entry.myRef.current);
            }

            return observer;
        });

        return () => {
            observers.forEach((observer) => observer.disconnect());
        };
    }, [entries]);

    return h(SideMenu, [
        h(MenuTitle, 'HolidayCheck Mietwagen'),
        h(
            MenuEntries,
            entries.map((entry, index) =>
                h(MenuItem, {
                    name: entry.name,
                    href: `${window.location.href}#${entry.sectionId}`,
                    key: index,
                    myRef: entry.myRef,
                    isActive: activeSection === entry.name,
                    window,
                }),
            ),
        ),
    ]);
}

Menu.propTypes = {
    entries: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            displayName: PropTypes.string.isRequired,
            myRef: PropTypes.shape({ current: PropTypes.any }),
        }),
    ),
};
Menu.contextTypes = {
    window: PropTypes.object.isRequired,
};
