import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import { Component } from 'react';

import { applyOnMobile } from '../../styles/mediaQueries';
import { pxToRem } from '../../styles/unitConverter';
import { subheadlineFont } from '../../styles/fonts';
import Link from '../navigation/Link';

const HeadlineContainer = glamorous.div({
    position: 'relative',
    height: pxToRem(95),
    textAlign: 'left',

    [applyOnMobile]: {
        height: 'initial',
    },
});

const Headline = glamorous.h2({ ...subheadlineFont, marginBottom: 0 });

const image = glamorous.img({
    height: pxToRem(84),
    position: 'absolute',
    right: 0,
    top: 0,
    [applyOnMobile]: {
        display: 'none',
    },
});

function getDestinationName(parents, type) {
    const parent = parents.find((p) => p.destinationType === type);
    return parent ? parent.name : '';
}

function getLocation(parents) {
    const city = getDestinationName(parents, 'CITY');
    const country = getDestinationName(parents, 'COUNTRY');
    return country ? `${city}, ${country}` : `${city}`;
}

class ReviewHeader extends Component {
    renderImage() {
        const { id, name } = this.props;
        return h(image, {
            src: `https://www.holidaycheck.de/main-photo-redirect/${id}/t_hc_fill_226_x_126`,
            alt: name,
        });
    }

    render() {
        const { id, name, parents } = this.props;
        const headline = id ? h(Link, { href: `/hi/${id}` }, name) : name;

        return h(HeadlineContainer, [
            h(Headline, [headline]),
            h('div', getLocation(parents)),
            this.renderImage(),
        ]);
    }
}

ReviewHeader.contextTypes = {
    config: PropTypes.object,
};

export default ReviewHeader;
