import { useEffect, useState } from 'react';

import { noticeError } from '../../lib/newrelic';

/**
 *
 * @param queue the service that should be used defaults to 'cs' (customer service) by the server
 * @param {context} providing fetch
 * @returns {null | object} check availability by {returned}?.active
 */
function useCallbackAvailability(queue, { pageName, component }, { fetch }) {
    const [availability, setAvailability] = useState(null);

    useEffect(() => {
        const execFetch = async () => {
            const query = queue ? `?queue=${queue}` : '';
            try {
                const response = await fetch(
                    `https://www.holidaycheck.de/svc/appointment-widget/getAvailability${query}`,
                );
                const data = await response.json();
                setAvailability(data);
            } catch (error) {
                noticeError(error, { queue, pageName, component });
            }
        };

        execFetch();
    }, [queue]);

    return availability;
}

export { useCallbackAvailability };
