async function fetchAuthorize(context) {
    const { fetch, config } = context;

    const res = await fetch(`${config.baseUrl}mhc/authorize`, {
        method: 'PUT',
        credentials: 'include',
    });

    return res.ok;
}

export default fetchAuthorize;
