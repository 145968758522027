import h from 'react-hyperscript';
import PropTypes from 'prop-types';

import AirlineAndBaggage from './AirlineAndBaggage';
import FlightDetails from './FlightDetails';
import Stop from './Stop';

const JourneyDetails = ({ flights, assetsPath }) => {
    return h(
        'div',
        flights.map((flight, index) => {
            const isFirst = index === 0;
            const isLast = index === flights.length - 1;
            const startDate = flights[0].departure.date;

            return h('div', [
                h(AirlineAndBaggage, { flight, assetsPath }),
                h(FlightDetails, {
                    departure: flight.departure,
                    arrival: flight.arrival,
                    isFirst,
                    isLast,
                }),
                h(Stop, { flight, nextFlight: flights[index + 1], startDate }),
            ]);
        }),
    );
};

const flightTimeAndPlacePropType = PropTypes.shape({
    date: PropTypes.string,
    airportCode: PropTypes.string.isRequired,
    airportName: PropTypes.string,
});

JourneyDetails.propTypes = {
    flights: PropTypes.arrayOf(
        PropTypes.shape({
            departure: flightTimeAndPlacePropType.isRequired,
            arrival: flightTimeAndPlacePropType.isRequired,
            airlineInformation: PropTypes.shape({
                airlineName: PropTypes.string.isRequired,
            }),
            airlineCode: PropTypes.string,
            flightNumber: PropTypes.string,
        }),
    ).isRequired,
    assetsPath: PropTypes.string.isRequired,
};

export default JourneyDetails;
