import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import PropTypes from 'prop-types';

import { waveFontL, waveHeadline2XSSlim } from '../../../styles/waveHeadlines';
import { useCancellationContext } from '../context/CancellationContext';
import FuturePeriodDiscountButton from '../FuturePeriodDiscountButton';

const RetentionContainer = glamorous.div({
    marginBottom: options.space['3xl'],
});

const RetentionHeader = glamorous.div({
    ...waveHeadline2XSSlim,
    marginBottom: options.space['2xs'],
});
const RetentionDescription = glamorous.div({
    ...waveFontL,
    marginTop: options.space.m,
    '> a': {
        textDecoration: 'none',
    },
});
RetentionDescription.displayName = 'RetentionDescription';

const NextYearBenefitsBox = glamorous.ul({
    backgroundColor: options.colors.purple100,
    padding: `${options.space.m}px ${options.space.xl}px`,
    ...waveFontL,
    margin: 0,
    marginTop: options.space.m,
    listStyle: 'none',
    '& li': {
        wordBreak: 'break-all',
        position: 'relative',
        paddingLeft: options.space.xl,
        marginTop: options.space.s,
    },
    '& img': {
        position: 'absolute',
        top: '0px',
        width: options.space.l,
        left: '-2px',
    },
});

const spark = (assetsPath) => {
    return h('img', {
        src: `${assetsPath}/myPremium/spark.svg`,
    });
};

const RetentionCTA = glamorous.div({
    ...waveFontL,
    marginTop: options.space.m,
    fontWeight: options.fontWeights.bold,
});

const benefitElement = (text, { assetsPath }) =>
    h('li', [spark(assetsPath), text]);

const RetainMembership = (_, { config }) => {
    const { getCancellationReason, trackEventForPremium } =
        useCancellationContext();
    const selectedCancellationReason = getCancellationReason();

    if (!selectedCancellationReason) {
        return null;
    }

    return h(
        RetentionContainer,
        {
            onClick: (e) => {
                if (e.target.tagName === 'A') {
                    trackEventForPremium({
                        event: {
                            eventAction: `Click-${e.target.href}`,
                            eventLabel: selectedCancellationReason,
                        },
                    });
                }
            },
        },
        [
            h(RetentionHeader, 'Schade, dass Du gehen möchtest!'),
            h(
                RetentionDescription,
                'Wir verstehen, dass Deine Urlaubspläne oder Bedürfnisse sich ändern können, aber bevor Du Deine Entscheidung triffst, möchten wir Dir ein exklusives Angebot machen:',
            ),
            h(
                RetentionCTA,
                'Sichere Dir ein weiteres Jahr HolidayCheck Premium für nur 49 € statt 89 €!',
            ),

            h(NextYearBenefitsBox, [
                h(
                    'span',
                    'Mit Deiner Mitgliedschaft profitierst Du im nächsten Jahr von:',
                ),
                benefitElement(
                    'bis zu 350 € Reiseguthaben für Deine nächsten Reisen',
                    config,
                ),
                benefitElement(
                    'Auslandsreisekrankenversicherung für Dich und alle Mitreisenden',
                    config,
                ),
                benefitElement(
                    'Premium-Rabatte bei exklusiven Partnern, z.B. 10% auf Flughafenparkplätze',
                    config,
                ),
            ]),
            h(
                RetentionDescription,
                'Falls Du noch keine Reise geplant hast, keine Sorge! Unsere Gutscheine sind flexibel einsetzbar und das gesamte Mitgliedsjahr gültig – auch für Kurztrips oder spontane Reisen.',
            ),
            h(RetentionDescription, [
                h('strong', 'Wichtig:'),
                ' Viele der Vorteile des zweiten Mitgliedsjahres – wie die ',
                h('strong', 'zusätzlichen 50 € Gutscheine'),
                ' – erhältst Du nur, wenn Du dabei bleibst. Diese Vorteile gelten nicht, wenn Du später zurückkommst, also nutze die Gelegenheit jetzt!',
            ]),
            h(FuturePeriodDiscountButton, {
                trackingLabel: selectedCancellationReason,
                trackEventForPremium,
            }),
        ],
    );
};

RetainMembership.contextTypes = {
    config: PropTypes.object,
};

export default RetainMembership;
