import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { fetchRentalCarBooking } from '../../../fetchers/fetchRentalcarBookings';

export const useRentalCarBooking = (context) => {
    const [booking, setBooking] = useState(null);

    const { bookingId } = useParams();
    const bookingIdParam = Number.parseInt(bookingId, 10);

    useEffect(() => {
        (async () => {
            if (bookingIdParam && !Number.isNaN(bookingIdParam)) {
                const response = await fetchRentalCarBooking(
                    context,
                    bookingIdParam,
                );
                setBooking(response);
            }
        })();
    }, []);

    return booking;
};
