import glamorous from 'glamorous';
import h from 'react-hyperscript';

import colors from '../../styles/colors';

const { sliderThumbBackgroundColor, sliderThumbBorderColor, sliderTrackColor } =
    colors;

const sliderThumbStyle = {
    appearance: 'none',
    background: sliderThumbBackgroundColor,
    border: `1px solid ${sliderThumbBorderColor}`,
    borderRadius: '50%',
    boxSizing: 'border-box',
    cursor: 'pointer',
    height: '32px',
    marginTop: '-14px',
    width: '32px',
};

const sliderTrackStyle = {
    appearance: 'none',
    background: sliderTrackColor,
    borderColor: 'transparent',
    color: 'transparent',
    cursor: 'pointer',
    height: '4px',
    width: '100%',
};

const Container = glamorous.div({
    alignItems: 'center',
    display: 'flex',
});

const Label = glamorous.label({
    flexBasis: '25%',
    fontWeight: 300,
    textAlign: 'center',
});

const Input = glamorous.input({
    appearance: 'none',
    background: 'transparent',
    flexBasis: '50%',
    height: '32px',
    padding: 0,

    '&:focus': {
        outline: 'none',
    },

    '&::-moz-focus-outer': {
        border: 0,
    },
    '&::-moz-range-thumb': sliderThumbStyle,
    '&::-moz-range-track': sliderTrackStyle,

    '&::-ms-thumb': {
        ...sliderThumbStyle,
        margin: 0,
    },
    '&::-ms-track, &::-ms-fill-lower, &::-ms-fill-upper': sliderTrackStyle,

    '&::-webkit-slider-thumb': sliderThumbStyle,
    '&::-webkit-slider-runnable-track': sliderTrackStyle,

    '@supports (-ms-ime-align: auto)': {
        '&::-webkit-slider-thumb': {
            margin: 0,
        },
    },
});

export default function Slider({
    decreaseLabel,
    increaseLabel,
    onChange,
    value,
}) {
    return h(Container, [
        h(Label, decreaseLabel),
        h(Input, {
            max: 2,
            min: 1,
            onChange: (event) => onChange(parseFloat(event.target.value)),
            step: 'any',
            type: 'range',
            value,
        }),
        h(Label, increaseLabel),
    ]);
}
