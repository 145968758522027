import * as R from 'ramda';

const groupPictures = (pictures) => {
    return R.groupBy((picture) => {
        const entity =
            picture.entityId || picture.entityName || picture.entityType;
        return `${entity}_${picture.travelDate}`;
    }, pictures);
};

export default groupPictures;
