import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { css } from 'glamor';

import colors from '../../styles/colors';
import { pxToRem } from '../../styles/unitConverter';

const { loadingAnimationCircleColor } = colors;

const loadingAnimation = css.keyframes({
    '0%, 10%': {
        opacity: 0,
        transform: `translate3d(${pxToRem(-14)}, 0, 0)`,
    },

    '20%': {
        opacity: 1,
        transform: 'translate3d(0, 0, 0)',
    },

    '80%': {
        opacity: 1,
        transform: `translate3d(${pxToRem(21)}, 0, 0)`,
    },

    '90%, 100%': {
        opacity: 0,
        transform: `translate3d(${pxToRem(28)}, 0, 0)`,
    },
});

const LoadingCircle = glamorous.span({
    animation: `${loadingAnimation} 1.4s infinite linear`,
    background: loadingAnimationCircleColor,
    borderRadius: '50%',
    display: 'block',
    height: pxToRem(8),
    opacity: 0,
    width: pxToRem(8),

    '&:nth-child(1)': {
        animationDelay: '0.45s',
    },

    '&:nth-child(2)': {
        animationDelay: '0.3s',
    },

    '&:nth-child(3)': {
        animationDelay: '0.15s',
    },

    '&:nth-child(4)': {
        animationDelay: 0,
    },
});

const LoadingCircles = glamorous.div({
    alignItems: 'center',
    display: 'flex',
    height: pxToRem(21),
    justifyContent: 'center',
    width: '100%',
});

export default function LoadingAnimation() {
    return h(LoadingCircles, [
        h(LoadingCircle),
        h(LoadingCircle),
        h(LoadingCircle),
        h(LoadingCircle),
    ]);
}
