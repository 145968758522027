import { stripIndent } from 'common-tags';

export default stripIndent`
    id
    title
    recommendation
    traveledWith
    travelDuration
    travelReason
    travelDate
    hotel {
        id
        name
        parents { name, destinationType }
    }
    ratings {
        GENERAL {
            GENERAL
        }
        HOTEL {
            GENERAL
            CLEANNESS
            CONDITION
            FAMILY
            HANDICAPPED
        }
        LOCATION {
            GENERAL
            ACTIVITIES
            SHOPPING
            BEACH
            FOOD
            TRAFFIC
            SKIING
        }
        FOOD {
            GENERAL
            CLEANNESS
            QUALITY
            ATMOSPHERE
            VARIETY
        }
        SPORT {
            GENERAL
            OFFER
            BEACH
            POOL
            CHILDREN
        }
        ROOM {
            GENERAL
            CLEANNESS
            SIZE
            BATH
            INTERIOR
        }
        SERVICE {
            GENERAL
            CLAIM
            SKILLS
            CHECKIN
            SERVICE
        }
    }
    texts {
        GENERAL
        HOTEL
        FOOD
        SPORT
        LOCATION
        SERVICE
        ROOM
    }
    additional {
        tourOperator
        tourOperatorName
        costPerformance
        catalogCorrect
        starsCorrect
        roomType
        roomView
        roomCategory
    }
    children
    user {
        id
        firstName
        ageGroup
        destinationId
    }
    ownerComment {
        text
        entryDate
    }
    status
    pictures {
        id
    }
    contributions {
        TITLE {
            id
        }
    }
`;
