import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import { pxToRem } from '../../../../styles/unitConverter';
import { smallDistance, xsmallDistance } from '../../../../styles/distances';
import { gray40 } from '../../../../styles/waveColors';
import '../ToggleIcon';

const BaggageInformationContainer = glamorous.div({
    margin: `${smallDistance} ${pxToRem(26)} 0`,
});
BaggageInformationContainer.displayName = 'BaggageInformationContainer';

const BaggageInfoContainer = glamorous.div({
    '>div:last-child': {
        marginTop: pxToRem(16),
    },
});

const BaggageInfo = glamorous.div({
    '>div:not(:last-child)': {
        marginBottom: pxToRem(10),
    },
});
BaggageInfo.displayName = 'BaggageInfo';

const Headline = glamorous.div({
    fontWeight: 'bold',
    marginBottom: pxToRem(9),
});
Headline.displayName = 'Headline';

const BaggageInfoItem = glamorous.div({
    '>img': {
        marginRight: xsmallDistance,
        verticalAlign: 'text-top',
    },
});
BaggageInfoItem.displayName = 'BaggageInfoItem';

const AdditionalBaggage = glamorous.div({
    marginTop: pxToRem(9),
});
AdditionalBaggage.displayName = 'AdditionalBaggage';

const NoBaggageInformation = glamorous.div({
    color: gray40,
    fontWeight: 500,
    textAlign: 'center',
    margin: `${xsmallDistance} 0 ${pxToRem(9)}`,
});
NoBaggageInformation.displayName = 'NoBaggageInformation';

const mapBaggageInfo = (baggageInfo) => {
    const accumulator = [];
    const keyToTextMap = {
        adults: 'Erwachsene',
        children: 'Kinder ab 2',
        infants: 'Kinder unter 2',
    };

    for (const item in baggageInfo) {
        if (keyToTextMap[item] && baggageInfo[item]) {
            accumulator.push(`${keyToTextMap[item]}: ${baggageInfo[item]}`);
        }
    }

    return accumulator;
};

const extractBaggageInfo = (baggageAllowance) => {
    if (!baggageAllowance) {
        return {};
    }

    return {
        freeBaggages: mapBaggageInfo(baggageAllowance.freeBaggage),
        handBaggages: mapBaggageInfo(baggageAllowance.handBaggage),
    };
};

const renderBaggageInfoItem = (item, index, { icon, assetsPath }) =>
    h(BaggageInfoItem, { key: index }, [
        h('img', { src: `${assetsPath}/myBookings/${icon}.svg`, alt: '' }),
        h('span', item),
    ]);

const renderStandardBaggageInfo = (items, text, { assetsPath, icon }) =>
    h(BaggageInfo, [
        h(Headline, `${text} (p.P.)`),
        items.map((item, index) =>
            renderBaggageInfoItem(item, index, { icon, assetsPath }),
        ),
    ]);

const BaggageInformation = (
    { baggageAllowance },
    { config: { assetsPath } },
) => {
    const { freeBaggages, handBaggages } = extractBaggageInfo(baggageAllowance);

    return h(BaggageInformationContainer, [
        h(BaggageInfoContainer, [
            renderStandardBaggageInfo(freeBaggages, 'Aufgabegepäck', {
                assetsPath,
                icon: 'free-baggage',
            }),
            baggageAllowance.additionalBaggage &&
                h(AdditionalBaggage, baggageAllowance.additionalBaggage),
            renderStandardBaggageInfo(handBaggages, 'Handgepäck', {
                assetsPath,
                icon: 'hand-baggage',
            }),
        ]),
    ]);
};

const BaggageInfoType = PropTypes.shape({
    adults: PropTypes.string.isRequired,
    children: PropTypes.string,
    infants: PropTypes.string,
});

BaggageInformation.propTypes = {
    baggageInformation: PropTypes.shape({
        freeBaggage: BaggageInfoType.isRequired,
        handBaggage: BaggageInfoType.isRequired,
        additionalBaggage: PropTypes.string,
    }),
};

BaggageInformation.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

export default BaggageInformation;
