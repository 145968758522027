import h from 'react-hyperscript';

import Page from './Page';
import MietwagenSingleBooking from './myMietwagen/bookings/MietwagenSingleBooking';

function MyMietwagenBooking() {
    return h(
        Page,
        {
            pagename: '/mhc/mietwagen/single',
            title: 'meinHolidayCheck - die ReiseCommunity',
        },
        [h(MietwagenSingleBooking)],
    );
}

export default MyMietwagenBooking;
