import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import options from '@designsystem/options';

import { tinyFont } from '../../styles/fonts';
import { smallDistance, mediumDistance } from '../../styles/distances';
import colors from '../../styles/colors';
import fetchHappyTravelers from '../../fetchers/fetchHappyTravelers';
import HappyTraveler from './HappyTraveler';

const { reviewFooterBorderColor } = colors;

const Container = glamorous.div(tinyFont, {
    clear: 'both',
    borderTop: `solid 1px ${reviewFooterBorderColor}`,
    padding: `${smallDistance} 0`,
    margin: `0 ${mediumDistance}`,
    textAlign: 'left',
});
Container.displayName = 'HappyTravelersContainer';

const List = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    gap: `${options.space.xs}px`,
    marginTop: `${options.space.xs}px`,
});
List.displayName = 'HappyTravelersList';

const HappyTravelers = ({ hotelReviewId }, context) => {
    const [travelers, setTravelers] = useState([]);

    useEffect(() => {
        const loadTravelers = async () => {
            const foundTravelers = await fetchHappyTravelers(
                context,
                hotelReviewId,
            );
            setTravelers(foundTravelers.slice(0, 3));
            context.trackEvent({
                event: 'event',
                eventCategory: 'happyTravelers',
                eventAction: 'loaded',
                eventLabel: foundTravelers.length,
            });
        };
        loadTravelers();
    }, [hotelReviewId]);

    if (travelers.length < 1) {
        return null;
    }

    return h(Container, [
        'Diese Urlauber haben deine Bewertung gelesen und sie hat ihnen geholfen, das richtige Hotel zu finden:',
        h(
            List,
            travelers.map((traveler) =>
                h(HappyTraveler, { traveler, key: traveler.hotelReviewId }),
            ),
        ),
    ]);
};

HappyTravelers.propTypes = {
    hotelReviewId: PropTypes.string.isRequired,
};

HappyTravelers.contextTypes = {
    trackEvent: PropTypes.func.isRequired,
    fetch: PropTypes.func.isRequired,
    config: PropTypes.object.isRequired,
};

export default HappyTravelers;
