import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function WaitingTime(options = {}) {
    return h(Icon, options, [
        h('path', {
            d: oneLine`
        M14.91 4.794h-1.647a.532 .532 0 01-.536 -.544V3.183a.545 .545 0 01.536
        -.543h5.474c.296 0 .536 .23 .536 .543V4.25c0 .3 -.249 .544 -.536
        .544h-1.646V6.02C23.207 6.557 28 11.554 28 17.64c0 6.443 -5.373 11.667 -12
        11.667S4 24.083 4 17.64C4 11.554 8.793 6.557 14.91 6.02V4.795zM16 26.974c5.302 0
        9.6 -4.18 9.6 -9.334S21.302 8.307 16 8.307 6.4 12.485 6.4 17.64s4.298 9.333 9.6
        9.333zm7.2 -9.334c0 3.866 -3.224 7 -7.2 7s-7.2 -3.134 -7.2 -7 3.224 -7 7.2
        -7v7h7.2z`,
        }),
    ]);
}
