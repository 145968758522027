import * as R from 'ramda';

import groupPictures from './groupPictures';
import remapPictures from './remapPictures';
import truncateObjectToNumberOfValues from './truncateObjectToNumberOfValues';

const removeDuplicateIds = (pictures, unpublishedPictures) => {
    const unpublishedPicturesIds = R.pluck('id', unpublishedPictures);
    return R.filter(
        (picture) => !R.contains(picture.id, unpublishedPicturesIds),
        pictures,
    );
};

const sortAlbums = (albums) => {
    const sortedAlbumsKeys = R.sort((keyA, keyB) => {
        return albums[keyB][0].travelDate - albums[keyA][0].travelDate;
    }, R.keys(albums));
    return sortedAlbumsKeys.map((key) => albums[key]);
};

const mergePictures = (groupedUnpublishedPictures, publishedPictures) => {
    const albums = R.clone(publishedPictures);
    R.forEachObjIndexed((value, key) => {
        albums[key] = R.concat(
            groupedUnpublishedPictures[key],
            publishedPictures[key] || [],
        );
    }, groupedUnpublishedPictures);

    return albums;
};

const findRejectedPictureCount = (
    rejectedPictures,
    entityId,
    travelDateToCompare,
) => {
    const rejectedPictureInformation = rejectedPictures.find(
        ({ parent: { id }, travelDate }) => {
            return id === entityId && travelDate === travelDateToCompare;
        },
    );
    return rejectedPictureInformation
        ? rejectedPictureInformation.countRejected
        : 0;
};

export default (pictures, unpublishedPictures, rejectedPictures, limit) => {
    const filteredPictures = removeDuplicateIds(pictures, unpublishedPictures);
    const publishedPictures = truncateObjectToNumberOfValues(
        groupPictures(remapPictures(filteredPictures)),
        limit,
    );
    const groupedUnpublishedPictures = groupPictures(
        remapPictures(unpublishedPictures),
    );
    const albums = mergePictures(groupedUnpublishedPictures, publishedPictures);

    return sortAlbums(albums).map((albumPictures) => {
        const { entityId, travelDate, entityName, entityType } =
            R.last(albumPictures);
        const rejectedPictureCount = findRejectedPictureCount(
            rejectedPictures,
            entityId,
            travelDate,
        );
        return {
            pictures: albumPictures,
            entityId,
            travelDate,
            entityName,
            entityType,
            pictureCount: albumPictures.length + rejectedPictureCount,
            rejectedPictureCount,
        };
    });
};
