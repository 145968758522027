import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import { pxToRem } from '../../../styles/unitConverter';
import {
    blue,
    gray20,
    gray90,
    blue20,
    white,
} from '../../../styles/waveColors';
import {
    secondaryFont,
    FONTS,
    superLoudFont,
    defaultFont,
} from '../../../styles/fonts';
import { smallDistance } from '../../../styles/distances';
import { applyOnMobile, applyOnTablet } from '../../../styles/mediaQueries';
import getOnlineCheckinTexts from '../lib/getOnlineCheckinTexts';
import useBookingContext from '../lib/useBookingContext';
import Clipboard from '../../common/Clipboard';

const { UITextMLoud, UITextM } = secondaryFont;

const CheckinInfoWrapper = glamorous.div({
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    gap: pxToRem(8),

    padding: pxToRem(16),
});
CheckinInfoWrapper.displayName = 'CheckinInfoWrapper';

const CheckinInfo = glamorous.div({
    display: 'flex',
    flexFlow: 'column nowrap',
});
CheckinInfo.displayName = 'CheckinInfo';

const DescriptionContainer = glamorous.div({
    display: 'flex',
    gap: pxToRem(8),

    [applyOnMobile]: {
        flexDirection: 'column',
    },

    [applyOnTablet]: {
        flexDirection: 'column',
    },
});

const AirlineTitle = glamorous.h4(
    {
        ...UITextMLoud,
        margin: 0,
    },
    'title-cls',
);
AirlineTitle.displayName = 'AirlineTitle';

const CheckinDescription = glamorous.div({
    ...UITextM,
    fontFamily: FONTS.primary,
    color: gray20,
    marginTop: pxToRem(4),
});
CheckinDescription.displayName = 'CheckinDescription';

const LinkIcon = glamorous.img({
    width: pxToRem(16),
    height: pxToRem(16),
    marginLeft: pxToRem(6),
    verticalAlign: 'text-top',
    color: blue,

    ':hover': {
        fill: blue20,
    },
});
LinkIcon.displayName = 'LinkIcon';

const Icon = glamorous.img({
    marginRight: pxToRem(8),
    width: pxToRem(50),
    height: pxToRem(50),
});
Icon.displayName = 'Icon';

const CopyToClipboardIcon = glamorous.img({
    width: pxToRem(16),
    height: pxToRem(14),
});
CopyToClipboardIcon.displayName = 'CopyToClipboardIcon';

const CheckmarkIcon = glamorous.img({
    width: pxToRem(14),
    height: pxToRem(10),
});
CheckmarkIcon.displayName = 'CheckmarkIcon';

const CheckinLinkWrapper = glamorous.a({
    width: '100%',
    borderTop: `1px solid ${gray90}`,
    paddingTop: smallDistance,
    paddingBottom: smallDistance,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
});
CheckinLinkWrapper.displayName = 'CheckinLinkWrapper';

const CheckinBoxWrapper = glamorous.div({
    marginTop: pxToRem(16),
    marginBottom: pxToRem(16),
    border: `1px solid ${gray90}`,
    borderRadius: '4px',
});
CheckinBoxWrapper.displayName = 'CheckinBoxWrapper';

const CheckinLink = glamorous.div({
    ...superLoudFont,
    fontFamily: FONTS.primary,
    letterSpacing: pxToRem(0.4),
    textTransform: 'uppercase',
    textAlign: 'center',
    color: blue,
    cursor: 'pointer',

    ':hover': {
        color: blue20,
    },
});
CheckinLink.displayName = 'CheckinLink';

const CopyToClipboardButton = glamorous.button({
    ...defaultFont,
    fontFamily: FONTS.primary,
    lineHeight: 1.4,

    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: pxToRem(8),
    padding: `${pxToRem(4)} ${pxToRem(8)} ${pxToRem(4)} ${pxToRem(8)}`,

    border: `${pxToRem(1)} solid ${blue}`,
    borderRadius: pxToRem(4),
    background: white,
});
CopyToClipboardButton.displayName = 'CopyToClipboardButton';

const ButtonContainer = glamorous.div({
    [applyOnTablet]: {
        display: 'inline-block',
    },
});

const FlightFileKey = glamorous.span({
    whiteSpace: 'nowrap',
});
FlightFileKey.displayName = 'FlightFileKey';

const CheckinLinkBox = (
    { link, airlineName, flightFileKey, tourOperator },
    { config: { assetsPath } },
) => {
    const { trackEventWithBooking } = useBookingContext();
    const { ctaTitle, ctaDescription } = getOnlineCheckinTexts();

    const trackClipboardClick = trackEventWithBooking.bind(null, {
        event: 'event',
        eventCategory: 'mybooking',
        eventAction: 'copy',
        eventLabel: 'flightFilekey',
        ofOrganizerSelected: tourOperator.name || tourOperator.id,
    });

    return h(CheckinBoxWrapper, [
        h(CheckinInfoWrapper, [
            h(Icon, {
                src: `${assetsPath}/myBookings/boarding-documents.svg`,
                alt: 'c',
            }),
            h(CheckinInfo, [
                h(AirlineTitle, `${airlineName} Online-Check-in`),
                h(DescriptionContainer, [
                    h(CheckinDescription, ctaDescription),
                    flightFileKey
                        ? h(ButtonContainer, [
                              h(Clipboard, {
                                  value: flightFileKey,
                                  trackClipboardClick,
                              }),
                          ])
                        : null,
                ]),
            ]),
        ]),
        h(CheckinLinkWrapper, { target: '_blank', href: link }, [
            h(CheckinLink, [
                h('span', ctaTitle),
                h(LinkIcon, {
                    src: `${assetsPath}/myBookings/link.svg`,
                    alt: 'Link zu einer externen Webseite',
                }),
            ]),
        ]),
    ]);
};

CheckinLinkBox.propTypes = {
    link: PropTypes.string.isRequired,
    airlineName: PropTypes.string.isRequired,
    tourOperator: PropTypes.object.isRequired,
    flightFileKey: PropTypes.string,
    trackLinkClick: PropTypes.func,
    iconUrl: PropTypes.string,
};

CheckinLinkBox.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

CheckinLinkBox.displayName = 'CheckinLinkBox';

export default CheckinLinkBox;
