import h from 'react-hyperscript';
import { Query } from '@apollo/client/react/components';

import ReviewCard from './ReviewCard';
import { reviewCard } from '../../../../queries/myHolidaycheckDashboard';

export default function ReviewCardQuery() {
    return h(
        Query,
        { fetchPolicy: 'cache-and-network', query: reviewCard },
        ({ loading, data }) => {
            if (loading || !data) {
                return null;
            }

            const publishedItems = data.hotelReviewsForUser.items || [];
            const unpublishedItems = data.unpublishedReviewsForUser.items || [];
            const combinedReviews = [...publishedItems, ...unpublishedItems];
            const sortedReviews = combinedReviews.sort(
                (a, b) => b.travelDate - a.travelDate,
            );
            const latestReview = sortedReviews[0];
            const reviewsCount = combinedReviews.length;

            return h(ReviewCard, { latestReview, reviewsCount });
        },
    );
}
