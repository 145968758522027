import { stripIndent } from 'common-tags';

import graphqlFetch from '../lib/graphqlFetch';
import hotelReviewData from './hotelReviewData';

export default async function fetchHotelReviews(context, offset, limit) {
    const query = stripIndent`
        query MyHotelReviews($limit: Int, $offset: Int) {
            hotelReviewsForUser(limit:$limit, offset:$offset) {
                items {
                    ${hotelReviewData}
                    viewCount
                    helpfulCount
                }
                total
            }
        }
    `;
    const variables = { limit, offset };

    const response = await graphqlFetch(context, { query, variables });

    if (response.ok) {
        const {
            data: { hotelReviewsForUser },
        } = await response.json();
        return hotelReviewsForUser;
    }

    return null;
}
