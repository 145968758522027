import {
    gray90,
    blue,
    black,
    white,
    gray,
    gray100,
    gray20,
    blue20,
} from './waveColors';

/* eslint-disable no-unused-vars */
// styleguide-colors
const hcBlue = '#0058a3';
const hcDarkblue = '#043967';

const hcLightyellow = '#fae896';
const hcYellow = '#fad73c';
const hcDarkyellow = '#d9bd43';
const hcDeepyellow = '#665925';

const hcBrightcyan = '#f7fbfd';
const hcLightcyan = '#b6dbf2';
const hcCyan = '#51a9e0';
const hcDarkcyan = '#3e81ab';
const hcDeepcyan = '#254d66';

const hcLightgreen = '#c1f2b6';
const hcGreen = '#54b13f';
const hcDarkgreen = '#4d8c3f';
const hcDeepgreen = '#315928';

const hcLightred = '#f7aea6';
const hcRed = '#e8402d';
const hcDarkred = '#b13228';
const hcDeepred = '#75211b';

const hcPremium = '#7334A7';

const hcGray1 = '#fcfcfc';
const hcGray6 = '#fbfbfb';
const hcGray10 = '#f5f6f7';
const hcGray20 = '#edf0f2';
const hcGray30 = '#dfe2e6';
const hcGray40 = '#c2c7cc';
const hcGray50 = '#8a9299';
const hcGray60 = '#525c66';

const hcWhite = '#fff';
const hcBlack = '#333';
/* eslint-enable no-unused-vars */

// proxy-colors (preferred if available)
const albumItemPlaceholderColor = hcGray20;
const primaryBrandColor = hcBlue;
const primaryBrandColorDark = hcDeepcyan;
const secondaryBrandColor = hcYellow;
const linkColor = blue;
const linkHoverColor = blue20;
const headerLinkColor = hcWhite;
const textColor = hcBlack;
const defaultBackgroundColor = hcGray10;
const lightBackgroundColor = hcGray6;
const footerBackgroundColor = hcGray20;
const defaultBorderColor = hcGray30;
const lightBorderColor = hcGray20;
const alternateBorderColor = hcDarkblue;
const separatorColor = '#d8d8d8';
const shadowColor = 'rgba(0, 0, 0, 0.09)';
const uploadCTABackgroundColor = hcGray20;
const uploadCTABorderColor = hcGray40;
const uploadCTAIllustrationColor = hcGray40;
const buttonShadow = hcDarkyellow;
const buttonHoverBackgroundColor = hcCyan;
const buttonHoverShadowColor = hcDarkcyan;
const buttonHoverTextColor = hcWhite;
const favoritesCountFont = hcWhite;
const favoritesCountBackground = hcRed;
const loadingAnimationCircleColor = hcWhite;
const hcGreenWithOpacity = 'rgba(84, 177, 63, 0.17)';
const ownerCommentBackgroundColor = '#daedf8';

export default {
    albumItemPlaceholderColor,
    awardBadgeTextColor: hcWhite,
    primaryBrandColor,
    primaryBrandColorDark,
    secondaryBrandColor,
    linkColor,
    linkHoverColor,
    headerLinkColor,
    textColor,
    defaultBackgroundColor,
    lightBackgroundColor,
    footerBackgroundColor,
    defaultBorderColor,
    lightBorderColor,
    alternateBorderColor,
    separatorColor,
    shadowColor,
    uploadCTABackgroundColor,
    uploadCTABorderColor,
    uploadCTAIllustrationColor,
    buttonActiveBackground: hcDarkcyan,
    buttonShadow,
    buttonHoverBackgroundColor,
    buttonHoverShadowColor,
    buttonHoverTextColor,
    checkboxBackgroundColor: hcWhite,
    checkboxBorderColor: hcGray40,
    countriesListBackgroundColor: hcWhite,
    dialogBackdropColor: 'rgba(0, 0, 0, 0.3)',
    dialogBackgroundColor: hcWhite,
    dialogHeaderBorderColor: gray90,
    emailChangeIconFill: hcWhite,
    emailChangeBackgroundColor: hcWhite,
    errorColor: hcRed,
    favoritesCountFont,
    favoritesCountBackground,
    loadingAnimationCircleColor,
    badgesActionButtonBackgroundColor: hcGray20,
    backToTopButtonBackgroundColor: hcGray20,
    backButtonBackgroundColor: hcGray20,
    backButtonBorderColor: hcGray30,
    backToTopButtonHoverBackgroundColor: hcGray30,
    navBackgroundColor: hcWhite,
    navBarOverflowShadow: 'rgba(255, 255, 255, 0)',
    navLinkTextDecorationColor: hcYellow,
    negativeRating: hcDarkred,
    flashMessageErrorBackgroundColor: hcLightred,
    flashMessageSuccessBackgroundColor: hcLightgreen,
    inputBackgroundColor: white,
    inputBorderColor: gray,
    inputHoverBorderColor: blue,
    lightboxMetadataColor: hcWhite,
    lightboxNavigationArrowColor: hcWhite,
    lightboxNavigationArrowColorInverted: hcGray60,
    lightboxNavigationArrowHoverColor: hcCyan,
    lightboxNavigationBackgroundColor: hcWhite,
    lightboxNavigationButtonOutlineColor: hcGray40,
    lightboxNavigationButtonOutlineHoverColor: hcCyan,
    lightboxOverlayCloseColor: hcWhite,
    lightboxOverlayColor: '#000',
    privateMarkerColor: gray20,
    radioButtonActiveColor: blue,
    radioButtonActiveColorDisabled: gray,
    radioButtonBackgroundColor: white,
    radioButtonGroupBackgroundColor: hcWhite,
    radioButtonGroupBorderColor: hcGray30,
    radioButtonOutlineColor: gray,
    radioButtonQualifierColor: gray,
    radioButtonLabelColor: black,
    radioButtonLabelDisabledColor: gray,
    radioButtonBackgroundDisabled: gray100,
    radioButtonBorderDisabled: gray90,
    reviewApprovedBackgroundColor: hcGreenWithOpacity,
    reviewApprovedColor: hcDarkgreen,
    reviewFooterBorderColor: hcGray20,
    reviewPendingBackgroundColor: hcLightyellow,
    reviewPendingColor: hcDeepyellow,
    reviewRejectedBackgroundColor: hcLightred,
    reviewRejectedColor: hcDeepred,
    secondaryButtonBackgroundColor: hcGray30,
    secondaryButtonShadowColor: hcGray40,
    sliderThumbBackgroundColor: hcLightyellow,
    sliderThumbBorderColor: hcYellow,
    sliderTrackColor: hcGray30,
    warningBoxBackgroundColor: hcWhite,
    warningColor: hcRed,
    pictureCountBorderColor: hcGray40,
    profilePictureBorderColor: hcWhite,
    profilePictureBoxShadowColor: hcGray40,
    profilePictureMotivationClaimColor: hcGray60,
    avatarEditorBackgroundColor: hcWhite,
    avatarEditorBorderColor: hcWhite,
    avatarEditorUnselectableAreaColor: hcGray50,
    selectedCountryBackgroundColor: hcLightyellow,
    selectedCountryBorderColor: hcDarkyellow,
    brightSunColor: hcYellow,
    darkSunColor: hcGray30,
    thumbsUpColor: hcGreen,
    thumbsDownColor: hcRed,
    pictureUploadTextColor: hcGray50,
    reviewContainerBackgroundColor: hcGray1,
    reviewHeaderBackgroundColor: hcWhite,
    reviewContainerBorderColor: hcGray30,
    ownerCommentBackgroundColor,
    additionalInfoIconColor: hcGray50,
    counterTextColor: hcGray60,
    metaTextColor: hcGray50,
    brightStarBackgroundColor: hcYellow,
    darkStarColor: hcGray50,
    brightStarColor: hcWhite,
    darkStarBackgroundColor: hcGray10,
    userLevelTextColor: hcDarkblue,
    statsBorderColor: hcGray30,
    statsNoLinkColor: hcGray50,
    iconColor: hcDarkblue,
    iconBackgroundColor: hcBrightcyan,
    badgeProgressPillBackgroundColor: hcBlack,
    badgeProgressPillTextColor: hcWhite,
    upcomingTravelCountdownText: hcWhite,
    timeCountdownTileBackground: 'rgba(51, 51, 51, 0.5)',
    yellowButtonBackground: hcYellow,
    noRatingLabelBorder: hcGray40,
    hotelRatingThumbColor: hcWhite,
    premiumColor: hcPremium,
};
