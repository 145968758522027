import h from 'react-hyperscript';
import { Component } from 'react';
import { Navigate } from 'react-router-dom';

import Button from '../navigation/Button';

class CancelButton extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirectToMhcProfile: false,
        };

        this.setRedirectToMhcProfile = this.setRedirectToMhcProfile.bind(this);
    }

    setRedirectToMhcProfile() {
        this.setState({ redirectToMhcProfile: true });
    }

    render() {
        const { redirectToMhcProfile } = this.state;

        return h([
            h(
                Button,
                {
                    isSecondary: true,
                    onClick: this.setRedirectToMhcProfile,
                    type: 'button',
                },
                'Abbrechen',
            ),
            redirectToMhcProfile
                ? h(Navigate, { to: '/mhc/mein-profil' })
                : null,
        ]);
    }
}

export default CancelButton;
