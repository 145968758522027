import glamorous from 'glamorous';
import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import options from '@designsystem/options';

import {
    waveTextS,
    waveTextM,
    waveTextMBold,
    waveTextLBold,
} from '../../../styles/waveText';
import {
    waveSmMediaQuery,
    waveMdMediaQuery,
    waveMdOnlyMediaQuery,
    waveLgMediaQuery,
} from '../../../styles/waveMediaQueries';

const BadgesCardContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    margin: `${options.space.xl}px ${options.space.l}px`,
    [waveMdOnlyMediaQuery]: {
        margin: `${options.space.xl}px ${options.space.m}px`,
    },
});

const buildFilter = (colored) => {
    return {
        filter: colored ? 'saturate(100%)' : 'saturate(0)',
        opacity: colored ? 1 : 0.25,
    };
};

const BadgeLogo = glamorous.img(({ colored }) => ({
    width: `${options.space['5xl']}px`,
    height: `${options.space['5xl']}px`,
    borderRadius: '50%',
    ...buildFilter(colored),
}));

const Headline = glamorous.span({
    ...waveTextLBold,
    marginBottom: `${options.space.m}px`,
});

const BadgeContainer = glamorous.span({
    display: 'flex',
    flexDirection: 'row',
    columnGap: `${options.space.s}px`,
});

const BadgeDetailsContainer = glamorous.span({
    display: 'flex',
    flexDirection: 'column',
});

const BadgeTitle = glamorous.span({
    ...waveTextMBold,
});

const BadgeDescription = glamorous.span({
    ...waveTextS,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '@supports (-webkit-line-clamp: 2)': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'initial',
        display: '-webkit-box',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
    },
});

const BUTTON_STYLES = {
    ...waveTextM,
    color: options.colors.link,
    borderColor: options.colors.link,
    textDecoration: 'none',
    marginTop: `${options.space.l}px`,
    height: `${options.space['3xl']}px`,
    width: 239,
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: options.radii.full,
    cursor: 'pointer',
    display: 'inline-flex',
    flexGrow: 0,
    flexShrink: 0,
    fontWeight: 'bold',
    justifyContent: 'center',
    textTransform: 'uppercase',
    transition: 'all 0.3s ease',
    background: 'transparent',
    '&:not(:disabled):hover': {
        background: options.colors.white,
        borderColor: options.colors.blue20,
        color: options.colors.blue20,
    },
    '&:focus': {
        color: options.colors.blue,
        outlineOffset: 'unset',
    },
    [waveSmMediaQuery]: {
        width: '184px',
    },
    [waveMdMediaQuery]: {
        width: '188px',
    },
    [waveLgMediaQuery]: {
        width: '239px',
    },
};

const Button = glamorous.a(BUTTON_STYLES);

const ButtonTextLong = glamorous.span({
    display: 'block',
    [waveSmMediaQuery]: {
        display: 'none',
    },
    [waveLgMediaQuery]: {
        display: 'block',
    },
});

const ButtonTextShort = glamorous.span({
    display: 'none',
    [waveSmMediaQuery]: {
        display: 'block',
    },
    [waveLgMediaQuery]: {
        display: 'none',
    },
});

const BADGE_WITH_TEXT_STYLES = {
    flexWrap: 'nowrap',
};

const BADGE_ONLY_STYLES = {
    flexWrap: 'wrap',
    height: `${options.space['6xl']}px`,
    overflow: 'hidden',
};

const DISABLED_BADGE = {
    id: 'FIRST_HOTELREVIEW',
    name: 'EinChecker',
    description: 'Veröffentliche Deine erste Hotelbewertung',
};

const renderDisabledBadge = (badge, config) => [
    h(BadgeLogo, {
        alt: badge.name,
        colored: false,
        src: `${config.mediaBaseUrl}badges/${badge.id}`,
    }),
    h(BadgeDetailsContainer, [
        h(BadgeTitle, badge.name),
        h(
            BadgeDescription,
            { style: { color: options.colors.secondary } },
            badge.description,
        ),
    ]),
];

const renderSingleBadge = (badge, config) => [
    h(BadgeLogo, {
        alt: badge.name,
        colored: true,
        src: `${config.mediaBaseUrl}badges/${badge.id}`,
    }),
    h(BadgeDetailsContainer, [
        h(BadgeTitle, badge.name),
        h(BadgeDescription, badge.description),
    ]),
];

const renderMultipleBadges = (badges, config) =>
    badges.map((badge, index) =>
        h(BadgeLogo, {
            alt: badge.name,
            colored: true,
            key: index,
            style: { padding: `${options.space['2xs']}px` },
            src: `${config.mediaBaseUrl}badges/${badge.id}`,
        }),
    );

const BadgesCard = (props, { config, trackEvent }) => {
    const { badgesForUser } = props;

    const renderBadgeContainer = (badges) => {
        if (badges.length === 0) {
            return h(
                BadgeContainer,
                { style: BADGE_WITH_TEXT_STYLES },
                renderDisabledBadge(DISABLED_BADGE, config),
            );
        }

        if (badges.length === 1) {
            return h(
                BadgeContainer,
                { style: BADGE_WITH_TEXT_STYLES },
                renderSingleBadge(badges[0], config),
            );
        }

        return h(
            BadgeContainer,
            {
                style: BADGE_ONLY_STYLES,
            },
            renderMultipleBadges(badges, config),
        );
    };

    return h(BadgesCardContainer, [
        h(Headline, 'Auszeichnungen & Sterne'),
        renderBadgeContainer(badgesForUser.items),
        h(
            Button,
            {
                href: '/mhc/meine-auszeichnungen',
                onClick: () =>
                    trackEvent({
                        event: 'event',
                        eventCategory: 'mHCHub',
                        eventAction: 'gotobadges',
                        eventLabel: 'Meine Auszeichnungen',
                    }),
            },
            [
                h(ButtonTextLong, 'Meine Auszeichnungen'),
                h(ButtonTextShort, 'Auszeichnungen'),
            ],
        ),
    ]);
};

BadgesCard.propTypes = {
    badgesForUser: PropTypes.shape({
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
            }).isRequired,
        ).isRequired,
    }).isRequired,
};

BadgesCard.contextTypes = {
    config: PropTypes.object,
    trackEvent: PropTypes.func,
};

export default BadgesCard;
