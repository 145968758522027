import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { css } from 'glamor';

import Preloader from '../icons/Preloader';
import { pxToRem } from '../../styles/unitConverter';
import { xxlargeDistance } from '../../styles/distances';
import { applyOnMobile } from '../../styles/mediaQueries';
import colors from '../../styles/colors';

const { primaryBrandColorDark } = colors;

const loadingAnimation = css.keyframes({
    '0%': {
        transform: 'rotate(0deg)',
        transformOrigin: 'center',
    },
    '100%': {
        transform: 'rotate(360deg)',
        transformOrigin: 'center',
    },
});

const Screen = glamorous.section({
    padding: `${xxlargeDistance} 0`,
    textAlign: 'center',
    fontWeight: 600,
    minHeight: 'calc(100vh - 24rem)',

    [applyOnMobile]: {
        minHeight: 'calc(100vh - 20rem)',
    },
});

const preloaderCss = {
    width: pxToRem(40),
    height: pxToRem(40),
    color: primaryBrandColorDark,
    animation: `${loadingAnimation} 1s infinite linear`,
};

export default function TransitionScreen() {
    return h(Screen, [
        h(Preloader, { css: preloaderCss }),
        h('p', 'Wird geladen...'),
    ]);
}
