import glamorous from 'glamorous';
import h from 'react-hyperscript';

import { tinyFont } from '../../styles/fonts';
import Eye from '../icons/Eye';
import WaitingTime from '../icons/WaitingTime';
import formatNumber from '../../lib/formatNumber';
import { applyOnTabletAndUp } from '../../styles/mediaQueries';
import { pxToRem } from '../../styles/unitConverter';

const iconSizeMobile = 16;
const iconSizeTabletAndUp = 19;
const defaultLabelStyle = {
    ...tinyFont,
    color: 'white',
    lineHeight: pxToRem(iconSizeMobile),

    [applyOnTabletAndUp]: {
        lineHeight: pxToRem(iconSizeTabletAndUp),
    },
};
const iconProps = {
    css: {
        display: 'inline-block',
        height: pxToRem(iconSizeMobile),
        marginRight: '5px',
        verticalAlign: 'bottom',
        width: pxToRem(iconSizeMobile),

        [applyOnTabletAndUp]: {
            height: pxToRem(iconSizeTabletAndUp),
            width: pxToRem(iconSizeTabletAndUp),
        },
    },
};

const LabelWithIcon = glamorous.span(defaultLabelStyle);

const getLabel = (status) => {
    const states = {
        CHECK: 'wird geprüft',
        CONSULTATION: 'Bitte E-Mail beantworten',
        WAITING: 'Bitte E-Mail bestätigen',
    };

    return states[status];
};

function AlbumItemViewsLabel({ viewCount, status }) {
    if (status) {
        return h(LabelWithIcon, [h(WaitingTime, iconProps), getLabel(status)]);
    }

    if (viewCount === undefined || viewCount === null) {
        return null;
    }

    return h(LabelWithIcon, { css: { fontWeight: 600 } }, [
        h(Eye, iconProps),
        formatNumber(viewCount),
    ]);
}

export default AlbumItemViewsLabel;
