import { pxToRem } from './unitConverter';

const FONTS = {
    legacy: 'Open Sans',
    primary: 'Montserrat',
    secondary: 'PT Sans',
};
const defaultFont = { fontSize: '1rem', lineHeight: '1.5rem', fontWeight: 400 };
const extraTinyFont = { fontSize: pxToRem(11), lineHeight: '1rem' };
const tinyFont = {
    fontSize: pxToRem(12),
    lineHeight: pxToRem(16),
    fontWeight: 400,
};
const tinyLoudFont = { ...tinyFont, fontWeight: 600 };
const loudFont = { ...defaultFont, fontWeight: 600 };
const superLoudFont = { ...defaultFont, fontWeight: 700 };
const bigFont = { fontSize: '1.14rem', lineHeight: '1.5rem', fontWeight: 300 };
const bigLoudFont = { ...bigFont, fontWeight: 600 };

const subheadlineFont = {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    fontWeight: 300,
};
const headlineFont = {
    fontSize: '2rem',
    lineHeight: '2.5rem',
    fontWeight: 300,
};
const bigHeadlineFont = {
    fontSize: '2.5rem',
    lineHeight: '3.5rem',
    fontWeight: 300,
};
const waveFontM = {
    fontSize: pxToRem(14),
    lineHeight: pxToRem(21),
    fontWeight: 400,
};

const secondaryFont = {
    headline2XS: {
        fontSize: pxToRem(20),
        lineHeight: 1.22,
        fontWeight: 800,
    },
    headlineXS: {
        fontSize: pxToRem(24),
        lineHeight: 1.22,
        fontWeight: 800,
    },
    headlineS: {
        fontSize: pxToRem(32),
        lineHeight: 1.22,
        fontWeight: 800,
    },
    headlineM: {
        fontSize: pxToRem(40),
        lineHeight: 1.22,
        fontWeight: 800,
    },
    headlineL: {
        fontSize: pxToRem(48),
        lineHeight: 1.15,
        fontWeight: 800,
    },
    headlineXL: {
        fontSize: pxToRem(56),
        lineHeight: 1.15,
        fontWeight: 800,
    },
    headline2XL: {
        fontSize: pxToRem(64),
        lineHeight: 1.15,
        fontWeight: 800,
    },
    headline3XL: {
        fontSize: pxToRem(72),
        lineHeight: 1.22,
        fontWeight: 800,
    },
    UITextM: {
        fontSize: pxToRem(14),
        lineHeight: 1.5,
        fontWeight: 'normal',
    },
    UITextMLoud: {
        fontSize: pxToRem(14),
        lineHeight: 1.5,
        fontWeight: 'bold',
    },
    UITextL: {
        fontSize: pxToRem(16),
        lineHeight: 1.5,
        fontWeight: 'normal',
    },
    UITextLLoud: {
        fontSize: pxToRem(16),
        lineHeight: 1.5,
        fontWeight: 'bold',
    },
    PTSansFamily: {
        fontFamily: FONTS.secondary,
    },
    UIWaveText: {
        fontSize: pxToRem(16),
        lineHeight: pxToRem(24),
        fontWeight: 400,
        fontFamily: FONTS.primary,
    },
};

export {
    defaultFont,
    extraTinyFont,
    tinyFont,
    tinyLoudFont,
    loudFont,
    superLoudFont,
    bigLoudFont,
    subheadlineFont,
    headlineFont,
    bigFont,
    bigHeadlineFont,
    secondaryFont,
    waveFontM,
    FONTS,
};
