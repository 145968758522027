export default (price, currency) =>
    price.toLocaleString('de-DE', { style: 'currency', currency });

const formatPriceShort = (price, currency, locale) => {
    const minimumFractionDigits = price % 1 === 0 ? 0 : 2;
    return price.toLocaleString(locale || 'de-DE', {
        style: 'currency',
        currency,
        minimumFractionDigits,
    });
};

export { formatPriceShort };
