import { useEffect, useState } from 'react';
import moment from 'moment/moment';

import fetchUpcomingTravel from '../../fetchers/fetchUpcomingTravel';
import fetchRentalcarBookings from '../../fetchers/fetchRentalcarBookings';

const sortAndFilterBookings = (bookings, now) => {
    return bookings
        .filter((booking) => moment(booking.dropoffDatetime).isAfter(now))
        .sort((booking, compareBooking) => {
            const pickupMoment = moment(booking.pickupDatetime);
            const dropoffMoment = moment(booking.dropoffDatetime);
            if (now.isBetween(pickupMoment, dropoffMoment)) {
                return -1;
            }

            return moment(compareBooking.pickupDatetime).diff(pickupMoment);
        });
};

export function useFetchRentalCarBooking(context) {
    const { getCurrentDate } = context;
    const [hcTravel, setHcTravel] = useState(null);
    const [bookings, setBookings] = useState([]);

    useEffect(() => {
        const fetchBookings = async () => {
            const travelResponse = await fetchUpcomingTravel(context);
            setHcTravel(travelResponse);

            const { startDate, endDate } = travelResponse
                ? travelResponse.booking
                : {};

            const [bookingsWithTravel, responseNoTravel] = await Promise.all([
                startDate && endDate
                    ? fetchRentalcarBookings(context, startDate, endDate)
                    : Promise.resolve([]),
                fetchRentalcarBookings(context),
            ]);

            const now = moment(getCurrentDate());
            // prioritize the Booking(s) with a travel date

            const activeAndFutureBookings = [
                ...sortAndFilterBookings(bookingsWithTravel, now),
                ...sortAndFilterBookings(responseNoTravel, now),
            ]
                .filter((booking) =>
                    ['Pending', 'Confirmed'].includes(booking.status),
                )
                .filter((booking, index, allBookings) => {
                    return (
                        index ===
                        allBookings.findIndex(
                            (found) => found.id === booking.id,
                        )
                    );
                });

            setBookings(activeAndFutureBookings);
        };

        fetchBookings();
    }, []);

    return {
        bookings,
        hcTravel,
    };
}
