import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import MietwagenExperienceArticle from './MietwagenExperienceArticle';
import TripplannerBanner from './TripplannerBanner';

const ArticleRowContainer = glamorous.div({
    display: 'flex',
    gap: options.space.l,
    overflowX: 'auto',
});

function MietwagenExperienceArticles({
    context,
    experienceArticles,
    regionName,
}) {
    const assetsPath = context.config.assetsPath;

    const articleElements = experienceArticles.map((article) =>
        h(MietwagenExperienceArticle, {
            key: article.link,
            assetsPath,
            link: article.link,
            imageUrl: article.imageUrl,
            headline: article.headline,
        }),
    );

    const tripPlannerbanner = h(TripplannerBanner, {
        assetsPath,
        destination: regionName,
        fullWidth: false,
        articleAmount: articleElements.length,
    });

    const finalElements = [1, 2].includes(articleElements.length)
        ? [tripPlannerbanner, ...articleElements]
        : articleElements;

    return h('div', { key: 'main-container' }, [
        h(ArticleRowContainer, finalElements),
        h(TripplannerBanner, {
            assetsPath,
            destination: regionName,
            fullWidth: true,
            articleAmount: articleElements.length,
        }),
    ]);
}

export default MietwagenExperienceArticles;
