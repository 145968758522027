import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import { pxToRem } from '../../styles/unitConverter';
import formatDate from '../../lib/formatDate';
import {
    waveMdMediaQuery,
    waveSmMediaQuery,
    waveXsAndSmOnlyMediaQuery,
} from '../../styles/waveMediaQueries';
import { waveTextSMedium } from '../../styles/waveText';

const MembershipInformationContainer = glamorous.div({
    background: options.colors.white,
    borderRadius: pxToRem(4),
    display: 'flex',
    maxWidth: pxToRem(792),
    margin: 'auto',
    justifyContent: 'space-between',
    flexDirection: 'column',
    border: `1px solid ${options.colors.gray90}`,
    [waveSmMediaQuery]: {
        flexDirection: 'row',
    },
    [waveMdMediaQuery]: {
        marginBottom: options.space['2xl'],
    },
    [waveXsAndSmOnlyMediaQuery]: {
        marginBottom: options.space.xl,
    },
});

const MembershipInformationStyle = glamorous.div({
    flex: 1,
    textAlign: 'left',
    padding: options.space.s,
    borderBottom: `1px solid ${options.colors.gray90}`,
    ':last-child': {
        borderBottom: 'none',
    },
    [waveSmMediaQuery]: {
        borderRight: `1px solid ${options.colors.gray90}`,
        borderBottom: 'none',
        ':last-child': {
            borderRight: 'none',
        },
    },
});

const MembershipInformationStyleTitle = glamorous.h4({
    ...waveTextSMedium,
    margin: 0,
    textTransform: 'uppercase',
    letterSpacing: options.letterSpacings.l,
    color: options.colors.purple,
});

const MembershipInformationStyleDescription = glamorous.span({
    fontSize: options.fontSizes.xs,
    margin: 0,
});

const MembershipInformationItem = ({ title, description }) => {
    return h(MembershipInformationStyle, [
        h(MembershipInformationStyleTitle, title),
        h(MembershipInformationStyleDescription, description),
    ]);
};

export default function MembershipInformation({ activeContract }) {
    return h(MembershipInformationContainer, [
        h(MembershipInformationItem, {
            title: 'Meine Mitgliedsnummer',
            description: activeContract.subscriptionContractID,
        }),
        h(MembershipInformationItem, {
            title: 'Ich bin Mitglied seit',
            description:
                `${formatDate(activeContract.contractStartDate, 'DD.MM.YYYY')} · ` +
                `${activeContract.currentSubscriptionYear}. Mitgliedsjahr`,
        }),
        activeContract.hasPendingTermination &&
            h(MembershipInformationItem, {
                title: 'Vertrag wird gekündigt zum',
                description: formatDate(
                    activeContract.contractEndDate,
                    'DD.MM.YYYY',
                ),
            }),
    ]);
}
