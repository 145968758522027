import h from 'react-hyperscript';

import LinkWithIcon from './LinkWithIcon';
import RightArrowLine from '../icons/RightArrowLine';
import colors from '../../styles/colors';
import { smallDistance, xsmallDistance } from '../../styles/distances';
import { bigLoudFont } from '../../styles/fonts';
import { pxToRem } from '../../styles/unitConverter';

const {
    buttonHoverBackgroundColor,
    buttonHoverShadowColor,
    buttonHoverTextColor,
    buttonShadow,
    primaryBrandColor,
    secondaryBrandColor,
} = colors;

export default function ButtonLink({
    children,
    css,
    href,
    icon = RightArrowLine,
    onClick,
}) {
    return h(
        LinkWithIcon,
        {
            href,
            icon,
            iconSize: pxToRem(16),
            iconCss: { margin: '0 0 -2px 0' },
            isRightIcon: true,
            onClick,
            css: {
                ...bigLoudFont,
                lineHeight: '1.5rem',
                padding: `${xsmallDistance} ${smallDistance}`,

                color: primaryBrandColor,
                backgroundColor: secondaryBrandColor,
                borderBottom: `4px solid ${buttonShadow}`,

                '&:hover': {
                    color: buttonHoverTextColor,
                    backgroundColor: buttonHoverBackgroundColor,
                    borderBottom: `4px solid ${buttonHoverShadowColor}`,
                },

                '&:active': {
                    color: buttonHoverTextColor,
                    backgroundColor: buttonHoverShadowColor,
                    borderBottom: `4px solid ${buttonHoverShadowColor}`,
                },
                ...css,
            },
        },
        children,
    );
}
