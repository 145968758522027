import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function Email({ css }) {
    return h(
        Icon,
        {
            viewBox: '0 0 20 20',
            css,
        },
        [
            h('path', {
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                d: oneLine`M0.809577 4.66941C0.315795 4.43974 0 3.94458 0 3.4V1.9C0 0.850659 0.850659 0 1.9 0H18.1C19.1493
             0 20 0.850665 20 1.9V3.4C20 3.94458 19.6842 4.43974 19.1904 4.66941L10.8013 8.57133C10.2932 8.80764
             9.70677 8.80764 9.19871 8.57133L0.809577 4.66941ZM1.4 3.4V1.9C1.4 1.62386 1.62386 1.4 1.9 1.4H18.1C18.3761
              1.4 18.6 1.62386 18.6 1.9V3.4L10.2109 7.30192C10.0772 7.36411 9.92283 7.36411
               9.78913 7.30192L1.4 3.4ZM0.700003 6.1C1.0866 6.1 1.4 6.4134 1.4 6.8V12.3C1.4 12.4657 1.53432 12.6
                1.7 12.6H18.3C18.4657 12.6 18.6 12.4657 18.6 12.3V6.8C18.6 6.4134 18.9134 6.1 19.3 6.1C19.6866
                 6.1 20 6.4134 20 6.8V12.3C20 13.2389 19.2389 14 18.3 14H1.7C0.761121 14 2.98023e-06 13.2389
                  2.98023e-06 12.3V6.8C2.98023e-06 6.4134 0.313404 6.1 0.700003 6.1Z`,
            }),
        ],
    );
}
