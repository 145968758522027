import PropTypes from 'prop-types';
import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { useNavigate } from 'react-router-dom';

import BadgeTypeLayer from './BadgeTypeLayer';
import Badge from './Badge';
import { smallDistance } from '../../styles/distances';
import { tinyLoudFont } from '../../styles/fonts';
import { pxToRem } from '../../styles/unitConverter';
import { componentSurfaceLayer } from '../../styles/layers';
import colors from '../../styles/colors';
import { applyOnTablet, applyOnMobile } from '../../styles/mediaQueries';

const { badgeProgressPillTextColor, badgeProgressPillBackgroundColor } = colors;

const Item = glamorous.div({
    position: 'relative',
    display: 'inline-block',
    width: `calc(33% - 2 * ${smallDistance})`,
    padding: smallDistance,
    [applyOnTablet]: {
        width: `calc(50% - 2 * ${smallDistance})`,
    },
    [applyOnMobile]: {
        width: `calc(100% - 2 * ${smallDistance})`,
    },
});

const buildLayerHash = (type) => `#${type.id}`;

const openLayer = ({ trackEvent, navigate, layerHash, badge }) => {
    trackEvent({
        event: 'event',
        eventCategory: 'MHC',
        eventAction: 'BadgeClick',
        eventLabel: badge.created ? badge.id : `type:${badge.typeId}`,
    });
    navigate(layerHash);
};

const closeLayer = (navigate, event) => {
    if (event) {
        event.stopPropagation();
    }
    navigate('/mhc/meine-auszeichnungen');
};

const ProgressPill = glamorous.span({
    ...tinyLoudFont,
    display: 'inline-block',
    textAlign: 'center',
    color: badgeProgressPillTextColor,
    borderRadius: pxToRem(16),
    backgroundColor: badgeProgressPillBackgroundColor,
    paddingBottom: pxToRem(2),
    paddingTop: pxToRem(2),
    width: pxToRem(32),
    position: 'absolute',
    bottom: pxToRem(8),
    left: pxToRem(34),
    zIndex: componentSurfaceLayer,
});

const Type = ({ badges, type }, { trackEvent, location }) => {
    if (badges.length === 0) {
        return null;
    }
    const navigate = useNavigate();
    const createdBadges = badges.filter((badge) => badge.created);
    const badgeToShow = createdBadges[createdBadges.length - 1] || badges[0];
    const layerHash = buildLayerHash(type);

    return h(
        Item,
        {
            onClick: openLayer.bind(null, {
                navigate,
                trackEvent,
                layerHash,
                badge: badgeToShow,
            }),
        },
        [
            h(ProgressPill, `${createdBadges.length}/${badges.length}`),
            h(Badge, { ...badgeToShow, asLink: true }),
            location.hash === layerHash &&
                h(BadgeTypeLayer, {
                    badges,
                    ...type,
                    onClose: closeLayer.bind(null, navigate),
                }),
        ],
    );
};

Type.propTypes = {
    badges: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            created: PropTypes.number,
        }),
    ).isRequired,
    type: PropTypes.shape({
        id: PropTypes.string.isRequired,
        headline: PropTypes.string,
        description: PropTypes.string,
        actionLabel: PropTypes.string,
        actionLink: PropTypes.string,
    }).isRequired,
};

Type.contextTypes = {
    location: PropTypes.object.isRequired,
    trackEvent: PropTypes.func,
};

export default Type;
