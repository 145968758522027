/* eslint-disable max-statements */
import PropTypes from 'prop-types';
import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import {
    waveTextLBold,
    waveTextMBold,
    waveTextL,
    waveTextS,
    waveTextM,
} from '../../../../styles/waveText';
import {
    waveMdMediaQuery,
    waveMdOnlyMediaQuery,
} from '../../../../styles/waveMediaQueries';
import SunIcon from '../../../icons/Sun';
import Recommendation from './Recommendation';
import Rating from './Rating';

const TitleContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'row',
});

const Title = glamorous.div({
    ...waveTextLBold,
    marginBottom: options.space.m,
});

const ReviewsCount = glamorous.div({
    ...waveTextL,
});

const ReviewCardContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: `${options.space.xl}px ${options.space.l}px`,
    borderRadius: `${options.space.xs}px`,
    background: options.colors.white,
    [waveMdOnlyMediaQuery]: {
        padding: `${options.space.xl}px ${options.space.m}px`,
    },
});

const ReviewContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    [waveMdMediaQuery]: {
        justifyContent: 'space-between',
    },
});

const HotelImageAndText = glamorous.div({
    display: 'flex',
    flexDirection: 'row',
});

const HotelName = glamorous.div({
    ...waveTextMBold,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
});

const HotelLocation = glamorous.div({
    ...waveTextS,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
});

const IconContainer = glamorous.span({
    width: `${options.space.m}px`,
    height: `${options.space.m}px`,
    marginRight: `${options.space['2xs']}px`,
});

const Button = glamorous.a([
    {
        ...waveTextM,
        color: options.colors.link,
        borderColor: options.colors.link,
        textDecoration: 'none',
        marginTop: options.space.l,
        height: options.space['3xl'],
        width: 217,
        alignItems: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: options.radii.full,
        cursor: 'pointer',
        display: 'inline-flex',
        flexGrow: 0,
        flexShrink: 0,
        fontWeight: 'bold',
        justifyContent: 'center',
        textTransform: 'uppercase',
        transition: 'all 0.3s ease',
        background: 'transparent',
        '&:not(:disabled):hover': {
            background: options.colors.white,
            borderColor: options.colors.blue20,
            color: options.colors.blue20,
        },
        '&:focus': {
            color: options.colors.blue,
            outlineOffset: 'unset',
        },
    },
]);

const Image = glamorous.img({
    height: options.space['6xl'],
    width: options.space['6xl'],
    objectFit: 'cover',
    borderRadius: options.radii.small,
    marginRight: `${options.space.xs}px`,
});

const HotelNameAndLocationContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
});

const ReviewRatingContainer = glamorous.div({
    display: 'none',
    [waveMdMediaQuery]: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: `${options.space.m}px`,
    },
});

const ReviewRating = glamorous.div({
    display: 'none',
    [waveMdMediaQuery]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '104px',
        height: '32px',
    },
});

const SunAndRating = glamorous.div({
    display: 'none',
    [waveMdMediaQuery]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '30px',
        borderTop: `1px solid ${options.colors.gray90}`,
        borderRight: `1px solid ${options.colors.gray90}`,
        borderBottom: `1px solid ${options.colors.gray90}`,
        borderTopRightRadius: options.radii.small,
        borderBottomRightRadius: options.radii.small,
    },
});

const getHotelParentByType = (hotel, typeToFind) =>
    hotel.parents.find((parent) => parent.destinationType === typeToFind).name;

export default function ReviewCard(
    { latestReview, reviewsCount },
    { config, trackEvent },
) {
    const { assetsPath, topLevelDomain } = config;
    const hotelReviewLink = `https://www.holidaycheck.${topLevelDomain}/wcf`;

    if (reviewsCount === 0 || !latestReview) {
        return h(ReviewCardContainer, [
            h(TitleContainer, [h(Title, 'Meine Bewertungen')]),
            h(ReviewContainer, { style: { justifyContent: 'flex-start' } }, [
                h(Image, {
                    src: `${assetsPath}/reviews/hotel-image.svg`,
                    alt: 'Hotel bewerten',
                }),
                h(
                    HotelNameAndLocationContainer,
                    { dataTestId: 'empty-review-hotel-details' },
                    [
                        h(HotelName, 'Du hast momentan keine Bewertungen'),
                        h(HotelLocation, 'Bewerte jetzt ein Hotel!'),
                    ],
                ),
            ]),
            h(Button, { href: hotelReviewLink }, 'Hotel bewerten'),
        ]);
    }

    const { ratings, recommendation, hotel } = latestReview;

    const latestReviewRating = ratings.GENERAL.GENERAL;
    const latestReviewRecommendation = recommendation;

    return h(ReviewCardContainer, [
        h(TitleContainer, { dataTestId: 'review-card-title-container' }, [
            h(Title, 'Meine Bewertungen'),
            '\u00A0',
            h(ReviewsCount, `(${reviewsCount})`),
        ]),
        h(ReviewContainer, { style: { justifyContent: 'space-between' } }, [
            h(HotelImageAndText, [
                h(Image, {
                    src: `https://www.holidaycheck.de/main-photo-redirect/${hotel.id}`,
                    alt: hotel.name,
                }),
                h(
                    HotelNameAndLocationContainer,
                    { dataTestId: 'review-hotel-details' },
                    [
                        h(HotelName, hotel.name),
                        h(
                            HotelLocation,
                            `${getHotelParentByType(hotel, 'CITY')}, ${getHotelParentByType(hotel, 'COUNTRY')}`,
                        ),
                    ],
                ),
            ]),
            h(ReviewRatingContainer, [
                h(ReviewRating, { dataTestId: 'review-rating' }, [
                    h(Recommendation, {
                        type: latestReviewRecommendation
                            ? 'positive'
                            : 'negative',
                    }),
                    h(SunAndRating, [
                        h(IconContainer, [
                            h(SunIcon, {
                                css: {
                                    width: '100%',
                                    height: '100%',
                                },
                            }),
                        ]),
                        h(Rating, { value: latestReviewRating }),
                    ]),
                ]),
            ]),
        ]),
        h(
            Button,
            {
                href: '/mhc/meine-bewertungen',
                onClick: () =>
                    trackEvent({
                        event: 'event',
                        eventCategory: 'mHCHub',
                        eventAction: 'gotohotelreviews',
                        eventLabel: 'Meine Bewertungen',
                    }),
            },
            'Meine Bewertungen',
        ),
    ]);
}

ReviewCard.propTypes = {
    latestReview: PropTypes.shape({
        title: PropTypes.string,
        recommendation: PropTypes.bool,
        travelDate: PropTypes.number,
        ratings: PropTypes.shape({
            GENERAL: PropTypes.shape({
                GENERAL: PropTypes.number,
            }),
        }),
        hotel: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            parents: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    destinationType: PropTypes.string,
                }),
            ),
        }),
    }),
};

ReviewCard.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
        topLevelDomain: PropTypes.string.isRequired,
    }).isRequired,
    trackEvent: PropTypes.func,
};
