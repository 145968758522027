import h from 'react-hyperscript';

import Hint from '../Hint';

const CancellationConfirmation = ({ isSubmitted, cancellationResponse }) => {
    if (!isSubmitted) {
        return null;
    }

    switch (cancellationResponse.cancelPremiumMembership) {
        case 'SUCCESS':
            return h(Hint, {
                type: 'success',
                header: 'Deine Premium-Mitgliedschaft wurde erfolgreich gekündigt',
                body: '',
            });
        case 'EMAIL_SENT_TO_OTA':
            return h(Hint, {
                type: 'error',
                header: 'Es gab ein Problem bei der automatischen Kündigung',
                body: 'Du brauchst nichts weiter zu tun. Ein Mitarbeiter wurde informiert und wir melden uns in den nächsten 3 Werktagen bei Dir.',
            });
        default:
            return h(Hint, {
                type: 'error',
                header: 'Es gab ein Problem bei der automatischen Kündigung',
                body: 'Bitte versuche es in einigen Minuten erneut. Sollte das Probleme bestehen bleiben, kontaktiere bitte den Kundenservice.',
            });
    }
};

export default CancellationConfirmation;
