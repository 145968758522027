import glamorous from 'glamorous';
import h from 'react-hyperscript';
import options from '@designsystem/options';

import { pxToRem } from '../../../styles/unitConverter';

const TooltipWrapper = glamorous.div(({ width }) => ({
    display: 'block',
    textAlign: 'center',
    padding: options.space.xs,
    position: 'absolute',
    bottom: pxToRem(options.space['2xl']),
    right: (width / 2) * -1,
    width,

    boxShadow: options.shadows.shallow,
    filter: `drop-shadow(${options.shadows.shallow})`,
    backgroundColor: options.colors.black,
    opacity: 0.8,
    zIndex: options.zIndex.plusTwo,
    color: options.colors.white,

    ':before': {
        content: '""',
        position: 'absolute',
        top: '100%',
        left: '50%',
        marginLeft: `-${pxToRem(options.space.xs)}`,
        borderWidth: `${pxToRem(options.space.xs)}`,
        borderStyle: 'solid',
        opacity: 0.8,
        borderColor: `${options.colors.black} transparent transparent transparent`,
    },
}));

const Tooltip = ({ text, width }) => h(TooltipWrapper, { width }, text);

export default Tooltip;
