import glamorous from 'glamorous';
import h from 'react-hyperscript';
import options from '@designsystem/options';
import PropTypes from 'prop-types';

import { pxToRem } from '../../styles/unitConverter';
import { waveMdMediaQuery } from '../../styles/waveMediaQueries';
import { secondaryFont } from '../../styles/fonts';
import { waveTextMBold } from '../../styles/waveText';

const HintContainer = glamorous.div(({ type }) => {
    let bgColor;
    switch (type) {
        case 'error':
            bgColor = options.colors.red90;
            break;
        case 'warning':
            bgColor = options.colors.yellow90;
            break;
        case 'premium':
            bgColor = options.colors.purple100;
            break;
        case 'success':
        default:
            bgColor = options.colors.green90;
            break;
    }

    return {
        background: bgColor,
        borderRadius: options.radii.small,
        padding: options.space.m,
        display: 'flex',
        alignItems: 'self-start',
        maxWidth: pxToRem(770),
        fontSize: options.fontSizes.xs,
        [waveMdMediaQuery]: {
            fontSize: options.fontSizes.s,
        },
        marginTop: options.space['2xl'],
    };
});
const HintTextContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
});

const HintHeader = glamorous.div({
    ...secondaryFont.PTSansFamily,
    ...waveTextMBold,
    [waveMdMediaQuery]: {
        fontSize: options.fontSizes.s,
    },
});

const HintBody = glamorous.p({
    margin: 0,
    ...secondaryFont.PTSansFamily,
    '> a': {
        color: options.colors.blue,
        textDecoration: 'none',
    },
});

const Icon = glamorous.img({
    marginRight: options.space.xs,
});

export default function Hint(
    { type, header, body },
    { config: { assetsPath } },
) {
    const icon =
        type === 'success'
            ? `${assetsPath}/myPremium/checkmark.svg`
            : `${assetsPath}/myPremium/exclamationMark.svg`;

    return h(HintContainer, { type }, [
        h(Icon, { src: icon }),
        h(HintTextContainer, [h(HintHeader, header), h(HintBody, body)]),
    ]);
}

Hint.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};
