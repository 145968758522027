import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';

import { pxToRem } from '../../../styles/unitConverter';
import {
    defaultFont,
    secondaryFont,
    superLoudFont,
} from '../../../styles/fonts';
import { gray40 } from '../../../styles/waveColors';
import getOnlineCheckinTexts from '../lib/getOnlineCheckinTexts';
import ExpandableText from './ExpandableText';
import CheckinLinkBox from './CheckinLinkBox';
import copyValueToClipboard from '../../../lib/copyValueToClipboard';

const { PTSansFamily } = secondaryFont;

const SubsectionTitle = glamorous.h4({
    ...superLoudFont,
    margin: 0,
});
SubsectionTitle.displayName = 'SubsectionTitle';

const SubsectionDescription = glamorous.p({
    ...defaultFont,
    ...PTSansFamily,
    color: gray40,

    marginBottom: pxToRem(8),
    marginTop: pxToRem(8),
});
SubsectionDescription.displayName = 'SubsectionDescription';

const OnlineCheckin = ({
    airlineName,
    link,
    additionalCheckInDetails,
    flightFileKey,
    tourOperator,
}) => {
    const {
        subsectionTitleText,
        subsectionDescriptionText,
        flightKeySectionTitle,
        flightKeySectionText,
    } = getOnlineCheckinTexts();

    return h('div', [
        h(SubsectionTitle, subsectionTitleText),
        h(SubsectionDescription, subsectionDescriptionText),
        h(CheckinLinkBox, {
            link,
            airlineName,
            copyValueToClipboard,
            flightFileKey,
            tourOperator,
        }),
        h(ExpandableText, {
            title: flightKeySectionTitle,
            text: flightKeySectionText,
            eventLabel: 'flightKeyInfo',
        }),
        additionalCheckInDetails &&
            h(ExpandableText, {
                title: `Online-Check-In bei ${airlineName}`,
                text: additionalCheckInDetails,
                eventLabel: 'specificAirlineCheckInInfo',
            }),
    ]);
};

OnlineCheckin.propTypes = {
    airlineName: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    tourOperator: PropTypes.object.isRequired,
    flightFileKey: PropTypes.string,
    additionalCheckInDetails: PropTypes.string,
};

OnlineCheckin.displayName = 'OnlineCheckin';

export default OnlineCheckin;
