import glamorous from 'glamorous';
import options from '@designsystem/options';

import { waveMdOnlyMediaQuery } from '../../../styles/waveMediaQueries';

const RentalcarCardContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    margin: `${options.space.xl}px ${options.space.l}px`,
    [waveMdOnlyMediaQuery]: {
        margin: `${options.space.xl}px ${options.space.m}px`,
    },
});

export { RentalcarCardContainer };
