import { Component } from 'react';
import PropTypes from 'prop-types';
import h from 'react-hyperscript';
import glamorous from 'glamorous';

import {
    xsmallDistance,
    smallDistance,
    mediumDistance,
} from '../../styles/distances';
import colors from '../../styles/colors';
import { tinyFont, loudFont, headlineFont } from '../../styles/fonts';
import { pxToRem } from '../../styles/unitConverter';
import { applyOnTabletAndUp } from '../../styles/mediaQueries';
import Dialog from '../dialog/Dialog';
import ButtonLink from '../navigation/ButtonLink';
import Badge from './Badge';
import formatDate from '../../lib/formatDate';

const { defaultBorderColor, badgesActionButtonBackgroundColor } = colors;

const badgeContainerWidth = pxToRem(300);
const buildImagePath = (mediaBaseUrl, id) => `${mediaBaseUrl}badges/${id}`;
const buildFilter = (colored) => {
    return {
        filter: colored ? 'saturate(100%)' : 'saturate(0)',
        opacity: colored ? 1 : 0.25,
    };
};

const Image = glamorous.img(({ colored }) => ({
    verticalAlign: 'middle',
    maxWidth: pxToRem(144),
    marginBottom: mediumDistance,
    ...buildFilter(colored),
}));
const CurrentBadgeName = glamorous.div(headlineFont, {
    marginBottom: xsmallDistance,
});
const ReceivedDate = glamorous.div(tinyFont);
const SubHeadline = glamorous.div(loudFont, { textAlign: 'center' });
const Divider = glamorous.hr({
    marginTop: mediumDistance,
    marginBottom: mediumDistance,
    border: 0,
    borderTop: `1px solid ${defaultBorderColor}`,
});
const BadgeContainer = glamorous.div({
    width: badgeContainerWidth,
    margin: `${mediumDistance} auto 0`,
    textAlign: 'center',
});
const Badges = glamorous.div({
    [applyOnTabletAndUp]: {
        textAlign: 'left',
    },
});
const BadgeTypeDescription = glamorous.div({
    marginTop: xsmallDistance,
    marginBottom: smallDistance,

    [applyOnTabletAndUp]: {
        marginBottom: mediumDistance,
    },
});
const Container = glamorous.div({
    width: '100%',
});
const Content = glamorous.div({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
});

const trackActionClick = (context, typeId) => {
    context.trackEvent({
        event: 'event',
        eventCategory: 'MHC',
        eventAction: 'BadgeActionClick',
        eventLabel: typeId,
    });
};

class BadgeTypeLayer extends Component {
    findBadgesCollection() {
        const { badges } = this.props;
        const receivedBadges = badges.filter((badge) => badge.created);

        if (receivedBadges.length > 0) {
            return {
                currentBadge: receivedBadges[receivedBadges.length - 1],
                receivedBadges,
            };
        }
        return {
            currentBadge: badges[0],
            receivedBadges: [],
        };
    }

    renderTypeComponents() {
        const { id, headline, description, actionLabel, actionLink } =
            this.props;

        return [
            headline && h(SubHeadline, headline),
            description && h(BadgeTypeDescription, description),
            actionLabel &&
                h(
                    ButtonLink,
                    {
                        href: actionLink,
                        onClick: trackActionClick.bind(null, this.context, id),
                        css: {
                            width: pxToRem(244),
                            backgroundColor: badgesActionButtonBackgroundColor,
                            borderBottomColor: defaultBorderColor,
                            justifyContent: 'center',
                        },
                    },
                    actionLabel,
                ),
        ].filter(Boolean);
    }

    renderType() {
        const typeComponents = this.renderTypeComponents();
        return (
            typeComponents.length > 0 &&
            h(Container, [h(Divider), ...typeComponents])
        );
    }

    render() {
        const { config } = this.context;
        const { badges } = this.props;
        const { currentBadge, receivedBadges } = this.findBadgesCollection();
        const hasBadges = badges.length > 1;

        return h(
            Dialog,
            {
                onClose: this.props.onClose,
            },
            h(Content, [
                h('div', [
                    h(Image, {
                        src: buildImagePath(
                            config.mediaBaseUrl,
                            currentBadge.id,
                        ),
                        alt: '',
                        colored: Boolean(currentBadge.created),
                    }),
                    h(CurrentBadgeName, currentBadge.name),
                    currentBadge.created &&
                        h(
                            ReceivedDate,
                            `Erhalten am ${formatDate(currentBadge.created, 'DD. MMMM Y')}`,
                        ),
                ]),
                hasBadges &&
                    h(Container, [
                        h(Divider),
                        h(Badges, [
                            h(
                                SubHeadline,
                                `${receivedBadges.length} von ${badges.length} Auszeichnungen in dieser Kategorie`,
                            ),
                            ...badges.map((badge) =>
                                h(
                                    BadgeContainer,
                                    { key: badge.id },
                                    h(Badge, badge),
                                ),
                            ),
                        ]),
                    ]),
                this.renderType(),
            ]),
        );
    }
}

BadgeTypeLayer.propTypes = {
    badges: PropTypes.array.isRequired,
    id: PropTypes.string,
    description: PropTypes.string,
    headline: PropTypes.string,
    actionLabel: PropTypes.string,
    actionLink: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};

BadgeTypeLayer.contextTypes = {
    config: PropTypes.object.isRequired,
    trackEvent: PropTypes.func,
};

export default BadgeTypeLayer;
