import h from 'react-hyperscript';
import { Query } from '@apollo/client/react/components';

import UserProfileCard from './UserProfileCard';
import { userProfileCard } from '../../../queries/myHolidaycheckDashboard';

export default function UserProfileCardQuery() {
    return h(
        Query,
        { fetchPolicy: 'cache-and-network', query: userProfileCard },
        ({ loading, data }) => {
            if (!loading && data) {
                return h(UserProfileCard, data);
            }

            return null;
        },
    );
}
