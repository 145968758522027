import h from 'react-hyperscript';
import PropTypes from 'prop-types';

import getAirportCheckinTexts from '../lib/getAirportCheckinTexts';
import { red } from '../../../styles/waveColors';
import ExpandableText from './ExpandableText';

const getSubsectionTitleText = ({ amount, currencyCode }, airportOnly) => {
    if (airportOnly) {
        return 'Dein Check-In ist nur am Flughafen möglich!';
    }

    return amount === 0
        ? 'Alternativ ist der Check-In auch am Flughafen möglich'
        : `Alternativ ist der Check-In auch am Flughafen für
            <span style="color:${red};">${amount},- ${currencyCode}</span> p. P. möglich`;
};

const AirportCheckin = ({ fee, airportOnly }) => {
    return h(ExpandableText, {
        title: getSubsectionTitleText(fee, airportOnly),
        text: getAirportCheckinTexts(airportOnly),
        eventLabel: 'airportCheckInInfo',
        isInitiallyExpanded: airportOnly,
    });
};

AirportCheckin.propTypes = {
    fee: PropTypes.shape({
        amount: PropTypes.number.isRequired,
        currencyCode: PropTypes.string.isRequired,
    }).isRequired,
    airportOnly: PropTypes.bool.isRequired,
};

export default AirportCheckin;
