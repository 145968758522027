import { useEffect } from 'react';
import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import { waveFontM, waveHeadline2XS } from '../../styles/waveHeadlines';
import {
    waveMdMediaQuery,
    waveMdOnlyMediaQuery,
    waveSmMediaQuery,
} from '../../styles/waveMediaQueries';
import { waveTextM } from '../../styles/waveText';
import { RentalcarCardContainer } from './components/RentalcarCardContainer';

const FoldingHeadline = glamorous.div({
    ...waveHeadline2XS,
    [waveMdOnlyMediaQuery]: {
        maxWidth: '50%',
    },
});

const TeaserCardContentContainer = glamorous.div({
    flexBasis: '100%',
    flex: 1.5,
    display: 'flex',
    flexDirection: 'column',
    gap: options.space.l,
});

const TeaserCardImageContainer = glamorous.div({
    flexBasis: '100%',
    flex: 1,
    display: 'none',
    justifyContent: 'flex-end',
    maxWidth: '160px',
    [waveSmMediaQuery]: {
        display: 'flex',
    },
});

const TeaserCardImageContainerImage = glamorous.img({
    width: '100%',
    height: 'auto',
    objectFit: 'contain',
});

const TeaserCardContent = glamorous.div({
    display: 'flex',
    gap: options.space.l,
});

const TeaserCardContentImage = glamorous.img({
    objectFit: 'contain',
    width: '64px',
    transform: 'rotateY(-180deg)',
    [waveSmMediaQuery]: {
        display: 'none',
    },
});

const TeaserText = glamorous.span({
    ...waveFontM,
    color: options.colors.secondary,
});

const Button = glamorous.a([
    {
        ...waveTextM,
        color: options.colors.link,
        borderColor: options.colors.link,
        textDecoration: 'none',
        height: options.space['3xl'],
        width: 202,
        alignItems: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: options.radii.full,
        cursor: 'pointer',
        display: 'inline-flex',
        flexGrow: 0,
        flexShrink: 0,
        fontWeight: 'bold',
        justifyContent: 'center',
        textTransform: 'uppercase',
        transition: 'all 0.3s ease',
        background: 'transparent',
        '&:not(:disabled):hover': {
            background: options.colors.white,
            borderColor: options.colors.blue20,
            color: options.colors.blue20,
        },
        '&:focus': {
            color: options.colors.blue,
            outlineOffset: 'unset',
        },
        [waveMdMediaQuery]: {
            flexBasis: 'auto',
            order: 1,
        },
    },
]);

const ButtonTextDefault = glamorous.span({
    display: 'block',
});

const Container = glamorous(RentalcarCardContainer)({
    flexDirection: 'row',
});

export function RentalcarLinkCard({
    bubbleImageLink,
    carImageLink,
    trackEvent,
    parameters,
}) {
    useEffect(() => {
        trackEvent({
            event: 'event',
            eventCategory: 'mHCHub - Rentalcar',
            eventAction: `displayCard`,
            eventLabel: 'Rentalcar - Linkcard',
        });
    }, []);

    return h(Container, [
        h(TeaserCardContentContainer, [
            h(FoldingHeadline, 'HolidayCheck Mietwagen'),
            h(TeaserCardContent, [
                h(TeaserCardContentImage, { src: carImageLink }),
                h(
                    TeaserText,
                    'Über 1000 Mietwagen-Angebote für Deinen Urlaub. Schau doch mal rein.',
                ),
            ]),
            h(
                Button,
                {
                    href: `/mietwagen${parameters}`,
                    onClick: () =>
                        trackEvent({
                            event: 'event',
                            eventCategory: 'mHCHub',
                            eventAction: 'gotorentalcar',
                            eventLabel: 'Mietwagen Buchen',
                        }),
                },
                [h(ButtonTextDefault, 'Mietwagen buchen')],
            ),
        ]),
        h(TeaserCardImageContainer, [
            h(TeaserCardImageContainerImage, { src: bubbleImageLink }),
        ]),
    ]);
}
