import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function Beach({ css }) {
    return h(
        Icon,
        {
            viewBox: '0 0 15 16',
            css,
        },
        [
            h('path', {
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                fill: '#000820',
                d: oneLine`M7.69034 14.4777C9.314 14.4748 10.8848 14.758 12.1695 15.2749C12.4257 15.3779 12.7169 15.2538 12.82 14.9976C12.9231 14.7415 12.7989 14.4502 12.5427 14.3471C11.1223 13.7757 9.42179 13.4747 7.68901 13.4777H0.689453C0.413311 13.4777 0.189453 13.7016 0.189453 13.9777C0.189453 14.2538 0.413311 14.4777 0.689453 14.4777L7.69034 14.4777Z`,
            }),
            h('path', {
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                fill: '#000820',
                d: oneLine`M9.52813 2.37671C8.07094 1.84821 6.4917 1.80395 5.00902 2.25037C3.52661 2.6967 2.21692 3.61046 1.25806 4.86181L1.25562 4.865C1.14041 5.01787 1.06285 5.19558 1.02777 5.38308C0.992704 5.57055 1.00082 5.76399 1.05166 5.94789C1.10251 6.13184 1.19505 6.30236 1.3233 6.44475C1.45163 6.58723 1.61227 6.6976 1.79294 6.76487L1.79535 6.76577L12.926 10.8452C12.9259 10.8452 12.926 10.8452 12.926 10.8452C13.1139 10.9141 13.3162 10.9338 13.5141 10.9019C13.712 10.87 13.8974 10.7878 14.0543 10.6647C14.2112 10.5417 14.3344 10.3818 14.415 10.2006C14.4937 10.0237 14.53 9.83071 14.5216 9.63765C14.511 8.04477 14.0288 6.49227 13.1382 5.19293C12.2453 3.89038 10.985 2.90511 9.52813 2.37671ZM5.29732 3.20791C6.57353 2.82366 7.93209 2.86159 9.18718 3.31679C10.4426 3.77211 11.5356 4.62371 12.3133 5.75831C13.0912 6.89316 13.5143 8.25414 13.5217 9.65383C13.5217 9.66199 13.522 9.67015 13.5224 9.6783C13.5246 9.7189 13.5171 9.7588 13.5014 9.79409C13.4857 9.8293 13.463 9.85764 13.4371 9.87797C13.4113 9.89819 13.3829 9.91013 13.3549 9.91466C13.3269 9.91918 13.298 9.91652 13.2703 9.90637L2.14188 5.82773C2.14162 5.82763 2.14214 5.82782 2.14188 5.82773C2.11535 5.81771 2.08863 5.80025 2.06632 5.77548C2.0437 5.75037 2.02572 5.71837 2.01551 5.68145C2.0053 5.6445 2.00359 5.60509 2.01072 5.56695C2.01775 5.52938 2.03287 5.49561 2.05338 5.46801C2.88762 4.38015 4.02163 3.592 5.29732 3.20791Z`,
            }),
            h('path', {
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                fill: '#000820',
                d: oneLine`M9.97268 1.00352C9.71081 0.915888 9.42748 1.05714 9.33985 1.319L8.88157 2.68849C8.79393 2.95036 8.93518 3.23369 9.19705 3.32132C9.45892 3.40895 9.74225 3.2677 9.82988 3.00584L10.2882 1.63635C10.3758 1.37448 10.2345 1.09115 9.97268 1.00352Z`,
            }),
            h('path', {
                fillRule: 'evenodd',
                clipRule: 'evenodd',
                fill: '#000820',
                d: oneLine`M7.6941 7.84334C7.43213 7.75601 7.14896 7.89758 7.06163 8.15955L5.23544 13.6375C5.1481 13.8995 5.28967 14.1826 5.55164 14.27C5.81361 14.3573 6.09678 14.2157 6.18411 13.9538L8.0103 8.47581C8.09764 8.21384 7.95607 7.93068 7.6941 7.84334Z`,
            }),
        ],
    );
}
