import { stripIndent } from 'common-tags';

import graphqlFetch from '../lib/graphqlFetch';
import { noticeError } from '../lib/newrelic';

const query = stripIndent`
        query RootQuery {
          rentalcarOffercardState {
            isActive
          }
        }
    `;

export default async function fetchRentalcarOffercardState(context) {
    try {
        const response = await graphqlFetch(context, { query });

        if (response.ok) {
            const {
                data: { rentalcarOffercardState },
            } = await response.json();
            return rentalcarOffercardState.isActive;
        }
    } catch (error) {
        noticeError(error, {
            pageName: 'MyHolidaycheckDashboard',
            componentName: 'fetchRentalcarOffercardState',
        });
    }

    return false;
}
