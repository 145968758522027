import h from 'react-hyperscript';
import glamorous from 'glamorous';
import { oneLine } from 'common-tags';

import colors from '../../styles/colors';

const { uploadCTABackgroundColor } = colors;

const Svg = glamorous.svg({
    display: 'inline-block',
    fill: 'currentColor',

    ':hover': {
        fill: 'currentColor',
    },
});

export default function Upload() {
    return h(
        Svg,
        {
            viewBox: '0 0 78 62',
        },
        [
            h(
                'g',
                {
                    transform: oneLine`translate(-243, -1298) translate(127, 291) translate(0, 632) translate(0, 285)
                translate(85, 90) translate(31, 0)`,
                    fill: 'none',
                    fillRule: 'evenodd',
                },
                [
                    h('rect', {
                        stroke: 'currentColor',
                        strokeWidth: 2,
                        x: 8,
                        y: 7,
                        width: 62,
                        height: 48,
                        rx: 4,
                    }),
                    h('rect', {
                        stroke: 'currentColor',
                        x: 11.5,
                        y: 10.5,
                        width: 55,
                        height: 41,
                        rx: 2,
                    }),
                    h('circle', {
                        fill: '#fff',
                        stroke: 'currentColor',
                        cx: 26,
                        cy: 23,
                        r: 4.5,
                    }),
                    h('g', [
                        h('path', {
                            fill: '#fff',
                            stroke: 'currentColor',
                            d: oneLine`M11.5000011,48.8196357 C11.5000011,48.8450911 11.500001,48.8777971 11.500001,48.9205414
                        L11.5000011,48.8196357 Z M11.500001,48.9205414 C11.5000009,48.9541879 11.5000008,48.9940545
                        11.5000007,49.0415006 C11.5000002,49.5260002 11.5000002,49.5260002 11.5,50.5 C11.5,50.9598369
                        11.6686461,51.212806 11.9736068,51.3652863 C12.1729541,51.46496 12.3831935,51.4999999
                        12.5,51.4999999 L54.9999986,51.5 L55,37.6665165 L47.0673437,27.0777192 L33.7549263,44.4371203
                        L25.3826583,35.541802 L11.500001,48.9205414 Z`,
                        }),
                    ]),
                    h('g', [
                        h(
                            'g',
                            {
                                transform: 'translate(48, 32)',
                            },
                            [
                                h('circle', {
                                    fill: 'currentColor',
                                    cx: 14,
                                    cy: 14,
                                    r: 14,
                                }),
                                h('circle', {
                                    stroke: uploadCTABackgroundColor,
                                    strokeWidth: 2,
                                    cx: 14,
                                    cy: 14,
                                    r: 15,
                                }),
                            ],
                        ),
                        h('path', {
                            fill: '#fff',
                            transform: oneLine`translate(48, 32) translate(14, 14) rotate(-90) translate(-14, -14)`,
                            d: 'M14 16L8 16 8 12 14 12 14 9 20 14 14 19',
                        }),
                    ]),
                ],
            ),
        ],
    );
}
