import h from 'react-hyperscript';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import { pxToRem } from '../../styles/unitConverter';
import {
    largeDistance,
    mediumDistance,
    smallDistance,
    xsmallDistance,
    xxlargeDistance,
} from '../../styles/distances';
import { applyOnTabletAndUp } from '../../styles/mediaQueries';
import { FONTS, secondaryFont } from '../../styles/fonts';
import colors from '../../styles/colors';
import Phone from '../icons/Phone';
import Email from '../icons/Email';
import FAQ from '../icons/FAQ';
import {
    waveMdMediaQuery,
    waveSmMediaQuery,
} from '../../styles/waveMediaQueries';
import { waveTextMButton } from '../../styles/waveText';

const MembershipContactContainer = glamorous.div({
    margin: largeDistance,
    marginBottom: xxlargeDistance,
    [waveMdMediaQuery]: {
        marginLeft: xxlargeDistance,
        marginRight: xxlargeDistance,
    },
    display: 'flex',
    flexDirection: 'column',
});

const MembershipInformationContainer = glamorous.div({
    marginBottom: xxlargeDistance,
    borderRadius: `${options.space['2xs']}px`,
    padding: smallDistance,
    paddingBottom: largeDistance,
    fontSize: options.fontSizes.xs,
    lineHeight: pxToRem(21),
    [waveMdMediaQuery]: {
        fontSize: options.fontSizes.s,
        lineHeight: pxToRem(24),
    },
    background: options.colors.purple100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const ContactTitle = glamorous.h1({
    textAlign: 'center',
    fontFamily: FONTS.primary,
    fontSize: options.fontSizes.l,
    [waveSmMediaQuery]: {
        fontSize: options.fontSizes.xl,
    },
    [waveMdMediaQuery]: {
        fontSize: options.fontSizes['2xl'],
    },
});

const ContactDescription = glamorous.p({
    marginTop: smallDistance,
    [waveSmMediaQuery]: {
        marginTop: mediumDistance,
    },
    [waveMdMediaQuery]: {
        marginTop: largeDistance,
    },
    maxWidth: pxToRem(792),
    ...secondaryFont.PTSansFamily,
    textAlign: 'center',
});

const ContactChannels = glamorous.div({
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: largeDistance,
    width: '100%',
    maxWidth: pxToRem(792),
    [applyOnTabletAndUp]: {
        flexDirection: 'row',
    },
});

const ContactChannelItemLink = glamorous.a({
    color: colors.premiumColor,
    textDecoration: 'none',
    fontWeight: options.fontWeights.bold,
    position: 'relative',
    fontSize: options.fontSizes.xs,
    lineHeight: pxToRem(21),
    [waveMdMediaQuery]: {
        fontSize: options.fontSizes.s,
        lineHeight: pxToRem(24),
    },
    '> svg': {
        position: 'relative',
        top: xsmallDistance,
        marginRight: xsmallDistance,
    },
});

const ContactLinkContainer = glamorous.div({
    display: 'flex',
    flexDirection: 'column',
    gap: mediumDistance,
    marginLeft: 'auto',
    marginRight: 'auto',
    [waveMdMediaQuery]: {
        gap: xxlargeDistance,
        flexDirection: 'row',
    },
});

const ContactLink = glamorous.a({
    display: 'block',
    padding: `${pxToRem(options.space.xs)} ${pxToRem(options.space.m)}`,
    textAlign: 'center',
    color: options.colors.blue,
    textTransform: 'uppercase',
    textDecoration: 'none',
    ...waveTextMButton,
    fontWeight: options.fontWeights.bold,
    [`@media (min-width: ${options.breakpoints.m})`]: {
        width: 'inherit',
    },

    '&:hover': {
        color: options.colors.blue20,
    },
});

const contactChannels = [
    {
        href: 'tel:+498914379118',
        icon: Phone,
        value: '+49 89 14379 118',
    },
    {
        href: 'mailto: info@premium.holidaycheck.com',
        icon: Email,
        value: 'info@premium.holidaycheck.com',
    },
    {
        href: 'https://www.holidaycheck.de/premium/faq',
        icon: FAQ,
        value: 'Fragen & Antworten',
    },
];

export default function MembershipContact({ isCancellationPage = false }) {
    return h(MembershipContactContainer, [
        h(MembershipInformationContainer, [
            h(ContactTitle, 'Mein Premium-Kontakt'),
            h(ContactDescription, [
                'Du erreichst uns per E-Mail oder über die Premium Hotline (Ortstarif, Mobilfunk abweichend)',
                h('br'),
                'von Montag bis Freitag 10:00–18:00 Uhr und am Wochenende von 10:00–16:00 Uhr.',
            ]),
            h(ContactChannels, {}, [
                contactChannels.map(({ href, icon, value }, index) =>
                    h(ContactChannelItemLink, { href, key: index }, [
                        h(icon, {
                            css: {
                                width: `${options.space.l}px`,
                                [waveMdMediaQuery]: {
                                    width: `${options.space.xl}px`,
                                },
                            },
                        }),
                        value,
                    ]),
                ),
            ]),
        ]),
        h(ContactLinkContainer, [
            h(
                ContactLink,
                {
                    href: '/premium/faq',
                },
                'HolidayCheck Premium FAQ',
            ),
            h(
                ContactLink,
                {
                    href: 'https://storage.googleapis.com/hc-web-assets/assets/club/documents/de/assets-club-documents-agb.pdf',
                },
                'HolidayCheck Premium AGB',
            ),
            !isCancellationPage &&
                h(
                    ContactLink,
                    {
                        href: '/mhc/mein-premium/kuendigen',
                    },
                    'Mitgliedschaft kündigen',
                ),
        ]),
    ]);
}
