import h from 'react-hyperscript';
import { oneLine } from 'common-tags';

import Icon from './Icon';

export default function Preloader(options = {}) {
    return h(Icon, options, [
        h('path', {
            d: oneLine`
        M16 2.64c-1.125 0 -2.073 .385 -2.844 1.156S12 5.515 12 6.64c0 1.083 .385 2.02
        1.156 2.813S14.875 10.64 16 10.64c1.125 0 2.073 -.396 2.844 -1.188S20 7.724 20
        6.64C20 5.515 19.615 4.567 18.844 3.796S17.125 2.64 16 2.64zM8.533 24.773c-.291
        0 -.53 .094 -.718 .282s-.282 .427 -.282 .718c0 .292 .094 .532 .282 .72s.427 .28
        .718 .28c.292 0 .532 -.093 .72 -.28s.28 -.428 .28 -.72c0 -.291 -.093 -.53 -.28
        -.718s-.428 -.282 -.72 -.282zM5.267 17.64c-.542 0 -1.01 .198 -1.407 .594s-.593
        .864 -.593 1.406c0 .542 .198 1.01 .593 1.406s.865 .594 1.407 .594c.541 0 1.01
        -.198 1.406 -.594s.594 -.864 .594 -1.406c0 -.542 -.198 -1.01 -.594 -1.406S5.808
        17.64 5.267 17.64zm1.466 -3.313c.834 0 1.542 -.291 2.125 -.874s.875 -1.292 .875
        -2.125c0 -.834 -.291 -1.542 -.875 -2.125S7.567 8.328 6.733 8.328c-.833 0 -1.541
        .291 -2.125 .875s-.875 1.291 -.875 2.125c0 .833 .292 1.541 .875 2.125s1.292 .875
        2.125 .875z`,
        }),
    ]);
}
