import { useEffect, useReducer } from 'react';

import { isReviewDateMatchingBookingDates } from '../../../../common/lib/bookingHelper';
import fetchHotelReviews from '../../../fetchers/fetchHotelReviews';
import isMoreThanTwoYearsInThePast from '../../../lib/isMoreThanTwoYearsInThePast';

const initialReviewStatusState = {
    hotelReview: undefined,
    isBookingMoreThanTwoYearsInThePast: false,
};

const reviewStatusStateReducer = (
    _,
    { filteredHotelReviews, currentDate, bookingEndDate },
) => {
    const hotelReview = filteredHotelReviews[0] || undefined;
    const isBookingMoreThanTwoYearsInThePast = isMoreThanTwoYearsInThePast(
        currentDate,
        bookingEndDate,
    );

    return {
        hotelReview,
        isBookingMoreThanTwoYearsInThePast,
    };
};

const useBookingReviewStatusState = ({
    bookingStartDate,
    bookingEndDate,
    bookingHotelId,
    fetch,
    config,
    currentDate,
}) => {
    const [
        { hotelReview, isBookingMoreThanTwoYearsInThePast },
        setReviewStatusState,
    ] = useReducer(reviewStatusStateReducer, initialReviewStatusState);

    useEffect(() => {
        const getHotelReview = async () => {
            const filteredHotelReviews = (
                await fetchHotelReviews({ fetch, config })
            ).items.filter((review) => {
                return (
                    review.hotel.id === bookingHotelId &&
                    isReviewDateMatchingBookingDates(
                        review.travelDate,
                        bookingStartDate,
                        bookingEndDate,
                    )
                );
            });

            setReviewStatusState({
                filteredHotelReviews,
                currentDate,
                bookingEndDate,
            });
        };
        getHotelReview();
    }, []);

    return { hotelReview, isBookingMoreThanTwoYearsInThePast };
};

export { useBookingReviewStatusState };
