import h from 'react-hyperscript';
import glamorous from 'glamorous';
import moment from 'moment';

import { extraTinyFont } from '../../styles/fonts';
import formatDate from '../../lib/formatDate';

const travelDurationMapper = {
    ONE_TO_THREE_DAYS: '1-3 Tage',
    THREE_TO_FIVE_DAYS: '3-5 Tage',
    ONE_WEEK: '1 Woche',
    TWO_WEEKS: '2 Wochen',
    THREE_WEEKS: '3 Wochen',
    FOUR_WEEKS: '4 Wochen',
    FIVE_WEEKS: '5 Wochen',
    LONGER: 'Länger als 5 Wochen',
};

const travelReasonMapper = {
    BEACH: 'Strandurlaub',
    BUSINESS: 'Arbeit',
    CITY: 'Stadt',
    HIKING_WELLNESS: 'Wandern und Wellness',
    WINTER_SPORT: 'Winter',
    OTHER: 'Sonstige',
};

const TravelInfoContainer = glamorous.div(extraTinyFont);

export default function TravelInfo({
    travelDuration,
    travelDate,
    travelReason,
}) {
    const date = moment(travelDate).utc();
    const travelInfos = [
        travelDurationMapper[travelDuration],
        formatDate(date, 'MMMM Y'),
        travelReasonMapper[travelReason],
    ];

    return h(TravelInfoContainer, [
        travelInfos.filter((empty) => empty).join(' • '),
    ]);
}
