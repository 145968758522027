import h from 'react-hyperscript';
import glamorous from 'glamorous';

import Link from '../navigation/Link';
import { applyOnMobile, applyOnTabletAndUp } from '../../styles/mediaQueries';
import colors from '../../styles/colors';
import { bigLoudFont, subheadlineFont } from '../../styles/fonts';

const { linkColor } = colors;

const AlbumTitle = glamorous.h2({
    margin: 0,
    color: linkColor,
    [applyOnMobile]: bigLoudFont,
    [applyOnTabletAndUp]: {
        display: 'inline-block',
    },
    ...subheadlineFont,
    fontWeight: 'normal',
});

export default function AlbumDate(props) {
    return h(AlbumTitle, [
        props.link
            ? h(
                  Link,
                  {
                      href: props.link,
                  },
                  props.title,
              )
            : props.title,
    ]);
}
