import { Component } from 'react';
import h from 'react-hyperscript';
import { Query } from '@apollo/client/react/components';

import { contentPoints } from '../../queries/contentPoints';
import calculateContentPoints from '../../lib/calculateContentPoints';
import UserPoints from './UserPoints';

const calculateContentPointsFromProfile = ({
    hotelReviewsForUser: { total: hotelReviewCount },
    poiReviewsForUser: { total: poiReviewCount },
    shipReviewsForUser: { count: shipReviewCount },
    videosForUser: { total: videoCount },
    picturesForUser: { total: pictureCount },
}) =>
    calculateContentPoints({
        hotelReviewCount,
        poiReviewCount,
        shipReviewCount,
        pictureCount,
        videoCount,
    });

class UserPointsQuery extends Component {
    render() {
        return h(
            Query,
            { fetchPolicy: 'cache-and-network', query: contentPoints },
            ({ loading, data, error }) => {
                if (loading || error || !data) {
                    return null;
                }

                return h(UserPoints, {
                    points: calculateContentPointsFromProfile(data),
                });
            },
        );
    }
}

export default UserPointsQuery;
