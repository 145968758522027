import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import formatPrice from './formatPrice';
import formatDate from '../../../lib/formatDate';
import { superLoudFont, waveFontM } from '../../../styles/fonts';
import { gray40, green40, red40 } from '../../../styles/waveColors';
import { pxToRem } from '../../../styles/unitConverter';

const getTextColor = (isFullyPaid, isOverdue) => {
    if (isFullyPaid) {
        return green40;
    }
    if (isOverdue) {
        return red40;
    }
    return 'inherit';
};

const Box = glamorous.div(({ isFullyPaid, isOverdue }) => ({
    minHeight: pxToRem(80),
    borderRadius: pxToRem(4),
    textAlign: 'start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: getTextColor(isFullyPaid, isOverdue),
}));
Box.displayName = 'Box';

const Success = glamorous.img({
    verticalAlign: 'sub',
    marginRight: pxToRem(8),
});
Success.displayName = 'Success';

const TitleAndPrice = glamorous.div(superLoudFont, {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: pxToRem(4),
});
TitleAndPrice.displayName = 'TitleAndPrice';

const Title = glamorous.div(waveFontM);
Title.displayName = 'Title';

const Price = glamorous.div(waveFontM);
Price.displayName = 'Price';

const Calendar = glamorous.img({
    marginBottom: '-1px',
    marginRight: pxToRem(10),
});
Calendar.displayName = 'Calendar';

const DueDate = glamorous.span({
    color: gray40,
});
DueDate.displayName = 'DueDate';

const createDueDateText = (dueDate, isOverdue) => {
    return isOverdue
        ? 'Fällig sofort'
        : `Fällig am ${formatDate(dueDate, 'DD.MM.Y')}`;
};

const Installment = (
    { title, amount, currencyCode, dueDate, isFullyPaid, isOverdue },
    { config: { assetsPath } },
) => {
    return h(Box, { isFullyPaid, isOverdue }, [
        h(
            TitleAndPrice,
            [
                isFullyPaid &&
                    h(Success, {
                        src: `${assetsPath}/myBookings/success.svg`,
                        alt: '',
                    }),
                `${title}: ${formatPrice(amount, currencyCode)}`,
            ],
            [
                h(Title, `${title}`),
                h(Price, `${formatPrice(amount, currencyCode)}`),
            ],
        ),
        !isFullyPaid &&
            dueDate &&
            h('div', [h(DueDate, createDueDateText(dueDate, isOverdue))]),
    ]);
};

Installment.propTypes = {
    title: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    currencyCode: PropTypes.string.isRequired,
    dueDate: PropTypes.string,
    isFullyPaid: PropTypes.bool,
    isOverdue: PropTypes.bool,
};

Installment.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

export default Installment;
