import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import { pxToRem } from '../../styles/unitConverter';
import { applyOnMobile } from '../../styles/mediaQueries';
import buttons from '../../styles/buttons';
import DownArrowLine from '../icons/DownArrowLine';
import UpArrowLine from '../icons/UpArrowLine';
import { visuallyHidden } from '../../styles/accessibility';

const { whiteButton } = buttons;

const ShowDetailsButton = glamorous.button({
    ...whiteButton,
    fontFamily: 'inherit',
    fontSize: pxToRem(14),
    margin: `${pxToRem(12)} 0 0 0`,
    width: pxToRem(225),
    height: pxToRem(42),

    [applyOnMobile]: {
        marginInline: 'auto',
    },
});
ShowDetailsButton.displayName = 'ShowDetailsButton';

const ButtonText = glamorous.span({
    marginInline: 'auto',
});
ButtonText.displayName = 'ButtonText';

const SROnlySpan = glamorous.span(visuallyHidden);
SROnlySpan.displayName = 'SROnlySpan';

const arrowProps = {
    width: 20,
    height: 20,
    css: { marginInline: 'auto' },
};

const handleOnClick = (onClick) => (event) => {
    event.preventDefault();

    onClick();
};

function BookingDetailsButton({ onClick, isExpanded, hotelName }) {
    return h(
        ShowDetailsButton,
        { onClick: handleOnClick(onClick), 'aria-expanded': isExpanded },
        [
            h(SROnlySpan, hotelName),
            h(ButtonText, !isExpanded ? 'Reise anzeigen' : 'Reise verbergen'),
            !isExpanded
                ? h(DownArrowLine, arrowProps)
                : h(UpArrowLine, arrowProps),
        ],
    );
}

BookingDetailsButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    isExpanded: PropTypes.bool.isRequired,
};

export default BookingDetailsButton;
