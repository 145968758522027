import moment from 'moment';

import { findHotelService } from './serviceHelpers';

const countryToCodeMap = {
    Afghanistan: '146',
    Albanien: '147',
    Algerien: '191',
    'Amerikanisch-Samoa': '231',
    'Amerikanische Jungferninsel': '228',
    'Amerikanische Überseeinseln, kleinere': '232',
    'Andorra, Fürstentum': '246',
    Angola: '148',
    Anguilla: '214',
    'Antigua und Barbuda': '151',
    Argentinien: '87',
    Armenien: '150',
    Aruba: '149',
    Aserbaidschan: '153',
    Australien: '152',
    Bahamas: '168',
    Bahrain: '166',
    Bangladesch: '156',
    Barbados: '157',
    Belgien: '155',
    Belize: '161',
    Benin: '190',
    Bermudas: '158',
    'BES-Inseln': '269',
    'Bhutan, Königreich': '162',
    Bolivien: '164',
    'Bosnien-Herzegowina': '163',
    Botsuana: '88',
    Bouvetinseln: '233',
    Brasilien: '165',
    'Britische Jungferninseln': '227',
    'Britisches Territorium im Indischen Ozean': '217',
    'Brunei Darussalam': '167',
    Bulgarien: '160',
    'Burkina Faso (ehem Obervolta)': '159',
    Burundi: '169',
    Chile: '92',
    'China, Republik (Taiwan)': '25',
    'China, Volksrepublik': '89',
    China: '89',
    Cookinseln: '234',
    'Costa Rica': '180',
    Curacao: '267',
    Curaçao: '267',
    Deutschland: '184',
    Dominica: '36',
    'Dominikanische Republik': '188',
    Dschibuti: '185',
    Dänemark: '186',
    Ecuador: '196',
    'El Salvador': '197',
    'Elfenbeinküste (Cote dlvoire)': '175',
    'Eritrea, Republik': '215',
    Estland: '198',
    'Falkland Inseln (Islas Malvinas)': '202',
    Fidschi: '204',
    Finnland: '203',
    Frankreich: '201',
    'Französisch-Guayana': '252',
    'Französisch-Polynesien': '221',
    'Französische Südgebiete': '235',
    Färöer: '206',
    Gabun: '207',
    Gambia: '33',
    Georgien: '210',
    Ghana: '211',
    Gibraltar: '208',
    Grenada: '37',
    Griechenland: '145',
    Großbritannien: '19',
    'Großbritannien & Nordirland': '19',
    Grönland: '187',
    Guadeloupe: '251',
    Guam: '236',
    Guatemala: '209',
    Guernsey: '20',
    Guinea: '75',
    'Guinea-Bissau': '212',
    'Guyana, Kooperative Republik': '128',
    Haiti: '58',
    'Heart und McDonaldinseln': '237',
    Honduras: '131',
    Hongkong: '130',
    Indien: '136',
    Indonesien: '59',
    Irak: '139',
    'Iran, Islamische Republik': '137',
    Irland: '138',
    Island: '140',
    'Isle of Man': '22',
    Israel: '135',
    Italien: '134',
    Jamaika: '142',
    Japan: '141',
    Jemen: '10',
    Jersey: '21',
    Jordanien: '143',
    Kaimaninseln: '127',
    Kambodscha: '144',
    Kamerun: '177',
    Kanada: '172',
    'Kap Verde': '181',
    'Kapverdische Inseln': '181',
    Kasachstan: '111',
    'Katar (Qatari)': '86',
    Kenia: '193',
    Kirgisistan: '113',
    'Kiribati, Republik': '218',
    Kokosinseln: '238',
    Kolumbien: '178',
    'Komoren, Islamische Bundesrepublik': '179',
    'Kongo, Demokratische Republik (ehem Zaire)': '173',
    'Kongo, Republik': '91',
    'Korea, Demokratische Volksrepublik': '66',
    'Korea, Republik': '189',
    Kosovo: '253',
    Kroatien: '132',
    Kuba: '171',
    Kuwait: '115',
    'Laos, Demokratische Volksrepublik': '117',
    'Lesotho, Königreich': '120',
    Lettland: '122',
    Libanon: '61',
    Liberia: '118',
    'Libysch-Arabische Volks-Jamahiria, Sozialistische': '119',
    Liechtenstein: '205',
    Litauen: '121',
    Luxemburg: '116',
    'Macau (Aomen)': '125',
    Madagaskar: '62',
    Malawi: '102',
    Malaysia: '126',
    Malediven: '101',
    Mali: '63',
    Malta: '123',
    Marokko: '124',
    'Marshallinseln, Republik der': '219',
    Martinique: '250',
    Mauretanien: '60',
    Mauritius: '100',
    Mayotte: '230',
    'Mazedonien (ehem jugoslawische Republik)': '97',
    Mexiko: '95',
    'Mikronesien, Föderierte Staaten von': '216',
    'Moldau, Republik': '94',
    Monaco: '110',
    Mongolei: '96',
    'Montenegro, Republik': '245',
    Montenegro: '245',
    Montserrat: '98',
    Mosambik: '99',
    'Myanmar (ehem Birma / Burma)': '103',
    Namibia: '106',
    Nauru: '107',
    Nepal: '108',
    Neukaledonien: '93',
    Neuseeland: '77',
    Nicaragua: '109',
    Niederlande: '76',
    Niger: '64',
    Nigeria: '35',
    Niueinseln: '239',
    Norfolkinseln: '240',
    Norwegen: '104',
    'Nördliche Marianen, Commenwealth der': '220',
    Oman: '78',
    Osttimor: '241',
    Pakistan: '81',
    'Palau, Republik': '224',
    Palästina: '248',
    Panama: '80',
    'Papua-Neuguinea': '84',
    Paraguay: '85',
    Peru: '82',
    Philippinen: '68',
    Pitcairninseln: '223',
    Polen: '83',
    Portugal: '79',
    'Puerto Rico': '249',
    Ruanda: '71',
    Rumänien: '65',
    'Russische Föderation': '70',
    Russland: '70',
    Réunion: '270',
    Salomonen: '45',
    Sambia: '14',
    'Samoa (Westsamoa)': '39',
    'Saudi Arabien': '114',
    Schweden: '72',
    Schweiz: '174',
    Senegal: '44',
    'Serbien, Republik': '11',
    Seychellen: '49',
    'Sierra Leone': '34',
    Simbabwe: '17',
    Singapur: '74',
    'Sint Maarten': '268',
    'Slowakische Republik': '41',
    Slowakei: '41',
    Slowenien: '42',
    Somalia: '46',
    Spanien: '192',
    'Sri Lanka (ehem Ceylon)': '176',
    'Sri Lanka': '176',
    'St. Helena': '57',
    'St. Kitts und Nevis (ehem St. Christopher und Nevis)': '112',
    'St. Lucia': '38',
    'St. Marino': '133',
    'St. Pierre und Miquelon': '222',
    'St. Vincent und die Grenadinen': '23',
    'Sudan, Republik': '48',
    Suriname: '43',
    Swasiland: '73',
    'Syrien, Arabische Republik': '50',
    'São Tomé und Principe, Demokratische Republik': '47',
    Südafrika: '15',
    'Südgeorgien und die Sandwichinseln': '242',
    Tadschikistan: '53',
    Tansania: '194',
    Thailand: '52',
    Togo: '69',
    Tokelau: '243',
    'Tonga, Königreich': '56',
    'Trinidad und Tobago': '24',
    Tschad: '51',
    'Tschechische Republik': '183',
    Tschechien: '183',
    Tunesien: '55',
    Turkmenistan: '54',
    'Turks- und Caicosinseln': '225',
    Tuvalu: '226',
    Türkei: '40',
    Uganda: '195',
    Ukraine: '26',
    Ungarn: '129',
    Uruguay: '67',
    Usbekistan: '29',
    Vanuatu: '32',
    Vatikanstadt: '30',
    Venezuela: '13',
    'Vereinigte Arabische Emirate': '27',
    VAE: '27',
    'Vereinigte Staaten von Amerika': '28',
    USA: '28',
    Vietnam: '31',
    'Wallis und Futuna': '229',
    Weihnachtsinseln: '244',
    'Weißrußland (Belarus)': '170',
    'Zentralafrikanische Republik': '90',
    Zypern: '182',
    Ägypten: '199',
    Äquatorialguinea: '213',
    Äthiopien: '200',
    Österreich: '154',
};

const BASE_URL = 'https://secure.hmrv.de/rvw-ba/initBa.jsp?';
const ADULT_AGE_HM = 21;
const defaultHCQuery = '&qb=Y&aid=loginhc&fTypId=0';

const getDomainSpecificParams = (topLevelDomain) => {
    switch (topLevelDomain) {
        case 'ch':
            return 'adnr=4336194&locale=de_CH';
        case 'at':
            return 'adnr=4127510&locale=de_AT&subBaId=1';
        default:
            return 'adnr=4127502&locale=de_DE';
    }
};

const getTravellerAge = (traveller, currentDate) =>
    moment(currentDate).diff(traveller.dateOfBirth, 'years');

const formatDate = (date) => moment(date).format('DDMMYYYY');

const getCountryCode = (country) => countryToCodeMap[country];

const getDoBDates = (array) =>
    array.map((traveller) => formatDate(traveller.dateOfBirth)).join(',');

const getPeopleCountQuery = (adults, children) => {
    const adultsQuery = adults.length ? `&anzahl=${adults.length}` : '';
    const childrenQuery = children.length
        ? `&anzahlKinder=${children.length}`
        : '';
    return `${adultsQuery}${childrenQuery}`;
};

const getPeopleDoBQuery = (adults, children) => {
    const adultsDoBQuery = adults.length
        ? `&gebDatVersicherte=${getDoBDates(adults)}`
        : '';
    const childrenDoBQuery = children.length
        ? `&gebDatKinder=${getDoBDates(children)}`
        : '';
    return `${adultsDoBQuery}${childrenDoBQuery}`;
};

const getUrlQueryFromTravellers = (
    travellers,
    currentDate,
    isDoBNeeded = false,
) => {
    // for booking.com in "Hotel only" option we are not required to specify travellers
    // if there is a traveller whose age is not specified we don't provide the url param
    if (
        !travellers ||
        !travellers.length ||
        travellers.some((t) => !t.dateOfBirth)
    ) {
        return '';
    }

    const adults = travellers.filter(
        (t) => getTravellerAge(t, currentDate) >= ADULT_AGE_HM,
    );
    const children = travellers.filter(
        (t) => getTravellerAge(t, currentDate) < ADULT_AGE_HM,
    );

    const peopleCountQuery = getPeopleCountQuery(adults, children);

    return isDoBNeeded
        ? `${peopleCountQuery}${getPeopleDoBQuery(adults, children)}`
        : peopleCountQuery;
};

const getUrlQueryDates = ({ startDate, endDate, creationDate }) => {
    return `&beginn=${formatDate(startDate)}&ende=${formatDate(endDate)}&buchungsdatum=${formatDate(creationDate)}`;
};

const getHealthInsuranceUrl = (booking, topLevelDomain, currentDate) => {
    const { startDate, endDate, creationDate, travellers } = booking;

    const datesQuery = getUrlQueryDates({ startDate, endDate, creationDate });

    const queryFromBooking = `${datesQuery}${getUrlQueryFromTravellers(travellers, currentDate, true)}`;

    return `${BASE_URL}${getDomainSpecificParams(topLevelDomain)}&baid=61${defaultHCQuery}${queryFromBooking}`;
};

const getCancellationUrl = (booking, topLevelDomain, currentDate) => {
    const { startDate, endDate, creationDate, travellers, tourOperatorPrice } =
        booking;

    const datesQuery = getUrlQueryDates({ startDate, endDate, creationDate });

    const priceQuery = tourOperatorPrice
        ? `&preisObjekt=${String(tourOperatorPrice.amount).replace('.', ',')}`
        : '';

    const hotel = findHotelService(booking.services);
    const countryQuery =
        hotel && hotel.detail
            ? `&reiseland=${getCountryCode(hotel.detail.countryName)}`
            : '';

    const queryFromBooking = `${datesQuery}${priceQuery}${getUrlQueryFromTravellers(travellers, currentDate)}${countryQuery}`;

    return `${BASE_URL}${getDomainSpecificParams(topLevelDomain)}&baid=532${defaultHCQuery}${queryFromBooking}`;
};

export {
    getHealthInsuranceUrl,
    getCancellationUrl,
    // exported for testing purpose
    formatDate,
    getCountryCode,
};
