import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import { Fragment, useEffect } from 'react';

import { secondaryFont } from '../../styles/fonts';
import { pxToRem } from '../../styles/unitConverter';
import { applyOnMobile } from '../../styles/mediaQueries';
import BookingSectionHeadline from './BookingSectionHeadline';
import BookingFile from './BookingFile';

const { UITextL, PTSansFamily } = secondaryFont;

const SubHeadline = glamorous.div({
    textTransform: 'uppercase',
    marginBottom: pxToRem(4),
    marginTop: pxToRem(16),
    width: '100%',
});
SubHeadline.displayName = 'SubHeadline';

const Documents = glamorous.div({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'stretch',
});
Documents.displayName = 'Documents';

const TravelRules = glamorous.div({
    paddingTop: pxToRem(8),
    display: 'flex',
    flexWrap: 'wrap',
});
TravelRules.displayName = 'TravelRules';

const Instruction = glamorous.div(UITextL, PTSansFamily, {
    boxSizing: 'border-box',
    flexBasis: '50%',
    flexGrow: 1,
    whiteSpace: 'pre-wrap',
    paddingRight: pxToRem(24),
    marginBottom: pxToRem(24),
    [applyOnMobile]: {
        flexBasis: '100%',
        paddingRight: 0,
    },
});
Instruction.displayName = 'Instruction';

const Text = glamorous.p({ fontFamily: 'PT Sans' });
Text.displayName = 'Text';

const TravelDocuments = ({ booking, myRef, setWillRender }) => {
    const files = booking.document.files;
    useEffect(() => setWillRender(true), []);

    return h(Fragment, [
        h(BookingSectionHeadline, { innerRef: myRef }, 'Deine Dokumente'),
        h(Instruction, booking.document.instruction),
        h(Documents, [
            files.length > 0 &&
                files.map((file) =>
                    h(BookingFile, {
                        file,
                        bookingId: booking.id,
                        key: file.id,
                    }),
                ),
        ]),
    ]);
};

const flightTimeAndPlacePropType = PropTypes.shape({
    date: PropTypes.string,
});

const flightPropType = PropTypes.shape({
    departure: flightTimeAndPlacePropType.isRequired,
    arrival: flightTimeAndPlacePropType.isRequired,
});

TravelDocuments.propTypes = {
    booking: PropTypes.shape({
        document: PropTypes.shape({
            instruction: PropTypes.string.isRequired,
            files: PropTypes.array.isRequired,
        }).isRequired,
        services: PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.shape({
                    type: PropTypes.oneOf(['FLIGHT']),
                    detail: PropTypes.shape({
                        outBound: PropTypes.arrayOf(flightPropType).isRequired,
                        inBound: PropTypes.arrayOf(flightPropType).isRequired,
                    }).isRequired,
                }),
                PropTypes.shape({
                    type: (props) => {
                        return props.type === 'FLIGHT' ? new Error() : null;
                    },
                }),
                PropTypes.shape({
                    type: PropTypes.oneOf(['HOTEL']),
                    detail: PropTypes.shape({
                        id: PropTypes.string,
                    }).isRequired,
                }),
            ]),
        ).isRequired,
    }),
    // from using `useRef` hook, `ref` prop is treated differently and would need the `forwardRef` crap
    myRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
    setWillRender: PropTypes.func.isRequired,
};

TravelDocuments.displayName = 'TravelDocuments';

export default TravelDocuments;
