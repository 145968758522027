import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import options from '@designsystem/options';
import { useEffect, useState } from 'react';

import { useFetchRentalCarBooking } from '../../../../rentalcarBookingCard/useFetchRentalCarBooking';
import { RentalcarOfferCard } from '../../../../rentalcarBookingCard/RentalcarOfferCard';
import fetchRentalcarOfferCardState from '../../../../../fetchers/fetchRentalcarOffercardState';
import { RentalcarBookingCard } from '../../../../rentalcarBookingCard/RentalcarBookingCard';

const ComponentContainer = glamorous.section({
    backgroundColor: options.colors.white,
    borderRadius: options.space.xs,
    maxWidth: 894,
    margin: `0 ${options.space.xl}px`,
});

function MietwagenBooking({ myRef, sectionId, context }) {
    const { trackEvent } = context;
    const { bookings, hcTravel } = useFetchRentalCarBooking(context);
    const currentBooking = bookings[0] || null;

    const [isActive, setIsActive] = useState(false);
    const showDefaultHubSearchOffer = !(
        isActive &&
        currentBooking === null &&
        hcTravel &&
        hcTravel.booking.travelkind === 'PACKAGE'
    );

    useEffect(() => {
        const fetchStatus = async () => {
            const status = await fetchRentalcarOfferCardState(context);

            setIsActive(status);
        };

        fetchStatus();
    }, []);

    const eventCategory = 'HCMHub';

    if (currentBooking !== null) {
        return h(ComponentContainer, { id: sectionId, innerRef: myRef }, [
            h(RentalcarBookingCard, {
                id: currentBooking.id,
                status: currentBooking.status,
                destinationName: currentBooking.carOfferDestination,
                pickupDatetime: currentBooking.pickupDatetime,
                dropoffDatetime: currentBooking.dropoffDatetime,
                carOfferDestinationInfo:
                    currentBooking.carOfferDestinationRegion,
                carOfferPictureUrl: currentBooking.carOfferPictureUrl,
                transferType: currentBooking.transferType,
                trackEvent,
                eventCategory,
            }),
        ]);
    }

    return h(ComponentContainer, { id: sectionId, innerRef: myRef }, [
        h(RentalcarOfferCard, {
            hcTravel,
            context,
            showDefaultHubSearchOffer,
            utmParameter: {
                // prettier-ignore
                'utm_source': 'mHC', // utm parameters must be snake_case, prettier does not allow quoted keys, eslint needs camelCase keys ...
                // prettier-ignore
                'utm_medium': 'HCMHub', // utm parameters must be snake_case, prettier does not allow quoted keys, eslint needs camelCase keys ...
                // prettier-ignore
                'utm_campaign': 'PackageYesCarNo', // utm parameters must be snake_case, prettier does not allow quoted keys, eslint needs camelCase keys ...
            },
            eventCategory,
        }),
    ]);
}

MietwagenBooking.displayName = 'Mein Mietwagen';
MietwagenBooking.propTypes = {
    sectionId: PropTypes.string.isRequired,
    myRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any }),
    ]),
    context: PropTypes.any,
    hubDestinationId: PropTypes.string,
    articleDestinationId: PropTypes.string,
    bookingId: PropTypes.string,
    regionName: PropTypes.string,
};
MietwagenBooking.contextTypes = {
    config: PropTypes.object,
    trackEvent: PropTypes.func,
    fetch: PropTypes.func,
    getCurrentDate: PropTypes.func,
    window: PropTypes.object.isRequired,
};
export { MietwagenBooking };
