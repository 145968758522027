import React from 'react';
import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import glamorous from 'glamorous';
import options from '@designsystem/options';

import RadioButtonGroup from '../../inputs/RadioButtonGroup';

const Container = glamorous.fieldset({
    border: 0,
    padding: 0,
    margin: 0,
});

const CONTACT_ALLOWED_VALUES = {
    loggedInUsers: 'urlaubers',
    nobody: 'nobody',
};

class ContactAllowedSetting extends React.PureComponent {
    render() {
        const { contactAllowed, onChange, disabled } = this.props;
        return h(Container, [
            h(
                'div',
                {
                    style: {
                        fontWeight: options.fontWeights.bold,
                        fontSize: options.fontSizes.s,
                        color: options.colors.black,
                        marginBottom: options.space.xs,
                    },
                },
                'Wer darf Dir zu Deinen Beiträgen Fragen stellen?',
            ),
            h(
                'div',
                {
                    style: {
                        color: options.colors.gray20,
                        fontSize: options.fontSizes.xs,
                        marginBottom: options.space.m,
                    },
                },
                'Deine Antworten auf Fragen zu Deinen Erfahrungen sind sehr hilfreich für andere Urlauber.',
            ),
            h(RadioButtonGroup, {
                isInline: true,
                name: 'contact',
                onChange,
                options: [
                    {
                        label: 'Eingeloggte Urlauber',
                        value: CONTACT_ALLOWED_VALUES.loggedInUsers,
                    },
                    {
                        label: 'Niemand',
                        value: CONTACT_ALLOWED_VALUES.nobody,
                    },
                ],
                selectedValue: contactAllowed
                    ? CONTACT_ALLOWED_VALUES.loggedInUsers
                    : CONTACT_ALLOWED_VALUES.nobody,
                disabled,
            }),
        ]);
    }
}

ContactAllowedSetting.propTypes = {
    contactAllowed: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

export default ContactAllowedSetting;
