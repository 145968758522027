import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import { Fragment, useEffect } from 'react';

import BookingSectionHeadline from '../BookingSectionHeadline';
import BookingSummary from './Summary';
import Hotel from './Hotel';
import { findHotelService } from '../lib/serviceHelpers';

function HotelInformation({ booking, myRef, setWillRender }) {
    const hotel = findHotelService(booking.services);

    if (!hotel) {
        return null;
    }

    useEffect(() => setWillRender(true), []);

    return h(Fragment, [
        h(BookingSectionHeadline, { innerRef: myRef }, [
            'Dein Zuhause vor Ort',
        ]),
        h(Hotel, { hotel: hotel.detail }),
        h(BookingSummary, {
            bookingDetail: hotel.detail,
            travellers: booking.travellers,
        }),
    ]);
}

HotelInformation.propTypes = {
    booking: PropTypes.object.isRequired,
    myRef: PropTypes.shape({ current: PropTypes.any }).isRequired,
    setWillRender: PropTypes.func.isRequired,
};

HotelInformation.displayName = 'HotelInformation';

export default HotelInformation;
