import h from 'react-hyperscript';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import glamorous from 'glamorous';

import Page from './Page';
import BookingsQuery from './myBookings/BookingsQuery';
import { black } from '../styles/waveColors';

const MyBookingsContainer = glamorous.div({
    fontFamily: 'Montserrat',
    color: black,
});

const MyBookings = (props, { location }) => {
    if (location.search.includes('action=vouchers')) {
        return h(Navigate, { to: `/mhc/meine-gutscheine${location.search}` });
    }

    return h(MyBookingsContainer, [
        h(
            Page,
            {
                headline: 'Meine Buchungen',
            },
            h(BookingsQuery),
        ),
    ]);
};

MyBookings.contextTypes = {
    location: PropTypes.object.isRequired,
    elementModifiers: PropTypes.object.isRequired,
};

export default MyBookings;
