import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';

import additionalServiceNames from '../../../../common/lib/additionalServiceNames';
import {
    isCancellationInsurance,
    isRailAndFlyIncluded,
    isNotWished,
    isPremiumService,
} from '../lib/serviceHelpers';
import { pxToRem } from '../../../styles/unitConverter';
import { secondaryFont } from '../../../styles/fonts';
import Service from './Service';
import { gray90 } from '../../../styles/waveColors';

const { UITextLLoud } = secondaryFont;

const Subheadline = glamorous.h4({
    ...UITextLLoud,
    padding: `${pxToRem(16)} ${pxToRem(24)}`,
    borderBottom: `1px solid ${gray90}`,
    margin: 0,
});
Subheadline.displayName = 'Subheadline';

const filterValidServicesToDisplay = (services) =>
    services.filter(
        (service) =>
            (additionalServiceNames[service.type] ||
                isCancellationInsurance(service)) &&
            !isRailAndFlyIncluded(service) &&
            !isNotWished(service) &&
            !isPremiumService(service),
    );

const AdditionalServicesContainer = glamorous.div({
    marginTop: pxToRem(40),
    border: `1px solid ${gray90}`,
    boxSizing: 'border-box',
    borderRadius: '4px',
});

const AdditionalServices = ({ services }) => {
    const filteredServices = filterValidServicesToDisplay(services);

    if (!filteredServices.length) {
        return null;
    }

    return h(AdditionalServicesContainer, [
        h(Subheadline, 'Zusätzliche Leistungen'),
        filteredServices.map((service, index) =>
            h(Service, {
                service,
                key: index,
            }),
        ),
    ]);
};

AdditionalServices.propTypes = {
    services: PropTypes.array.isRequired,
};

export default AdditionalServices;
