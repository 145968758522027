import h from 'react-hyperscript';
import { useState } from 'react';
import { Mutation } from '@apollo/client/react/components';
import { electronicFormatIBAN } from 'ibantools';

import Dialog from '../dialog/Dialog';
import insertBankDataForm from './InsertBankDataForm';
import DialogSubmitButton from '../myBookings/rebookingDialogs/DialogSubmitButton';
import { insertBankDataMutation } from '../../mutators/insertBankDataMutation';
import { noticeError } from '../../lib/newrelic';

const BankDataFormDialog = ({
    bookingId,
    redemptions,
    voucherExpirationDate,
    closeDialog,
    queryRefetchCallback,
}) => {
    const [accountHolder, setAccountHolder] = useState('');
    const [iban, setIban] = useState('');
    const [errors, setErrors] = useState({});
    const [showSubmitError, setShowSubmitError] = useState(false);

    const canSubmitForm = () => {
        const hasErrors = Object.values(errors).some((value) => Boolean(value));
        const areFieldsEmpty = !(Boolean(accountHolder) && Boolean(iban));
        return !hasErrors && !areFieldsEmpty;
    };

    const redemptionIdsToUpdateBankData = redemptions
        .filter((redemption) => {
            if (bookingId !== redemption.bookingId) {
                return false;
            }

            if (redemption.haveBankDataBeenProvided) {
                return false;
            }

            return Boolean(redemption.releaseStatus === 'REDEEMED');
        })
        .map((redemption) => {
            return redemption.uuid;
        });

    return h(
        Mutation,
        { mutation: insertBankDataMutation },
        (insertBankData) => {
            const onSubmit = async () => {
                setShowSubmitError(false);

                try {
                    await insertBankData({
                        variables: {
                            input: {
                                accountHolder,
                                iban: electronicFormatIBAN(iban),
                                redemptionIds: redemptionIdsToUpdateBankData,
                            },
                        },
                    });

                    queryRefetchCallback();
                    closeDialog();
                } catch (error) {
                    if (error) {
                        noticeError(error, {
                            pageName: 'MyRedemptions',
                            component: 'BankDataFormDialog',
                        });
                    }
                    setShowSubmitError(true);
                }
            };
            return h(
                Dialog,
                {
                    title: 'Reisegutschein einlösen',
                    onClose: closeDialog,
                },
                [
                    h(insertBankDataForm, {
                        bookingId,
                        voucherExpirationDate,
                        accountHolder,
                        setAccountHolder,
                        iban,
                        setIban,
                        errors,
                        setErrors,
                        showSubmitError,
                    }),
                    h(
                        DialogSubmitButton,
                        {
                            disabled: !canSubmitForm(),
                            onClick: onSubmit,
                        },
                        'Abschicken',
                    ),
                ],
            );
        },
    );
};

export default BankDataFormDialog;
