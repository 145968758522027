import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

class ResizeObserver extends Component {
    constructor(...args) {
        super(...args);

        this.element = null;
        this.updateElement = this.updateElement.bind(this);
        this.emitResizeEvent = this.emitResizeEvent.bind(this);
    }

    updateElement(element) {
        this.element = element;
    }

    emitResizeEvent() {
        const rect = {
            width: this.element.offsetWidth,
            height: this.element.offsetHeight,
        };

        this.props.onResize(rect);
    }

    componentDidMount() {
        this.context.window.addEventListener('resize', this.emitResizeEvent);
        this.emitResizeEvent();
    }

    componentWillUnmount() {
        this.context.window.removeEventListener('resize', this.emitResizeEvent);
    }

    addRefListenerToProps(originalProps) {
        return R.assoc('innerRef', this.updateElement, originalProps);
    }

    render() {
        const child = Children.only(this.props.children);
        const newProps = this.addRefListenerToProps(child.props);
        const clonedChild = React.cloneElement(child, newProps);

        return clonedChild;
    }
}

ResizeObserver.contextTypes = {
    window: PropTypes.object,
};

export default ResizeObserver;
