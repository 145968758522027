import { Component, Fragment } from 'react';
import glamorous from 'glamorous';
import h from 'react-hyperscript';
import PropTypes from 'prop-types';

import {
    xsmallDistance,
    smallDistance,
    mediumDistance,
} from '../../styles/distances';
import { applyOnTabletAndUp } from '../../styles/mediaQueries';
import fetchPicture from '../../fetchers/fetchPicture';
import fetchUnpublishedPictures from '../../fetchers/fetchUnpublishedPictures';
import HideableControl from './HideableControl';
import Metadata from './Metadata';
import ResponsivePicture from './ResponsivePicture';

const ResizeContainer = glamorous.div({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
});

const MetadataContainer = glamorous(HideableControl)(
    {
        alignItems: 'flex-end',
        backgroundImage:
            'linear-gradient(to bottom, transparent 70%, rgba(0, 0, 0, 0.5), #000)',
        bottom: 0,
        display: 'flex',
        left: 0,
        padding: `0 ${smallDistance} ${xsmallDistance}`,
        position: 'absolute',
        right: 0,
        top: mediumDistance,

        [applyOnTabletAndUp]: {
            padding: `0 ${mediumDistance} ${smallDistance}`,
        },
    },
    ({ isHidden }) => ({
        transform: isHidden ? 'translateY(30%)' : null,
    }),
);

class PictureWithMetadata extends Component {
    constructor(props) {
        super(props);

        this.state = {
            metadata: null,
        };
    }

    async fetchMetadata() {
        const metadata = await fetchPicture(this.context, this.props.pictureId);
        if (metadata) {
            return metadata;
        }

        const unpublishedPictures = await fetchUnpublishedPictures(
            this.context,
        );
        if (unpublishedPictures) {
            return unpublishedPictures.items.find(
                (item) => item.id === this.props.pictureId,
            );
        }

        return null;
    }

    async componentDidMount() {
        if (!this.props.metadata) {
            const metadata = await this.fetchMetadata();
            this.setState({
                metadata,
            });
        }
    }

    render() {
        const { areControlsHidden, pictureId } = this.props;
        const metadata = this.props.metadata || this.state.metadata;

        return h(Fragment, [
            h(ResizeContainer, [h(ResponsivePicture, { pictureId })]),
            metadata
                ? h(
                      MetadataContainer,
                      {
                          isHidden: areControlsHidden,
                      },
                      h(Metadata, { metadata }),
                  )
                : null,
        ]);
    }
}

PictureWithMetadata.contextTypes = {
    config: PropTypes.object,
    fetch: PropTypes.func,
};

export default PictureWithMetadata;
