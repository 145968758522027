import breakpoints from './breakpoints';
import { pxToMediaQueryEm } from './unitConverter';

const mobileMaxWidth = pxToMediaQueryEm(breakpoints.tablet - 1);
const tabletMinWidth = pxToMediaQueryEm(breakpoints.tablet);
const tabletMaxWidth = pxToMediaQueryEm(breakpoints.desktop - 1);
const desktopMinWidth = pxToMediaQueryEm(breakpoints.desktop);

const applyOnMobile = `@media (max-width: ${mobileMaxWidth})`;
const applyOnTablet = `@media (min-width: ${tabletMinWidth}) and (max-width: ${tabletMaxWidth})`;
const applyOnDesktop = `@media (min-width: ${desktopMinWidth})`;

const applyOnTabletAndUp = `@media (min-width: ${tabletMinWidth})`;
const applyOnDesktopAndUp = `@media (min-width: ${desktopMinWidth})`;

export {
    applyOnMobile,
    applyOnTablet,
    applyOnDesktop,
    applyOnTabletAndUp,
    applyOnDesktopAndUp,
};
