import h from 'react-hyperscript';
import glamorous from 'glamorous';
import PropTypes from 'prop-types';
import { useState } from 'react';

import JourneyDetails from './JourneyDetails';
import FlightDetails from './FlightDetails';
import ToggleIcon from '../ToggleIcon';
import useBookingContext from '../../lib/useBookingContext';
import { pxToRem } from '../../../../styles/unitConverter';
import { loudFont, secondaryFont, FONTS } from '../../../../styles/fonts';
import {
    black,
    blue,
    blue20,
    gray20,
    gray90,
    white,
} from '../../../../styles/waveColors';
import { smallDistance, xsmallDistance } from '../../../../styles/distances';
import { getFormattedDate } from '../../lib/dateFormatting';

const Box = glamorous.div({
    borderRadius: pxToRem(4),
    border: `1px solid ${gray90}`,
    boxSizing: 'border-box',
    overflow: 'hidden',
});

const Title = glamorous.h4({
    ...secondaryFont.UITextLLoud,
    textAlign: 'center',
    color: black,
    paddingTop: xsmallDistance,
    margin: 0,
});
Title.displayName = 'Title';

const NoDates = glamorous.div({
    textAlign: 'center',
    color: gray20,
    marginTop: pxToRem(4),
});
NoDates.displayName = 'NoDates';

const FlightsTitle = glamorous.div({
    background: white,
    textAlign: 'center',
    padding: `${smallDistance} ${pxToRem(16)}`,
});
FlightsTitle.displayName = 'FlightsTitle';

const ExpandButton = glamorous.button({
    width: '100%',
    ...loudFont,
    fontFamily: FONTS.primary,
    letterSpacing: pxToRem(0.4),
    textTransform: 'uppercase',
    padding: `${pxToRem(18)} 0`,
    textAlign: 'center',
    color: blue,
    border: '1px solid transparent',
    borderBottomLeftRadius: pxToRem(4),
    borderBottomRightRadius: pxToRem(4),
    borderTop: `1px solid ${gray90}`,
    backgroundColor: 'transparent',
    ':hover': {
        color: blue20,
    },

    ':focus-visible': {
        color: blue20,
        outline: '2px solid -webkit-focus-ring-color',
        outlineOffset: '-2px',
    },
});
ExpandButton.displayName = 'ExpandButton';

const Details = glamorous.div({
    borderTop: `1px solid ${gray90}`,
    padding: smallDistance,
});
Details.displayName = 'Details';

const hasFlightDates = (flight) => flight.departure.date && flight.arrival.date;

const getTitle = (firstFlight, flightsName) => {
    const titleParts = [flightsName];
    if (firstFlight.date) {
        titleParts.push(getFormattedDate(firstFlight.date));
    }
    return titleParts.join(' ');
};

const Journey = (
    { eventLabel, flights, flightsName, icon, isBookingActive },
    { config: { assetsPath } },
) => {
    const [isExpanded, setExpanded] = useState(false);

    const hasAllFlightDates = flights.every(hasFlightDates);
    const { trackEventWithBooking } = useBookingContext();

    const firstFlight = flights[0].departure;
    const lastFlight = flights[flights.length - 1].arrival;

    const expandOrCollapse = () => {
        setExpanded(!isExpanded);
        trackEventWithBooking({
            event: 'event',
            eventCategory: 'mybooking',
            eventAction: isExpanded ? 'collapseFlights' : 'expandFlights',
            eventLabel,
        });
    };

    return h(Box, 'flight', [
        h(FlightsTitle, [
            h('img', { src: `${assetsPath}/myBookings/${icon}`, alt: '' }),
            h(Title, getTitle(firstFlight, flightsName)),
            !hasAllFlightDates &&
                h(
                    NoDates,
                    `Flugdaten sind ${isBookingActive ? 'noch ' : ''}nicht verfügbar`,
                ),
        ]),
        isExpanded
            ? h(Details, [h(JourneyDetails, { flights, assetsPath })])
            : h(Details, [
                  h(FlightDetails, {
                      departure: firstFlight,
                      arrival: lastFlight,
                      stops: flights.length - 1,
                  }),
              ]),
        hasAllFlightDates &&
            h(
                ExpandButton,
                { onClick: expandOrCollapse, 'aria-expanded': isExpanded },
                [
                    h(
                        'span',
                        isExpanded ? 'Ausblenden' : 'Flug- & Gepäckdetails',
                    ),
                    h(ToggleIcon, { isExpanded }),
                ],
            ),
    ]);
};

const flightTimeAndPlacePropType = PropTypes.shape({
    date: PropTypes.string,
    airportCode: PropTypes.string.isRequired,
    airportName: PropTypes.string,
});

Journey.propTypes = {
    flights: PropTypes.arrayOf(
        PropTypes.shape({
            departure: flightTimeAndPlacePropType.isRequired,
            arrival: flightTimeAndPlacePropType.isRequired,
            airlineInformation: PropTypes.shape({
                airlineName: PropTypes.string.isRequired,
            }),
            airlineCode: PropTypes.string,
            flightNumber: PropTypes.string,
        }),
    ).isRequired,
    flightsName: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    eventLabel: PropTypes.string.isRequired,
};

Journey.contextTypes = {
    config: PropTypes.shape({
        assetsPath: PropTypes.string.isRequired,
    }).isRequired,
};

export default Journey;
