import { Component } from 'react';
import PropTypes from 'prop-types';
import h from 'react-hyperscript';
import glamorous from 'glamorous';
import * as R from 'ramda';

import fetchHotelReviews from '../../fetchers/fetchHotelReviews';
import LoadingButton from '../loading-button/LoadingButton';
import DownArrowLine from '../icons/DownArrowLine';
import TransitionScreen from '../navigation/TransitionScreen';
import ReviewItem from '../reviews/ReviewItem';
import NoReviewsScreen from '../reviews/NoReviewsScreen';
import Counter from '../Counter';
import Grid from '../grid/Grid';
import Row from '../grid/Row';
import { xsmallDistance, xxlargeDistance } from '../../styles/distances';
import fetchUnpublishedReviews from '../../fetchers/fetchUnpublishedReviews';

const NUMBER_OF_REVIEWS_PER_REQUEST = 20;

const StyledGrid = glamorous(Grid)({
    paddingBottom: xxlargeDistance,
});

const OuterReviewContainer = glamorous.div({
    paddingTop: xsmallDistance,
});

const StyledLoadingButton = glamorous.div({
    paddingBottom: `${xxlargeDistance}`,
});

const concatSortReviews = (publishedReviews, unpublishedReviews) => {
    const allReviews = R.concat(publishedReviews, unpublishedReviews);
    return R.sort(R.descend(R.prop('travelDate')))(allReviews);
};

const filterConcatSortReviews = (hotelReviews, unpublishedHotelReviews) => {
    const hasSameOrMostRecentTravelDate = (unpublishedReview, item) => {
        return R.gte(
            R.prop('travelDate', unpublishedReview),
            R.prop('travelDate', item),
        );
    };
    const reviewsFilter = (unpublishedReview) =>
        hasSameOrMostRecentTravelDate(unpublishedReview, R.last(hotelReviews));
    const filterdUnpublishedReviews = R.filter(reviewsFilter)(
        unpublishedHotelReviews,
    );

    return concatSortReviews(hotelReviews, filterdUnpublishedReviews);
};

const filterMergeSortReviews = (
    hotelReviews,
    unpublishedHotelReviews,
    hasAllReviewsLoaded,
) =>
    R.ifElse(
        R.identity,
        () => concatSortReviews(hotelReviews, unpublishedHotelReviews),
        () => filterConcatSortReviews(hotelReviews, unpublishedHotelReviews),
    )(hasAllReviewsLoaded);

const getAllReviews = (
    hotelReviews,
    unpublishedHotelReviews,
    hasAllReviewsLoaded,
) => {
    const hasUnpublishedReviews = unpublishedHotelReviews.length;
    const hasPublishedReviews = hotelReviews.length;
    const shouldFilterMergeSortReviews =
        hasPublishedReviews && hasUnpublishedReviews;
    return shouldFilterMergeSortReviews
        ? filterMergeSortReviews(
              hotelReviews,
              unpublishedHotelReviews,
              hasAllReviewsLoaded,
          )
        : R.concat(hotelReviews, unpublishedHotelReviews);
};

const checkForNoReviews = (totalNumberOfReviews, unpublishedHotelReviews) => {
    return totalNumberOfReviews < 1 && !unpublishedHotelReviews.length;
};

class ContentHotelReviews extends Component {
    constructor(...args) {
        super(...args);
        this.state = {
            hotelReviews: [],
            unpublishedHotelReviews: [],
            totalNumberOfReviews: null,
            isLoading: true,
        };
    }

    async fetchReviews() {
        this.setState({ isLoading: true });
        const hotelReviews = await fetchHotelReviews(
            this.context,
            this.state.hotelReviews.length,
            NUMBER_OF_REVIEWS_PER_REQUEST,
        );

        const { items: unpublishedHotelReviews } =
            await fetchUnpublishedReviews(this.context);

        if (hotelReviews || unpublishedHotelReviews) {
            this.setState({
                isLoading: false,
                hotelReviews: this.state.hotelReviews.concat(
                    hotelReviews.items,
                ),
                unpublishedHotelReviews,
                totalNumberOfReviews: hotelReviews.total,
            });
        }
    }

    async componentDidMount() {
        await this.fetchReviews();
    }

    renderNoReviewsScreens(totalNumberOfReviews) {
        if (totalNumberOfReviews === null) {
            return h(TransitionScreen);
        }

        return h(Grid, [h(NoReviewsScreen)]);
    }

    render() {
        const {
            hotelReviews,
            isLoading,
            totalNumberOfReviews,
            unpublishedHotelReviews,
        } = this.state;
        const noReviews = checkForNoReviews(
            totalNumberOfReviews,
            unpublishedHotelReviews,
        );

        if (noReviews) {
            return this.renderNoReviewsScreens(totalNumberOfReviews);
        }
        const hasAllReviewsLoaded =
            hotelReviews.length === totalNumberOfReviews;
        const reviews = getAllReviews(
            hotelReviews,
            unpublishedHotelReviews,
            hasAllReviewsLoaded,
        );

        return h(OuterReviewContainer, [
            h(Grid, [
                h(Counter, {
                    count: totalNumberOfReviews,
                    label: {
                        singular: 'veröffentlichte Bewertung',
                        plural: 'veröffentlichte Bewertungen',
                    },
                }),
            ]),
            h(
                StyledGrid,
                reviews.map((review) =>
                    h(Row, null, h(ReviewItem, { review })),
                ),
            ),
            !hasAllReviewsLoaded &&
                h(
                    StyledLoadingButton,
                    null,
                    h(
                        LoadingButton,
                        {
                            isLoading,
                            onClick: this.fetchReviews.bind(this),
                            icon: DownArrowLine,
                        },
                        'Weitere Bewertungen anzeigen',
                    ),
                ),
        ]);
    }
}

ContentHotelReviews.contextTypes = {
    config: PropTypes.object,
    fetch: PropTypes.func,
    window: PropTypes.object,
};

export default ContentHotelReviews;
