import h from 'react-hyperscript';
import PropTypes from 'prop-types';

import RedirectWithReload from './navigation/RedirectWithReload';

const hasPassedAccessControl = ({
    isAuthorizationRequired,
    isLoggedIn,
    isAuthorized,
}) => {
    const authorizationGranted = isAuthorizationRequired && isAuthorized;

    return isLoggedIn && (!isAuthorizationRequired || authorizationGranted);
};

function AuthenticatedRoute(
    { isAuthorizationRequired, children },
    { isLoggedIn, isAuthorized, location },
) {
    if (
        hasPassedAccessControl({
            isAuthorizationRequired,
            isLoggedIn,
            isAuthorized,
        })
    ) {
        return children;
    }

    const { pathname, search, hash } = location;
    return h(RedirectWithReload, {
        to: `/login?ref=${encodeURIComponent(`${pathname}${search}${hash}`)}&messageId=hintForHighRiskAction`,
    });
}

AuthenticatedRoute.contextTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    isAuthorized: PropTypes.bool.isRequired,
};

AuthenticatedRoute.defaultProps = {
    isAuthorizationRequired: false,
};

export default AuthenticatedRoute;
