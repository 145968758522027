const extractOriginalTotalAmount = (tourOperatorPrice) =>
    tourOperatorPrice.amount;
const extractOriginalDeposit = (tourOperatorPrice) => tourOperatorPrice.deposit;
const extractOriginalDepositAmount = (tourOperatorPrice) => {
    const originalDeposit = extractOriginalDeposit(tourOperatorPrice);

    return originalDeposit ? originalDeposit.amount : null;
};
const extractDepositDueDate = (tourOperatorPrice) =>
    extractOriginalDeposit(tourOperatorPrice).dueDate;
const extractFinalDueDate = (tourOperatorPrice) => tourOperatorPrice.dueDate;
const calculateOriginalFinalAmount = (tourOperatorPrice) =>
    extractOriginalTotalAmount(tourOperatorPrice) -
    extractOriginalDepositAmount(tourOperatorPrice);

const hasOriginalDeposit = (tourOperatorPrice) =>
    extractOriginalDeposit(tourOperatorPrice) &&
    extractOriginalDepositAmount(tourOperatorPrice) !== 0;

const isUpdatedTourOperatorPriceAvailable = (updatedTourOperatorPrice) =>
    Boolean(updatedTourOperatorPrice);

const extractUpdatedDeposit = (updatedTourOperatorPrice) =>
    isUpdatedTourOperatorPriceAvailable(updatedTourOperatorPrice) &&
    updatedTourOperatorPrice.remainingDeposit;
const extractUpdatedFinal = (updatedTourOperatorPrice) =>
    isUpdatedTourOperatorPriceAvailable(updatedTourOperatorPrice) &&
    updatedTourOperatorPrice.remainingFinal;

const isDepositFullyPaid = (
    originalTourOperatorPrice,
    updatedTourOperatorPrice,
) => {
    return (
        hasOriginalDeposit(originalTourOperatorPrice) &&
        isUpdatedTourOperatorPriceAvailable(updatedTourOperatorPrice) &&
        updatedTourOperatorPrice.remainingDeposit === 0
    );
};

const isDepositOverdue = (
    tourOperatorPrice,
    updatedTourOperatorPrice,
    getCurrentDate,
) =>
    isUpdatedTourOperatorPriceAvailable(updatedTourOperatorPrice) &&
    Boolean(extractOriginalDeposit(tourOperatorPrice)) &&
    !isDepositFullyPaid(tourOperatorPrice, updatedTourOperatorPrice) &&
    extractDepositDueDate(tourOperatorPrice) &&
    new Date(extractDepositDueDate(tourOperatorPrice)) < getCurrentDate();

const isFinalOverdue = (
    tourOperatorPrice,
    updatedTourOperatorPrice,
    getCurrentDate,
) =>
    isUpdatedTourOperatorPriceAvailable(updatedTourOperatorPrice) &&
    Boolean(extractFinalDueDate(tourOperatorPrice)) &&
    new Date(extractFinalDueDate(tourOperatorPrice)) < getCurrentDate();

const isOverdue = ({
    tourOperatorPrice,
    updatedTourOperatorPrice,
    getCurrentDate,
}) =>
    isDepositOverdue(
        tourOperatorPrice,
        updatedTourOperatorPrice,
        getCurrentDate,
    ) ||
    isFinalOverdue(tourOperatorPrice, updatedTourOperatorPrice, getCurrentDate);

const formatInstallmentText = (isPaymentOverdue, text) => {
    return isPaymentOverdue ? `Offene ${text}` : text;
};

const getDisplayDepositAmount = (tourOperatorPrice, updatedTourOperatorPrice) =>
    extractUpdatedDeposit(updatedTourOperatorPrice) ||
    extractOriginalDepositAmount(tourOperatorPrice);

const getDisplayFinalAmount = (tourOperatorPrice, updatedTourOperatorPrice) =>
    extractUpdatedFinal(updatedTourOperatorPrice) ||
    calculateOriginalFinalAmount(tourOperatorPrice);

export {
    hasOriginalDeposit,
    isDepositOverdue,
    isFinalOverdue,
    isOverdue,
    isDepositFullyPaid,
    formatInstallmentText,
    getDisplayDepositAmount,
    getDisplayFinalAmount,
    extractOriginalDeposit,
};
